import { useEffect, useState, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';
import {
  checkACTTestcondition,
  checkAPTestCondition,
  checkCLEPTestCondition,
  checkGenaralTestCondition,
  checkIBTestCondition,
} from 'screen/common/helper/checkFieldsMissingCondition';

interface ModalCheckFieldProps {
  show?: boolean;
  onClose: () => void;
  data: any;
  page: string;
}

export const ModalCheckTestFields = (props: ModalCheckFieldProps) => {
  const { show, onClose, data, page } = props;
  const [filedsFail, setFieldsFail] = useState<any[]>([]);

  const requiredFields = useMemo(() => {
    switch (page) {
      case 'genaral': {
        return checkGenaralTestCondition(data);
      }

      case 'sat&act': {
        return checkACTTestcondition(data);
      }

      case 'ap': {
        return checkAPTestCondition(data);
      }

      case 'ib': {
        return checkIBTestCondition(data);
      }

      case 'clep': {
        return checkCLEPTestCondition(data);
      }

      default:
        return [];
    }
  }, [data]);

  const checkRequiredFields = (data: any) => {
    const result: any = [];

    requiredFields.forEach((field) => {
      try {
        // Parse path với regex để xử lý cả mảng
        const path = field.key;
        let value = data;
        let valid = true;

        console.log('object');
        if (page === 'genaral' && path === 'is_wish_self_report_scores') {
          if (
           ( data?.is_wish_self_report_scores === true || 
            data?.is_wish_self_report_scores === 'true'
           ) &&
            !(
              data?.all_tests_wish_report_array?.is_SAT ||
              data?.all_tests_wish_report_array?.is_ACT ||
              data?.all_tests_wish_report_array?.is_AP_Subject ||
              data?.all_tests_wish_report_array?.is_IB_Subject ||
              data?.all_tests_wish_report_array?.is_CLEP ||
              data?.all_tests_wish_report_array?.is_TOEFL ||
              data?.all_tests_wish_report_array?.is_PTE ||
              data?.all_tests_wish_report_array?.is_IELTS ||
              data?.all_tests_wish_report_array?.is_DuoLingo
            )
          ) {
            result.push({
              key: 'sumary',
              name: 'There is not any test selected. You need to select one test at least',
              page: '/application/general-info?check=1',
            });
          }
        }

        if (page === 'sat&act' && path === 'satTest.highest_evidence_reading_writing_score') {
          if (
            !data?.satTest?.highest_evidence_reading_writing_score ||
            data?.satTest?.highest_evidence_reading_writing_score === '' ||
            data?.satTest?.highest_evidence_reading_writing_score === '0' ||
            data?.satTest?.highest_evidence_reading_writing_score === 0
          ) {
            result.push(field);
          }
        }

        if (
          page === 'sat&act' &&
          path === 'satTest.highest_math_score_based_evidence_reading_writing_score'
        ) {
          if (
            !data?.satTest?.highest_math_score_based_evidence_reading_writing_score ||
            data?.satTest?.highest_math_score_based_evidence_reading_writing_score === '' ||
            data?.satTest?.highest_math_score_based_evidence_reading_writing_score === '0' ||
            data?.satTest?.highest_math_score_based_evidence_reading_writing_score === 0
          ) {
            result.push(field);
          }
        }

        if (!path.includes('.') && !path.includes('[')) {
          // Trường hợp đơn giản nhất - không có cấu trúc lồng nhau
          if (!data[path] && typeof data[path] !== 'boolean') {
            result.push(field);
          }
          return; // Tiếp tục với field tiếp theo
        }

        // Regex để xử lý cả mẫu như: a.b[0].c
        const parts = path.match(/\w+|\[\d+\]/g);

        if (parts) {
          for (const part of parts) {
            if (part.startsWith('[') && part.endsWith(']')) {
              // Đây là một phần tử mảng
              const index = parseInt(part.slice(1, -1));
              if (!value || !Array.isArray(value) || value.length <= index) {
                valid = false;
                break;
              }
              value = value[index];
            } else {
              // Đây là thuộc tính thông thường
              if (!value || typeof value !== 'object') {
                valid = false;
                break;
              }
              value = value[part];
            }
          }
        }

        if (!valid || value === undefined || value === null || value === '') {
          if (typeof value !== 'boolean') {
            result.push(field);
          }
        }
      } catch (error) {
        // Nếu có lỗi trong quá trình truy cập, coi như field không hợp lệ
        result.push(field);
      }
    });

    setFieldsFail(result);
  };

  useEffect(() => {
    checkRequiredFields(data);
  }, [data, requiredFields, page]);

  const history = useHistory();

  const handleClick = (field: any) => {
    history.push(field.page);
    onClose();
  };

  return (
    <Modal show={show} onHide={() => onClose()}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {data && filedsFail.length > 0 ? (
          <div>
            <h3>These fields are not yet valid, please complete them: </h3>
            <ul style={{ fontSize: '1rem', color: 'red' }}>
              {filedsFail.map((field, index) => (
                <li key={index} onClick={() => handleClick(field)} style={{ cursor: 'pointer' }}>
                  {field.name}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div>
            <h3>All required fields are filled</h3>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
