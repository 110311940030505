import DatePickerInput from 'components/input/DatePicker';
import GroupRadioButton from 'components/input/GroupRadioButton';
import SelectInput from 'components/input/SelectInput';
import TextInput from 'components/input/textInput';
import React from 'react';
import { Col } from 'react-bootstrap';
import GroupCheckbox from 'components/input/GroupCheckbox';
import { progressionHighSchool } from 'components/helper/helper';
import TextAreaInput from 'components/input/TextAreaInput';
interface Props {
  formikGraduatingSchool: any;
  highSchoolOptions: any[];
  check?: any;
}

const GraduatingSchool = (props: Props) => {
  const { check } = props;
  const [highSchoolOptions, setHighSchoolOptions] = React.useState<any[]>([]);
  React.useEffect(() => {
    const values = props.highSchoolOptions.map((item) => {
      return { value: item?.high_school_name || item?.fullName };
    });

    let list = values.filter((item: any) => item.value);
    list.push({
      value:
        'I did not finish high school but instead took a test (such as GED or CHSPE) to earn high school equivalency.',
    });
    setHighSchoolOptions(list);
  }, [props.highSchoolOptions]);
  return (
    <>
      <Col xs={12} className="text-dart fs-5 p-1 mt-3">
        Graduation Status
      </Col>
      <GroupRadioButton
        mdCol={12}
        logo="span-csu-logo"
        name="graduation_status"
        values={props.formikGraduatingSchool.values.graduation_status}
        onChange={(e: any) => {
          props.formikGraduatingSchool.handleChange(e);
          if (e.target.value === 'I will not be receiving a high school diploma or equivalent') {
            props.formikGraduatingSchool.setFieldValue('graduating_date_received', null);
          }
        }}
        label="Select your anticipated or current graduation status from high school or equivalent.*"
        options={[
          {
            value: 'I have or will graduate with my high school diploma',
            extendElement: (
              <DatePickerInput
                logo="span-csu-logo"
                label="Date Received/Expected*"
                isFloatRightLogo={true}
                name="graduating_date_received"
                values={props.formikGraduatingSchool.values.graduating_date_received}
                onChange={(e) =>
                  props.formikGraduatingSchool.setFieldValue('graduating_date_received', e)
                }
                isMissing={!props.formikGraduatingSchool.values.graduating_date_received && check}
              />
            ),
          },
          {
            value: 'I have or will have a high school equivalency',
            extendElement: (
              <DatePickerInput
                logo="span-csu-logo"
                label="Date Received/Expected*"
                isFloatRightLogo={true}
                name="graduating_date_received"
                values={props.formikGraduatingSchool.values.graduating_date_received}
                onChange={(e) =>
                  props.formikGraduatingSchool.setFieldValue('graduating_date_received', e)
                }
                isMissing={!props.formikGraduatingSchool.values.graduating_date_received && check}
              />
            ),
          },
          { value: 'I will not be receiving a high school diploma or equivalent' },
        ]}
        isMissing={!props.formikGraduatingSchool.values.graduation_status && check}
      />

      <GroupCheckbox
        label="Please indicate if any of these options will have affected your progression through or
                since secondary/high school. Check all that apply.*"
        logo="span-cm-logo"
        options={progressionHighSchool}
        form={props.formikGraduatingSchool}
        mdCol={6}
        isMissing={
          (
            !props.formikGraduatingSchool.values.progression_is_graduate_early &&
            !props.formikGraduatingSchool.values.progression_is_graduate_late &&
            !props.formikGraduatingSchool.values.progression_is_time_off &&
            !props.formikGraduatingSchool.values.progression_is_take_gap_year &&
            !props.formikGraduatingSchool.values.progression_is_no_change
          ) && check
        }
      />

      {(props.formikGraduatingSchool.values.progression_is_graduate_early ||
        props.formikGraduatingSchool.values.progression_is_graduate_late ||
        props.formikGraduatingSchool.values.progression_is_time_off ||
        props.formikGraduatingSchool.values.progression_is_take_gap_year) && (
          <>
            <TextAreaInput
              mdCol="12"
              logo="span-cm-logo"
              label="Please use the space below to provide details about the change in progression through secondary/high school that you indicated above.*"
              name="change_in_progression_through_highschool"
              values={props.formikGraduatingSchool.values.change_in_progression_through_highschool}
              onChange={props.formikGraduatingSchool.handleChange}
              countWord={250}
              isMissing={!props.formikGraduatingSchool.values.change_in_progression_through_highschool && check}

            />
          </>
        )}

      {props.formikGraduatingSchool.values.graduation_status ===
        'I have or will graduate with my high school diploma' && (
          <>
            <Col xs={12}>Graduating School</Col>
            <GroupRadioButton
              logo="span-csu-logo"
              label="Please select your graduating high school.*"
              mdCol={12}
              options={highSchoolOptions}
              name="graduating_high_school"
              values={props.formikGraduatingSchool.values.graduating_high_school}
              onChange={props.formikGraduatingSchool.handleChange}
              isMissing={!props.formikGraduatingSchool.values.graduating_high_school && check}
            />
          </>
        )}

      <SelectInput
        option={['9th', '10th', '11th', '12th', 'Post-12th Year', 'Already Graduated']}
        className="uc-common-flag"
        label="Most recent academic year *"
        name="graduating_school_year"
        values={props.formikGraduatingSchool.values.graduating_school_year}
        onChange={props.formikGraduatingSchool.handleChange}
        isMissing={!props.formikGraduatingSchool.values.graduating_school_year && check}
      />
      <SelectInput
        logo="span-cm-logo"
        form={props.formikGraduatingSchool}
        label="GPA Weighting*"
        isSatTest={true}
        option={[{ value: 'Weighted' }, { value: 'Unweighted' }]}
        name="graduating_school_gpa_type"
        values={props.formikGraduatingSchool.values.graduating_school_gpa_type}
        onChange={props.formikGraduatingSchool.handleChange}
        isMissing={!props.formikGraduatingSchool.values.graduating_school_gpa_type && check}
      />
      <SelectInput
        logo="span-cm-logo"
        form={props.formikGraduatingSchool}
        label="GPA Scale*"
        isSatTest={true}
        option={[
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '100',
          'None',
        ]}
        name="graduating_school_gpa_scale"
        values={props.formikGraduatingSchool.values.graduating_school_gpa_scale}
        onChange={props.formikGraduatingSchool.handleChange}
        isMissing={!props.formikGraduatingSchool.values.graduating_school_gpa_scale && check}
      />
      <TextInput
        type="number"
        decimalStep={0.001}
        max={99999}
        logo="span-cm-logo"
        label="Cumulative GPA*"
        isTestCsuLogo={true}
        name="graduating_school_cumulative_gpa"
        values={props.formikGraduatingSchool.values.graduating_school_cumulative_gpa}
        onChange={props.formikGraduatingSchool.handleChange}
        isMissing={!props.formikGraduatingSchool.values.graduating_school_cumulative_gpa && check}
      />
      {props.formikGraduatingSchool.values.graduating_school_gpa_scale === 'Other' && (
        <TextInput
          label=" GPA scale other *"
          logo="span-cm-logo"
          name="gpa_scale_other"
          values={props.formikGraduatingSchool.values.gpa_scale_other}
          onChange={props.formikGraduatingSchool.handleChange}
          countText={60}
          mdCol={'12'}
        />
      )}
      <SelectInput
        logo="span-cm-logo"
        form={props.formikGraduatingSchool}
        label="Class rank reporting*"
        isSatTest={true}
        isMissing={!props.formikGraduatingSchool.values.graduating_school_rank_report && check}
        option={[
          { value: 'Exact' },
          { value: 'Decile' },
          { value: 'Quintile' },
          { value: 'Quartile' },
          { value: 'None' },
        ]}
        name="graduating_school_rank_report"
        values={props.formikGraduatingSchool.values.graduating_school_rank_report}
        onChange={(e) => {
          props.formikGraduatingSchool.handleChange(e);
          props.formikGraduatingSchool.setFieldValue('class_rank', '');
        }}
        feedback={<>Only report if taken directly from your transcript</>}
      />
      {props.formikGraduatingSchool.values.graduating_school_rank_report === 'Exact' && (
        <TextInput
          label="Class Rank*"
          isTestCsuLogo={true}
          type="number"
          max={99999}
          name="class_rank"
          logo="span-cm-logo"
          values={props.formikGraduatingSchool.values.class_rank}
          onChange={props.formikGraduatingSchool.handleChange}
          isMissing={!props.formikGraduatingSchool.values.class_rank && check}
        />
      )}
      {props.formikGraduatingSchool.values.graduating_school_rank_report === 'Decile' && (
        <SelectInput
          option={[
            'Top 10%',
            'Top 20%',
            'Top 30%',
            'Top 40%',
            'Top 50%',
            'Top 60%',
            'Top 70%',
            'Top 80%',
            'Top 90%',
            'Top 100%',
          ]}
          label="Decile rank*"
          name="class_rank"
          logo="span-cm-logo"
          values={props.formikGraduatingSchool.values.class_rank}
          onChange={props.formikGraduatingSchool.handleChange}
          isMissing={!props.formikGraduatingSchool.values.class_rank && check}
        />
      )}
      {props.formikGraduatingSchool.values.graduating_school_rank_report === 'Quintile' && (
        <GroupRadioButton
          options={['Top 20%', 'Top 40%', 'Top 60%', 'Top 80%', 'Top 100%']}
          label="Quintile rank*"
          name="class_rank"
          logo="span-cm-logo"
          values={props.formikGraduatingSchool.values.class_rank}
          onChange={props.formikGraduatingSchool.handleChange}
          onClear={() => props.formikGraduatingSchool.setFieldValue('class_rank', '')}
          isMissing={!props.formikGraduatingSchool.values.class_rank && check}
        />
      )}
      {props.formikGraduatingSchool.values.graduating_school_rank_report === 'Quartile' && (
        <GroupRadioButton
          options={['Top 25%', 'Top 50%', 'Top 75%', 'Top 100%']}
          label="Quintile rank*"
          name="class_rank"
          logo="span-cm-logo"
          values={props.formikGraduatingSchool.values.class_rank}
          onChange={props.formikGraduatingSchool.handleChange}
          onClear={() => props.formikGraduatingSchool.setFieldValue('class_rank', '')}
          isMissing={!props.formikGraduatingSchool.values.class_rank && check}
        />
      )}
      <GroupRadioButton
        mdCol={4}
        logo="span-cm-logo"
        options={['Weighted', 'Unweighted']}
        label="How are the grades for this class reported on your transcript?*"
        name="rank_weighting"
        values={props.formikGraduatingSchool.values.rank_weighting}
        onChange={props.formikGraduatingSchool.handleChange}
        onClear={() => props.formikGraduatingSchool.setFieldValue('rank_weighting', '')}
        isMissing={!props.formikGraduatingSchool.values.rank_weighting && check}
      />
      <TextInput
        logo="span-cm-logo"
        label="Graduating Class Size (approx.)*"
        isTestCsuLogo={true}
        type="number"
        max={99999}
        name="graduating_school_class_size"
        values={props.formikGraduatingSchool.values.graduating_school_class_size}
        onChange={props.formikGraduatingSchool.handleChange}
        isMissing={!props.formikGraduatingSchool.values.graduating_school_class_size && check}
      />
    </>
  );
};

export default GraduatingSchool;
