import { useEffect, useState, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';
import {
  bassicInformationCondition,
  checkCBOCondition,
  checkCitizenshipCondition,
  checkDemographicsCondition,
  checkOtherCondition,
} from './helper/checkFieldsMissingCondition';

interface ModalCheckFieldProps {
  show?: boolean;
  onClose: () => void;
  data: any;
  page: string;
}

export const ModalCheckField = (props: ModalCheckFieldProps) => {
  const { show, onClose, data, page } = props;
  const [filedsFail, setFieldsFail] = useState<any[]>([]);

  const requiredFields = useMemo(() => {
    switch (page) {
      case 'basic-information': {
        return bassicInformationCondition(data);
      }
      case 'demographics': {
        return checkDemographicsCondition(data);
      }
      case 'citizenship': {
        return checkCitizenshipCondition(data);
      }
      case 'cbo': {
        return checkCBOCondition(data);
      }
      case 'other': {
        return checkOtherCondition(data);
      }
      default:
        return [];
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const checkRequiredFields = (data: any) => {
    const result: any = [];

    requiredFields.forEach((field) => {
      if (page === 'basic-information') {
        if (field.key === 'sex') {
          if (
            !data?.sex?.isFemale &&
            !data?.sex?.isMale &&
            !data?.sex?.isNonbinary &&
            !data?.sex?.isAnother
          ) {
            result.push(field);
          }
        }

        if (field.key === 'csu_info.gender_identity' && data?.csu_info?.gender_identity === 'Select') {
          result.push(field);
        }
      }

      if (page === 'demographics' && field.key === 'all_apply_array') {
        if (
          !data?.all_apply_array?.is_foster_care &&
          !data?.all_apply_array?.is_have_children &&
          !data?.all_apply_array?.is_married_or_in_civil_union &&
          !data?.all_apply_array?.is_none_above_apply_to_me &&
          !data?.all_apply_array?.is_will_be_current_member
        ) {
          result.push(field);
        }

        if (
          data?.is_consider_hispanic_latino &&
          !data?.best_group_describe_racial_background_array?.value_consider_hispanic_latino
        ) {
          result.push({
            key: 'best_group_describe_racial_background_array.value_consider_hispanic_latino',
            name: 'Which best describes your Hispanic or Latino background',
            page: '/application/demographics',
          });
        }

        if (
          !data?.best_group_describe_racial_background_array
            ?.racial_background_identify_AfricanAmerican &&
          !data?.best_group_describe_racial_background_array?.racial_background_identify_White &&
          !data?.best_group_describe_racial_background_array?.racial_background_identify_Asian &&
          !data?.best_group_describe_racial_background_array
            ?.racial_background_identify_Southwest_Asian_and_North_African &&
          !data?.best_group_describe_racial_background_array
            ?.racial_background_identify_NativeHawaiian &&
          !data?.best_group_describe_racial_background_array
            ?.racial_background_identify_AmericanIndian
        ) {
          result.push({
            key: 'identify_racial_background',
            name: 'Regardless of your answer to the prior question, please indicate how you identify yourself',
            page: '/application/demographics',
          });
        }

        if (data?.language_array && Array.isArray(data.language_array)) {
          data.language_array.forEach((langObj: any, index: any) => {
            if (!langObj.language) {
              result.push({
                key: `language-${index + 1}`,
                name: `Language data at option ${index + 1}`,
                page: '/application/demographics',
              });
            }
            if (
              !langObj.isFirstLanguage &&
              !langObj.isSpeak &&
              !langObj.isRead &&
              !langObj.isWrite
            ) {
              result.push({
                key: `language_proficiency-${index + 1}`,
                name: `Select your Language Proficiency options ${index + 1}`,
                page: '/application/demographics',
              });
            }
          });
        }
      }

      if (page === 'citizenship') {
        if (
          data?.list_citizenship &&
          Array.isArray(data.list_citizenship) &&
          !data.list_citizenship.length &&
          field.key === 'list_citizenship'
        ) {
          result.push(field);
        }
      }

      if (page === 'cbo' && field.key === 'is_financial_qualify_fee_waiver') {
        if (data?.cbo_info_array && Array.isArray(data.cbo_info_array)) {
          data.cbo_info_array.forEach((langObj: any, index: any) => {
            if (Object.keys(langObj).length === 0) {
              result.push({
                key: `organization-${index + 1}`,
                name: `Organization from which you received the most help option ${index + 1}`,
                page: '/application/cbo-and-fee-waiver?check=1',
              });
            }
          });
        }
      }

      if (!field.key.includes('.')) {
        if (!data[field.key]) {
          result.push(field);
        }
      } else {
        const fieldArray = field.key.split('.');
        let temp = data;
        fieldArray.forEach((item) => {
          if (temp && temp[item]) {
            temp = temp[item];
          } else {
            result.push(field);
          }
        });
      }
    });

    setFieldsFail(result);
  };

  useEffect(() => {
    if (page === 'additional-info') {
      let result:any[] = checkRequiredFields_AdditionInfo(data);
      setFieldsFail(result);
    }
    else {
      checkRequiredFields(data);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, requiredFields, page]);

  const history = useHistory();

  const handleClick = (field: any) => {
    history.push(field.page);
    onClose();
  };

  return (
    <Modal show={show} onHide={() => onClose()}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {data && filedsFail.length > 0 ? (
          <div>
            <h3>These fields are not yet valid, please complete them: </h3>
            <ul style={{ fontSize: '1rem', color: 'red' }}>
              {filedsFail.map((field, index) => (
                <li key={index} onClick={() => handleClick(field)} style={{ cursor: 'pointer' }}>
                  {field.name}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div>
            <h3>All required fields are filled</h3>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};


export const checkRequiredFields_AdditionInfo = (data:any) =>{   
  const result:any[] = [];
  try {
    if (!data.wish_to_share_option) {
      result.push({
        key: 'wish_to_share_option',
        name: 'Do you wish to share anything on this topic?*',
        page: '/application/additional-info/?check=1#wish_to_share_option'
      });
    }
    if (data.wish_to_share_option === 'true' || data.wish_to_share_option === true) {
      if (!data.additional_comment_3) {
        result.push({
          key: 'additional_comment_3',
          name: 'Please use this space to describe how these events have impacted you.*',
          page: '/application/additional-info/?check=1#additional_comment_3'
        });
      }
    }
    if (!data.wish_to_provide_detail_of_circumstances_option) {
      result.push({
        key: 'wish_to_provide_detail_of_circumstances_option',
        name: 'Do you wish to provide details of circumstances or qualifications not reflected in the application?*',
        page: '/application/additional-info/?check=1#wish_to_provide_detail_of_circumstances_option'
      });
    }
  
    if (data.wish_to_provide_detail_of_circumstances_option === 'true' || data.wish_to_provide_detail_of_circumstances_option === true) {
      if (!data.additional_comment_1) {
        result.push({
          key: 'additional_comment_1',
          name: 'Additional Information',
          page: '/application/additional-info/?check=1#additional_comment_1',
        });
      }
    }
 
    return result;
  }
  catch(ex){
    console.log('Error:', ex);
  }
  return result;
 }
