/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import YesNoSwitch from 'components/input/YesNoSwitch';
import { getGeneral, updateGeneral } from 'app/course/courseSlice';
import CheckboxInput from 'components/input/CheckboxInput';
import { BoolToString, checkEnableParseJSON, formatBoolean } from 'components/helper/helper';
import TextInput from 'components/input/textInput';
import SelectInput from 'components/input/SelectInput';
import { debounce } from 'lodash';
import MiddleCourseWork, {
  TAdditionalInfoCourse,
  initialValuesMiddleCourseWork,
} from './components/MiddleCourseWork';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { getCollegeData, getHighSchoolData } from 'app/highSchoolSlice/highSchoolSlice';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { ExpandMore } from 'screen/activitiesAndHonors/Activities';
import { scrollToObject } from 'components/helper/ui_helper';
interface Props {}

const GeneralInfo = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const [expandAll, setExpandAll] = React.useState(false);
  const [expandActive, setExpandActive] = React.useState<number>(0);

  const course = useAppSelector((store) => store.course);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const query = new URLSearchParams(location.search);
  const check = query.get('check');

  const formik = useFormik({
    initialValues: {
      is_able_obtain_copy_transcript: '',
      is_counselor_submit_transcript: false,
      is_transcript_show_grade_completed: '',
      is_counselor_submit_transcript_2: false,
      is_take_high_school_math_in_grade_7_or_8: '',
      take_high_school_math_array: '',
      is_take_high_school_english_in_grade_7_or_8: '',
      take_high_school_english_array: '',
      is_attend_school_outside_us_6_through_8: '',
      language_instruction: '',
      specify_language_instruction: '',
      number_of_course_to_report: '',
      course_scheduling_system_is_using: '',
      course_array: '',
      additional_info: {
        completed_high_school_outside_US: '',
        high_school_academic_performance: '',
        is_take_course_in_middle_school: '',
        is_have_course_issued_official_transcript_grades: '',
        degree_or_diploma_name: '',
        course_work: [],
      } as TAdditionalInfoCourse,
    },
    onSubmit: async (values) => {
      if (token && studentId) {
        try {
          let additional_info: any = values.additional_info;

          if (additional_info && additional_info.course_work) {
            // fix grades[] and credits[]
            for (let i = 0; i < additional_info.course_work.length; i++) {
              if (additional_info.course_work[i]?.schedule_transcript === 'One final Grade') {
                additional_info.course_work[i].grades = [];
                additional_info.course_work[i].credits = [];
              } else if (
                additional_info.course_work[i]?.schedule_transcript === 'One grade per term'
              ) {
                additional_info.course_work[i].final_grade = '';
                additional_info.course_work[i].final_credit = '';
              }
            }
          }

          const newValues = {
            ...values,
            student_id: studentId,
            is_able_obtain_copy_transcript: formatBoolean(values.is_able_obtain_copy_transcript),
            is_transcript_show_grade_completed: formatBoolean(
              values.is_transcript_show_grade_completed
            ),
            is_take_high_school_math_in_grade_7_or_8: formatBoolean(
              values.is_take_high_school_math_in_grade_7_or_8
            ),
            is_take_high_school_english_in_grade_7_or_8: formatBoolean(
              values.is_take_high_school_english_in_grade_7_or_8
            ),

            is_attend_school_outside_us_6_through_8: formatBoolean(
              values.is_attend_school_outside_us_6_through_8
            ),

            additional_info: JSON.stringify(additional_info),
          };

          await dispatch(updateGeneral(newValues)).unwrap();
        } catch (ex) {
          console.log('Error:', ex);
        }
      } else {
        handleLogout();
      }
    },
  });
  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = () => {
    formik.handleSubmit();
    history.push(routers.grade9.path);
  };

  const AutoScrollAndExpand = () => {
    const expandDiv = (divId:string) => {
      let div = document.getElementById(divId);
      if (div) {
        if (div.getAttribute('aria-expanded') === 'false') {
         div.click();
        }
      }
    }
    try {
      const hash = location.hash.slice(1); // Remove the '#' character from the hash
      if (hash) {
        if (hash==='is_able_obtain_copy_transcript') {
          setTimeout(() => {
            scrollToObject(hash, 0);
          }, 1500);
        } else
        if (hash==='is_counselor_submit_transcript') {
          setTimeout(() => {
            scrollToObject(hash, 100);
          }, 1500);
        } else
        if (hash==='is_transcript_show_grade_completed') {
          setTimeout(() => {
            scrollToObject(hash, 100);
          }, 1500);
        } else
        if (hash==='is_counselor_submit_transcript_2') {
          setTimeout(() => {
            scrollToObject(hash, 100);
          }, 1500);
        } else
        if (hash==='is_take_course_in_middle_school') {
          setTimeout(() => {
            scrollToObject(hash, 100);
          }, 1500);
        } else
        if (hash==='is_have_course_issued_official_transcript_grades') {
          setTimeout(() => {
            scrollToObject(hash, 100);
          }, 1500);
        } else
        if (hash==='is_attend_school_outside_us_6_through_8') {
          setTimeout(() => {
            scrollToObject(hash, 100);
          }, 1500);
        } else
        if (hash==='language_instruction') {
          setTimeout(() => {
            scrollToObject(hash, 100);
          }, 1500);
        } else
        if (hash==='specify_language_instruction') {
          setTimeout(() => {
            scrollToObject(hash, 100);
          }, 1500);
        } else
        if (hash==='completed_high_school_outside_US') {
          setTimeout(() => {
            scrollToObject(hash, 100);
          }, 1500);
        } else
        if (hash==='high_school_academic_performance') {
          setTimeout(() => {
            scrollToObject(hash, 100);
          }, 1500);
        } else
        if (hash==='degree_or_diploma_name') {
          setTimeout(() => {
            scrollToObject(hash, 100);
          }, 1500);
        } else 

        if (hash.includes("school_")) {
          let str = hash.replaceAll("school_","");
          let itemIndex = Number(str);
          setTimeout(() => {
            expandDiv(`midCoureItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
 
          }, 2000);
        } else
        if (hash.includes("gradeLevel_")) {
          let str = hash.replaceAll("gradeLevel_","");
          let itemIndex = Number(str);
          setTimeout(() => {
            expandDiv(`midCoureItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
 
          }, 2000);
        } else
        if (hash.includes("schoolYear_")) {
          let str = hash.replaceAll("schoolYear_","");
          let itemIndex = Number(str);
          setTimeout(() => {
            expandDiv(`midCoureItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
 
          }, 2000);
        } else
        if (hash.includes("gradeScale_")) {
          let str = hash.replaceAll("gradeScale_","");
          let itemIndex = Number(str);
          setTimeout(() => {
            expandDiv(`midCoureItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
 
          }, 2000);
        } else
        if (hash.includes("termPeriod_")) {
          let str = hash.replaceAll("termPeriod_","");
          let itemIndex = Number(str);
          setTimeout(() => {
            expandDiv(`midCoureItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
 
          }, 2000);
        } else
        if (hash.includes("schedule_")) {
          let str = hash.replaceAll("schedule_","");
          let itemIndex = Number(str);
          setTimeout(() => {
            expandDiv(`midCoureItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
 
          }, 2000);
        } else
        if (hash.includes("AGsubjectRequirement_")) {
          let str = hash.replaceAll("AGsubjectRequirement_","");
          let itemIndex = Number(str);
          setTimeout(() => {
            expandDiv(`midCoureItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
 
          }, 2000);
        } else
        if (hash.includes("subjectDiscipline_")) {
          let str = hash.replaceAll("subjectDiscipline_","");
          let itemIndex = Number(str);
          setTimeout(() => {
            expandDiv(`midCoureItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
 
          }, 2000);
        } else
        if (hash.includes("courseNameforUC_")) {
          let str = hash.replaceAll("courseNameforUC_","");
          let itemIndex = Number(str);
          setTimeout(() => {
            expandDiv(`midCoureItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
 
          }, 2000);
        } else
        if (hash.includes("courseName_")) {
          let str = hash.replaceAll("courseName_","");
          let itemIndex = Number(str);
          setTimeout(() => {
            expandDiv(`midCoureItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
 
          }, 2000);
        } else
        if (hash.includes("languageNameforUC_")) {
          let str = hash.replaceAll("languageNameforUC_","");
          let itemIndex = Number(str);
          setTimeout(() => {
            expandDiv(`midCoureItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
 
          }, 2000);
        } else
        if (hash.includes("courseCategory_")) {
          let str = hash.replaceAll("courseCategory_","");
          let itemIndex = Number(str);
          setTimeout(() => {
            expandDiv(`midCoureItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
 
          }, 2000);
        } else
        if (hash.includes("typeOfClass_")) {
          let str = hash.replaceAll("typeOfClass_","");
          let itemIndex = Number(str);
          setTimeout(() => {
            expandDiv(`midCoureItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
 
          }, 2000);
        } else
        if (hash.includes("college_")) {
          let str = hash.replaceAll("college_","");
          let itemIndex = Number(str);
          setTimeout(() => {
            expandDiv(`midCoureItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
 
          }, 2000);
        } else
        if (hash.includes("takeSemester_")) {
          let str = hash.replaceAll("takeSemester_","");
          let itemIndex = Number(str);
          setTimeout(() => {
            expandDiv(`midCoureItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
           }, 2000);
        } else
        if (hash.includes("scheduleTranscript_")) {
          let str = hash.replaceAll("scheduleTranscript_","");
          let itemIndex = Number(str);
          setTimeout(() => {
            expandDiv(`midCoureItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
           }, 2000);
        } else

        if (hash.includes("grade_")) {
          let str = hash.replaceAll("grade_","");
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`midCoureItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
           }, 2000);
        } else
        if (hash.includes("credits_")) {
          let str = hash.replaceAll("credits_","");
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`midCoureItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
           }, 2000);
        } else
        if (hash.includes("finalGrade_")) {
          let str = hash.replaceAll("finalGrade_","");
          let itemIndex = Number(str);
          setTimeout(() => {
            expandDiv(`midCoureItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
           }, 2000);
        } else
        if (hash.includes("finalCredits_")) {
          let str = hash.replaceAll("finalCredits_","");
          let itemIndex = Number(str);
          setTimeout(() => {
            expandDiv(`midCoureItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
           }, 2000);
        } 

      } 
    }
    catch(ex){
      console.log('Error:', ex);
    }
  }

  const getDetail = async () => {
    if (token && studentId) {
      try {
        setLoading(true);
        const res = await dispatch(getGeneral(studentId)).unwrap();
        if (res) {
          formik.resetForm({
            values: {
              ...res,
              additional_info: checkEnableParseJSON(res.additional_info),
              is_able_obtain_copy_transcript: BoolToString(
                res.is_able_obtain_copy_transcript
              ),
              is_transcript_show_grade_completed: BoolToString(
                res.is_transcript_show_grade_completed
              ),
              is_take_high_school_english_in_grade_7_or_8: BoolToString(
                res.is_take_high_school_english_in_grade_7_or_8
              ),
              is_take_high_school_math_in_grade_7_or_8: BoolToString(
                res.is_take_high_school_math_in_grade_7_or_8
              ),
              is_attend_school_outside_us_6_through_8: BoolToString(
                res.is_attend_school_outside_us_6_through_8
              ),
            },
          });
        }

        await dispatch(getCollegeData(studentId)).unwrap();
        await dispatch(getHighSchoolData(studentId)).unwrap();
        setLoading(false);
        AutoScrollAndExpand();
      } catch (ex) {
        console.log('Error: ', ex);
        setLoading(false);
      }
    }
  };
  React.useEffect(() => {
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const hash = location.hash.slice(1); // Remove the '#' character from the hash
    if (hash) {
      AutoScrollAndExpand();
    }
  }, [location]);

  const handleExpandAll = () => {
    setExpandAll(!expandAll);
    if (!expandAll) {
      if (formik.values.additional_info?.course_work && formik.values.additional_info?.course_work.length) {
        setExpandActive(formik.values.additional_info?.course_work?.length);
      }
    } else {
      setExpandActive(0);
    }
  };

  const handleExpandActive = (value: any) => {
    value
      ? setExpandActive(Math.abs(expandActive - 1))
      : setExpandActive(Math.abs(expandActive + 1));
  };

  React.useEffect(() => {
    if (formik.values.additional_info?.course_work && formik.values.additional_info?.course_work.length) {
      if (expandActive === formik.values.additional_info?.course_work.length) {
        setExpandAll(true);
      }
    }

    if (expandActive === 0) {
      setExpandAll(false);
    }
  }, [expandActive, formik.values.additional_info?.course_work?.length]);

  const handleAddCourse = () => {
    if (formik.values.additional_info?.course_work?.length > 5) return;
    formik.setFieldValue(
      'additional_info.course_work',
      formik.values.additional_info?.course_work
        ? [...formik.values.additional_info?.course_work, initialValuesMiddleCourseWork]
        : [initialValuesMiddleCourseWork]
    );
  };

  // Auto save function ==========
  React.useEffect(() => {
    formik.dirty && debounceSaving();
  }, [formik.values]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (!loading) formik.handleSubmit();
    }, 1500),
    []
  );
  // Auto save function ==========
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[
          { percentOnProcess: 25, title: 'Declarations' },
          { percentOnProcess: 50, title: 'Academic History' },
          { percentOnProcess: 75, title: 'International School Experience' },
        ]}
        title="Courses & Grades"
        process={1}
        icon={<StackedLineChartIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <Row className="m-0">
              <div className="text-field-mid fs-3 mb-4" id="Declarations">
                General Info
              </div>
              <YesNoSwitch
                id="is_able_obtain_copy_transcript"
                label="I am able to obtain a copy of my transcript(s)*"
                className="common-flag"
                name="is_able_obtain_copy_transcript"
                values={formik.values.is_able_obtain_copy_transcript}
                onChange={formik.handleChange}
                isMissing={!formik.values.is_able_obtain_copy_transcript && check ? true : false}
              />
              {formik.values.is_able_obtain_copy_transcript === 'false' && (
                <div className="w-100 bg-light p-3 common-flag">
                  <div className="text-field-light m-3">
                    Based on your resspanonses, you are not required to complete this section.
                    Colleges who are listed above as requiring this information will not penalize
                    you. Please have your counselor submit your transcripts as per their usual
                    methods*
                  </div>
                  <div className="m-3">
                    <CheckboxInput
                      id="is_counselor_submit_transcript"
                      name="is_counselor_submit_transcript"
                      values={formik.values.is_counselor_submit_transcript}
                      onChange={formik.handleChange}
                      label="I understand"
                      isMissing={!formik.values.is_counselor_submit_transcript && check ? true : false}
                    />
                  </div>
                </div>
              )}
              <YesNoSwitch
                id="is_transcript_show_grade_completed"
                label="My transcript shows grades for courses I've completed*"
                className="common-flag my-3"
                name="is_transcript_show_grade_completed"
                values={formik.values.is_transcript_show_grade_completed}
                onChange={formik.handleChange}
                isMissing={!formik.values.is_transcript_show_grade_completed && check ? true : false}
              />
              {formik.values.is_transcript_show_grade_completed === 'false' && (
                <div className="w-100 bg-light p-3 common-flag">
                  <div className="text-field-light m-3">
                    Based on your responses, you are not required to complete this section. Colleges
                    who are listed above as requiring this information will not penalize you. Please
                    have your counselor submit your transcripts as per their usual methods*
                  </div>
                  <div className="m-3">
                    <CheckboxInput
                      id="is_counselor_submit_transcript_2"
                      name="is_counselor_submit_transcript_2"
                      values={formik.values.is_counselor_submit_transcript_2}
                      onChange={formik.handleChange}
                      label="I understand"
                      isMissing={!formik.values.is_counselor_submit_transcript_2 && check ? true : false}
                    />
                  </div>
                </div>
              )}
              <div className="border w-100 my-3" id="AcademicHistory" />
              <div className="text-dark fs-5 p-1 mb-3 uc-flag push-flag-up">
                Middle School - Academic History
              </div>
              <div className="text-field-light">
                Mathematics or foreign language courses are common middle courses that count toward
                your high school requirements.
              </div>
              <div className="text-field-light">
                If any of your middle school courses are listed on your transcript, be sure to list
                them here, too.
              </div>
              <div className="text-field-light">
                Note: On the college applications, you do not need to list the courses under your
                middle school name, they will be listed under your high school
              </div>
              <YesNoSwitch
                id="is_take_course_in_middle_school"
                label="Did you take any course(s) in middle school that fulfilled your high school subject requirements?"
                logo="span-uc-csu-logo"
                className="my-3"
                name="additional_info.is_take_course_in_middle_school"
                values={formik.values.additional_info?.is_take_course_in_middle_school}
                onChange={formik.handleChange}
                isMissing={!formik.values.additional_info?.is_take_course_in_middle_school && check ? true : false}
              />
              {formik.values.additional_info?.is_take_course_in_middle_school === 'true' && (
                <>
                  <YesNoSwitch
                    id="is_have_course_issued_official_transcript_grades"
                    logo="span-cm-logo"
                    label="Do you have middle school courses listed on your transcript that have been issued official transcript grades?*"
                    className="mb-3"
                    name="additional_info.is_have_course_issued_official_transcript_grades"
                    values={formik.values.additional_info?.is_have_course_issued_official_transcript_grades}
                    onChange={formik.handleChange}
                    isMissing={!formik.values.additional_info?.is_have_course_issued_official_transcript_grades && check ? true : false}
                  />
                  <div className="my-3 fs-5">Middle School Coursework</div>

                  {formik.values.additional_info?.course_work && formik.values.additional_info?.course_work.length > 0 && (
                    <div className="d-flex justify-content-end">
                      <ExpandMore
                        aria-expanded={expandAll}
                        aria-label="show all"
                        onClick={handleExpandAll}
                      >
                        {!expandAll ? (
                          <KeyboardDoubleArrowDownIcon />
                        ) : (
                          <KeyboardDoubleArrowUpIcon />
                        )}
                      </ExpandMore>
                    </div>
                  )}
                  {(formik.values.additional_info?.course_work || []).map((item, index) => (
                    <div key={index} className="position-relative">
                      <MiddleCourseWork 
                        expandAll={expandAll}
                        handleExpandActive={handleExpandActive}
                        formik={formik} 
                        index={index} 
                      />
                      <RemoveCircleOutlineIcon
                        className="position-absolute text-field-light pointer"
                        style={{ right: '-24px', top: '30px' }}
                        onClick={() => {
                          const newCourseData = [...formik.values.additional_info?.course_work];
                          newCourseData.splice(index, 1);
                          formik.setFieldValue('additional_info.course_work', newCourseData);
                        }}
                      />
                    </div>
                  ))}
                  <div className="my-3 w-100 d-flex flex-column align-items-center">
                    <div className="text-field-light sized70">You can add up to 6 courses</div>
                    <Button variant="primary" className="p-2 px-5" onClick={handleAddCourse}>
                      Add another Course
                    </Button>
                  </div>
                </>
              )}
              <div id="InternationalSchoolExperience" />
              <div className="fs-5 p-1 py-3">International school experience</div>
              <YesNoSwitch
                id="is_attend_school_outside_us_6_through_8"
                label="Did you attend school outside the United States for any part of 6th through 8th grade?"
                className="span-uc-logo"
                name="is_attend_school_outside_us_6_through_8"
                values={formik.values.is_attend_school_outside_us_6_through_8}
                onChange={formik.handleChange}
                isMissing={!formik.values.is_attend_school_outside_us_6_through_8 && check ? true : false}
              />
              {formik.values.is_attend_school_outside_us_6_through_8 === 'true' && (
                <>
                  <SelectInput
                    id="language_instruction"
                    option={[
                      '- Select -',
                      'Arabic',
                      'Armenian',
                      'Bengali',
                      'Chinese',
                      'English',
                      'French',
                      'German',
                      'Greek',
                      'Hebrew',
                      'Hindi/Urdu',
                      'Hmong',
                      'Hungarian',
                      'Italian',
                      'Japanese',
                      'Italian',
                      'Lao',
                      'Malay',
                      'Mon-Khmer',
                      'Navajo',
                      'Persian(Farsi)',
                      'Polish',
                      'Portugese',
                      'Punjabi',
                      'Russian',
                      'Spanish',
                      'Tagalog',
                      'Thai',
                      'Turkish',
                      'Vietnamese',
                      'Other',
                    ]}
                    className="uc-flag"
                    label="What was the language of instruction? *"
                    name="language_instruction"
                    values={formik.values.language_instruction}
                    onChange={formik.handleChange}
                    isMissing={!formik.values.language_instruction && check ? true : false}
                  />
                  {formik.values.language_instruction === 'Other' && (
                    <TextInput
                      id="specify_language_instruction"
                      label="Specify the Language"
                      name="specify_language_instruction"
                      values={formik.values.specify_language_instruction}
                      onChange={formik.handleChange}
                      countText={30}
                      isMissing={!formik.values.specify_language_instruction && check ? true : false}
                    />
                  )}
                </>
              )}
              <YesNoSwitch
                id="completed_high_school_outside_US"
                label="Did you complete high school/secondary school outside of the United States? *"
                className="span-csu-logo py-3"
                name="additional_info.completed_high_school_outside_US"
                values={formik.values.additional_info?.completed_high_school_outside_US}
                onChange={formik.handleChange}
                isMissing={!formik.values.additional_info?.completed_high_school_outside_US && check ? true : false}
              />
              {formik.values.additional_info?.completed_high_school_outside_US === 'true' && (
                <>
                  <TextInput
                    id="high_school_academic_performance"
                    label="Academic Performance (High School GPA, Standing, Percentage, etc.)*"
                    countText={49}
                    mdCol={12}
                    name="additional_info.high_school_academic_performance"
                    values={formik.values.additional_info?.high_school_academic_performance}
                    onChange={formik.handleChange}
                    isMissing={!formik.values.additional_info?.high_school_academic_performance && check ? true : false}
                  />

                  <TextInput
                    id="degree_or_diploma_name"
                    label="Name of Degree/Diploma (e.g. Diploma, A-Level, Abitur, Certificado)*"
                    name="additional_info.degree_or_diploma_name"
                    countText={49}
                    values={formik.values.additional_info?.degree_or_diploma_name}
                    onChange={formik.handleChange}
                    mdCol={12}
                    isMissing={!formik.values.additional_info?.degree_or_diploma_name && check ? true : false}
                  />
                </>
              )}
            </Row>
            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {course.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default GeneralInfo;
