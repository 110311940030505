/* eslint-disable react-hooks/exhaustive-deps */
import { emailRegex, phoneRegExp } from 'components/helper/appConfig';
import React from 'react';
import { Col, Form } from 'react-bootstrap';

interface Props {
  id?: string;
  hidden?: boolean;
  mdCol?: string | number;
  label?: string;
  name: string;
  values: any;
  onChange?: (e: any) => void;
  isValid?: boolean;
  feedback?: any;
  placeholder?: string;
  disabled?: boolean;
  countText?: number;
  type?: string;
  min?: number;
  max?: number;
  className?: string;
  decimalStep?: number;
  form?: any;
  logo?: string;
  otherProps?: any;
  isOverCount?: boolean;
  countWord?: number;
  readOnly?: boolean;
  setLabelHight?: boolean;
  isMissing?: any;
  isCMLogo?: boolean;
  isTestCsuLogo?: boolean;
  isCuScuLogo?: boolean;
}

const TextInput = (props: Props) => {
  const [counter, setCounter] = React.useState(0);
  const [phoneValidation, setPhoneValidation] = React.useState<string | undefined>();
  const [counterWord, setCounterWord] = React.useState(0);

  const getInfo = () => {
    const wordCount = ((props?.type === 'string' && props.values) || '')
      .split(/\s+/)
      .filter(Boolean).length;
    if (props.values?.length && props.countText) {
      setCounter(props.values.length ?? 0);
    } else if (props.countWord) {
      setCounterWord(wordCount ?? 0);
    }
  };

  React.useEffect(() => {
    if (props.type === 'phoneNumber' && !phoneRegExp.test(props.values)) {
      setPhoneValidation('Phone number is not valid');
    } else if (props.type === 'email' && !emailRegex.test(props.values)) {
      setPhoneValidation('Email is not valid');
    } else {
      setPhoneValidation(undefined);
    }
    getInfo();
  }, [props.values]);

  const handleChange = async (e: any) => {
    if (!props.isOverCount && props.countText && e.target.value.length > props.countText) {
      return;
    }

    const wordCount = (e.target.value || '').split(/\s+/).filter(Boolean).length;
    if (!props.isOverCount && props.countWord && wordCount > props.countWord) return;

    // prevent add more space
    if (!props.isOverCount && props.countWord && wordCount === props.countWord) {
      if (e.target.value[e.target.value.length - 1] === ' ') return;
    }

    props.onChange && props.onChange(e);
    setCounter(e.target.value.length);
  };

  return props.hidden ? null : (
    <Form.Group
      name={props.name}
      className={`p-1 p-md-1 ${props.className ?? ''}`.trim()}
      as={Col}
      md={props.mdCol || '6'}
      id={props.id}
    >
      <div
        style={
          props.setLabelHight
            ? {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              minHeight: '40px',
            }
            : {}
        }
      >
        <Form.Label
          className={`label-14px `.trim()}
          style={
            props.setLabelHight
              ? {
                display: 'inline-block',
                margin: 0,
                marginBottom: '2px',
                backgroundColor: props.isMissing ? '#FFEBEB' : '',
              }
              : {
                display: 'inline-block',
                backgroundColor: props.isMissing ? '#FFEBEB' : '',
              }
          }
        >
          {props.label}

          {/* tách logo khỏi label in right */}
          {(props?.logo && props.setLabelHight) ? <Form.Label
            className={`csu-logo-select-input-float-right ${props?.logo ?? null}`.trim()}>
          </Form.Label> : null}

        </Form.Label>
        {(props?.logo && !props.setLabelHight && !props.isTestCsuLogo && !props.isCuScuLogo) ? <span className={`cm-logo-select-input-float-right ${props?.logo ?? null}`}></span> : null}
        {(props?.logo && props.isTestCsuLogo) ? <span className={`csu-logo-sat-text-float-right ${props?.logo ?? null}`}></span> : null}
        {(props?.logo && props.isCuScuLogo) ? <span className={`csu-logo-cu-csu-citizen-right ${props?.logo ?? null}`}></span> : null}
      </div>
      <Form.Control
        id={props.id}
        {...props.otherProps}
        step={props.decimalStep ?? 1}
        min={props.min || 0}
        max={props.max}
        disabled={props.disabled}
        placeholder={props.placeholder}
        type={props.type || 'text'}
        name={props.name}
        value={props.values ?? ''}
        maxLength={props.isOverCount ? undefined : props.countText}
        onChange={handleChange}
        isValid={props.isValid}
        onWheel={(e) => e.currentTarget.blur()}
        readOnly={props.readOnly}
      />

      {(props.feedback ||
        phoneValidation ||
        (!!props.max && props.type === 'number' && props.values > props.max) ||
        (!!props.min && props.type === 'number' && props.values < props.min) ||
        (props.countText && props.type !== 'number') ||
        (props.countWord && props.type !== 'number')) && (
          <div className="sized70 text-field-light w-100 d-flex justify-content-between mh-1rem">
            {props.feedback && <span>{props.feedback}</span>}
            {phoneValidation && <span className="text-danger">{phoneValidation}</span>}
            {!!props.max && props.type === 'number' && props.values > props.max && (
              <span className="text-danger">Invalid number</span>
            )}
            {!!props.min && props.type === 'number' && props.values < props.min && (
              <span className="text-danger">Invalid number</span>
            )}
            {props.countText && props.type !== 'number' && (
              <span title={`${counter}/${props.countText} characters`}>
                {counter}/{props.countText} characters
              </span>
            )}
            {props.countWord && props.type !== 'number' && (
              <span title={`${counterWord}/${props.countWord} words`}>
                {counterWord}/{props.countWord} words
              </span>
            )}
          </div>
        )}
    </Form.Group>
  );
};

export default TextInput;
