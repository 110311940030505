/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import YesNoSwitch from 'components/input/YesNoSwitch';
import SelectInput from 'components/input/SelectInput';
import TextInput from 'components/input/textInput';
import TemplateScore from './components/TemplateScore';
import {
  BoolToString,
  checkEnableParseJSON,
  formatBoolean,
  optionValues,
  optionValues2,
  setArrayLength,
} from 'components/helper/helper';
import DatePickerInput from 'components/input/DatePicker';
import { getACTTest, updateACTTest } from 'app/testingSlice/testingSlice';
import { debounce } from 'lodash';
import ACTItem from './components/ACTItem';

const ACTSampleItem = {
  test_date: '',
  composite_score: '',
  english: '',
  mathematics: '',
  reading: '',
  science: '',
  writing: '',
};

const ACTTest = () => {
  const [loading, setLoading] = React.useState(false);
  const testing = useAppSelector((store) => store.testing);
  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const query = new URLSearchParams(useLocation().search);
  const check = query.get('check');
  const checkIndex = query.get('index');
  const formik = useFormik({
    initialValues: {
      // New field
      act_id_number: 0,
      csu_info: {
        act_score: [],
      },
      is_have_act_score_report: '',
      have_taken_act_plus_writing_test: true,
      number_of_act_score_report: 0,
      superscore_calculated_by_act: 0,
      highest_composite_score: 0,
      highest_composite_score_sub: 0,
      highest_composite_test_day: '',
      highest_english_score: 0,
      highest_english_score_sub: 0,
      highest_english_test_day: '',
      highest_mathematic_score: 0,
      highest_mathematic_score_sub: 0,
      highest_mathematic_test_day: '',
      highest_reading_score: 0,
      highest_reading_score_sub: 0,
      highest_reading_test_day: '',
      highest_science_score: 0,
      highest_science_score_sub: 0,
      highest_science_test_day: '',
      highest_writing_score: 0,
      highest_writing_score_sub: 0,
      highest_writing_test_day: '',
      future_act_test_plan_to_take: 0,
      future_testing_date_1: {
        test1: '',
        test2: '',
        test3: '',
      },
    },
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        student_id: studentId,
        csu_info: JSON.stringify(values.csu_info),
        is_have_act_score_report: formatBoolean(values.is_have_act_score_report),
        have_taken_act_plus_writing_test: formatBoolean(values.have_taken_act_plus_writing_test),
        future_testing_date_1: JSON.stringify(values.future_testing_date_1),
      };
      await dispatch(updateACTTest(newValues));
    },
  });

  useEffect(() => {
    const actScores = formik.values.csu_info?.act_score || [];
    if (actScores.length === 0) return;

    let highestCompositeScore = 0;
    let highestCompositeTestDay = '';
    let highestEnglishScore = 0;
    let highestEnglishTestDay = '';
    let highestMathematicScore = 0;
    let highestMathematicTestDay = '';
    let highestReadingScore = 0;
    let highestReadingTestDay = '';
    let highestScienceScore = 0;
    let highestScienceTestDay = '';
    let highestWritingScore = 0;
    let highestWritingTestDay = '';

    actScores.forEach((item: any) => {
      const compositeScore = parseInt(item.composite_score) || 0;
      const englishScore = parseInt(item.english) || 0;
      const mathematicsScore = parseInt(item.mathematics) || 0;
      const readingScore = parseInt(item.reading) || 0;
      const scienceScore = parseInt(item.science) || 0;
      const writingScore = parseInt(item.writing) || 0;

      if (compositeScore > highestCompositeScore && item.test_date) {
        highestCompositeScore = compositeScore;
        highestCompositeTestDay = item.test_date;
      }

      if (englishScore > highestEnglishScore && item.test_date) {
        highestEnglishScore = englishScore;
        highestEnglishTestDay = item.test_date;
      }

      if (mathematicsScore > highestMathematicScore && item.test_date) {
        highestMathematicScore = mathematicsScore;
        highestMathematicTestDay = item.test_date;
      }

      if (readingScore > highestReadingScore && item.test_date) {
        highestReadingScore = readingScore;
        highestReadingTestDay = item.test_date;
      }

      if (scienceScore > highestScienceScore && item.test_date) {
        highestScienceScore = scienceScore;
        highestScienceTestDay = item.test_date;
      }

      if (writingScore > highestWritingScore && item.test_date && formik.values.is_have_act_score_report === 'true') {
        highestWritingScore = writingScore;
        highestWritingTestDay = item.test_date;
      }
    });

    formik.setFieldValue('highest_composite_score', highestCompositeScore);
    formik.setFieldValue('highest_composite_score_sub', highestCompositeScore);
    formik.setFieldValue('highest_composite_test_day', highestCompositeTestDay);

    formik.setFieldValue('highest_english_score', highestEnglishScore);
    formik.setFieldValue('highest_english_score_sub', highestEnglishScore);
    formik.setFieldValue('highest_english_test_day', highestEnglishTestDay);

    formik.setFieldValue('highest_mathematic_score', highestMathematicScore);
    formik.setFieldValue('highest_mathematic_score_sub', highestMathematicScore);
    formik.setFieldValue('highest_mathematic_test_day', highestMathematicTestDay);

    formik.setFieldValue('highest_reading_score', highestReadingScore);
    formik.setFieldValue('highest_reading_score_sub', highestReadingScore);
    formik.setFieldValue('highest_reading_test_day', highestReadingTestDay);

    formik.setFieldValue('highest_science_score', highestScienceScore);
    formik.setFieldValue('highest_science_score_sub', highestScienceScore);
    formik.setFieldValue('highest_science_test_day', highestScienceTestDay);

    if (formik.values.is_have_act_score_report === 'true') {
      formik.setFieldValue('highest_writing_score', highestWritingScore);
      formik.setFieldValue('highest_writing_score_sub', highestWritingScore);
      formik.setFieldValue('highest_writing_test_day', highestWritingTestDay);
    }

  }, [formik.values.csu_info?.act_score, formik.values.is_have_act_score_report]);

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = () => {
    formik.handleSubmit();
    const nextRoute = Object.keys(testing.roleTest).find(
      (key, index) =>
        index > Object.keys(testing.roleTest).indexOf('actTest') && testing.roleTest[key] === true
    );
    history.push(nextRoute ? routers?.[nextRoute]?.path : routers.otherTest.path);
  };

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      let res = await dispatch(getACTTest(studentId));
      const dataLoaded: any = res.payload;
      if (dataLoaded) {
        const values: any = {
          ...dataLoaded,
          csu_info: {
            ...formik.values.csu_info,
            ...checkEnableParseJSON(dataLoaded.csu_info),
          },
          is_have_act_score_report: BoolToString(dataLoaded.is_have_act_score_report),
          have_taken_act_plus_writing_test: BoolToString(
            dataLoaded.have_taken_act_plus_writing_test
          ),
          future_testing_date_1: checkEnableParseJSON(dataLoaded.future_testing_date_1, {
            test1: '',
            test2: '',
            test3: '',
          }),
        };
        formik.resetForm({ values: values });
      }
      setLoading(false);
    } else {
      handleLogout();
    }
  };

  React.useEffect(() => {
    getDetail();
  }, []);

  // Auto save function ==========
  React.useEffect(() => {
    formik.dirty && debounceSaving();
  }, [formik.values]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (!loading) formik.handleSubmit();
    }, 1500),
    []
  );
  // Auto save function ==========
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[
          { title: 'General', percentOnProcess: 25 },
          { title: 'Composite', percentOnProcess: 33 },
          { title: 'English', percentOnProcess: 41 },
          { title: 'Math', percentOnProcess: 50 },
          { title: 'Reading', percentOnProcess: 60 },
          { title: 'Science', percentOnProcess: 70 },
          { title: 'Writing', percentOnProcess: 80 },
          { title: 'Future ACT', percentOnProcess: 90 },
        ]}
        title="Standardized Testing"
        process={1}
        icon={<CalculateOutlinedIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <Row className="m-0" id="General">
              <div className="text-field-mid fs-3 mb-4">ACT Tests</div>

              <TextInput
                type="number"
                max={999999999}
                name="act_id_number"
                values={formik.values.act_id_number}
                onChange={formik.handleChange}
                label="ACT ID number"
              />
              <SelectInput
                className="common-flag end-flag"
                form={formik}
                option={optionValues(Array.from(Array(6).keys()))}
                label="Number of past ACT scores you wish to report*"
                name="number_of_act_score_report"
                values={formik.values.number_of_act_score_report}
                isMissing={formik.values.number_of_act_score_report === null && check}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldValue(
                    'csu_info.act_score',
                    setArrayLength(
                      formik.values.csu_info?.act_score ?? [],
                      e.target.value,
                      ACTSampleItem
                    )
                  );
                }}
              />
              <YesNoSwitch
                className="mb-3"
                label="Have you taken the ACT Plus Writing test?*"
                logo="span-cm-logo"
                name="is_have_act_score_report"
                values={formik.values.is_have_act_score_report}
                onChange={formik.handleChange}
                isMissing={!formik.values.is_have_act_score_report && check}
              />

              {(formik.values.csu_info?.act_score ?? []).map((item, index) => {
                return (
                  <div key={index}>
                    <ACTItem form={formik} index={index} check={check} checkIndex={checkIndex}/>
                  </div>
                );
              })}
              <div className="fs-5 p-1 span-cm-logo">Super Score</div>
              {/* <SelectInput
                option={optionValues2(37).reverse()}
                className="coallition-flag"
                mdCol="12"
                name="superscore_calculated_by_act"
                values={formik.values.superscore_calculated_by_act}
                onChange={formik.handleChange}
                label="Superscore: Provide the Superscore calculated by ACT from your score report; do not calculate your own Superscore."
              />*/}
              <div className="border w-100 my-3" id="Composite" /> 
              <TemplateScore
                formik={formik}
                highestLabel="Highest Composite Score*"
                highestValuesName="highest_composite_score"
                scoreValuesName="highest_composite_score_sub"
                dateValuesName="highest_composite_test_day"
                check={check}
                readOnly={true}
              />

              <TemplateScore
                formik={formik}
                highestLabel="Highest English Score*"
                highestValuesName="highest_english_score"
                scoreValuesName="highest_english_score_sub"
                dateValuesName="highest_english_test_day"
                check={check}
                readOnly={true}
              />

              <TemplateScore
                formik={formik}
                highestLabel="Highest Mathematics Score*"
                highestValuesName="highest_mathematic_score"
                scoreValuesName="highest_mathematic_score_sub"
                dateValuesName="highest_mathematic_test_day"
                check={check}
                readOnly={true}
              />

              <TemplateScore
                formik={formik}
                highestLabel="Highest Reading Score*"
                highestValuesName="highest_reading_score"
                scoreValuesName="highest_reading_score_sub"
                dateValuesName="highest_reading_test_day"
                check={check}
                readOnly={true}
              />

              <TemplateScore
                formik={formik}
                highestLabel="Highest Science Score*"
                highestValuesName="highest_science_score"
                scoreValuesName="highest_science_score_sub"
                dateValuesName="highest_science_test_day"
                check={check}
                readOnly={true}
              />

              {formik.values.is_have_act_score_report === 'true' && (
                <>
                  <TemplateScore
                    options={optionValues2(11, 2).reverse()}
                    formik={formik}
                    highestLabel="Highest Writing Score*"
                    highestValuesName="highest_writing_score"
                    scoreValuesName="highest_writing_score_sub"
                    dateValuesName="highest_writing_test_day"
                    check={check}
                    readOnly={true}
                  />
                </>
              )}
            </Row>

            <Row className="m-0">
              <div className="border w-100 my-3" id="FutureACT" />

              <div className="text-field-mid fs-5 p-1 mb-4">Future ACT Tests You Plan To Take?</div>
              <SelectInput
                className="common-coallition-flag"
                form={formik}
                option={optionValues(Array.from(Array(4).keys()))}
                label="Number of future ACT sittings you expect*"
                name="future_act_test_plan_to_take"
                values={formik.values.future_act_test_plan_to_take}
                onChange={formik.handleChange}
                isMissing={!formik.values.future_act_test_plan_to_take && check}
              />
              <div />
              {[1, '1', '2', '3', 2, 3].includes(formik.values.future_act_test_plan_to_take) && (
                <DatePickerInput
                  className="common-flag"
                  label={`Future testing date 1*`}
                  name="future_testing_date_1.test1"
                  values={formik.values?.future_testing_date_1?.test1}
                  onChange={(value) => formik.setFieldValue('future_testing_date_1.test1', value)}
                  isMissing={!formik.values?.future_testing_date_1?.test1 && check}
                />
              )}
              {['2', '3', 2, 3].includes(formik.values.future_act_test_plan_to_take) && (
                <DatePickerInput
                  className="common-flag end-flag"
                  label={`Future testing date 2*`}
                  name="future_testing_date_1.test2"
                  values={formik.values?.future_testing_date_1?.test2}
                  onChange={(value) => formik.setFieldValue('future_testing_date_1.test2', value)}
                  isMissing={!formik.values?.future_testing_date_1?.test2 && check}
                />
              )}
              {['3', 3].includes(formik.values.future_act_test_plan_to_take) && (
                <DatePickerInput
                  className="common-flag"
                  label={`Future testing date 3*`}
                  name="future_testing_date_1.test3"
                  values={formik.values?.future_testing_date_1?.test3}
                  onChange={(value) => formik.setFieldValue('future_testing_date_1.test3', value)}
                  isMissing={!formik.values?.future_testing_date_1?.test3 && check}
                />
              )}
            </Row>

            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {testing.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default ACTTest;