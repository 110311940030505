import { optionValues } from 'components/helper/helper';
import DatePickerInput from 'components/input/DatePicker';
import SelectInput from 'components/input/SelectInput';
import TextInput from 'components/input/textInput';
import moment from 'moment';
import React from 'react';

interface Props {
  formik: any;
  highestLabel: string;
  highestValuesName: string;
  scoreValuesName: string;
  dateValuesName: string;
  options?: any[];
  check?: any;
  readOnly?: boolean;
}

const TemplateScore = (props: Props) => {
  const { formik, highestLabel, highestValuesName, scoreValuesName, dateValuesName, options, check, readOnly = true } = props;

  return (
    <>
      <SelectInput
        form={formik}
        option={options ?? optionValues(Array.from(Array(37).keys()).reverse())}
        label={highestLabel}
        name={highestValuesName}
        onChange={formik.handleChange}
        values={formik.values[highestValuesName]}
        isMissing={(formik.values[highestValuesName] === '' || formik.values[highestValuesName] === null) && check}
        disabled={readOnly}
      />
      <TextInput
        className="d-none"
        label="Score*"
        type="number"
        max={36}
        mdCol="3"
        name={scoreValuesName}
        onChange={formik.handleChange}
        values={formik.values[scoreValuesName]}
        isMissing={!formik.values[scoreValuesName] && check}
        disabled={readOnly}
      />
      <DatePickerInput
        className="common-coallition-flag end-2-flag"
        label="Test Date*"
        name={dateValuesName ?? moment().format()}
        onChange={(values) => formik.setFieldValue(dateValuesName, values)}
        values={formik.values[dateValuesName]}
        isMissing={!formik.values[dateValuesName] && check}
        disabled={readOnly}
      />
    </>
  );
};

export default TemplateScore;