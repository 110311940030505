import * as React from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import NotFound from 'screen/notFound';
import AppLayout from './AppLayout';
import DashBoardLayout from './DashBoardLayout';
import ProtectedRoute from './ProtectedRoute';
import UserLayout from './UserLayout';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { get } from 'lodash';
import { routers } from './router';
import Button from 'react-bootstrap/esm/Button';
import { STRIPE_SUBSCRIPTION_STATUS } from 'constants/common';
import Cookies from 'js-cookie';
import { authenticateSettings } from 'components/helper/appConfig';
import { getStudent } from 'app/authorization/authorizationSlice';
import { getStripeSubscriptionInfo } from 'app/basicInformationSlice/basicInformationSlice';

const TrialExpiresDialog = ({ open, status, trialCount, studentId, studentEmail}: any) => {
  const history = useHistory();
  let title = ''; 
  switch (status) {
      case STRIPE_SUBSCRIPTION_STATUS.canceled:
        if (trialCount>0)
          title = "Sorry, it looks like your trial has ended. <br/>Please subscribe now to continue using GradMap!";
        else
          title = 'Your subscription has been canceled!';
        break;        
      case STRIPE_SUBSCRIPTION_STATUS.incomplete:
        title = 'Your subscription is incomplete!';
        break; 
      case STRIPE_SUBSCRIPTION_STATUS.incomplete_expired:
        title =  'Your subscription has expired!';
        break; 
      case STRIPE_SUBSCRIPTION_STATUS.past_due:
        title =  'Your subscription is past due!';
        break; 
      case STRIPE_SUBSCRIPTION_STATUS.paused:
        title =  'Your subscription is paused!';
        break; 
      case STRIPE_SUBSCRIPTION_STATUS.unpaid:
        title =  'Your trial has expired!';
        break; 
      default:
        title =  'Your subscription has expired!';
        break; 
  }

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>
        <img
          src={'/assets/images/logo-color.svg'}
          alt="GradMap logo"
          style={{ alignSelf: 'center' }}
          height={48}
          className="d-inline pointer"
        />
      </DialogTitle>
      <DialogContent>
        <div className="text-center py-5">
          <h4 dangerouslySetInnerHTML={{__html: title}}></h4>
        </div>
      </DialogContent>

      <DialogActions className="text-center">
        {(status==="canceled" && trialCount>0) ? (
          <Button
              onClick={() => {
                Cookies.set('request_id', studentId);
                Cookies.set('userEmail', studentEmail);
                history.push(routers.payment.path);
              }}
              variant="warning"
              className="p-2 px-5"
            >
              SUBCRIBE NOW
          </Button>
        ):
        (
          <Button
            onClick={() => {
              history.push(routers.settings.path + '?active-tab=5');
            }}
              variant="primary"
              className="p-2 px-5"
            >
            Go to billing
          </Button>
        )}       
      </DialogActions>
    </Dialog>
  );
};

const RouterConfig = () => {
  const [stripeStatus, setStripeStatus] = React.useState('');
  const [trialCount, setTrialCount] = React.useState(0);
  const [showDialog, setShowDialog] = React.useState(false);
  const [studentEmail, setStudentEmail] = React.useState('');
  const dispatch = useAppDispatch(); 
  let location = useLocation();
  const personalStore:any = useAppSelector((store:any) => store.basicInformation.myBasicInformation);
  const studentId = personalStore?.student_id;
  let token = Cookies.get(authenticateSettings.tokenName);
  let isLogin:boolean = token ? true : false;
  React.useEffect(() => {
    const getInfo = async() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      token = Cookies.get(authenticateSettings.tokenName);
      if (studentId && token) {
        const res = await dispatch(getStudent(studentId)).unwrap();
        setTrialCount(res?.trial_count ?? 0);
        setStudentEmail(res?.email ?? '');
        const subStripeInfo: any = await dispatch(getStripeSubscriptionInfo(personalStore?.student_id));
        let showDlg = false;
        if (subStripeInfo) {
          let st = get(subStripeInfo, 'payload.subcription_status', '');
          setStripeStatus(st); 
          showDlg = isLogin && 
            ![STRIPE_SUBSCRIPTION_STATUS.active, STRIPE_SUBSCRIPTION_STATUS.trialing].includes(st) &&
            !(location.pathname.includes('/settings') || location.pathname.includes('/support') || 
              location.pathname.includes('/payment') || location.pathname.includes('/success') 
            );
        }
        setShowDialog(showDlg);

        if (location.pathname.includes('/payment')){
          setShowDialog(false);
        }
        else
          setShowDialog(showDlg);
      }
  };
  getInfo();
  },[studentId, location, dispatch]);

  return (
    <>
     {stripeStatus && (
     <TrialExpiresDialog 
        open={showDialog} 
        status={stripeStatus} 
        trialCount={trialCount}
        studentId={studentId}
        studentEmail={studentEmail} 
      />)}
      <Switch>
        <Route path="/public" component={(props: any) => <UserLayout {...props} />} />
        <ProtectedRoute path="/application">
          <AppLayout />
        </ProtectedRoute>
        <ProtectedRoute path={['/dashboard', '/support', '/settings', '/payment','/success']}>
          <DashBoardLayout />
        </ProtectedRoute>
        <Route exact path="/">
          <Redirect to="/dashboard" />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </>
  );
};

export default RouterConfig;
