/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Spinner, Card } from 'react-bootstrap';
import { useFormik } from 'formik';
import TextInput from 'components/input/textInput';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getCurrentBasicDetail, update } from 'app/basicInformationSlice/basicInformationSlice';
import {
  BoolToString,
  checkEnableParseJSON,
  formatBoolean,
  formatDate,
  suffixOptions,
  userpilotDomain,
} from 'components/helper/helper';
import DatePickerInput from 'components/input/DatePicker';
import { basicInformationRules } from './validation';
import SelectInput from 'components/input/SelectInput';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LoadingContent from 'components/LoadingContent';
import { countryOptions } from 'components/helper/countryPhoneCode';
import GroupCheckbox from 'components/input/GroupCheckbox';
import collegeServices from 'services/collegeServices';
import moment from 'moment';
import YesNoSwitch from 'components/input/YesNoSwitch';
import { debounce } from 'lodash';
import { stateAndCounty } from 'components/helper/stateAndCounty';
import GroupRadioButton from 'components/input/GroupRadioButton';
import { Userpilot } from 'userpilot';
import { useLocation } from 'react-router-dom';

interface Props { }
const BasicInformationPage = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const [waiting, setWaiting] = React.useState(false);
  const [stateOptions, setStateOptions] = React.useState<any[]>([]);
  const [process, setProcess] = React.useState<number>(0);
  const [anotherMailingAddress, setAnotherMailingAddress] = React.useState(false);
  const dispatch = useAppDispatch();
  const basicInformationStore = useAppSelector((store) => store.basicInformation);
  const query = new URLSearchParams(useLocation().search);
  const check = query.get('check');

  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      let res = await dispatch(getCurrentBasicDetail(studentId));
      if (res.payload) {
        const dataLoaded: any = res.payload;
        const formValues = {
          ...formik.values,
          ...dataLoaded,
          gender_identity_description: dataLoaded.gender_identity_description,
          is_share_different_first_name: BoolToString(dataLoaded.is_share_different_first_name),
          is_have_alternate_address: BoolToString(dataLoaded.is_have_alternate_address),
          is_have_legal_name: BoolToString(dataLoaded.is_have_legal_name),
          is_should_send_mail: BoolToString(dataLoaded.is_should_send_mail),
          is_temporary_address: BoolToString(dataLoaded.is_temporary_address),
          csu_info: {
            ...formik.values.csu_info,
            ...checkEnableParseJSON(dataLoaded.csu_info),
          },
        };
        formik.setValues(formValues);
        formSex.setValues(checkEnableParseJSON(dataLoaded.sex) || {});
      }
      setLoading(false);
      formik.setStatus('idle');
    } else {
      handleLogout();
    }
  };
  const information = useAppSelector((store) => store.basicInformation.myBasicInformation);

  const formik = useFormik({
    initialValues: {
      // address: '',
      address_line_1: '',
      address_line_2: '',
      country: '',
      alternate_address: '',
      alternate_address_line_1: '',
      alternate_address_line_2: '',
      alternate_city: '',
      alternate_state: '',
      alternate_zip_code: '',
      city: '',
      dob: '',
      email: '',
      first_name: '',
      gender_identity_description: '',
      is_have_alternate_address: '',
      is_have_legal_name: '',
      is_should_send_mail: '',
      is_temporary_address: '',
      label: '',
      last_name: '',
      middle_name: '',
      nick_name: '',
      other_name: '',
      sex: 'female',
      sex_self_describe: '', // CU App
      sex_self_consider: '', // CU App
      sex_self_consider_describe: '', // CU App

      sex_orientation: '', // CSU App
      sex_orientation_describe: '', // CSU App
      gender_describe: '', // CSU App
      gender_indicate_another: '', // CSU APP
      are_you_transgender: '', // CSU App

      state: '',
      temporary_from_date: moment().toISOString(),
      temporary_to_date: moment().toISOString(),
      zip_code: '',
      legal_my_first_name: '',
      legal_first_name: '',
      legal_middle_name: '',
      legal_family_name: '',
      legal_first_name_you_go_by: '',
      is_share_different_first_name: '',
      different_first_name: '',
      is_different_first_name_pronouns_as_he: true,
      is_different_first_name_pronouns_as_she: true,
      is_different_first_name_pronouns_as_they: true,
      is_different_first_name_pronouns_as_other: true,
      other_different_first_name_pronouns: '',
      // Add State Code and State Code Alternate
      state_code: '',
      alternate_state_code: '',
      csu_info: {
        another_identity: '',
        country: '',
        city: '',
        state: '',
        county: '',
        emailType: '',
        gender_identity: '',
        another_gender_identity: '',
        address_county: '',
        mailing_address_county: '',
        approximate_date: '',
        legal_sex: '',
        have_lived_name: '',
        lived_first_name: '',
        lived_middle_name: '',
        lived_last_name: '',
      },
    },
    validationSchema: basicInformationRules,
    onSubmit: async (values) => {
      studentId = Cookies.get('userId');
      token = Cookies.get(authenticateSettings.tokenName);
      if (token && studentId) {
        try {
          setWaiting(true);
          const newValues = {
            ...values,
            sex: JSON.stringify(formSex.values),
            is_share_different_first_name: formatBoolean(values.is_share_different_first_name),
            is_have_alternate_address: formatBoolean(values.is_have_alternate_address),
            is_have_legal_name: formatBoolean(values.is_have_legal_name),
            is_should_send_mail: formatBoolean(values.is_should_send_mail),
            is_temporary_address: formatBoolean(values.is_temporary_address),
            dob: formatDate(values.dob),
            temporary_from_date: formatDate(values.temporary_from_date),
            temporary_to_date: formatDate(values.temporary_to_date),
            student_id: parseInt(studentId || ''),
            csu_info: JSON.stringify(values.csu_info),
          };
          Userpilot.identify((studentId ?? 'undefined') + userpilotDomain, {
            name: information?.first_name ?? 'undefined',
            created_at: information?.created_at ?? 'undefined',
            firstName: newValues.first_name,
            lastName: newValues.last_name,
            email: newValues.email,
            state: newValues.state,
            userType: 'student',
          });

          await dispatch(update(newValues)).unwrap();

          window.Intercom('update', {
            home_address_state: newValues.state,
            home_address_city: newValues.city,
            home_address_county: newValues.country,
          });

          await dispatch(getCurrentBasicDetail(studentId)).unwrap();
        } catch (ex) {
          console.log('Error: ', ex);
        }
        setWaiting(false);
      }
    },
  });

  const handleContinue = async () => {
    formik.handleSubmit();
    history.push(routers.contactDetail.path);
  };
  useEffect(() => {
    getDetail().then(() => {
      try {
        setWaiting(true);
        collegeServices.getState().then((res: any) => {
          const states = res?.map((item: any) => {
            return { value: item.descr, stateCode: item.state_code };
          });
          setStateOptions(states);
          setWaiting(false);
        });
      } catch (ex) {
        console.log('Error: ', ex);
        setWaiting(false);
      }
    });
  }, []);

  const formSex = useFormik({
    initialValues: {
      isMale: false,
      isFemale: false,
      isNonbinary: false,
      isAnother: false,
      anotherGender: '',
    },
    onSubmit: () => { },
  });

  useEffect(() => {
    if (formik.values.zip_code?.length) {
      setProcess(99);
    } else {
      setProcess(1);
    }
  }, [formik.values, formSex.values, check]);

  useEffect(() => {
    if (formik.values.state?.length && !formik.values.state_code?.length && stateOptions.length) {
      handleChangeState(formik.values.state, 'state_code');
    }
    if (
      formik.values.alternate_state?.length &&
      !formik.values.alternate_state_code?.length &&
      stateOptions.length
    ) {
      handleChangeState(formik.values.alternate_state, 'alternate_state_code');
    }
  }, [formik.values.alternate_state, formik.values.state, stateOptions]);

  useEffect(() => {
    if (formik.values.is_have_alternate_address === 'true') {
      setAnotherMailingAddress(true);
    } else {
      setAnotherMailingAddress(false);
    }
  }, [formik.values.is_have_alternate_address]);
  useEffect(() => {
    if (formik.values.is_should_send_mail === 'true') {
      formik.setFieldValue('is_have_alternate_address', 'false');
    } else if (formik.values.is_should_send_mail === 'false') {
      formik.setFieldValue('is_have_alternate_address', 'true');
    }
  }, [formik.values.is_should_send_mail]);

  const handleChangeState = (selectedStateName: string, formFieldName: string) => {
    const stateSelected = stateOptions.find((state) => state.value === selectedStateName);
    formik.setFieldValue(formFieldName, stateSelected?.stateCode);
  };
  // Auto save function ==========
  React.useEffect(() => {
    if (!!formik.status) debounceSaving();
  }, [formik.values, formSex.values]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (!waiting) formik.handleSubmit();
    }, 1500),
    []
  );
  // Auto save function ==========

  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[
          {
            title: 'General',
            percentOnProcess: 35,
          },
          {
            title: 'Gender',
            percentOnProcess: 60,
          },
          {
            title: 'Address',
            percentOnProcess: 85,
          },
        ]}
        title="Personal Information"
        process={process}
        icon={<PersonOutlineOutlinedIcon />}
      />
      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <div id="General" className="text-field-mid fs-3 mb-4">
              Basic Information
            </div>

            <Row>
              <TextInput
                label={'First/given name*'}
                name={'first_name'}
                values={formik.values.first_name}
                onChange={formik.handleChange}
                feedback={
                  formik.touched.first_name &&
                  formik.errors.first_name && (
                    <span className="text-danger">{formik.errors.first_name}</span>
                  )
                }
                isMissing={!formik.values.first_name && check ? true : false}
              />
              <TextInput
                label={'Middle name'}
                name={'middle_name'}
                values={formik.values.middle_name}
                onChange={formik.handleChange}
                feedback={
                  formik.touched.middle_name &&
                  formik.errors.middle_name && (
                    <span className="text-danger">{formik.errors.middle_name}</span>
                  )
                }
              />
              <TextInput
                label={'Last/family/surname*'}
                name={'last_name'}
                values={formik.values.last_name}
                onChange={formik.handleChange}
                feedback={
                  formik.touched.last_name &&
                  formik.errors.last_name && (
                    <span className="text-danger">{formik.errors.last_name}</span>
                  )
                }
                isMissing={!formik.values.last_name && check ? true : false}
              />
              <SelectInput
                form={formik}
                option={suffixOptions}
                label={'Suffix'}
                name={'label'}
                values={formik.values.label}
                onChange={formik.handleChange}
                feedback={
                  formik.touched.label &&
                  formik.errors.label && <span className="text-danger">{formik.errors.label}</span>
                }
              />
              <TextInput
                className="coallition-flag"
                label={'Email*'}
                name={'email'}
                values={formik.values.email}
                onChange={formik.handleChange}
                feedback={
                  formik.touched.email && formik.errors.email ? (
                    <span className="text-danger">{formik.errors.email}</span>
                  ) : (
                    <span className="text-field-mid sized70">
                      Go to your settings to update your email
                    </span>
                  )
                }
                isMissing={!formik.values.email && check ? true : false}
              />
              <SelectInput
                label="Email Address Type*"
                form={formik}
                logo="label-14px span-csu-logo"
                option={['Home', 'Work', 'School']}
                name={'csu_info.emailType'}
                values={formik.values.csu_info?.emailType}
                onChange={formik.handleChange}
                isMissing={!formik.values.csu_info?.emailType && check ? true : false}
              />
              <DatePickerInput
                className="mt-3"
                label={'Date of Birth*'}
                name={'dob'}
                values={formik.values.dob}
                onChange={(value) => formik.setFieldValue('dob', value)}
                feedback={
                  formik.touched.dob &&
                  formik.errors.dob && <span className="text-danger">{formik.errors.dob}</span>
                }
                isMissing={!formik.values.dob && check ? true : false}
              />
              {/* 1073 */}
              {/* <Col xs={12}>
                <YesNoSwitch
                  logo="label-14px"
                  label="Would you like to share a different first name that you go by?"
                  name="is_share_different_first_name"
                  values={formik.values.is_share_different_first_name}
                  onChange={formik.handleChange}
                  onClear={() => formik.setFieldValue('is_share_different_first_name', '')}
                />
              </Col> */}
              {/* {formik.values.is_share_different_first_name === 'true' && (
                <> */}
              {/* <TextInput
                    countText={50}
                    label="My first name*"
                    name="different_first_name"
                    values={formik.values.different_first_name}
                    onChange={formik.handleChange}
                  /> */}
              {/* <TextInput
                label="Prefered Name (Nickname)"
                name="nick_name"
                values={formik.values.nick_name}
                onChange={formik.handleChange}
                feedback={
                  formik.touched.nick_name && formik.errors.nick_name ? (
                    <span className="text-danger">{formik.errors.nick_name}</span>
                  ) : (
                    <span className="text-field-mid sized70">
                      (If different than Legal First Name)
                    </span>
                  )
                }
              /> */}
              {/* </>
              )} */}
              {/* <div className="sized70 text-field-light w-100 d-flex justify-content-between mh-1rem"></div> */}
              <YesNoSwitch
                label="Do you have a lived /preferred name?*"
                name="csu_info.have_lived_name"
                onChange={formik.handleChange}
                values={formik.values.csu_info?.have_lived_name}
                logo="span-cm-logo"
                isMissing={!formik.values.csu_info?.have_lived_name && check ? true : false}
              />
              <div className="label-14px p-1">
                A lived or preferred name is a name you go by that is different than your legal name
                (for example, a middle name that you go by or a name that aligns with your gender
                identity). If you enter a lived name, we'll use it in most of our communications
                with you about the application, except when it's required for us to use your legal
                name.
              </div>
              {formik.values.csu_info?.have_lived_name === 'true' && (
                <>
                  <TextInput
                    label="Lived first name *"
                    countText={32}
                    name="csu_info.lived_first_name"
                    onChange={formik.handleChange}
                    values={formik.values.csu_info?.lived_first_name}
                    isMissing={!formik.values.csu_info?.lived_first_name && check ? true : false}
                  />
                  <TextInput
                    label="Lived middle name"
                    countText={32}
                    name="csu_info.lived_middle_name"
                    values={formik.values.csu_info?.lived_middle_name}
                    onChange={formik.handleChange}
                  />
                  <TextInput
                    label="Lived Last / family / surname *"
                    countText={32}
                    name="csu_info.lived_last_name"
                    values={formik.values.csu_info?.lived_last_name}
                    onChange={formik.handleChange}
                    isMissing={!formik.values.csu_info?.lived_last_name && check ? true : false}
                  />
                </>
              )}

              <div className="label-14px p-1 span-uc-logo">
                Have you ever used any other names?*
              </div>
              <YesNoSwitch
                logo="text-field-mid fs-14px"
                label="For example, a maiden name or a legal name change. We ask this question so we can
                  match your academic records, transcripts and test scores to your application"
                name={'is_have_legal_name'}
                values={formik.values.is_have_legal_name}
                onChange={formik.handleChange}
              />

              {formik.values.is_have_legal_name === 'true' && (
                <>
                  <TextInput
                    label={'Former First Name *'}
                    name={'legal_first_name'}
                    values={formik.values.legal_first_name}
                    onChange={formik.handleChange}
                    feedback={
                      formik.touched.legal_first_name &&
                      formik.errors.legal_first_name && (
                        <span className="text-danger">{formik.errors.legal_first_name}</span>
                      )
                    }
                    isMissing={!formik.values.legal_first_name && check ? true : false}
                  />
                  <TextInput
                    label={'Former Middle Name'}
                    name={'legal_middle_name'}
                    values={formik.values.legal_middle_name}
                    onChange={formik.handleChange}
                    feedback={
                      formik.touched.legal_middle_name &&
                      formik.errors.legal_middle_name && (
                        <span className="text-danger">{formik.errors.legal_middle_name}</span>
                      )
                    }
                  />
                  <TextInput
                    label={'Former Last Name *'}
                    name={'legal_family_name'}
                    values={formik.values.legal_family_name}
                    onChange={formik.handleChange}
                    feedback={
                      formik.touched.legal_family_name &&
                      formik.errors.legal_family_name && (
                        <span className="text-danger">{formik.errors.legal_family_name}</span>
                      )
                    }
                    isMissing={!formik.values.legal_family_name && check ? true : false}
                  />
                  <SelectInput
                    form={formik}
                    option={suffixOptions}
                    label={'Former Suffix'}
                    name={'legal_first_name_you_go_by'}
                    values={formik.values.legal_first_name_you_go_by}
                    onChange={formik.handleChange}
                    feedback={
                      formik.touched.legal_first_name_you_go_by &&
                      formik.errors.legal_first_name_you_go_by && (
                        <span className="text-danger">
                          {formik.errors.legal_first_name_you_go_by}
                        </span>
                      )
                    }
                  />
                </>
              )}

              <div className="w-100 border my-3" />
              <div id="Gender" className="fs-5 p-1 p-1">
                Gender and Sexual Orientation?
              </div>
              <Col md={6}>
                <GroupCheckbox
                  form={formSex}
                  logo="span-cm-logo"
                  label="Sex*"
                  options={[
                    {
                      value: 'isFemale',
                      label: 'Female',
                    },
                    {
                      value: 'isMale',
                      label: 'Male',
                    },

                    {
                      value: 'isNonbinary',
                      label: 'Nonbinary',
                    },
                    {
                      value: 'isAnother',
                      label: 'Another gender',
                    },
                  ]}
                  isMissing={
                    !formSex.values.isFemale &&
                      !formSex.values.isMale &&
                      !formSex.values.isNonbinary &&
                      !formSex.values.isAnother &&
                      check
                      ? true
                      : false
                  }
                />
                {formSex.values.isAnother && (
                  <Row>
                    <TextInput
                      name="anotherGender"
                      label="My gender*"
                      values={formSex.values.anotherGender}
                      mdCol={'12'}
                      onChange={formSex.handleChange}
                      isMissing={!formSex.values.anotherGender && check ? true : null}
                    />
                  </Row>
                )}
              </Col>
              <Col md={6}>
                <GroupCheckbox
                  logo="span-cm-logo"
                  mdCol={12}
                  label="Pronouns"
                  form={formik}
                  options={[
                    { value: 'is_different_first_name_pronouns_as_he', label: 'He/Him' },
                    { value: 'is_different_first_name_pronouns_as_she', label: 'She/Her' },
                    { value: 'is_different_first_name_pronouns_as_they', label: 'They/Them' },
                    {
                      value: 'is_different_first_name_pronouns_as_other',
                      label: 'Add another pronoun set',
                    },
                  ]}
                />
                {formik.values.is_different_first_name_pronouns_as_other && (
                  <Row>
                    <TextInput
                      className="w-100"
                      label="My pronouns*"
                      name="other_different_first_name_pronouns"
                      onChange={formik.handleChange}
                      values={formik.values.other_different_first_name_pronouns}
                    />
                  </Row>
                )}
              </Col>

              <GroupRadioButton
                className="w-100"
                logo="span-cm-logo"
                mdCol={6}
                values={formik.values.csu_info?.legal_sex}
                name={'csu_info.legal_sex'}
                onChange={formik.handleChange}
                label="Legal sex*"
                options={['Female', 'Male', 'X or another legal sex']}
                isMissing={!formik.values.csu_info?.legal_sex && check ? true : false}
              />

              <GroupRadioButton
                logo="span-uc-logo"
                mdCol={6}
                values={formik.values.sex_self_describe}
                name={'sex_self_describe'}
                onChange={formik.handleChange}
                label="How do you describe yourself?*"
                options={[
                  { value: 'female', label: 'Female' },
                  { value: 'transMan', label: 'Trans Male/ Trans Man' },
                  { value: 'identity', label: 'Different Identity' },
                  { value: 'male', label: 'Male' },
                  { value: 'tranFemale', label: 'Trans Female/ Trans Woman' },
                  { value: 'nonGender', label: 'Genderqueer or Nonbinary Gender' },
                ]}
                onClear={() => formik.setFieldValue('sex_self_describe', '')}
                isMissing={!formik.values.sex_self_describe && check ? true : false}
              />
              {formik.values.sex_self_describe === 'identity' && (
                <>
                  <TextInput
                    countText={30}
                    logo="span-csu-logo"
                    label="Another identity (please specify)*"
                    values={formik.values.csu_info?.another_identity}
                    onChange={formik.handleChange}
                    name="csu_info.another_identity"
                    isMissing={!formik.values.csu_info?.another_identity && check ? true : false}
                  />
                </>
              )}

              <GroupRadioButton
                mdCol={6}
                logo="span-uc-logo"
                label="What do you consider yourself to be?*"
                values={formik.values.sex_self_consider}
                onChange={formik.handleChange}
                name="sex_self_consider"
                options={[
                  'Heterosexual or Straight',
                  'Gay or lesbian',
                  'Bisexual',
                  'Not listed above',
                ]}
                onClear={() => formik.setFieldValue('sex_self_consider', '')}
                isMissing={!formik.values.sex_self_consider && check ? true : false}
              />
              {formik.values.sex_self_consider === 'Not listed above' && (
                <TextInput
                  logo="span-uc-logo"
                  name="sex_self_consider_describe"
                  mdCol="12"
                  label="Please describe"
                  onChange={formik.handleChange}
                  countText={50}
                  values={formik.values.sex_self_consider_describe}
                />
              )}

              <GroupRadioButton
                mdCol={6}
                logo="span-csu-logo"
                label="Which best describes your current sexual orientation?"
                values={formik.values.sex_orientation}
                onChange={formik.handleChange}
                name="sex_orientation"
                options={[
                  'Lesbian',
                  'Gay',
                  'Bisexual',
                  'Queer',
                  'Straight or heterosexual',
                  'Pansexual',
                  'Omnisexual',
                  'Asexual',
                  'Demisexual',
                  'Another Orientation',
                  'Decline to State',
                ]}
                onClear={() => formik.setFieldValue('sex_orientation', '')}
              />
              {formik.values.sex_orientation === 'Another Orientation' && (
                <TextInput
                  logo="span-csu-logo"
                  name="sex_orientation_describe"
                  mdCol="12"
                  label="Please provide another orientation"
                  setLabelHight={true}
                  onChange={formik.handleChange}
                  countText={50}
                  values={formik.values.sex_orientation_describe}
                />
              )}

              <GroupRadioButton
                mdCol={6}
                logo="span-csu-logo"
                label="Which best describes your current gender?"
                values={formik.values.gender_describe}
                onChange={formik.handleChange}
                name="gender_describe"
                options={[
                  'Woman',
                  'Man',
                  'Nonbinary',
                  'Genderqueer or gender fluid',
                  'Another gender',
                  'Decline to state',
                ]}
                onClear={() => formik.setFieldValue('gender_describe', '')}
              />
              {formik.values.gender_describe === 'Another gender' && (
                <TextInput
                  logo="span-csu-logo"
                  name="gender_indicate_another"
                  mdCol="12"
                  label="Please indicate another gender"
                  setLabelHight={true}
                  onChange={formik.handleChange}
                  countText={30}
                  values={formik.values.gender_indicate_another}
                />
              )}

              <GroupRadioButton
                mdCol={6}
                logo="span-csu-logo"
                label="Are you transgender?"
                values={formik.values.are_you_transgender}
                onChange={formik.handleChange}
                name="are_you_transgender"
                options={['No, I am not transgender', 'Yes, I am transgender', 'Decline to state']}
                onClear={() => formik.setFieldValue('are_you_transgender', '')}
              />

              <SelectInput
                mdCol="12"
                option={[
                  'Select',
                  'Gender conforming',
                  'Gender non-conforming',
                  'Both',
                  'Not Sure',
                  'Decline to State',
                  'None of the Above',
                ]}
                name={'csu_info.gender_identity'}
                logo="span-csu-logo"
                values={formik.values.csu_info?.gender_identity}
                onChange={formik.handleChange}
                label="How do you describe the way you express your gender identity in terms of behavior, appearance, speech, and movement?*"
                isMissing={
                  (!formik.values.csu_info?.gender_identity ||
                    formik.values.csu_info?.gender_identity === 'Select') &&
                    check
                    ? true
                    : false
                }
              />
              <TextInput
                hidden={formik.values.csu_info?.gender_identity !== 'None of the Above'}
                name="csu_info.another_gender_identity"
                label="Another identity expression (please specify) *"
                onChange={formik.handleChange}
                countText={30}
                values={formik.values.csu_info?.another_gender_identity}
                isMissing={!formik.values.csu_info?.another_gender_identity && check ? true : false}
              />

              <div className="w-100 border my-3"></div>
              <div id="Address" className="fs-5 p-1">
                What is your home address?*
              </div>
              <div className="text-field-mid">
                This address is your permanent address. It’s most likely where your parents or legal
                guardians live. If you’re an international student, please enter your home country
                address.
              </div>

              <SelectInput
                option={countryOptions}
                label={'Country*'}
                name={'country'}
                values={formik.values.country}
                onChange={formik.handleChange}
                mdCol={'12'}
                isMissing={!formik.values.country && check ? true : false}
              />
              <TextInput
                label={'Address Line 1 (including Apartment or Suite Number)*'}
                name={'address_line_1'}
                values={formik.values.address_line_1}
                onChange={formik.handleChange}
                isMissing={!formik.values.address_line_1 && check ? true : false}
              />
              <TextInput
                label={'Address Line 2'}
                name={'address_line_2'}
                values={formik.values.address_line_2}
                onChange={formik.handleChange}
              />
              <SelectInput
                option={Object.keys(stateAndCounty)}
                label={'State*'}
                name={'state'}
                values={formik.values.state}
                onChange={(e, child) => {
                  handleChangeState(e.target.value, 'state_code');
                  formik.setFieldValue('csu_info.address_county', '');
                  formik.handleChange(e);
                }}
                feedback={
                  formik.touched.state &&
                  formik.errors.state && <span className="text-danger">{formik.errors.state}</span>
                }
                isMissing={!formik.values.state && check ? true : false}
              />
              <TextInput
                label={'City*'}
                name={'city'}
                values={formik.values.city}
                onChange={formik.handleChange}
                feedback={
                  formik.touched.city &&
                  formik.errors.city && <span className="text-danger">{formik.errors.city}</span>
                }
                isMissing={!formik.values.city && check ? true : false}
              />
              <SelectInput
                logo="span-csu-logo"
                option={formik.values.state ? stateAndCounty[formik.values.state] : []}
                label={'County*'}
                name={'csu_info.address_county'}
                values={formik.values.csu_info?.address_county}
                onChange={formik.handleChange}
              />
              <TextInput
                label={'Zip Code'}
                name={'zip_code'}
                values={formik.values.zip_code}
                onChange={formik.handleChange}
                isMissing={!formik.values.zip_code && check ? true : false}
              />
              <DatePickerInput
                logo="span-csu-logo"
                label="Approximate date through which current address is valid"
                isFloatRightLogo={true}
                name="csu_info.approximate_date"
                values={formik.values.csu_info?.approximate_date}
                onChange={(value) => formik.setFieldValue('csu_info.approximate_date', value)}
              />
              <div />
              <Col md={6}>
                <div className="text-field-mid p-1">
                  Is this your permanent address and where we should send mail?*
                </div>
                <YesNoSwitch
                  logo="text-field-mid fs-14px"
                  label="UC's need your current mailing address to send mail correspondences about the
                  admissions process"
                  name={'is_should_send_mail'}
                  values={formik.values.is_should_send_mail}
                  onChange={formik.handleChange}
                />
              </Col>

              <Col md={6}>
                <div className="text-field-mid p-1">Do you have an alternate mailing address?</div>
                <YesNoSwitch
                  label=" If you attend a boarding school or use a different mailing address"
                  name={'is_have_alternate_address'}
                  values={formik.values.is_have_alternate_address}
                  onChange={formik.handleChange}
                />
              </Col>

              {anotherMailingAddress && (
                <Card body className="bg-light border-0 px-1 w-100 mt-3">
                  <Row>
                    <div>Mailing Address</div>
                    <TextInput
                      label={'Address Line 1 (including Apartment or Suite Number)*'}
                      name={'alternate_address_line_1'}
                      values={formik.values.alternate_address_line_1}
                      onChange={formik.handleChange}
                      isMissing={!formik.values.alternate_address_line_1 && check ? true : false}
                    />
                    <TextInput
                      label={'Address Line 2'}
                      name={'alternate_address_line_2'}
                      values={formik.values.alternate_address_line_2}
                      onChange={formik.handleChange}
                    />
                    <TextInput
                      label={'City*'}
                      name={'alternate_city'}
                      values={formik.values.alternate_city}
                      onChange={formik.handleChange}
                      isMissing={!formik.values.alternate_city && check ? true : false}
                    />
                    <SelectInput
                      label={'State*'}
                      option={Object.keys(stateAndCounty)}
                      name={'alternate_state'}
                      values={formik.values.alternate_state}
                      onChange={(e) => {
                        handleChangeState(e.target.value, 'alternate_state_code');
                        formik.handleChange(e);
                        formik.setFieldValue('csu_info.mailing_address_county', '');
                      }}
                      isMissing={!formik.values.alternate_state && check ? true : false}
                    />
                    <SelectInput
                      logo="span-csu-logo"
                      option={stateAndCounty[formik.values.alternate_state]}
                      label={'County*'}
                      name={'csu_info.mailing_address_county'}
                      values={formik.values.csu_info?.mailing_address_county}
                      onChange={formik.handleChange}
                      isMissing={
                        !formik.values.csu_info?.mailing_address_county && check ? true : false
                      }
                    />
                    <TextInput
                      label={'Zip Code*'}
                      name={'alternate_zip_code'}
                      values={formik.values.alternate_zip_code}
                      onChange={formik.handleChange}
                      isMissing={!formik.values.alternate_zip_code && check ? true : false}
                    />
                    <YesNoSwitch
                      label="Is this a temporary address?"
                      values={formik.values.is_temporary_address}
                      name="is_temporary_address"
                      onChange={formik.handleChange}
                    />

                    {formik.values.is_temporary_address === 'true' && (
                      <>
                        <DatePickerInput
                          mdCol="6"
                          label="From date"
                          name="temporary_from_date"
                          values={formik.values.temporary_from_date}
                          onChange={(value) => formik.setFieldValue('temporary_from_date', value)}
                        />
                        <DatePickerInput
                          mdCol="6"
                          label="To date"
                          name="temporary_to_date"
                          values={formik.values.temporary_to_date}
                          onChange={(value) => formik.setFieldValue('temporary_to_date', value)}
                        />
                      </>
                    )}
                  </Row>
                </Card>
              )}
            </Row>
            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {basicInformationStore.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default BasicInformationPage;
