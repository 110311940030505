/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { getGradeAndCourse, updateGradeAndCourse } from 'app/course/courseSlice';
import CheckboxInput from 'components/input/CheckboxInput';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CollegeCourseConfig from './components/CollegeCourseConfig';
import { debounce } from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { reorder, tryParseJSON } from 'components/helper/helper';
import { getCollege } from 'app/highSchoolSlice/highSchoolSlice';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { ExpandMore } from 'screen/activitiesAndHonors/Activities';
import { scrollToObject } from 'components/helper/ui_helper';

interface Props {}

const CollegeCourse = (props: Props) => {
  const MAX_COLLEGE_TERMS = 20;  
  const [myColleges, setMyColleges] =  React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const [showWarning, setShowWarning] = React.useState(false);
  const [errorArr, setErrorArr] = React.useState<any[]>([]);
  const [expandAll, setExpandAll] = React.useState(false);
  const [expandActive, setExpandActive] = React.useState<number>(0);
  const course = useAppSelector((store) => store.course);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const [courseData, setCourseData] = React.useState<any[]>([]);
  const query = new URLSearchParams(location.search);
  const check = query.get('check');

  const formik = useFormik({
    initialValues: {
      college_course_array: '',
      is_finish_adding_all_college_grade: false,
    },
    onSubmit: async (values) => {
      studentId = Cookies.get('userId');
      token = Cookies.get(authenticateSettings.tokenName);
      if (token && studentId) {
        try {
          const newValues = {
            ...values,
            student_id: studentId,
            college_course_array: JSON.stringify(courseData),
          };
    
          await dispatch(updateGradeAndCourse(newValues));
          await recheckInputSchool();
        }
        catch(ex){
          console.log('Error:', ex);
        }
      } else {
        handleLogout();
      }

    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = () => {
    formik.handleSubmit();
    history.push(routers.generalTest.path);
  };

  const checkInputSchool = async(courseArr:any[], myColleges:any) => {
    let arr: any[] = [];
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      console.log('myColleges:', myColleges);
      if (myColleges){
        const colleges: any[] = tryParseJSON(myColleges.colleges_taken_coursework_array, []);
        console.log('colleges:', colleges);
        console.log('courseArr:', courseArr);
        if (colleges.length === 0) {
          arr.push({
            value: 'number_of_colleges_taken_coursework',
            name: 'Please add high college on the Colleges & Universities page.',
          });
        }
        else {
          if (colleges && colleges.length) {
            for(let i=0; i<colleges.length; i++) {
              let item:any = colleges[i];
              let college_name = colleges[i].college_name;
              if (courseArr && courseArr.length>0) {
                for (let j=0;j<courseArr.length;j++) {
                  let course = courseArr[j];
                  if (course?.collegeName === college_name) {
                    if (college_name === "") {
                      arr.push({
                        value: `collegeName_${i+1}`,
                        name: 'Please search and select/enter College/University name on the Colleges & Universities page.',
                      });
                    }
                    else {
                      if (!item?.fromDate && !item?.toDate) {
                        arr.push({
                          value: `fromDate_${i+1}`,
                          name: 'Please enter the From date and To date on the Colleges & Universities page.',
                        });
                      } else
                      if (!item.fromDate) {
                        arr.push({
                          value: `fromDate_${i+1}`,
                          name: 'Please enter the From date on the Colleges & Universities page.',
                        });
                      } else if (!item.toDate) {
                        arr.push({
                          value: `toDate_${i+1}`,
                          name: 'Please input the To date on the Colleges & Universities page.',
                        });
                      }
                    }
                  }

                }
              }
              else {
                
              }
            }
          } 

        }

      }
      if (arr.length > 0) {
        setShowWarning(true);
        setErrorArr(arr);
      }
      else {
        setShowWarning(false);
      }
    } else {
      handleLogout();
    }
  };


  const recheckInputSchool = async() => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      const ret = await dispatch(getCollege(studentId)).unwrap();
      const dataLoaded = await dispatch(getGradeAndCourse(studentId)).unwrap();
      let courseArr = tryParseJSON(dataLoaded.college_course_array) ?? [];
      await checkInputSchool(courseArr, ret);
    }
    else {
      handleLogout();
    }
  };

  const AutoScrollAndExpand = () => {
    const expandDiv = (divId:string) => {
      let div = document.getElementById(divId);
      if (div) {
        if (div.getAttribute('aria-expanded') === 'false') {
         div.click();
        }
      }
    }
    try {
      const hash = location.hash.slice(1); // Remove the '#' character from the hash
      if (hash) {
        if (hash==='AddCollege') {
          setTimeout(() => {
            scrollToObject(hash, 200);
          }, 1500);
        } else
        if (hash.includes("collegeName_")) {
          let str = hash.replaceAll("collegeName_","");
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`collegeItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 200);
            }, 1500);
           }, 2000);
        } else
        if (hash.includes("academicYear_")) {
          let str = hash.replaceAll("academicYear_","");
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`collegeItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
           }, 2000);
        } else
        if (hash.includes("academicStatus_")) {
          let str = hash.replaceAll("academicStatus_","");
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`collegeItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
           }, 2000);
        } else
        if (hash.includes("scheduleSystem_")) {
          let str = hash.replaceAll("scheduleSystem_","");
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`collegeItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
           }, 2000);
        } else
        if (hash.includes("term_")) {
          let str = hash.replaceAll("term_","");
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`collegeItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
           }, 2000);
        }


        if (hash.includes("dept_")) {
          let str = hash.replaceAll("dept_","");
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`collegeItem${itemIndex}`);
            try {
              let sid = Number(ar[1]);
              setTimeout(() => {
                expandDiv(`subjectItem${sid}`);
                  setTimeout(() => {
                  scrollToObject(hash, 100);
                }, 1500);
               }, 2000);
            }
            catch(ex2){
              //skip
            }
           }, 2000);
        } else
        if (hash.includes("courseNumber_")) {
          let str = hash.replaceAll("courseNumber_","");
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`collegeItem${itemIndex}`);
            try {
              let sid = Number(ar[1]);
              setTimeout(() => {
                expandDiv(`subjectItem${sid}`);
                  setTimeout(() => {
                  scrollToObject(hash, 100);
                }, 1500);
               }, 2000);
            }
            catch(ex2){
              //skip
            }
           }, 2000);
        } else
        if (hash.includes("courseName_")) {
          let str = hash.replaceAll("courseName_","");
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`collegeItem${itemIndex}`);
            try {
              let sid = Number(ar[1]);
              setTimeout(() => {
                expandDiv(`subjectItem${sid}`);
                  setTimeout(() => {
                  scrollToObject(hash, 100);
                }, 1500);
               }, 2000);
            }
            catch(ex2){
              //skip
            }
           }, 2000);
        } else
        if (hash.includes("subject_")) {
          let str = hash.replaceAll("subject_","");
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`collegeItem${itemIndex}`);
            try {
              let sid = Number(ar[1]);
              setTimeout(() => {
                expandDiv(`subjectItem${sid}`);
                  setTimeout(() => {
                  scrollToObject(hash, 100);
                }, 1500);
               }, 2000);
            }
            catch(ex2){
              //skip
            }
           }, 2000);
        } else
        if (hash.includes("completionStatus_")) {
          let str = hash.replaceAll("completionStatus_","");
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`collegeItem${itemIndex}`);
            try {
              let sid = Number(ar[1]);
              setTimeout(() => {
                expandDiv(`subjectItem${sid}`);
                  setTimeout(() => {
                  scrollToObject(hash, 100);
                }, 1500);
               }, 2000);
            }
            catch(ex2){
              //skip
            }
           }, 2000);
        } else
        if (hash.includes("grade_")) {
          let str = hash.replaceAll("grade_","");
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`collegeItem${itemIndex}`);
            try {
              let sid = Number(ar[1]);
              setTimeout(() => {
                expandDiv(`subjectItem${sid}`);
                  setTimeout(() => {
                  scrollToObject(hash, 100);
                }, 1500);
               }, 2000);
            }
            catch(ex2){
              //skip
            }
           }, 2000);
        } else


        //
        if (true) {
          //skip
        }

      } 
    }
    catch(ex){
      console.log('Error:', ex);
    }
  }

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      try {
        setLoading(true);
        
        const ret = await dispatch(getCollege(studentId)).unwrap();
        setMyColleges(ret);


        const dataLoaded = await dispatch(getGradeAndCourse(studentId)).unwrap();
        if (dataLoaded) {
          formik.setValues({
            ...formik.values,
            ...dataLoaded,
            is_finish_adding_all_college_grade: dataLoaded.is_finish_adding_all_college_grade,
          });

          let courseArr = tryParseJSON(dataLoaded.college_course_array) ?? [];
          setCourseData(courseArr);

          await checkInputSchool(courseArr, ret);

          setTimeout(() => {
            AutoScrollAndExpand();
          }, 2000);
        }
        setLoading(false);
        formik.setStatus('idle');
      } catch (ex) {
        console.log('Error: ', ex);
      }
      setLoading(false);
    } else {
      handleLogout();
    }
  };
  React.useEffect(() => {
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const hash = location.hash.slice(1); // Remove the '#' character from the hash
    if (hash) {
      AutoScrollAndExpand();
    }
  }, [location]);

  const handleAddCollege = () => {
    if (courseData.length > MAX_COLLEGE_TERMS - 1) return;
    setCourseData([...courseData, {}]);
  };

  const handleExpandAll = () => {
    setExpandAll(!expandAll);
    if (!expandAll) {
      setExpandActive(courseData.length);
    } else {
      setExpandActive(0);
    }
  };

  const handleExpandActive = (value: any) => {
    value
      ? setExpandActive(Math.abs(expandActive - 1))
      : setExpandActive(Math.abs(expandActive + 1));
  };

  React.useEffect(() => {
    if (expandActive === courseData.length) {
      setExpandAll(true);
    }

    if (expandActive === 0) {
      setExpandAll(false);
    }
  }, [expandActive, courseData.length]);

  // Auto save function ==========
  const notInitialRender = React.useRef(false);
  React.useEffect(() => {
    debounceSaving();
  }, [formik.values, courseData, formik.values.is_finish_adding_all_college_grade]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (notInitialRender.current) {
        if (!loading) formik.handleSubmit();
      } else {
        notInitialRender.current = true;
      }
    }, 1500),
    []
  );
  // Auto save function ==========
  const [forceRenderKey, setForceRenderKey] = React.useState(0);

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const dataList = reorder(courseData, result.source.index, result.destination.index);
    setCourseData([...dataList]);
    setForceRenderKey(forceRenderKey + 1);
  };
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[{ percentOnProcess: 30, title: '' }]}
        title="Courses & Grades"
        process={1}
        icon={<StackedLineChartIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <Row className="m-0">
              <div className="text-field-mid fs-3 mb-4">College Courses</div>

              {/* start warning */}
              {showWarning && (
                <div className="row">
                  <div className="col-md-12 mb-4">
                    <div className="alert alert-danger" role="alert">
                      {errorArr && errorArr.length > 0 && (
                        <>
                          Prior to entering your college by From date, To date, go to Education
                          &gt;
                          <a
                            href={'/#/application/college-and-university/?check=1#' + errorArr[0].value}
                            title="click to return the Colleges & Universities page"
                          >
                            {' '}
                            Colleges & Universities{' '}
                          </a>
                          &nbsp; and enter the following information for each of the college(s) you
                          attended.
                          {` ${errorArr[0].name}`}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {/* end warning */}

              {courseData.length > 0 && (
                <div className="d-flex justify-content-end">
                  <ExpandMore
                    aria-expanded={expandAll}
                    aria-label="show all"
                    onClick={handleExpandAll}
                  >
                    {!expandAll ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
                  </ExpandMore>
                </div>
              )}

              <DragDropContext onDragEnd={onDragEnd} key={forceRenderKey}>
                <Droppable droppableId="list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {courseData.map((item, index) => (
                        <div key={index + '-' + courseData.length}>
                          <Draggable draggableId={index + '-' + courseData.length} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  key={index + '-' + courseData.length}
                                  className="position-relative"
                                >
                                  <CollegeCourseConfig
                                    expandAll={expandAll}
                                    handleExpandActive={handleExpandActive}
                                    handleSave={(values) => {
                                      const newCourseData = [...courseData];
                                      newCourseData.splice(index, 1, values);
                                      setCourseData(newCourseData);                                     
                                    }}
                                    item={item}
                                    index={index + 1}
                                    total={courseData.length}
                                  />
                                  <RemoveCircleOutlineIcon
                                    className="position-absolute text-field-light pointer"
                                    style={{ right: '-24px', top: '12px' }}
                                    onClick={() => {
                                      const newCourseData = [...courseData];
                                      newCourseData.splice(index, 1);
                                      setCourseData(newCourseData);
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        </div>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div id="AddCollege" className="my-3 w-100 d-flex flex-column align-items-center">
                <div className="text-field-light sized70">{`You can add up to ${MAX_COLLEGE_TERMS} College Terms`}</div>
                <Button variant="primary" className="p-2 px-5" onClick={handleAddCollege}>
                  Add College/Term
                </Button>
              </div>
              <div>
                <div className="text-field-light">
                  Please indicate that you added all of the courses you have taken or plan to take
                  for every college
                </div>
                <CheckboxInput
                  name="is_finish_adding_all_college_grade"
                  values={formik.values.is_finish_adding_all_college_grade}
                  onChange={formik.handleChange}
                  label={`I'm finished adding all the college grade classes I've taken or plan to take for every college I've attended. (I can still edit my classes if I need to.)`}
                />
              </div>
            </Row>

            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {course.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default CollegeCourse;
