/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';
import { RequiredField} from './helper/checkFieldsMissingCondition';
import { tryParseJSON } from 'components/helper/helper';

interface ModalCheckGeneralGradeFieldProps {
  show?: boolean;
  onClose: () => void;
  data: any;
}

export const ModalCheckGeneralGradeField = (props: ModalCheckGeneralGradeFieldProps) => {
  const page = 'general-course-and-grade';
  const { show, onClose, data } = props;
  const [fieldsFail, setFieldsFail] = useState<any[]>([]);
  const history = useHistory();

  const checkRequiredFields = (data:any) =>{   
    //console.log('data:', data);
    const result: RequiredField[]  = [];
    try {
      if (data?.is_able_obtain_copy_transcript == null){
        result.push({
          key: 'is_able_obtain_copy_transcript',
          name: 'I am able to obtain a copy of my transcript(s)*',
          page: '/application/general-course-and-grade/?check=1#is_able_obtain_copy_transcript'
        })
      }
      if (data?.is_able_obtain_copy_transcript === false) {
        if (!data.is_counselor_submit_transcript) {
          result.push({
            key: 'is_counselor_submit_transcript',
            name: 'I understand',
            page: '/application/general-course-and-grade/?check=1#is_counselor_submit_transcript'
          })
        }
      }

      if (data?.is_transcript_show_grade_completed === null){
        result.push({
          key: 'is_transcript_show_grade_completed',
          name: "My transcript shows grades for courses I've completed*",
          page: '/application/general-course-and-grade/?check=1#is_transcript_show_grade_completed'
        })
      }
      if (data?.is_transcript_show_grade_completed === false) {
        if (!data.is_counselor_submit_transcript_2) {
          result.push({
            key: 'is_counselor_submit_transcript_2',
            name: 'I understand',
            page: '/application/general-course-and-grade/?check=1#is_counselor_submit_transcript_2'
          })
        }
      }

      if (data?.additional_info?.is_take_course_in_middle_school === null || data?.additional_info?.is_take_course_in_middle_school === "") {
        result.push({
          key: 'is_take_course_in_middle_school',
          name:  'Did you take any course(s) in middle school that fulfilled your high school subject requirements?',
          page: '/application/general-course-and-grade/?check=1#is_take_course_in_middle_school'
        })
      }

      if (data?.additional_info?.is_take_course_in_middle_school === "true") {
        if (data?.additional_info?.is_have_course_issued_official_transcript_grades === null ||
          data?.additional_info?.is_have_course_issued_official_transcript_grades === "") {
            result.push({
              key: 'is_have_course_issued_official_transcript_grades',
              name: 'Do you have middle school courses listed on your transcript that have been issued official transcript grades?*', 
              page: '/application/general-course-and-grade/?check=1#is_have_course_issued_official_transcript_grades'
            })
        }
        //#region check coure list
        let course_work:any[] = data.additional_info?.course_work ?? [];
        if (course_work && course_work.length>0) {
          for(let i=0; i<course_work.length; i++) {
            let item:any = course_work[i];
            if (!item?.school ||item?.school === '') {
              result.push({
                key: 'school',
                name: 'Select the school for this coursework*',
                page: `/application/${page}/?check=1#school_${i+1}`
              })
            }
            if (!item?.grade_level || item?.grade_level === '') {
              result.push({
                key: 'grade_level',
                name: 'Grade Level*',
                page: `/application/${page}/?check=1#gradeLevel_${i+1}`
              })
            }
            if (!item?.school_year || item?.school_year === '') {
              result.push({
                key: 'schoolYear',
                name: 'School Year*',
                page: `/application/${page}/?check=1#schoolYear_${i+1}`
              })
            }
            if (!item?.grade_scale || item?.grade_scale === '') {
              result.push({
                key: 'grade_scale',
                name: 'Grading Scale*',
                page: `/application/${page}/?check=1#gradeScale_${i+1}`
              })
            }
            if (!item?.term_period || item?.term_period === '') {
              result.push({
                key: 'term_period',
                name: 'Term period*',
                page: `/application/${page}/?check=1#termPeriod_${i+1}`
              })
            }
            if (!item?.schedule || item?.schedule === '') {
              result.push({
                key: 'schedule',
                name: 'Schedule*',
                page: `/application/${page}/?check=1#schedule_${i+1}`
              })
            }
            if (item?.schedule=== 'Other') {
              if (!item?.describe_other_schedule || item?.describe_other_schedule === '') {
                result.push({
                  key: 'describe_other_schedule',
                  name: 'Schedule*',
                  page: `/application/${page}/?check=1#describeOtherSchedule_${i+1}`
                });
              }

            }
            if (!item?.ag_subject_requirement || item?.ag_subject_requirement === '') {
              result.push({
                key: 'ag_subject_requirement',
                name: 'A-G Subject Requirement',
                page: `/application/${page}/?check=1#AGsubjectRequirement_${i+1}`
              })
            }
            if (!item?.subject_discipline || item?.subject_discipline === '') {
              result.push({
                key: 'subject_discipline',
                name: 'Subject/Discipline',
                page: `/application/${page}/?check=1#subjectDiscipline_${i+1}`
              })
            }
            if (item?.ag_subject_requirement === 'C Mathematics') {
              if (!item?.course_name_for_UC || item?.course_name_for_UC === '') {
                result.push({
                  key: 'course_name_for_UC',
                  name: 'Course name *',
                  page: `/application/${page}/?check=1#courseNameforUC_${i+1}`
                })
              }
            }
            if (!item?.course_name || item?.course_name === '') {
              result.push({
                key: 'course_name',
                name: 'Course Name*',
                page: `/application/${page}/?check=1#courseName_${i+1}`
              })
            }
            if (item?.ag_subject_requirement === 'E Language Other Than English') {
              if (!item?.language_name_for_UC || item?.language_name_for_UC === '') {
                result.push({
                  key: 'language_name_for_UC',
                  name: 'Language Name',
                  page: `/application/${page}/?check=1#languageNameforUC_${i+1}`
                })
              }
              if (!item?.course_category || item?.course_category === '') {
                result.push({
                  key: 'course_category',
                  name: 'Course category *',
                  page: `/application/${page}/?check=1#courseCategory_${i+1}`
                })
              }
            }
            if (!item?.type_of_class || item?.type_of_class === '') {
              result.push({
                key: 'type_of_class',
                name: 'Type of Class *',
                page: `/application/${page}/?check=1#typeOfClass_${i+1}`
              })
            }

            if (item?.is_take_class_at_college === null || item?.is_take_class_at_college==="") {
              result.push({
                key: 'college',
                name: 'Select College *',
                page: `/application/${page}/?check=1#college_${i+1}`
              })
            }

            switch (item?.schedule_transcript) {
              case "One grade per term":
              case "One grade per term and one final grade":
                let bFirstSemester = false;  
                  switch(item?.schedule){
                    case 'Semester':
                      if (!item?.isFirstSemester) {
                        bFirstSemester = true;
                        result.push({
                          key: 'isFirstSemester',
                          name: 'First Semester',
                          page: `/application/${page}/?check=1#takeSemester_${i+1}`
                        })
                      }
                      if (bFirstSemester) {
                        if (!item?.isSecondSemester) {
                          result.push({
                            key: 'isSecondSemester',
                            name: 'Second Semester',
                            page: `/application/${page}/?check=1#takeSemester_${i+1}`
                          })
                        }
                      }
                      break;
                    case 'Trimester':
                      if (!item?.isFirstSemester) {
                        bFirstSemester = true;
                        result.push({
                          key: 'isFirstSemester',
                          name: 'First Semester',
                          page: `/application/${page}/?check=1#takeSemester_${i+1}`
                        })
                      }
                      if (bFirstSemester) {
                        if (!item?.isSecondSemester) {
                          result.push({
                            key: 'isSecondSemester',
                            name: 'Second Semester',
                            page: `/application/${page}/?check=1#takeSemester_${i+1}`
                          })
                        }
                        if (!item?.isThirdSemester) {
                          result.push({
                            key: 'isThirdSemester',
                            name: 'Third Semester',
                            page: `/application/${page}/?check=1#takeSemester_${i+1}`
                          })
                        }
                      }
                      break;
                    case 'Quarters':
                      if (!item?.isFirstSemester) {
                        bFirstSemester = true;
                        result.push({
                          key: 'isFirstSemester',
                          name: 'First Semester',
                          page: `/application/${page}/?check=1#takeSemester_${i+1}`
                        })
                      }
                      if (bFirstSemester) {
                        if (!item?.isSecondSemester) {
                          result.push({
                            key: 'isSecondSemester',
                            name: 'Second Semester',
                            page: `/application/${page}/?check=1#takeSemester_${i+1}`
                          })
                        }
                        if (!item?.isThirdSemester) {
                          result.push({
                            key: 'isThirdSemester',
                            name: 'Third Semester',
                            page: `/application/${page}/?check=1#takeSemester_${i+1}`
                          })
                        }
                        if (!item?.isFourthSemester) {
                          result.push({
                            key: 'isFourthSemester',
                            name: 'Fourth Semester',
                            page: `/application/${page}/?check=1#takeSemester_${i+1}`
                          })
                        }
                      }
                      break;
                  }
                  break;
              case "One final Grade":
              default:
                break;
            }

            if (item?.schedule === 'Semester' ||
                item?.schedule === 'Trimester' ||
                item?.schedule === 'Quarters') {
              if (!item?.schedule_transcript || item?.schedule_transcript==='') {
                result.push({
                  key: 'scheduleTranscript_',
                  name: 'How are the grades for this class reported on your transcript?',
                  page: `/application/${page}/?check=1#scheduleTranscript_${i+1}`
                });
              }
            }

            
            if (item?.schedule_transcript === 'One grade per term' ||
                item?.schedule_transcript === 'One grade per term and one final grade') {
              let bFirst = false;
              switch (item?.schedule) {
                case 'Semester':
                  if (item?.isFirstSemester) {
                    bFirst = true;
                    if (!item.grades[0]) {
                      result.push({
                        key: 'grades',
                        name: `${item.schedule} ${1} Grade`,
                        page: `/application/${page}/?check=1#grade_${i+1}_0`
                      });
                    }
                    if (!item.credits[0]) {
                      result.push({
                        key: 'credits',
                        name: `${item.schedule} ${1} Credits`,
                        page: `/application/${page}/?check=1#credits_${i+1}_0`
                      });
                    }
                  }
                  if (bFirst) {
                    if (item?.isSecondSemester) {
                      if (!item.grades[1]) {
                        result.push({
                          key: 'grades',
                          name: `${item.schedule} ${2} Grade`,
                          page: `/application/${page}/?check=1#grade_${i+1}_1`
                        });
                      }
                      if (!item.credits[1]) {
                        result.push({
                          key: 'credits',
                          name: `${item.schedule} ${2} Credits`,
                          page: `/application/${page}/?check=1#credits_${i+1}_1`
                        });
                      }
                    }
                  }
                  break;
                case 'Trimester':
                  if (item?.isFirstSemester) {
                    bFirst = true;
                    if (!item?.grades[0]) {
                      result.push({
                        key: 'grades',
                        name: `${item.schedule} ${1} Grade`,
                        page: `/application/${page}/?check=1#grade_${i+1}_0`
                      });
                    }
                    if (!item?.credits[0]) {
                      result.push({
                        key: 'credits',
                        name: `${item.schedule} ${1} Credits`,
                        page: `/application/${page}/?check=1#credits_${i+1}_0`
                      });
                    }
                  }
                  if (bFirst) {
                    if (item?.isSecondSemester) {
                      if (!item?.grades[1]) {
                        result.push({
                          key: 'grades',
                          name: `${item.schedule} ${2} Grade`,
                          page: `/application/${page}/?check=1#grade_${i+1}_1`
                        });
                      }
                      if (!item?.credits[1]) {
                        result.push({
                          key: 'credits',
                          name: `${item.schedule} ${2} Credits`,
                          page: `/application/${page}/?check=1#credits_${i+1}_1`
                        });
                      }
                    }
                    if (item?.isThirdSemester) {
                      if (!item?.grades[2]) {
                        result.push({
                          key: 'grades',
                          name: `${item.schedule} ${3} Grade`,
                          page: `/application/${page}/?check=1#grade_${i+1}_2`
                        });
                      }
                      if (!item?.credits[2]) {
                        result.push({
                          key: 'credits',
                          name: `${item.schedule} ${3} Credits`,
                          page: `/application/${page}/?check=1#credits_${i+1}_2`
                        });
                      }
                    }
                  }
                  break;
                case 'Quarters':
                  if (item?.isFirstSemester) {
                    bFirst = true;
                    if (!item.grades[0]) {
                      result.push({
                        key: 'grades',
                        name: `${item.schedule} ${1} Grade`,
                        page: `/application/${page}/?check=1#grade_${i+1}_0`
                      });
                    }
                    if (!item.credits[0]) {
                      result.push({
                        key: 'credits',
                        name: `${item.schedule} ${1} Credits`,
                        page: `/application/${page}/?check=1#credits_${i+1}_0`
                      });
                    }
                  }
                  if (bFirst) {
                    if (item?.isSecondSemester) {
                      if (!item?.grades[1]) {
                        result.push({
                          key: 'grades',
                          name: `${item.schedule} ${2} Grade`,
                          page: `/application/${page}/?check=1#grade_${i+1}_1`
                        });
                      }
                      if (!item?.credits[1]) {
                        result.push({
                          key: 'credits',
                          name: `${item.schedule} ${2} Credits`,
                          page: `/application/${page}/?check=1#credits_${i+1}_1`
                        });
                      }
                    }
                    if (item?.isThirdSemester) {
                      if (!item?.grades[2]) {
                        result.push({
                          key: 'grades',
                          name: `${item.schedule} ${3} Grade`,
                          page: `/application/${page}/?check=1#grade_${i+1}_2`
                        });
                      }
                      if (!item?.credits[2]) {
                        result.push({
                          key: 'credits',
                          name: `${item.schedule} ${3} Credits`,
                          page: `/application/${page}/?check=1#credits_${i+1}_2`
                        });
                      }
                    }
                    if (item?.isFourthSemester) {
                      if (!item?.grades[3]) {
                        result.push({
                          key: 'grades',
                          name: `${item.schedule} ${4} Grade`,
                          page: `/application/${page}/?check=1#grade_${i+1}_3`
                        });
                      }
                      if (!item?.credits[3]) {
                        result.push({
                          key: 'credits',
                          name: `${item.schedule} ${4} Credits`,
                          page: `/application/${page}/?check=1#credits_${i+1}_3`
                        });
                      }
                    }
                  }
                  break;
              }
            }

            if (item?.schedule_transcript !== 'One grade per term') {
              if (!item?.final_grade) {
                result.push({
                  key: 'finalGrade',
                  name: `Final Grade`,
                  page: `/application/${page}/?check=1#finalGrade_${i+1}`
                });
              }
              if (item?.final_credit==='' || item?.final_credit === null || item?.final_credit === undefined) {
                result.push({
                  key: 'finalCredits',
                  name: `Final Credits`,
                  page: `/application/${page}/?check=1#finalCredits_${i+1}`
                });
              }
            }



          }// for
        }

        //#endregion
      }

      if (data?.is_attend_school_outside_us_6_through_8 === null) {
        result.push({
          key: 'is_attend_school_outside_us_6_through_8',
          name: 'Did you attend school outside the United States for any part of 6th through 8th grade?',
          page: '/application/general-course-and-grade/?check=1#is_attend_school_outside_us_6_through_8'
        });
      }
      if (data?.is_attend_school_outside_us_6_through_8 === true) {
        if (!data?.language_instruction) {
          result.push({
            key: 'language_instruction',
            name: 'What was the language of instruction? *',
            page: '/application/general-course-and-grade/?check=1#language_instruction'
          });
        }
        if (data?.language_instruction === 'Other') {
          result.push({
            key: 'specify_language_instruction',
            name: 'Specify the Language',
            page: '/application/general-course-and-grade/?check=1#specify_language_instruction'
          });
        }
      }

      if (!data.additional_info?.completed_high_school_outside_US) {
        result.push({
          key: 'completed_high_school_outside_US',
          name: 'Did you complete high school/secondary school outside of the United States?',
          page: '/application/general-course-and-grade/?check=1#completed_high_school_outside_US'
        });
      }
      if (data?.additional_info?.completed_high_school_outside_US === "true") {
        if (!data.additional_info?.high_school_academic_performance) {
          result.push({
            key: 'high_school_academic_performance',
            name:  'Academic Performance (High School GPA, Standing, Percentage, etc.)*',
            page: '/application/general-course-and-grade/?check=1#high_school_academic_performance'
          });
        }
        if (!data.additional_info?.degree_or_diploma_name) {
          result.push({
            key: 'degree_or_diploma_name',
            name:  'Name of Degree/Diploma (e.g. Diploma, A-Level, Abitur, Certificado)*',
            page: '/application/general-course-and-grade/?check=1#degree_or_diploma_name'
          });
        }
      }

     
    }
    catch(ex){
      console.log('Error:', ex);
    }

    setFieldsFail(result);
  }

  useEffect(() => {
    checkRequiredFields(data);
  }, [data]);


  const handleClick = (field: any) => {
    history.push(field.page);
    onClose();
  };

  return (
    <Modal show={show} onHide={() => onClose()}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {data && fieldsFail.length > 0 ? (
          <div>
            <h3>These fields are not yet valid, please complete them: </h3>
            <ul style={{ fontSize: '1rem', color: 'red' }}>
              {fieldsFail.map((field, index) => (
                <li key={index} onClick={() => handleClick(field)} style={{ cursor: 'pointer' }}>
                  {field.name}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div>
            <h3>All required fields are filled</h3>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}