/* eslint-disable no-empty-pattern */
import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import basicInformationServices from 'services/basicInformationApi';

// Types
export interface CSUInfo {
  emailType?: string;
  have_lived_name?: string;
  lived_first_name?: string;
  lived_middle_name?: string;
  lived_last_name?: string;
  legal_sex?: string;
  gender_identity?: string;
  another_gender_identity?: string;
  country?: string;
  city?: string;
  state?: string;
  county?: string;
  address_county?: string;
  mailing_address_county?: string;
  approximate_date?: string;
  data?: string;
  visa_date_issued?: string;
  year_move_to_us?: string;
  degree_applying_for?: string;
  educational_status?: string;
  is_take_college_credit?: string;
  is_certify?: boolean;
  hereby_authorize_CD_release_CAASPP?: string;
  is_authorize_CSU_release_contact_information?: boolean;
  is_authorize_CSU_release_my_application?: boolean;
  is_interested_on_campus_space_available?: string;
  in_foster_care?: string;
  is_emancipated_minor?: string;
  is_homeless_liaison_determine?: string;
  is_director_or_transitional_determine?: string;
  is_director_of_runaway_or_homeless_youth_basic?: string;
  ssid?: string;
  is_in_good_standing?: string;
  is_disciplined_for_academic_performance?: string;
  is_academically_disqualified?: string;
  is_disciplined_for_student_conduct_violations?: string;
  explain_for_each_violation?: string;
  interest_in_teacher_or_educational_credential?: string;
  is_interested_eligibility_assistance_CalFresh?: string;
  how_hear_about_CalState?: string;
  is_interested_participating_CA_Promise?: string;
  have_rn_license?: string;
  rn_license?: string;
  agree_with_guiding_principles?: boolean;
}

export interface StudentBasicInfo {
  // Basic Personal Information
  id: string;
  first_name: string;
  middle_name?: string;
  last_name: string;
  email: string;
  dob: string;
  nick_name?: string;
  suffix?: string;
  created_at?: string;
  
  // Address Information
  country?: string;
  state?: string;
  city?: string;
  address_line_1?: string;
  address_line_2?: string;
  zip_code?: string;
  
  // Legal Name Information
  is_have_legal_name?: boolean;
  legal_first_name?: string;
  legal_middle_name?: string;
  legal_family_name?: string;
  legal_first_name_you_go_by?: string;
  legal_my_first_name?: string;
  
  // Contact Preferences
  is_share_different_first_name?: boolean;
  is_have_alternate_address?: boolean;
  is_should_send_mail?: boolean;
  is_temporary_address?: boolean;
  temporary_from_date?: string;
  temporary_to_date?: string;
  
  // Gender and Identity
  sex?: string;
  sex_self_describe?: string;
  sex_self_consider?: string;
  gender_identity_description?: string;
  
  // Social Security
  is_have_us_social_security_number?: string;
  social_security_number?: string;
  
  // California Specific
  is_graduated_california_high_school?: string;
  is_three_or_more_year_graduated_california_high_school?: string;
  is_currently_live_california?: string;
  
  // Profile
  profile_picture?: string;
  
  // CSU Specific Information
  csu_info?: CSUInfo;
  
  // Status Information
  status?: 'active' | 'inactive';
  student_id?: number;
}

export interface Student {
  id: string;
  basicInfo?: StudentBasicInfo;
  // Add other student-related fields as needed
}

export interface CounselorState {
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
  studentList: Student[];
  studentBasicInfo: StudentBasicInfo | null;
}

// Initial state
const initialState: CounselorState = {
  status: 'idle',
  error: null,
  studentList: [],
  studentBasicInfo: null,
};

// Async thunks
export const getStudentBasicInfo = createAsyncThunk<
  StudentBasicInfo,
  string,
  { rejectValue: string }
>(
  'counselor/getStudentBasicInfo',
  async (studentId: string, { rejectWithValue }) => {
    try {
      const response = await basicInformationServices.getBasicDetail(studentId);
      if (!response) {
        return rejectWithValue('No response received from server');
      }
      return response.data;
    } catch (err) {
      const error = err as AxiosError;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data as string);
    }
  }
);

// Actions
export const setStudentList = createAction<Student[]>('counselor/setStudentList');
export const setStudentBasicInfo = createAction<StudentBasicInfo>('counselor/setStudentBasicInfo');

// Slice
export const counselorSlice = createSlice({
  name: 'counselor',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    clearStudentBasicInfo: (state) => {
      state.studentBasicInfo = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setStudentList, (state, action: PayloadAction<Student[]>) => {
        state.studentList = action.payload;
      })
      .addCase(setStudentBasicInfo, (state, action: PayloadAction<StudentBasicInfo>) => {
        state.studentBasicInfo = action.payload;
      })
      // Handle async thunk states
      .addCase(getStudentBasicInfo.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getStudentBasicInfo.fulfilled, (state, action) => {
        state.status = 'idle';
        state.studentBasicInfo = action.payload;
        state.error = null;
      })
      .addCase(getStudentBasicInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ?? 'Failed to fetch student information';
      });
  },
});

export const { clearError, clearStudentBasicInfo } = counselorSlice.actions;
export default counselorSlice.reducer;
