import { Card, Stack, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import Cookies from 'js-cookie';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import CustomTabPanel from './TabPanel';
import LoadingContent from 'components/LoadingContent';
import { Button, Row } from 'react-bootstrap';
import { USER_ROLE } from 'constants/common';
import ItemContent from 'screen/summary/components/ItemContent';
import activitiesAndHonorsServices from 'services/activitiesAndHonorsServices';
import { buildClassName } from './RPersonalInformation';
import { ModalCheckField } from 'screen/common/ModalCheckField';

const RAdditionalInfo = ({ setCurrentModuleId }: { setCurrentModuleId: (id: string) => void }) => {
  const [myAdditionalInfo, setMyAdditionalInfo] = React.useState<any>();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const params: { id?: string | number } = useParams();
  const [show, setShow] = React.useState(false); // show model checkfield
  const handleCloseModalCheck = () => {
    setShow(false);
  };

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const getData = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      const res: any = await activitiesAndHonorsServices.getAdditionalInfo(params.id);
      setMyAdditionalInfo(res);
      setLoading(false);
    } else {
      handleLogout();
    }
  };

  React.useEffect(() => {
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  const [value, setValue] = React.useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setCurrentModuleId(`7.${newValue}`);
  };
  const checkModule = useAppSelector((store) => store.basicInformation.checkModule);

  const wordCount = (myAdditionalInfo?.additional_comment_1 || '')
    .split(/\s+/)
    .filter(Boolean).length;

  const wordCount2 = (myAdditionalInfo?.additional_comment_2 || '')
    .split(/\s+/)
    .filter(Boolean).length;

  return (
    <div>
      <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
        <Tab value={1} label="General" className={buildClassName('7.1', checkModule)} />
        <Tab value={2} label="Common App" className={buildClassName('7.2', checkModule)} />
        <Tab value={3} label="UC App" className={buildClassName('7.3', checkModule)} />
      </Tabs>
      <CustomTabPanel value={value} index={1}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Stack justifyContent={'center'} alignItems={'center'}>
            {loading ? (
              <LoadingContent />
            ) : (
              <Row className="w-100">
                <div className="d-flex justify-content-between mb-4">
                  <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
                    General
                  </Typography>
                  <div>
                    {localStorage.getItem('userRole') === USER_ROLE.student && (
                      <Button variant="primary" className="p-2 px-3" onClick={() => setShow(true)}>
                        Check missing values
                      </Button>
                    )}
                  </div>
                </div>
                <div className="text-field-light p-2">
                  Community disruptions such as COVID-19 and natural disasters can have deep and
                  long-lasting impacts. If you need it, this space is yours to describe those
                  impacts. Colleges care about the effects on your health and well-being, safety,
                  family circumstances, future plans, and education, including access to reliable
                  technology and quiet study spaces. For more information, check out our COVID-19
                  FAQ
                </div>
                <Row>
                  {myAdditionalInfo && (
                    <>
                      <ItemContent
                        mdCol={12}
                        values={myAdditionalInfo?.wish_to_share_option}
                        label="Do you wish to share anything on this topic?*"
                      />
                      {myAdditionalInfo?.wish_to_share_option === 'true' && (
                        <ItemContent
                          usePreTag={true}
                          mdCol={12}
                          label="Please use this space to describe how these events have impacted you.*"
                          values={myAdditionalInfo?.additional_comment_3}
                          countWord={250}
                          isOverCount={true}
                        />
                      )}
                      <ItemContent
                        mdCol={12}
                        values={myAdditionalInfo?.wish_to_provide_detail_of_circumstances_option}
                        label="Do you wish to provide details of circumstances or qualifications not reflected in the application?*"
                      />
                      {myAdditionalInfo?.wish_to_provide_detail_of_circumstances_option ===
                        'true' && (
                        <ItemContent
                          usePreTag={true}
                          mdCol={12}
                          label="Additional Information"
                          values={myAdditionalInfo?.additional_comment_1}
                          countWord={650}
                          isOverCount={true}
                        />
                      )}
                      <ItemContent
                        usePreTag={true}
                        mdCol={12}
                        label="Additional Comments"
                        values={myAdditionalInfo?.additional_comment_2}
                        countWord={550}
                        isOverCount={true}
                      />
                    </>
                  )}
                </Row>
              </Row>
            )}
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <div className="d-flex justify-content-between mb-4">
            <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
              Common App
            </Typography>
            <div>
              {localStorage.getItem('userRole') === USER_ROLE.student && (
                <Button variant="primary" className="p-2 px-3" onClick={() => setShow(true)}>
                  Check missing values
                </Button>
              )}
            </div>
          </div>
          <Stack justifyContent={'center'} alignItems={'center'}>
            {loading ? (
              <LoadingContent />
            ) : (
              <Row className="w-100">
                {myAdditionalInfo?.wish_to_provide_detail_of_circumstances_option === 'true' && (
                  <ItemContent
                    usePreTag={true}
                    mdCol={12}
                    label="Additional Information"
                    values={myAdditionalInfo?.additional_comment_1}
                    countWord={650}
                    isOverCount={true}
                  />
                )}

                <Stack direction="row" justifyContent={'end'} className="w-100">
                  <span>Word count: {wordCount}/650</span>
                </Stack>
              </Row>
            )}
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <div className="d-flex justify-content-between mb-4">
            <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
              UC App
            </Typography>
            <div>
              {localStorage.getItem('userRole') === USER_ROLE.student && (
                <Button variant="primary" className="p-2 px-3" onClick={() => setShow(true)}>
                  Check missing values
                </Button>
              )}
            </div>
          </div>
          <Stack justifyContent={'center'} alignItems={'center'}>
            {loading ? (
              <LoadingContent />
            ) : (
              <Row className="w-100">
                <ItemContent
                  usePreTag={true}
                  mdCol={12}
                  label="Additional Comments"
                  values={myAdditionalInfo?.additional_comment_2}
                />
                <Stack direction="row" justifyContent="end" className="w-100">
                  <span>Word count: {wordCount2}/550</span>
                </Stack>
              </Row>
            )}
          </Stack>
        </Card>
      </CustomTabPanel>
      {localStorage.getItem('userRole') === USER_ROLE.student && myAdditionalInfo && (
        <ModalCheckField
          show={show}
          onClose={handleCloseModalCheck}
          data={myAdditionalInfo}
          page={'additional-info'}
        />
      )}
    </div>
  );
};

export default RAdditionalInfo;
