export const USER_ROLE = {
  student: 'student',
  counselor: 'counselor',
};

export const RECEIVE_NOTIFICATION_TYPE = {
  email: 'email',
  phone: 'phone',
};

export const INVITATION_STATUS = {
  pending: 'pending',
  approved: 'approved', //accepted:   'accepted',
  rejected: 'rejected',
  removed: 'removed',
  withdrawn: 'withdrawn',
};

export enum UserRole {
  student = 'Student',
  counselor = 'Counselor',
}

export enum ReceiveNoticationType {
  email = 'email',
  phone = 'phone',
}

export enum InviteStatus {
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
  removed = 'removed',
  withdrawn = 'withdrawn',
}

export const STRIPE_SUBSCRIPTION_STATUS = {
  active: 'active',
  canceled: 'canceled',
  incomplete: 'incomplete',
  incomplete_expired: 'incomplete_expired',
  past_due: 'past_due',
  paused: 'paused',
  trialing: 'trialing',
  unpaid: 'unpaid',
};

export const STRIPE_PRICE_NAME = {
  SilverMonth: 'silver_month',
  SilverYear: 'silver_year',
  GoldMonth: 'gold_month',
  GoldYear: 'gold_year',
  SilverPlusMonth: 'silver_plus_month',
  SilverPlusYear: 'silver_plus_year',
};

export const BASIC_INFO_FIELS_COMMON = [
  {
    key: 'csu_info.emailType',
    name: 'Email Address Type',
    page: '/application/basic-information?check=1',
  },
  {
    key: 'first_name',
    name: 'First/given name',
    page: '/application/basic-information?check=1',
  },
  {
    key: 'last_name',
    name: 'Last/family/surname',
    page: '/application/basic-information?check=1',
  },
  {
    key: 'email',
    name: 'Email',
    page: '/application/basic-information?check=1',
  },
  {
    key: 'dob',
    name: 'Date of birth',
    page: '/application/basic-information?check=1',
  },
  {
    key: 'country',
    name: 'Country',
    page: '/application/basic-information?check=1',
  },
  {
    key: 'city',
    name: 'City',
    page: '/application/basic-information?check=1',
  },
  {
    key: 'state',
    name: 'State',
    page: '/application/basic-information?check=1',
  },
  {
    key: 'csu_info.have_lived_name',
    name: 'Do you have a lived /preferred name?',
    page: '/application/basic-information?check=1',
  },
  {
    key: 'csu_info.legal_sex',
    name: 'Legal sex',
    page: '/application/basic-information?check=1',
  },
  {
    key: 'sex_self_describe',
    name: 'How do you describe yourself?',
    page: '/application/basic-information?check=1',
  },
  {
    key: 'sex_self_consider',
    name: 'What do you consider yourself to be?',
    page: '/application/basic-information?check=1',
  },
  {
    key: 'csu_info.gender_identity',
    name: 'How do you describe the way you express your gender identity in terms of behavior, appearance, speech, and movement?',
    page: '/application/basic-information?check=1',
  },
  {
    key: 'sex',
    name: 'Sex',
    page: '/application/basic-information?check=1',
  },
  {
    key: 'address_line_1',
    name: 'Address Line 1',
    page: '/application/basic-information?check=1',
  },
  {
    key: 'phone_number',
    name: 'Contact Phone Number',
    page: '/application/contact-details?check=1',
  }
];

export const DEMOGRAPHICS_FIELDS_COMMON = [
  {
    key: 'all_apply_array',
    name: 'Check any of the following that apply to you',
    page: '/application/demographics?check=1',
  },
  {
    key: 'us_armed_forces_status',
    name: 'U.S. Armed Forces Status',
    page: '/application/demographics?check=1',
  },
  {
    key: 'best_group_describe_racial_background_array.csu_reporting_preferences',
    name: 'California State University often needs to report ONLY ONE summary race/ethnicity description for a person. Please select your reporting preferences',
    page: '/application/demographics?check=1',
  },
  {
    key: 'is_complete_satisfaction',
    name: `The questions in this section, while helpful to colleges, are entirely optional, and
            you're welcome to move on without answering them. Before you do, please confirm for us
            that you've completed this section to your satisfaction.`,
    page: '/application/demographics?check=1',
  },
  {
    key: 'number_language_proficient',
    name: `Number of languages you are proficient in.`,
    page: '/application/demographics?check=1',
  },
];

export const CITIZENSHIP_FIELDS_COMMON = [
  {
    key: 'current_citizenship_status',
    name: 'Select your citizenship status',
    page: '/application/citizenship?check=1',
  },
  {
    key: 'csu_info.current_working_with_representative_or_agency',
    name: 'Are you currently working with a representative or agency?',
    page: '/application/citizenship?check=1',
  },
  {
    key: 'country_birthplace',
    name: 'Country / Region / Territory',
    page: '/application/citizenship?check=1',
  },
  {
    key: 'city_birthplace',
    name: 'City of birth',
    page: '/application/citizenship?check=1',
  },
  {
    key: 'statement_legal_resident.have_lived_in_California_continuously_since_birth',
    name: 'Have you lived in California continuously since birth?',
    page: '/application/citizenship?check=1',
  },
  {
    key: 'statement_legal_resident.is_established_as_permanent_home',
    name: 'Have you and your parents established California as your permanent home for the past 366 days or more?',
    page: '/application/citizenship?check=1',
  },
  {
    key: 'statement_legal_resident.is_like_to_be_evaluated_for_tuition_purposes',
    name: 'Would you like to be evaluated for California residency for tuition purposes ?',
    page: '/application/citizenship?check=1',
  }
];

export const CBO_FIELDS_COMMON = [
  {
    key: 'avid',
    name: 'AVID',
    page: '/application/cbo-and-fee-waiver?check=1',
  },
  {
    key: 'upward_bound',
    name: 'Upward Bound',
    page: '/application/cbo-and-fee-waiver?check=1',
  },
  {
    key: 'talent_search',
    name: 'Talent Search or Educational Opportunity Center (EOC)',
    page: '/application/cbo-and-fee-waiver?check=1',
  },
  {
    key: 'puente_project',
    name: 'Puente Project',
    page: '/application/cbo-and-fee-waiver?check=1',
  },
  {
    key: 'ilp',
    name: 'Independent Living Project (ILP)',
    page: '/application/cbo-and-fee-waiver?check=1',
  },
  {
    key: 'MESA_project',
    name: 'MESA Project',
    page: '/application/cbo-and-fee-waiver?check=1',
  },
  {
    key: 'other_program',
    name: 'Other (CAMP, EAOP, EOP&S/CARE GEAR-UP, TRIO, or Foster youth support programs)',
    page: '/application/cbo-and-fee-waiver?check=1',
  },
];

export const OTHER_FIELDS_COMMON = [
  {
    key: 'csu_info.is_certify',
    name: 'Your certification of this statement serves the same purpose as a legal signature, and is binding',
    page: '/application/other-information?check=1',
  },
  {
    key: 'csu_info.is_disciplined_for_student_conduct_violations',
    name: 'Have you ever experienced discipline for student conduct violations (e.g. suspension, expulsion, dismissal etc.) by any college or university?',
    page: '/application/other-information?check=1',
  },
  {
    key: 'csu_info.is_disciplined_for_academic_performance',
    name: 'Have you ever experienced academic probation or academic disqualification by any college or university?',
    page: '/application/other-information?check=1',
  },
  {
    key: 'csu_info.is_interested_on_campus_space_available',
    name: 'Are you interested in on campus housing should space be available?* Note: housing availability is subject to your submission of a separate campus housing application and availability.',
    page: '/application/other-information?check=1',
  },
  {
    name: 'At any time since you turned age 13, were both your parents deceased, were you in foster care or were you a dependent or ward of the court?',
    key: 'csu_info.in_foster_care',
    page: '/application/other-information?check=1',
  },
  {
    name: 'At any time on or after July 1, 2024, did the director of a runaway or homeless youth basic center or transitional living program determine that you were an unaccompanied youth who was homeless or were self-supporting and at risk of being homeless?',
    key: 'csu_info.is_director_of_runaway_or_homeless_youth_basic',
    page: '/application/other-information?check=1',
  },
  {
    name: 'At any time on or after July 1, 2024, did the director of an emergency shelter or transitional housing program funded by the U.S. Department of Housing and Urban Development determine that you were an unaccompanied youth who was homeless or were self-supporting and at risk of being homeless?',
    key: 'csu_info.is_director_or_transitional_determine',
    page: '/application/other-information?check=1',
  },
  {
    name: 'At any time on or after July 1, 2024, did your high school or school district homeless liaison determine that you were an unaccompanied youth who was homeless or were self-supporting and at risk of being homeless?',
    key: 'csu_info.is_homeless_liaison_determine',
    page: '/application/other-information?check=1',
  },
  {
    name: 'Have you ever attended a California public school?',
    key: 'csu_info.attended_California_public_school',
    page: '/application/other-information?check=1',
  },
  {
    key: 'csu_info.is_in_good_standing',
    name: 'Are you in good standing with your last school attended?',
    page: '/application/other-information?check=1',
  },
  {
    key: 'csu_info.interest_in_teacher_or_educational_credential',
    name: 'Select your interest in obtaining your teacher or other educational credential.',
    page: '/application/other-information?check=1',
  },
  {
    name: 'Are you interested in checking your eligibility for monthly food assistance to be used towards groceries through the CalFresh Program?',
    key: 'csu_info.is_interested_eligibility_assistance_CalFresh',
    page: '/application/other-information?check=1',
  },
  {
    name: 'How did you hear about CalState.edu/apply?',
    key: 'csu_info.how_hear_about_CalState',
    page: '/application/other-information?check=1',
  },
  {
    name: 'Do you have an RN (registered nurse) license or expect to achieve a RN license?',
    key: 'csu_info.have_rn_license',
    page: '/application/other-information?check=1',
  },
  {
    name: 'I acknowledge, value, and agree with these guiding principles',
    key: 'csu_info.agree_with_guiding_principles',
    page: '/application/other-information?check=1',
  },
];

export const HOUSEHOLD_FIELDS_COMMON = [
  {
    key: 'csu_info.household_income_information_statements',
    name: 'Do any of the above statements apply to you?',
    page: '/application/household?check=1',
  },
  {
    key: 'parent_martial_status',
    name: "Parents'/Guardians' Marital Status (relative to each other)",
    page: '/application/household?check=1',
  },
  {
    key: 'whom_live_permanently',
    name: 'With whom do you make your permanent home',
    page: '/application/household?check=1',
  },
  {
    key: 'household_income',
    name: 'What was the total income earned in 2023 by your parents or legal guardians?',
    page: '/application/household?check=1',
  },
  {
    key: 'is_have_any_children',
    name: 'Do you have any children?',
    page: '/application/household?check=1',
  },
];

export const PARENT1_FIELDS_COMMON = [
  {
    key: 'is_parent_1_living',
    name: 'Is “Parent No.1” living?',
    page: '/application/parent-1?check=1',
  },
  {
    key: 'relationship_type',
    name: 'Parent type',
    page: '/application/parent-1?check=1',
  },
  {
    key: 'relationship_to_you',
    name: 'Relationship to you',
    page: '/application/parent-1?check=1',
  },
  {
    key: 'first_name',
    name: 'First/Given name',
    page: '/application/parent-1?check=1',
  },
  {
    key: 'last_name',
    name: 'Last Name',
    page: '/application/parent-1?check=1',
  },
  {
    key: 'birth_country',
    name: 'Birth country/region',
    page: '/application/parent-1?check=1',
  },
  {
    key: 'prefered_email',
    name: 'Email address',
    page: '/application/parent-1?check=1',
  },
  {
    key: 'country_code',
    name: 'Country Code',
    page: '/application/parent-1?check=1',
  },
  {
    key: 'phone_number',
    name: 'Phone Number',
    page: '/application/parent-1?check=1',
  },
  {
    key: 'parent_address_is',
    name: 'Parent Address is',
    page: '/application/parent-1?check=1',
  },
  {
    key: 'csu_info.is_give_permission_communicate_to',
    name: 'Do you give permission for the admission office to communicate with your Parent/Guardian regarding your application?',
    page: '/application/parent-1?check=1',
  },
  {
    key: 'csu_info.is_give_permission_to_receive_information',
    name: 'Do you give permission for your parent/guardian to receive university communications? ',
    page: '/application/parent-1?check=1',
  },
  {
    key: 'parent_current_job_status',
    name: 'Parent current job status',
    page: '/application/parent-1?check=1',
  },
  {
    key: 'employment_status',
    name: 'Employment status',
    page: '/application/parent-1?check=1',
  },
  {
    key: 'job_category',
    name: 'Job category',
    page: '/application/parent-1?check=1',
  },
  {
    key: 'job_title',
    name: 'Job title',
    page: '/application/parent-1?check=1',
  },

  {
    key: 'education_level',
    name: 'Education Level',
    page: '/application/parent-1?check=1',
  },
  {
    key: 'csu_info.isCurrentPermanentResidentOfCalifornia',
    name: 'Is your parent/guardian a current permanent resident of California?',
    page: '/application/parent-1?check=1',
  },
];

export const PARENT2_FIELDS_COMMON = [
  {
    key: 'is_parent_2_living',
    name: 'Is “Parent No.2” living?',
    page: '/application/parent-2?check=1',
  },
  {
    key: 'relationship_type',
    name: 'Parent type',
    page: '/application/parent-2?check=1',
  },
  {
    key: 'relationship_to_you',
    name: 'Relationship to you',
    page: '/application/parent-2?check=1',
  },
  {
    key: 'first_name',
    name: 'First/Given name',
    page: '/application/parent-2?check=1',
  },
  {
    key: 'last_name',
    name: 'Last Name',
    page: '/application/parent-2?check=1',
  },
  {
    key: 'birth_country',
    name: 'Birth country/region',
    page: '/application/parent-2?check=1',
  },
  {
    key: 'prefered_email',
    name: 'Email address',
    page: '/application/parent-2?check=1',
  },
  {
    key: 'country_code',
    name: 'Country Code',
    page: '/application/parent-2?check=1',
  },
  {
    key: 'phone_number',
    name: 'Phone Number',
    page: '/application/parent-2?check=1',
  },
  {
    key: 'parent_address_is',
    name: 'Parent Address is',
    page: '/application/parent-2?check=1',
  },
  {
    key: 'csu_info.is_give_permission_communicate_to',
    name: 'Do you give permission for the admission office to communicate with your Parent/Guardian regarding your application?',
    page: '/application/parent-2?check=1',
  },
  {
    key: 'csu_info.is_give_permission_to_receive_information',
    name: 'Do you give permission for your parent/guardian to receive university communications? ',
    page: '/application/parent-2?check=1',
  },
  {
    key: 'parent_current_job_status',
    name: 'Parent current job status',
    page: '/application/parent-2?check=1',
  },
  {
    key: 'employment_status',
    name: 'Employment status',
    page: '/application/parent-2?check=1',
  },
  {
    key: 'job_category',
    name: 'Job category',
    page: '/application/parent-2?check=1',
  },
  {
    key: 'job_title',
    name: 'Job title',
    page: '/application/parent-2?check=1',
  },
  {
    key: 'education_level',
    name: 'Education Level',
    page: '/application/parent-2?check=1',
  },
  {
    key: 'csu_info.isCurrentPermanentResidentOfCalifornia',
    name: 'Is your parent/guardian a current permanent resident of California?',
    page: '/application/parent-2?check=1',
  },
];

export const SIBLING_FIELDS_COMMON = [
  {
    key: 'number_of_siblings',
    name: 'Please specify number of siblings you have',
    page: '/application/siblings?check=1',
  },
];

export const STEP_PARENT_FIELDS_COMMON = (data: any) => {
  return [
    {
      key: `stepParent${data?.index}.step_parent_is_married`,
      name: `Step-parent ${data?.index} is married to`,
      page: `/application/step-parent-${data?.index}?check=1`,
    },
    {
      key: `stepParent${data?.index}.step_parent_type`,
      name: `Step-parent ${data?.index} type`,
      page: `/application/step-parent-${data?.index}?check=1`,
    },
    {
      key: `stepParent${data?.index}.step_parent_is_living`,
      name: `Is Step-parent ${data?.index} living?`,
      page: `/application/step-parent-${data?.index}?check=1`,
    },
    {
      key: `stepParent${data?.index}.first_name`,
      name: `Step-parent ${data?.index} First/Given name?`,
      page: `/application/step-parent-${data?.index}?check=1`,
    },
    {
      key: `stepParent${data?.index}.last_name`,
      name: `Step-parent ${data?.index} Last Name`,
      page: `/application/step-parent-${data?.index}?check=1`,
    },
    {
      key: `stepParent${data?.index}.step_parent_relationship`,
      name: `Step-parent ${data?.index} Relationship to you`,
      page: `/application/step-parent-${data?.index}?check=1`,
    },
    {
      key: `stepParent${data?.index}.step_parent_email`,
      name: `Step-parent ${data?.index} Email Address`,
      page: `/application/step-parent-${data?.index}?check=1`,
    },
    {
      key: `stepParent${data?.index}.phone_number_type`,
      name: `Step-parent ${data?.index} Preferred phone`,
      page: `/application/step-parent-${data?.index}?check=1`,
    },
    {
      key: `stepParent${data?.index}.phone_number`,
      name: `Step-parent ${data?.index} phone number`,
      page: `/application/step-parent-${data?.index}?check=1`,
    },
    {
      key: `stepParent${data?.index}.csu_info.parent_address_is`,
      name: `Step-parent ${data?.index} Parent Address is`,
      page: `/application/step-parent-${data?.index}?check=1`,
    },
    {
      key: `stepParent${data?.index}.csu_info.is_give_permission_communicate_to`,
      name: `Step-parent ${data?.index} Do you give permission for the admission office to communicate with your Parent/Guardian regarding your application?`,
      page: `/application/step-parent-${data?.index}?check=1`,
    },
    {
      key: `stepParent${data?.index}.csu_info.is_give_permission_to_receive_information`,
      name: `Step-parent ${data?.index} Do you give permission for your parent/guardian to receive university communications? `,
      page: `/application/step-parent-${data?.index}?check=1`,
    },
  ];
};

export const HIGH_SCHOOL_FIELDS_COMMON = [
  {
    key: 'citizenshipData.current_enrollment_status',
    name: 'What is your current enrollment status?',
    page: '/application/enrollment-status?check=1',
  },
  {
    key: 'citizenshipData.when_graduate_high_school',
    name: 'When will you graduate from high school or receive high school equivalency',
    page: '/application/enrollment-status?check=1',
  },
  {
    key: 'myHighSchool.graduating_school_array.graduation_status',
    name: 'Graduation Status',
    page: '/application/high-school?check=1',
  },
  {
    key: 'myHighSchool.graduating_school_array.graduating_school_year',
    name: 'Most recent academic year',
    page: '/application/high-school?check=1',
  },
  {
    key: 'myHighSchool.graduating_school_array.graduating_school_gpa_type',
    name: 'GPA Weighting',
    page: '/application/high-school?check=1',
  },
  {
    key: 'myHighSchool.graduating_school_array.graduating_school_gpa_scale',
    name: 'GPA Scale',
    page: '/application/high-school?check=1',
  },
  {
    key: 'myHighSchool.graduating_school_array.graduating_school_cumulative_gpa',
    name: 'Cumulative GPA',
    page: '/application/high-school?check=1',
  },
  {
    key: 'myHighSchool.graduating_school_array.graduating_school_rank_report',
    name: 'Class rank reporting',
    page: '/application/high-school?check=1',
  },

  {
    key: 'myHighSchool.graduating_school_array.rank_weighting',
    name: 'How are the grades for this class reported on your transcript',
    page: '/application/high-school?check=1',
  },
  {
    key: 'myHighSchool.graduating_school_array.graduating_school_class_size',
    name: 'Graduating Class Size (approx.)*',
    page: '/application/high-school?check=1',
  },
  {
    key: 'myHighSchool.counselor_first_name',
    name: "Counselor's First Name",
    page: '/application/high-school?check=1',
  },
  {
    key: 'myHighSchool.counselor_last_name',
    name: "Counselor's Last Name",
    page: '/application/high-school?check=1',
  },
];

export const FUTURE_PLAN_FIELDS_COMMON = [
  {
    key: 'which_best_describe_you',
    name: 'Which best describes you? I am:',
    page: '/application/future-plans?check=1',
  },
  {
    key: 'career_interest',
    name: 'Career Interest',
    page: '/application/future-plans?check=1',
  },
  {
    key: 'highest_degree_intend_to_earn',
    name: 'Highest Degree you intend to earn',
    page: '/application/future-plans?check=1',
  },
];

export const COLLEGE_FIELDS_COMMON = [
  {
    key: 'is_taken_college_courses',
    name: 'Have you taken college courses or earned college credit?',
    page: '/application/college-and-university?check=1',
  },
];

export const GENERAL_TEST_FIELDS_COMMON = [
  {
    key: 'is_wish_self_report_scores',
    name: 'In addition to sending official score reports as required by colleges, do you wish to self-report scores or future test dates for any of the following standardized tests: ACT, SAT/SAT Subject, AP, IB, CLEP, TOEFL, PTE Academic, and IELTS?',
    page: '/application/general-info?check=1',
  }
];

export const SAT_TEST_FIELDS_COMMON = [
  {
    key: 'satTest.number_of_past_sat_scores',
    name: 'Number of past SAT scores you wish to report',
    page: '/application/sat-test?check=1',
  },
  {
    key: 'satTest.taken_sat_essay_option',
    name: 'Have you taken the SAT essay?',
    page: '/application/sat-test?check=1',
  },
  {
    key: 'satTest.highest_evidence_reading_writing_score',
    name: 'Highest evidence-based reading and writing score',
    page: '/application/sat-test?check=1',
  },
  {
    key: 'satTest.evidence_reading_writing_date',
    name: 'Evidence-based reading and writing date',
    page: '/application/sat-test?check=1',
  },
  {
    key: 'satTest.highest_math_score_based_evidence_reading_writing_score',
    name: 'Highest math score',
    page: '/application/sat-test?check=1',
  },
  {
    key: 'satTest.math_reading_writing_date',
    name: 'Evidence-based math date',
    page: '/application/sat-test?check=1',
  },
  {
    key: 'satTest.future_sat_tests_plan_to_take',
    name: 'Number of future SAT sittings you expect',
    page: '/application/sat-test?check=1',
  },
];

export const ACT_TEST_FIELDS_COMMON = [
  {
    key: 'actTest.number_of_act_score_report',
    name: 'Number of past ACT scores you wish to report',
    page: '/application/act-test?check=1',
  },
  {
    name: 'Have you taken the ACT Plus Writing test?*',
    key: 'actTest.is_have_act_score_report',
    page: '/application/act-test?check=1',
  },
  {
    key: 'actTest.future_act_test_plan_to_take',
    name: 'Number of future ACT sittings you expect',
    page: '/application/act-test?check=1',
  },
  {
    key: 'actTest.highest_composite_score_sub',
    name: 'Highest Composite Score',
    page: '/application/act-test?check=1',
  },
  {
    key: 'actTest.highest_english_score_sub',
    name: 'Highest English Score',
    page: '/application/act-test?check=1',
  },
  {
    key: 'actTest.highest_mathematic_score_sub',
    name: 'Highest Mathematics Score',
    page: '/application/act-test?check=1',
  },
  {
    key: 'actTest.highest_reading_score_sub',
    name: 'Highest Reading Score',
    page: '/application/act-test?check=1',
  },
  {
    key: 'actTest.highest_science_score_sub',
    name: 'Highest Science Score',
    page: '/application/act-test?check=1',
  },

  {
    key: 'actTest.highest_composite_test_day',
    name: 'Highest Composite Score Test Date',
    page: '/application/act-test?check=1',
  },
  {
    key: 'actTest.highest_english_test_day',
    name: 'Highest English Score Test Date',
    page: '/application/act-test?check=1',
  },
  {
    key: 'actTest.highest_mathematic_test_day',
    name: 'Highest Mathematics Score Test Date',
    page: '/application/act-test?check=1',
  },
  {
    key: 'actTest.highest_reading_test_day',
    name: 'Highest Reading Score Test Date',
    page: '/application/act-test?check=1',
  },
  {
    key: 'actTest.highest_science_test_day',
    name: 'Highest Science Score Test Date',
    page: '/application/act-test?check=1',
  },
];

export const AP_TEST_FIELDS_COMMON = [];

export const IB_TEST_FIELDS_COMMON = [];

export const ACTIVITIES_FIELDS_COMMON = [
  {
    key: 'is_have_any_activity_to_report',
    name: 'Do you have any activities to report?',
    page: '/application/activities?check=1',
  },
];

export const HONORS_FIELDS_COMMON = [];

export const EOP_FIELDS_COMMON = [
  {
    key: 'average_hours_worked_per_week',
    name: 'What was the average number of hours worked per week over the most recent 36 months?',
    page: '/application/educational-programs?check=1',
  },
  {
    key: 'average_hours_activities_per_week',
    name: 'Select the average number of hours per week you are/were involved in activities during the last 36 months',
    page: '/application/educational-programs?check=1',
  },
  {
    key: 'wish_to_apply_EOP',
    name: 'Do you wish to apply to the Educational Opportunity Program (EOP)?',
    page: '/application/educational-programs?check=1',
  },
];
