import CardConfig from 'components/CardConfig';
import { formatDate, optionValues2 } from 'components/helper/helper';
import DatePickerInput from 'components/input/DatePicker';
import SelectInput from 'components/input/SelectInput';
import TextInput from 'components/input/textInput';
import { useEffect } from 'react';

import { Row } from 'react-bootstrap';

type Props = {
  form: any;
  index: number;
  check?: any;
};

const SATItem = ({ form, index, check }: Props) => {
  const formValues = form.values?.csu_info?.sat_score[index];
  const formName = `csu_info.sat_score[${index}].`;

  const buildHeader = () => {
    let title = `SAT Test Scores (${index + 1})`;
    const details = [];

    if (formValues?.test_date) {
      details.push(formatDate(formValues.test_date, 'MM-DD-YYYY'));
    }

    if (formValues?.total_score) {
      details.push(`Total Score: ${formValues.total_score}`);
    }

    if (formValues?.reading_writing_score) {
      details.push(`Reading/Writing Score: ${formValues.reading_writing_score}`);
    }

    if (formValues?.math_score) {
      details.push(`Math: ${formValues.math_score}`);
    }

    if (!details.length) {
      return title;
    }

    switch (details.length) {
      case 1:
        return `${title} - ${details[0]}`;
      case 2:
        return `${title} - ${details[0]} - ${details[1]}`;
      default:
        return `${title} - ${[
          details.slice(0, 2).join(' - '),
          details.slice(2, details.length).join(', '),
        ].join(', ')}`;
    }
  };

  const renderFillAppStatus = () => {
    return (
      <span>
        <img
          alt="csu"
          src="./assets/icons/icon-csu-5.svg"
          style={{ marginTop: '-8px', marginLeft: '4px' }}
        />
      </span>
    );
  };

  useEffect(() => {
    if (formValues) {
      const { essay_reading, essay_analysis, essay_writing } = formValues;
      const newEssayScore =
        Number(essay_reading || 0) + Number(essay_analysis || 0) + Number(essay_writing || 0);

      if (formValues.essay_scores !== newEssayScore) {
        form.setFieldValue(formName + 'essay_scores', newEssayScore);
      }
    }
  }, [
    formValues?.essay_reading,
    formValues?.essay_analysis,
    formValues?.essay_writing,
    formValues?.essay_scores,
    formName,
    form,
  ]);
  return !formValues ? null : (
    <CardConfig
      headerElement={
        <>
          {buildHeader()} {renderFillAppStatus()}
        </>
      }
      expandAll={check ? true : false}
    >
      <Row>
        <DatePickerInput
          label="Test Date *"
          name={formName + 'test_date'}
          values={formValues.test_date}
          onChange={(value) => form.setFieldValue(formName + 'test_date', value)}
          isMissing={!formValues.test_date && check}
        />
        <SelectInput
          label="Total Score *"
          name={formName + 'total_score'}
          option={optionValues2(121, 40, 10).reverse()}
          values={formValues.total_score}
          onChange={form.handleChange}
          isMissing={!formValues.total_score && check}
        />
        <SelectInput
          label="Reading/Writing Score*"
          onChange={form.handleChange}
          name={formName + 'reading_writing_score'}
          option={optionValues2(61, 20, 10).reverse()}
          values={formValues.reading_writing_score}
          isMissing={!formValues.reading_writing_score && check}
        />
        <SelectInput
          label="Math Score*"
          name={formName + 'math_score'}
          option={optionValues2(61, 20, 10).reverse()}
          onChange={form.handleChange}
          values={formValues.math_score}
          isMissing={!formValues.math_score && check}
        />
        {form.values.taken_sat_essay_option === 'true' && (
          <>
            <TextInput
              label="Essay Reading"
              name={formName + 'essay_reading'}
              values={formValues.essay_reading}
              onChange={(e) => {
                form.handleChange(e);
              }}
              type="number"
              min={0}
            />
            <TextInput
              label="Essay Analysis"
              name={formName + 'essay_analysis'}
              values={formValues.essay_analysis}
              onChange={(e) => {
                form.handleChange(e);
              }}
              type="number"
              min={0}
            />
            <TextInput
              label="Essay Writing"
              name={formName + 'essay_writing'}
              values={formValues.essay_writing}
              onChange={(e) => {
                form.handleChange(e);
              }}
              type="number"
              min={0}
            />

            <TextInput
              label="Combined Essay Scores"
              name={formName + 'essay_scores'}
              values={formValues.essay_scores}
              disabled={true}
            />
          </>
        )}
      </Row>
    </CardConfig>
  );
};

export default SATItem;
