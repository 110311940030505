/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Col, Container, Form, Row, Card, Button, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import SelectPhoneCodeInput from 'components/input/SelectPhoneCodeInput';
import TextIconInput from 'components/input/TextIconInput';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import CheckboxInput from 'components/input/CheckboxInput';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getContactDetail, updateContact } from 'app/basicInformationSlice/basicInformationSlice';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import { BoolToString, formatBoolean } from 'components/helper/helper';
import LoadingContent from 'components/LoadingContent';
import { contactDetailRules } from './validations';
import YesNoSwitch from 'components/input/YesNoSwitch';
import { debounce } from 'lodash';
import GroupRadioButton from 'components/input/GroupRadioButton';
import { useLocation } from 'react-router-dom';

interface Props { }

const ContactDetailPage = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const basicInformationStore = useAppSelector((store) => store.basicInformation);
  const query = new URLSearchParams(useLocation().search);
  const check = query.get('check');

  const [alternativePhone, setAlternativePhone] = React.useState(true);

  const formik = useFormik({
    initialValues: {
      country_code: undefined,
      phone_number: undefined,
      phone_number_type: 'home',
      is_have_alternative_phone_number: '',
      alternative_country_code: undefined,
      alternative_phone_number: undefined,
      alternative_phone_number_type: 'home',
      is_allowed_share_contact: true,
      is_authorized_text_message_sent: false,
      // ----
      is_have_international_phone_number: false,
      alternative_is_authorized_text_message_sent: true,
      is_agree_csu_term: false,
      // ----
      current_enrollment_status: '',
      year_plan_college_studies: '',
      is_need_based_financial_aid: '',
      is_inconsistent_access_internet: false,
      is_lost_my_job: false,
      is_parent_lost_their_job: false,
      is_essential_worker: false,
      is_parent_essential_worker: false,
      is_in_curfew_community: false,
      is_changed_home_responsibilities: false,
      is_health_affected: false,
      is_household_helth_affected: false,
      is_none_applied: false,
      is_none_above_apply_to_me: false,
      is_have_additional_information: false,
      additional_information: '',
      alternative_is_have_international_phone_number: false,
    },
    validationSchema: contactDetailRules,
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        is_have_alternative_phone_number: formatBoolean(values.is_have_alternative_phone_number),
        student_id: parseInt(studentId || ''),
      };

      window.Intercom('update', {
        phone: values.phone_number,
      });

      await dispatch(updateContact(newValues));
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = async () => {
    await formik.handleSubmit();
    history.push(routers.demographics.path);
  };
  React.useEffect(() => {
    setAlternativePhone(formik.values.is_have_alternative_phone_number === 'true');
  }, [formik.values.is_have_alternative_phone_number]);
  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      let res: any = await dispatch(getContactDetail(studentId));
      const dataLoaded: any = { ...res.payload };
      const values: any = {
        ...formik.values,
        ...dataLoaded,
        is_have_international_phone_number: dataLoaded.is_have_international_phone_number,
        is_have_alternative_phone_number:
          dataLoaded.is_have_alternative_phone_number === null
            ? 'true'
            : BoolToString(dataLoaded.is_have_alternative_phone_number),
        phone_number_type: dataLoaded.phone_number_type ?? 'home',
        alternative_phone_number_type: dataLoaded.alternative_phone_number_type ?? 'home',
        alternative_is_have_international_phone_number:
          dataLoaded.alternative_is_have_international_phone_number === null
            ? false
            : dataLoaded.alternative_is_have_international_phone_number,
      };

      window.Intercom('update', {
        phone: values.phone_number,
      });

      formik.setValues(values);
      setLoading(false);
      formik.setStatus('idle');
    } else {
      handleLogout();
    }
  };
  React.useEffect(() => {
    getDetail();
  }, []);
  const [disabledField, setDisabledField] = React.useState(false);
  React.useEffect(() => {
    disabledField &&
      formik.setValues({
        ...formik.values,
        is_inconsistent_access_internet: false,
        is_lost_my_job: false,
        is_parent_lost_their_job: false,
        is_essential_worker: false,
        is_parent_essential_worker: false,
        is_in_curfew_community: false,
        is_changed_home_responsibilities: false,
        is_health_affected: false,
        is_household_helth_affected: false,
      });
  }, [disabledField]);
  React.useEffect(() => {
    formik.values.is_none_applied ? setDisabledField(true) : setDisabledField(false);
  }, [formik.values.is_none_applied]);

  // Auto save function ==========
  React.useEffect(() => {
    if (!!formik.status) debounceSaving();
  }, [formik.values]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (!loading) formik.handleSubmit();
    }, 1500),
    []
  );
  // Auto save function ==========
  return loading ? (
    <LoadingContent />
  ) : (
    <div>
      <TopProcessBar
        stepProcess={[]}
        title="Personal Information"
        process={1}
        icon={<PersonOutlineOutlinedIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <div className="text-field-mid fs-3 mb-4">Contact Details</div>
            <div className="fs-5 p-1">What is your phone number?</div>
            <CheckboxInput
              name="is_have_international_phone_number"
              values={formik.values.is_have_international_phone_number}
              label="I have an international phone number"
              onChange={formik.handleChange}
            />
            <div className="text-field-light">
              This address is your permanent address. It’s most likely where your parents or legal
              guardians live. If you’re an international student, please enter your home country
              address.
            </div>

            <Row>
              {formik.values.is_have_international_phone_number && (
                <SelectPhoneCodeInput
                  label="Country Code*"
                  name="country_code"
                  onChange={formik.handleChange}
                  values={formik.values.country_code}
                  isMissing={!formik.values.country_code && check ? true : false}
                />
              )}
              <TextIconInput
                icon={<PhoneAndroidIcon />}
                name="phone_number"
                onChange={formik.handleChange}
                values={formik.values.phone_number}
                label="Phone Number*"
                hiddenFeedback={true}
                isMissing={!formik.values.phone_number && check ? true : false}
              />
              <GroupRadioButton
                mdCol={6}
                name={'phone_number_type'}
                hiddenFormLabel={true}
                values={formik.values.phone_number_type}
                onChange={formik.handleChange}
                options={[
                  { label: 'Home / Other', value: 'home' },
                  { label: 'Cell / Mobile', value: 'cell' },
                ]}
              />
            </Row>
            {formik.values.phone_number_type === 'cell' && (
              <div className="my-1">
                <CheckboxInput
                  logo="span-uc-logo"
                  setLabelHight={true}
                  mdCol={'12'}
                  label={
                    'I authorize text messages (for example, reminders about application deadlines) to be sent to the above phone number and accept responsibility for any charges incurred.'
                  }
                  name={'is_authorized_text_message_sent'}
                  values={formik.values.is_authorized_text_message_sent}
                  onChange={(e) => {
                    formik.setFieldValue('is_authorized_text_message_sent', e.target.checked);
                  }}
                />
              </div>
            )}
            <p></p>
            <div className="span-csu-logo">Text and Phone Authorization</div>
            <CheckboxInput
              mdCol={'12'}
              label={
                <span className="mt-2">
                  I agree to the Terms of Service and to receive calls and/or texts at any phone
                  number I have provided or may provide in the future, including any wireless
                  number, from any entity associated with my application process, including but not
                  limited to my designated schools and programs, the Liaison International support
                  team, the association or institution for this Centralized Application Service.
                </span>
              }
              name={'is_agree_csu_term'}
              values={formik.values.is_agree_csu_term}
              onChange={(e) => {
                formik.setFieldValue('is_agree_csu_term', e.target.checked);
              }}
            />
            <p></p>
            <YesNoSwitch
              name={'is_have_alternative_phone_number'}
              values={formik.values.is_have_alternative_phone_number}
              onChange={formik.handleChange}
              label="Do you have an alternative phone number in case we can't contact you?"
            />

            <Row>
              <Col md={12}>
                {alternativePhone && (
                  <Card body className="bg-light border-0 px-1 w-100 mt-3">
                    <div>Alternative Phone Number</div>
                    <Row className="my-1">
                      <CheckboxInput
                        name="alternative_is_have_international_phone_number"
                        values={formik.values.alternative_is_have_international_phone_number}
                        label="I have an international phone number"
                        onChange={formik.handleChange}
                      />
                      {formik.values.alternative_is_have_international_phone_number && (
                        <SelectPhoneCodeInput
                          label="Country Code*"
                          name="alternative_country_code"
                          onChange={formik.handleChange}
                          values={formik.values.alternative_country_code}
                        />
                      )}
                      <TextIconInput
                        icon={<PhoneAndroidIcon />}
                        name="alternative_phone_number"
                        onChange={formik.handleChange}
                        values={formik.values.alternative_phone_number}
                        label={
                          formik.values.alternative_is_have_international_phone_number
                            ? 'Phone Number*'
                            : 'Phone Number'
                        }
                        hiddenFeedback={true}
                        isMissing={!formik.values.alternative_phone_number && check ? true : false}
                      />
                      <GroupRadioButton
                        mdCol={6}
                        name={'alternative_phone_number_type'}
                        hiddenFormLabel={true}
                        values={formik.values.alternative_phone_number_type}
                        onChange={formik.handleChange}
                        options={[
                          { label: 'Home / Other', value: 'home' },
                          { label: 'Cell / Mobile', value: 'cell' },
                        ]}
                      />
                    </Row>

                    {formik.values.alternative_phone_number_type === 'cell' && (
                      <div className="my-1">
                        <CheckboxInput
                          mdCol={'12'}
                          label={
                            <span className="mt-2 text-primary">
                              I authorize text messages (for example, reminders about application
                              deadlines) to be sent to the above phone number and accept
                              responsibility for any charges incurred.
                            </span>
                          }
                          name={'alternative_is_authorized_text_message_sent'}
                          values={formik.values.alternative_is_authorized_text_message_sent}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'alternative_is_authorized_text_message_sent',
                              e.target.checked
                            );
                          }}
                        />
                      </div>
                    )}
                  </Card>
                )}
              </Col>
              <p></p>
              <div className="fs-5 p-1 my-0">
                Can we share your contact information with UC campuses and programs?
              </div>
              <p className="text-field-light">
                If you check this option, you may receive information about admissions tips, updates
                and reminders from UC campuses (even ones you haven't applied to) and outreach
                programs. They may contact you before and/or after you've submitted the application.
              </p>
              <div className="my-1">
                <CheckboxInput
                  mdCol={'6'}
                  label={
                    <span className="mt-2 text-primary">
                      I authorize the University of California to share my contact information.
                    </span>
                  }
                  name={'is_allowed_share_contact'}
                  values={formik.values.is_allowed_share_contact}
                  onChange={formik.handleChange}
                />
              </div>
            </Row>
            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {basicInformationStore.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </div>
  );
};

export default ContactDetailPage;
