import { ibSubjectHaveLanguageQuestion } from 'components/helper/appConfig';
import { formatBoolean } from 'components/helper/helper';
import {
  ACT_TEST_FIELDS_COMMON,
  ACTIVITIES_FIELDS_COMMON,
  AP_TEST_FIELDS_COMMON,
  BASIC_INFO_FIELS_COMMON,
  CBO_FIELDS_COMMON,
  CITIZENSHIP_FIELDS_COMMON,
  COLLEGE_FIELDS_COMMON,
  DEMOGRAPHICS_FIELDS_COMMON,
  EOP_FIELDS_COMMON,
  FUTURE_PLAN_FIELDS_COMMON,
  GENERAL_TEST_FIELDS_COMMON,
  HIGH_SCHOOL_FIELDS_COMMON,
  HONORS_FIELDS_COMMON,
  HOUSEHOLD_FIELDS_COMMON,
  OTHER_FIELDS_COMMON,
  PARENT1_FIELDS_COMMON,
  PARENT2_FIELDS_COMMON,
  SAT_TEST_FIELDS_COMMON,
  SIBLING_FIELDS_COMMON,
  STEP_PARENT_FIELDS_COMMON,
} from 'constants/common';

export interface RequiredField {
  key: string;
  name: string;
  page: string;
}

export const bassicInformationCondition = (data: any): RequiredField[] => {
  const baseRequiredFields = BASIC_INFO_FIELS_COMMON;
  let fields = [...baseRequiredFields];

  if (data?.csu_info?.have_lived_name === 'true' || data?.csu_info?.have_lived_name === true) {
    fields.push(
      {
        key: 'csu_info.lived_first_name',
        name: 'Lived first name',
        page: '/application/basic-information?check=1',
      },
      {
        key: 'csu_info.lived_last_name',
        name: 'Lived Last / family / surname',
        page: '/application/basic-information?check=1',
      }
    );
  }

  if (data?.is_have_legal_name === 'true' || data.is_have_legal_name === true) {
    fields.push(
      {
        key: 'legal_first_name',
        name: 'Former First Name',
        page: '/application/basic-information?check=1',
      },
      {
        key: 'legal_family_name',
        name: 'Former Last Name',
        page: '/application/basic-information?check=1',
      }
    );
  }

  if (data?.sex) {
    // const parsedSex = checkEnableParseJSON(data?.sex)
    if (data?.sex?.isAnother) {
      fields.push({
        key: 'sex.anotherGender',
        name: 'My gender',
        page: '/application/basic-information?check=1',
      });
    }
  }

  if (data?.sex_self_describe === 'identity') {
    fields.push({
      key: 'csu_info.another_identity',
      name: 'Another identity (please specify)',
      page: '/application/basic-information?check=1',
    });
  }

  if (data?.csu_info?.gender_identity === 'None of the Above') {
    fields.push({
      key: 'csu_info.another_gender_identity',
      name: 'Another identity expression (please specify)',
      page: '/application/basic-information?check=1',
    });
  }

  if (data?.country === 'United States of America') {
    fields.push(
      {
        key: 'csu_info.address_county',
        name: 'County',
        page: '/application/basic-information?check=1',
      },
      {
        key: 'zip_code',
        name: 'Zip code',
        page: '/application/basic-information?check=1',
      }
    );
  }

  if (data?.is_have_alternate_address === 'true' || data?.is_have_alternate_address === true) {
    fields.push({
      key: 'alternate_address_line_1',
      name: 'Mailing Address line 1',
      page: '/application/basic-information?check=1',
    });

    fields.push({
      key: 'alternate_city',
      name: 'Mailing Address City',
      page: '/application/basic-information?check=1',
    });

    fields.push({
      key: 'alternate_state',
      name: 'Mailing Address State',
      page: '/application/basic-information?check=1',
    });

    fields.push({
      key: 'csu_info.mailing_address_county',
      name: 'Mailing Address County',
      page: '/application/basic-information?check=1',
    });

    fields.push({
      key: 'alternate_zip_code',
      name: 'Mailing Address Zip code',
      page: '/application/basic-information?check=1',
    });
  }

  if (data?.is_have_international_phone_number) {
    fields.push({
      key: 'country_code',
      name: 'Contact Country Code',
      page: '/application/contact-details?check=1',
    });
  }

  if (data?.alternative_is_have_international_phone_number) {
    fields.push({
      key: 'alternative_phone_number',
      name: 'Contact Alternative Phone Number',
      page: '/application/contact-details?check=1',
    });
  }

  return fields;
};

export const checkDemographicsCondition = (data: any): RequiredField[] => {
  const baseRequiredFields = DEMOGRAPHICS_FIELDS_COMMON;
  let fields = [...baseRequiredFields];

  if (data?.all_apply_array && data?.all_apply_array.is_foster_care) {
    fields.push({
      key: 'demographics_additional_data.isInFosterCare',
      name: "I've been in foster care or am a ward of the court in the United States",
      page: '/application/demographics?check=1',
    });
  }

  if (
    data?.us_armed_forces_status === 'Currently Serving' ||
    data?.us_armed_forces_status === 'Previously Served'
  ) {
    fields.push(
      {
        key: 'number_military_experience',
        name: 'Please specify number of Military experience you have',
        page: '/application/demographics?check=1',
      },
      {
        key: 'anticipated_status',
        name: 'Anticipated status at the time you enroll',
        page: '/application/demographics?check=1',
      }
    );

    if (data?.anticipated_status === 'On Active Duty') {
      fields.push({
        key: 'demographics_additional_data.isPermanentlyStationed',
        name: 'Are you permanently stationed in California?',
        page: '/application/demographics?check=1',
      });
    }
  }

  if (data?.us_armed_forces_status === 'Current Dependent') {
    fields.push({
      key: 'demographics_additional_data.is_US_military_service_on_active_duty',
      name: 'Is the U.S. military service member on active duty?',
      page: '/application/demographics?check=1',
    });

    if (
      data?.demographics_additional_data?.is_US_military_service_on_active_duty === 'true' ||
      data?.demographics_additional_data?.is_US_military_service_on_active_duty === true
    ) {
      fields.push({
        key: 'demographics_additional_data.is_US_military_service_permanently_stationed_in_California',
        name: 'Is the U.S. military service member permanently stationed in California?',
        page: '/application/demographics?check=1',
      });
    }
  }
  if (
    data?.is_consider_hispanic_latino === '' ||
    data?.is_consider_hispanic_latino === undefined ||
    data?.is_consider_hispanic_latino === null
  ) {
    fields.push({
      key: 'is_consider_hispanic_latino',
      name: 'Do you consider yourself Hispanic or Latino',
      page: '/application/demographics?check=1',
    });
  }
  if (data?.is_consider_hispanic_latino === 'true' || data?.is_consider_hispanic_latino === true) {
    if (
      data?.best_group_describe_racial_background_array.value_consider_hispanic_latino ===
      'Other Central American'
    ) {
      fields.push({
        key: 'best_group_describe_racial_background_array.specify_is_Other_Central_American',
        name: 'Please specify Other Central American',
        page: '/application/demographics?check=1',
      });
    }

    if (
      data?.best_group_describe_racial_background_array.value_consider_hispanic_latino ===
      'Other South American'
    ) {
      fields.push({
        key: 'best_group_describe_racial_background_array.specify_is_Other_South_American',
        name: 'Please specify Other South American',
        page: '/application/demographics?check=1',
      });
    }

    if (
      data?.best_group_describe_racial_background_array.value_consider_hispanic_latino ===
      'Other Hispanic or Latino'
    ) {
      fields.push({
        key: 'best_group_describe_racial_background_array.specify_is_Other_Hispanic_or_Latino',
        name: 'Please specify Other Hispanic or Latino',
        page: '/application/demographics?check=1',
      });
    }
  }

  // If American Indian or Alaska Native then show following content
  if (
    data?.best_group_describe_racial_background_array?.racial_background_identify_AmericanIndian
  ) {
    fields.push({
      key: 'best_group_describe_racial_background_array.is_enrolled_member_of_American_Indian_or_Alaska_Native',
      name: 'Are you an enrolled member of an American Indian or Alaska Native tribe (U.S.)?',
      page: '/application/demographics?check=1',
    });
  }

  if (
    data?.best_group_describe_racial_background_array
      ?.is_enrolled_member_of_American_Indian_or_Alaska_Native === 'true' ||
    data?.best_group_describe_racial_background_array
      ?.is_enrolled_member_of_American_Indian_or_Alaska_Native === true
  ) {
    fields.push({
      key: 'best_group_describe_racial_background_array.tribal_enrollment_category',
      name: 'Please select the category that best reflects your tribal enrollment. If you are an enrolled member of more than one tribe, select your primary tribal affiliation from the list of recognized tribes',
      page: '/application/demographics?check=1',
    });

    if (
      data?.best_group_describe_racial_background_array.tribal_enrollment_category !==
      'Federally recognized tribe'
    ) {
      fields.push({
        key: 'best_group_describe_racial_background_array.tribal_enrollment_primary',
        name: 'Please select your primary tribal affiliation',
        page: '/application/demographics?check=1',
      });
    }
  }

  // If Black or African American is selected
  if (
    data?.best_group_describe_racial_background_array?.racial_background_identify_AfricanAmerican
  ) {
    fields.push({
      key: 'best_group_describe_racial_background_array.racial_background_African',
      name: 'What best describes your Black or African American background?',
      page: '/application/demographics?check=1',
    });

    if (data?.best_group_describe_racial_background_array?.racial_background_African === 'Other') {
      fields.push({
        key: 'best_group_describe_racial_background_array.racial_OtherAfrican_value',
        name: 'Describe other',
        page: '/application/demographics?check=1',
      });
    }
  }

  // If Asian then show following content
  if (data?.best_group_describe_racial_background_array?.racial_background_identify_Asian) {
    fields.push({
      key: 'best_group_describe_racial_background_array.racial_background_identify_Asian',
      name: 'Which best describes your Asian background?',
      page: '/application/demographics?check=1',
    });
    if (
      data?.best_group_describe_racial_background_array?.racial_background_Asian === 'Other Asian'
    ) {
      fields.push({
        key: 'best_group_describe_racial_background_array.racial_OtherAsian_value',
        name: 'Specify other Asian background',
        page: '/application/demographics?check=1',
      });
    }
  }

  //If _Southwest_Asian_and_North_African is selected
  if (
    data?.best_group_describe_racial_background_array
      ?.racial_background_identify_Southwest_Asian_and_North_African
  ) {
    fields.push({
      key: 'best_group_describe_racial_background_array.racial_Southwest_Asian_and_North_African',
      name: 'Which best describes your Southwest Asian or North African background',
      page: '/application/demographics?check=1',
    });

    if (
      data?.best_group_describe_racial_background_array?.racial_Southwest_Asian_and_North_African?.includes(
        'Other'
      )
    ) {
      fields.push({
        key: 'best_group_describe_racial_background_array.racial_Southwest_Asian_and_North_African_other',
        name: 'Please Specify',
        page: '/application/demographics?check=1',
      });
    }
  }

  //If Native Hawaiian or Other Pacific Islander is seleted
  if (
    data?.best_group_describe_racial_background_array?.racial_background_identify_NativeHawaiian
  ) {
    fields.push({
      key: 'best_group_describe_racial_background_array.racial_background_NativeHawaiian',
      name: 'Which best describes your Native Hawaiian or Other Pacific Islander background?',
      page: '/application/demographics?check=1',
    });
    if (
      data?.best_group_describe_racial_background_array?.racial_background_NativeHawaiian?.includes(
        'Other'
      )
    ) {
      fields.push({
        key: 'best_group_describe_racial_background_array.racial_OtherPacific_value',
        name: 'Please Specify Your Other Background',
        page: '/application/demographics?check=1',
      });
    }
  }

  // White is selected
  if (data?.best_group_describe_racial_background_array?.racial_background_identify_White) {
    fields.push({
      key: 'best_group_describe_racial_background_array.racial_background_White',
      name: 'Which best describes your White background?',
      page: '/application/demographics?check=1',
    });

    if (
      data?.best_group_describe_racial_background_array?.racial_background_identify_White ===
      'Other White'
    ) {
      fields.push({
        key: 'best_group_describe_racial_background_array.racial_OtherWhite_value',
        name: 'Please Specify Your Other Background',
        page: '/application/demographics?check=1',
      });
    }
  }

  return fields;
};

export const checkCitizenshipCondition = (data: any): RequiredField[] => {
  const fields = [...CITIZENSHIP_FIELDS_COMMON];

  if (typeof data?.is_have_us_social_security_number !== 'boolean') {
    fields.push({
      key: 'is_have_us_social_security_number',
      name: 'Do you have a U.S. Social Security number or an Individual Taxpayer Identification Number (ITIN)',
      page: '/application/citizenship?check=1',
    });
  }

  if (typeof data?.is_graduated_california_high_school !== 'boolean') {
    fields.push({
      key: 'is_graduated_california_high_school',
      name: 'By the time you attend UC, will you have graduated from a California high school?',
      page: '/application/citizenship?check=1',
    });
  }
  if (
    [
      'U.S. Dual Citizen',
      'U.S. Permanent Resident',
      'U.S. Refugee or Asylee',
      'DACA, undocumented, Deferred Enforced Departure or Temporary Protected Status',
      'Citizen of non-U.S. country',
    ].includes(data.current_citizenship_status)
  ) {
    if (
      data?.csu_info?.current_working_with_representative_or_agency === 'true' ||
      data?.csu_info?.current_working_with_representative_or_agency === true
    ) {
      fields.push(
        {
          key: 'csu_info.representative_type',
          name: 'Representative Type',
          page: '/application/citizenship?check=1',
        },
        {
          key: 'csu_info.representative_name',
          name: 'Representative Name',
          page: '/application/citizenship?check=1',
        },
        {
          key: 'csu_info.representative_country',
          name: 'Representative Country',
          page: '/application/citizenship?check=1',
        },
        {
          key: 'csu_info.representative_phone',
          name: 'Representative Phone',
          page: '/application/citizenship?check=1',
        },
        {
          key: 'csu_info.representative_email',
          name: 'Representative Email',
          page: '/application/citizenship?check=1',
        }
      );
    }
    fields.push(
      {
        key: 'list_citizenship',
        name: 'List Citizenship(s) other than the U.S.',
        page: '/application/citizenship?check=1',
      },
      {
        key: 'csu_info.year_move_to_US',
        name: 'What year did you (or do you plan to) move to the U.S.?',
        page: '/application/citizenship?check=1',
      },
      {
        key: 'country',
        name: 'What is your main country of Citizenship',
        page: '/application/citizenship?check=1',
      }
    );

    if (data?.country !== 'United States of America') {
      fields.push({
        key: 'citizenship_status',
        name: 'What will your citizenship status be on the date you submit this application?',
        page: '/application/citizenship?check=1',
      });

      if (
        data?.citizenship_status ===
        'Non-immigrant/foreign visa (you have a visa or will be applying for one)'
      ) {
        fields.push(
          {
            key: 'have_us_visa',
            name: 'Do you currently have a U.S. visa?',
            page: '/application/citizenship?check=1',
          },
          {
            key: 'will_you_have_different_visa',
            name: 'By the time you attend UC, will you have a different visa?*',
            page: '/application/citizenship?check=1',
          }
        );

        fields.push(
          ...(data?.have_us_visa === 'true' || data?.have_us_visa === true
            ? [
                {
                  key: 'type_of_current_us_visa',
                  name: 'Current visa type',
                  page: '/application/citizenship?check=1',
                },
                {
                  key: 'csu_info.require_F1_Visa_or_J1_visa',
                  name: 'Do you have or will you require an F1 Visa (student) or J1 Visa (exchange) to study at the California State University (CSU)?',
                  page: '/application/citizenship?check=1',
                },
                ...(data?.require_F1_Visa_or_J1_visa === true ||
                data?.require_F1_Visa_or_J1_visa === 'true'
                  ? [
                      {
                        key: 'csu_info.visa_you_have_at_CSU',
                        name: 'Which student visa do you have, or will you have when you study at the CSU?',
                        page: '/application/citizenship?check=1',
                      },
                    ]
                  : [
                      {
                        key: 'csu_info.us_state_you_regard_as_permanent_home',
                        name: 'What U.S. State/Possession do you regard as your permanent home?',
                        page: '/application/citizenship?check=1',
                      },
                    ]),
              ]
            : [
                {
                  key: 'type_of_apply_for_us_visa',
                  name: 'Which type of U.S. visa will you apply for (or have applied for)?',
                  page: '/application/citizenship?check=1',
                },
              ])
        );

        if (
          data?.will_you_have_different_visa === 'true' ||
          data?.will_you_have_different_visa === true
        ) {
          fields.push({
            key: 'planned_visa_type',
            name: 'Planned visa type',
            page: '/application/citizenship?check=1',
          });
        }

        if (
          data?.citizenshipData?.citizenship_status === 'Legal U.S. permanent resident (green card)'
        ) {
          fields.push({
            key: 'csu_info.date',
            name: 'Permanent Resident / Green Card Date Issued',
            page: '/application/citizenship?check=1',
          });
        }
      }
    }
  }

  if (data?.country_birthplace === 'United States of America') {
    fields.push(
      {
        key: 'state_birthplace',
        name: 'State',
        page: '/application/citizenship?check=1',
      },
      {
        key: 'number_of_year_in_us',
        name: 'Number of years you have lived in the United States',
        page: '/application/citizenship?check=1',
      }
    );
  } else {
    fields.push(
      {
        key: 'csu_info.year_move_to_us',
        name: 'If you were born outside of the U.S., what year did you or will you move to the U.S.',
        page: '/application/citizenship?check=1',
      },
      {
        key: 'number_of_year_outside_us',
        name: 'Number of years you have lived outside the United States',
        page: '/application/citizenship?check=1',
      }
    );
  }

  if (
    data?.statement_legal_resident?.have_lived_in_California_continuously_since_birth === 'true' ||
    data?.statement_legal_resident?.have_lived_in_California_continuously_since_birth === true
  ) {
    fields.push({
      key: 'statement_legal_resident.when_your_present_stay_in_California_begin',
      name: 'When did your present stay in California begin',
      page: '/application/citizenship?check=1',
    });
  }

  if (
    data?.is_three_or_more_year_graduated_california_high_school === 'false' ||
    data?.is_three_or_more_year_graduated_california_high_school === false
  ) {
    if (typeof data.is_currently_live_california !== 'boolean') {
      fields.push({
        key: 'is_currently_live_california',
        name: 'Are you currently living in California',
        page: '/application/citizenship?check=1',
      });
    }

    if (
      data?.is_currently_live_california === 'true' ||
      data?.is_currently_live_california === true
    ) {
      if (typeof data?.is_one_of_parent_permanent_resident_california !== 'boolean') {
        fields.push({
          key: 'is_one_of_parent_permanent_resident_california',
          name: 'Is one of your parents or legal guardians a permanent resident of California?',
          page: '/application/citizenship?check=1',
        });
      }

      if (typeof data?.is_parent_employee_university_california !== 'boolean') {
        fields.push({
          key: 'is_parent_employee_university_california',
          name: 'Is your parent, legal guardian, spouse or registered domestic partner an employee of the University of California or a UC-affiliated national laboratory (Los Alamos National Security LLC or Lawrence Livermore National Security LLC)?',
          page: '/application/citizenship?check=1',
        });
      }

      if (typeof data?.is_live_california_entire_life !== 'boolean') {
        fields.push({
          key: 'is_live_california_entire_life',
          name: 'Have you lived in California your entire life?',
          page: '/application/citizenship?check=1',
        });
      }
      fields.push(
        {
          key: 'month_live_here_since',
          name: 'Month',
          page: '/application/citizenship?check=1',
        },
        {
          key: 'year_live_here_since',
          name: 'Year',
          page: '/application/citizenship?check=1',
        }
      );

      if (
        data?.is_one_of_parent_permanent_resident_california === 'true' ||
        data?.is_one_of_parent_permanent_resident_california === true
      ) {
        fields.push(
          {
            key: 'month_parent_become_permanent_residence',
            name: 'Month of the year your parent became a permanent resident of California',
            page: '/application/citizenship?check=1',
          },
          {
            key: 'year_parent_become_permanent_residence',
            name: 'Year of the year your parent became a permanent resident of California',
            page: '/application/citizenship?check=1',
          }
        );
      }
    }
  }

  if (
    data?.statement_legal_resident?.is_like_to_be_evaluated_for_tuition_purposes === 'true' ||
    data?.statement_legal_resident?.is_like_to_be_evaluated_for_tuition_purposes === true
  ) {
    fields.push(
      {
        key: 'statement_legal_resident.is_declare_under_penalty_of_perjury',
        name: 'I declare under penalty of perjury under the laws governing the State of California that the statements I have provided on this Statement of Legal Residence are, and each of them is, true and correct.',
        page: '/application/citizenship?check=1',
      },
      {
        key: 'statement_legal_resident.is_understood_to_be_asked_submit_document',
        name: 'I understand that as part of the process to issue a Residency Classification...',
        page: '/application/citizenship?check=1',
      },
      {
        key: 'statement_legal_resident.is_physically_present_for_3_years',
        name: 'Have you been physically present in California for the last 3 years',
        page: '/application/citizenship?check=1',
      },
      {
        key: 'statement_legal_resident.is_attend_highschool_for_3_more_years',
        name: 'Did you attend high school in California for three or more years, and will you graduate or have you graduated from a high school in California? ',
        page: '/application/citizenship?check=1',
      },
      {
        key: 'statement_legal_resident.is_established_as_permanent_home',
        name: 'Have you and your parents established California as your permanent home for the past 366 days or more?',
        page: '/application/citizenship?check=1',
      }
    );

    if (
      data?.statement_legal_resident?.is_established_as_permanent_home !==
      'I do not consider myself a dependent upon my parents'
    ) {
      fields.push(
        {
          key: 'statement_legal_resident.is_parent_1_married_to_parent_2',
          name: 'Are your parents married to each other?',
          page: '/application/citizenship?check=1',
        },
        {
          key: 'statement_legal_resident.is_parent_1_physically_present',
          name: 'Has Parent 1 been physically present in California for the last 3 years?',
          page: '',
        }
      );

      if (
        data?.statement_legal_resident?.is_parent_1_physically_present !== 'My Parent is Deceased'
      ) {
        fields.push({
          key: 'statement_legal_resident.is_parent_1_US_citizen',
          name: 'Is Parent 1 a U.S. Citizen?',
          page: '/application/citizenship?check=1',
        });
      }

      if (data?.statement_legal_resident?.is_parent_1_married_to_parent_2 === 'No') {
        fields.push({
          key: 'statement_legal_resident.is_parents_live_together',
          name: 'Do your parents live together?',
          page: '/application/citizenship?check=1',
        });

        if (
          data?.statement_legal_resident?.is_parent_2_physically_present !== 'My Parent is Deceased'
        ) {
          fields.push({
            key: 'statement_legal_resident.is_parent_2_US_citizen',
            name: 'Is Parent 2 a U.S. Citizen?',
            page: '/application/citizenship?check=1',
          });
        }

        if (data?.statement_legal_resident?.is_parents_live_together === 'No') {
          fields.push({
            key: 'statement_legal_resident.is_parent_2_physically_present',
            name: 'Has Parent 2 been physically present in California for the last 3 years?',
            page: '',
          });
        }
      }
    }
  }

  if (data?.values?.citizenship_status === 'Refugee') {
    fields.push({
      key: 'csu_info.country',
      name: 'Country of Citizenship',
      page: '/application/citizenship?check=1',
    });
  }
  return fields;
};

export const checkCBOCondition = (data: any): RequiredField[] => {
  const fields = [...CBO_FIELDS_COMMON];

  if (
    data?.is_financial_qualify_fee_waiver === 'true' ||
    data?.is_financial_qualify_fee_waiver === true
  ) {
    fields.push({
      key: 'fee_waiver_signature',
      name: 'Please sign your name to certify that you qualify for a fee waiver',
      page: '/application/cbo-and-fee-waiver?check=1',
    });
  }
  if (data?.is_participate_cbo === 'true' || data?.is_participate_cbo === true) {
    fields.push({
      key: 'indicate_number_community_programs',
      name: 'Indicate the number of community programs or organizations that have provided you with free assistance in your application process',
      page: '/application/cbo-and-fee-waiver?check=1',
    });
  }

  if (data?.avid === 'true' || data?.avid === true) {
    fields.push({
      key: 'year_participated_in_AVID',
      name: 'Number of years you participated in AVID during High School',
      page: '/application/cbo-and-fee-waiver?check=1',
    });
  }

  if (data?.upward_bound === 'true' || data?.upward_bound === true) {
    fields.push({
      key: 'year_participated_in_upward_bound',
      name: 'Number of years you participated in Upward Bound during High School',
      page: '/application/cbo-and-fee-waiver?check=1',
    });
  }

  if (data?.talent_search === 'true' || data?.talent_search === true) {
    fields.push({
      key: 'year_participated_in_talent_search',
      name: 'Number of years you participated in Talent Search or Educational Opportunity Center (EOC) during high school',
      page: '/application/cbo-and-fee-waiver?check=1',
    });
  }

  if (data?.puente_project === 'true' || data?.puente_project === true) {
    fields.push({
      key: 'year_participated_in_puente_project',
      name: 'Number of years you participated in Puente Project',
      page: '/application/cbo-and-fee-waiver?check=1',
    });
  }

  if (data?.ilp === 'true' || data?.ilp === true) {
    fields.push({
      key: 'year_participated_in_ilp',
      name: 'Number of years you participated in Independent Living Project (ILP)',
      page: '/application/cbo-and-fee-waiver?check=1',
    });
  }

  if (data?.MESA_project === 'true' || data?.MESA_project === true) {
    fields.push({
      key: 'year_participated_in_MESA_project',
      name: 'Number of years you participated in MESA Project',
      page: '/application/cbo-and-fee-waiver?check=1',
    });
  }

  if (data?.other_program === 'true' || data?.other_program === true) {
    fields.push({
      key: 'year_participated_in_AVID',
      name: 'Number of years you participated in Gear-up, CAMP, Foster youth support programs, Student Support Services Programs (SSSP)',
      page: '/application/cbo-and-fee-waiver?check=1',
    });
  }

  return fields;
};

export const checkOtherCondition = (data: any): RequiredField[] => {
  const fields = [...OTHER_FIELDS_COMMON];

  if (data?.csu_info?.have_rn_license === 'true' || data?.csu_info?.have_rn_license === true) {
    fields.push({
      key: 'csu_info.rn_license',
      name: 'RN License',
      page: '/application/other-information?check=1',
    });
  }
  if (
    data?.csu_info?.is_disciplined_for_academic_performance === 'true' ||
    data?.csu_info?.is_disciplined_for_academic_performance === true
  ) {
    fields.push({
      key: 'csu_info.explain_for_each_violation',
      name: 'If you answered yes to any of the questions above, please explain include the college/university',
      page: '/application/other-information?check=1',
    });
  }
  return fields;
};

export const checkHouseholdCondition = (data: any): RequiredField[] => {
  const fields = [...HOUSEHOLD_FIELDS_COMMON];

  if (data?.who_in_household_array?.is_other) {
    fields.push({
      key: 'who_in_household_array.describe_other_family_members',
      name: 'Describe other family members',
      page: '/application/household?check=1',
    });
  }

  if (
    ![
      data?.who_in_household_array?.is_parent_guardian,
      data?.who_in_household_array?.is_sibbling,
      data?.who_in_household_array?.is_spouse,
      data?.who_in_household_array?.is_child,
    ].every((i) => i === false)
  ) {
    fields.push({
      key: 'household_size',
      name: 'Household size',
      page: '/application/household?check=1',
    });
  }

  if (
    data.csu_info?.parent_1_highest_level_education === '2-year college graduate' ||
    data.csu_info?.parent_1_highest_level_education === '4-year college graduate'
  ) {
    fields.push({
      key: 'csu_info.parent_1_highest_level_education_last_five_years',
      name: 'Did this parent graduate within the last five years',
      page: '/application/household?check=1',
    });
  }

  if (
    data.csu_info?.parent_2_highest_level_education_last_five_years === '2-year college graduate' ||
    data.csu_info?.parent_2_highest_level_education_last_five_years === '4-year college graduate'
  ) {
    fields.push({
      key: 'csu_info.parent_2_highest_level_education_last_five_years',
      name: 'Did this parent graduate within the last five years',
      page: '/application/household?check=1',
    });
  }

  if (data?.parent_martial_status === 'Divorced') {
    fields.push({
      key: 'year_of_divorce',
      name: 'Year of divorce',
      page: '/application/household?check=1',
    });
  }

  if (data?.whom_live_permanently === 'Other') {
    fields.push({
      key: 'other_live_with_free_text',
      name: 'Other',
      page: '/application/household?check=1',
    });
  }

  if (['Divorced', 'Never Married', 'Widowed', 'Separated'].includes(data?.parent_martial_status)) {
    fields.push({
      key: 'is_listing_step_parents',
      name: 'Do you wish to list any step-parents?',
      page: '/application/household?check=1',
    });

    if (data?.is_listing_step_parents === 'true' || data?.is_listing_step_parents === true) {
      fields.push({
        key: 'how_many_step_parents',
        name: 'How many?*',
        page: '/application/household?check=1',
      });
    }
  }

  if (
    data?.csu_info?.household_income_information_statements ===
    'None of the above statements apply to me'
  ) {
    fields.push(
      {
        key: 'csu_info.parent_gross_income',
        name: "Parents' adjusted gross income (AGI) for 2023. This is the income reported on your parent(s) federal tax return",
        page: '/application/household?check=1',
      },
      {
        key: 'csu_info.parent_untaxed_income',
        name: "Parents' untaxed income and benefits for 2023. This is income not reported on your parent(s) federal tax return",
        page: '/application/household?check=1',
      }
    );
  } else {
    fields.push(
      {
        key: 'csu_info.gross_income',
        name: 'Adjusted gross income (AGI) for 2023, all income reported on your federal tax return',
        page: '/application/household?check=1',
      },
      {
        key: 'csu_info.untaxed_income',
        name: 'Untaxed income and benefits for 2023, income not reported on your federal tax return',
        page: '/application/household?check=1',
      }
    );
  }

  if (data?.is_have_any_children === 'true' || data?.is_have_any_children === true) {
    fields.push({
      key: 'how_many_children',
      name: 'How many',
      page: '/application/household?check=1',
    });
  }
  return fields;
};

export const checkParent1Condition = (data: any): RequiredField[] => {
  const fields = [...PARENT1_FIELDS_COMMON];

  if (data?.parent_graduation_college_array && data?.parent_graduation_college_array?.length > 0) {
    data?.parent_graduation_college_array.forEach((item: any, index: number) => {
      if (!item?.school?.id) {
        fields.push(
          {
            key: `parent_graduation_college_array[${index}].school.name`,
            name: `Name of College/University #${index + 1}`,
            page: `/application/parent-1?check=1&index=${index + 1}`,
          },
          {
            key: `parent_graduation_college_array[${index}].school.school_type`,
            name: `School Type #${index + 1}`,
            page: `/application/parent-1?check=1&index=${index + 1}`,
          },
          {
            key: `parent_graduation_college_array[${index}].school.country`,
            name: `Country/Region/Territory #${index + 1}`,
            page: `/application/parent-1?check=1&index=${index + 1}`,
          },
          {
            key: `parent_graduation_college_array[${index}].school.zip`,
            name: `Zip Code #${index + 1}`,
            page: `/application/parent-1?check=1&index=${index + 1}`,
          }
        );

        if (item?.school?.country === 'United States of America') {
          fields.push({
            key: `parent_graduation_college_array[${index}].school.state`,
            name: `State #${index + 1}`,
            page: `/application/parent-1?check=1&index=${index + 1}`,
          });
        }

        if (item?.school?.country === 'Canada') {
          fields.push({
            key: `parent_graduation_college_array[${index}].school.province`,
            name: `Province #${index + 1}`,
            page: `/application/parent-1?check=1&index=${index + 1}`,
          });
        }
      }
    });
  }

  if (data?.is_parent_1_living === 'false' || data?.is_parent_1_living === false) {
    fields.push({
      key: 'date_deceased',
      name: 'Date deceased',
      page: '/application/parent-1?check=1',
    });
  }

  if (data?.relationship_to_you === 'Other') {
    fields.push({
      key: 'other_relationship_to_you_free_text',
      name: 'Other',
      page: '/application/parent-1?check=1',
    });
  }

  if (data?.parent_address_is === 'A different address') {
    fields.push(
      {
        key: 'different_parent_address_country',
        name: 'Country',
        page: '/application/parent-1?check=1',
      },
      {
        key: 'different_parent_address_line_1',
        name: 'Address Line 1',
        page: '/application/parent-1?check=1',
      },
      {
        key: 'different_parent_address_city',
        name: 'City',
        page: '/application/parent-1?check=1',
      },
      {
        key: 'different_parent_address_zip_code',
        name: 'Zip Code',
        page: '/application/parent-1?check=1',
      }
    );

    if (data?.different_parent_address_city !== 'United States of America') {
      fields.push({
        key: 'different_parent_address_province',
        name: 'Province',
        page: '/application/parent-1?check=1',
      });
    }

    if (data?.different_parent_address_city === 'United States of America') {
      fields.push({
        key: 'different_parent_address_state',
        name: 'State',
        page: '/application/parent-1?check=1',
      });
    }

    if (data?.college_or_university?.country === 'Canada') {
      fields.push({
        key: 'college_or_university.province',
        name: 'Province',
        page: '/application/parent-1?check=1',
      });
    }

    if (data?.college_or_university?.country === 'United States of America') {
      fields.push({
        key: 'college_or_university.state',
        name: 'State',
        page: '/application/parent-1?check=1',
      });
    }
  }

  if (['Employed', 'Retired'].includes(data.employment_status)) {
    fields.push({
      key: 'parent_currently_employed_or_retire_from_college',
      name: 'Is parent currently employed by or retired from a college or university?',
      page: '/application/parent-1?check=1',
    });

    if (
      ['Currently employed at a college/university', 'Retired from a college/university'].includes(
        data.parent_currently_employed_or_retire_from_college
      )
    ) {
      fields.push({
        key: 'college_or_university.name',
        name: 'College/University employer lookup',
        page: '/application/parent-1?check=1',
      });
    }
  }

  if (
    [
      'Some trade school or community college',
      'Graduated from a trade school or community college',
      'Some college/university',
      'Graduated from college/university',
      'Graduate school',
    ].includes(data?.education_level)
  ) {
    fields.push({
      key: 'total_number_institutions_attended',
      name: 'Total number of institutions attended',
      page: '/application/parent-1?check=1',
    });
  }

  if (
    data?.csu_info?.isCurrentPermanentResidentOfCalifornia === 'true' ||
    data?.csu_info?.isCurrentPermanentResidentOfCalifornia === true
  ) {
    fields.push({
      key: 'csu_info.isLivedInCaliforniaContinuouslySinceBirth',
      name: 'Has your parent/guradian lived in California continuously since birth?',
      page: '/application/parent-1?check=1',
    });
  }
  if (
    data?.csu_info?.isLivedInCaliforniaContinuouslySinceBirth === 'false' ||
    data?.csu_info?.isCurrentPermanentResidentOfCalifornia === false
  ) {
    fields.push(
      {
        key: 'csu_info.whenStayInCaliforniaBegin',
        name: 'When did his/her current stay in California begin?',
        page: '/application/parent-1?check=1',
      },
      {
        key: 'csu_info.priorCountry',
        name: 'Prior Country',
        page: '/application/parent-1?check=1',
      },
      {
        key: 'csu_info.priorState',
        name: 'Prior State/Territory',
        page: '/application/parent-1?check=1',
      },
      {
        key: 'csu_info.fromWhenToWhenLiveThere',
        name: 'From when to when did he/she live there',
        page: '/application/parent-1?check=1',
      },
      {
        key: 'csu_info.ToWhenToWhenLiveThere',
        name: 'To',
        page: '/application/parent-1?check=1',
      }
    );
  }

  if (!data?.college_or_university?.id) {
    fields.push(
      {
        key: 'college_or_university.name',
        name: 'Name of college/university',
        page: '/application/parent-1?check=1',
      },
      {
        key: 'college_or_university.country',
        name: 'Country/Region/Territory',
        page: '/application/parent-1?check=1',
      },
      {
        key: 'college_or_university.zip_code',
        name: 'Zip code',
        page: '/application/parent-1?check=1',
      }
    );

    if (data?.college_or_university?.country === 'United States of America') {
      fields.push({
        key: 'college_or_university.state',
        name: 'State',
        page: '/application/parent-1?check=1',
      });
    }

    if (data?.college_or_university?.country === 'Canada') {
      fields.push({
        key: 'college_or_university.province',
        name: 'Province',
        page: '/application/parent-1?check=1',
      });
    }
  }

  return fields;
};

export const checkParent2Condition = (data: any): RequiredField[] => {
  const fields = [...PARENT2_FIELDS_COMMON];

  if (data?.parent_graduation_college_array && data?.parent_graduation_college_array?.length > 0) {
    data?.parent_graduation_college_array.forEach((item: any, index: number) => {
      if (!item?.school?.id) {
        fields.push(
          {
            key: `parent_graduation_college_array[${index}].school.name`,
            name: `Name of College/University #${index + 1}`,
            page: `/application/parent-2?check=1&index=${index + 1}`,
          },
          {
            key: `parent_graduation_college_array[${index}].school.school_type`,
            name: `School Type #${index + 1}`,
            page: `/application/parent-2?check=1&index=${index + 1}`,
          },
          {
            key: `parent_graduation_college_array[${index}].school.country`,
            name: `Country/Region/Territory #${index + 1}`,
            page: `/application/parent-2?check=1&index=${index + 1}`,
          },
          {
            key: `parent_graduation_college_array[${index}].school.zip`,
            name: `Zip Code #${index + 1}`,
            page: `/application/parent-2?check=1&index=${index + 1}`,
          }
        );

        if (item?.school?.country === 'United States of America') {
          fields.push({
            key: `parent_graduation_college_array[${index}].school.state`,
            name: `State #${index + 1}`,
            page: `/application/parent-2?check=1&index=${index + 1}`,
          });
        }

        if (item?.school?.country === 'Canada') {
          fields.push({
            key: `parent_graduation_college_array[${index}].school.province`,
            name: `Province #${index + 1}`,
            page: `/application/parent-2?check=1&index=${index + 1}`,
          });
        }
      }
    });
  }

  if (!data?.college_or_university?.id) {
    fields.push(
      {
        key: 'college_or_university.name',
        name: 'Name of college/university',
        page: '/application/parent-2?check=1',
      },
      {
        key: 'college_or_university.country',
        name: 'Country/Region/Territory',
        page: '/application/parent-2?check=1',
      },
      {
        key: 'college_or_university.zip_code',
        name: 'Zip code',
        page: '/application/parent-2?check=1',
      }
    );

    if (data?.college_or_university?.country === 'United States of America') {
      fields.push({
        key: 'college_or_university.state',
        name: 'State',
        page: '/application/parent-2?check=1',
      });
    }

    if (data?.college_or_university?.country === 'Canada') {
      fields.push({
        key: 'college_or_university.province',
        name: 'Province',
        page: '/application/parent-2?check=1',
      });
    }
  }

  if (data?.is_parent_2_living === 'false' || data?.is_parent_2_living === false) {
    fields.push({
      key: 'date_deceased',
      name: 'Date deceased',
      page: '/application/parent-2?check=1',
    });
  }

  if (data?.relationship_to_you === 'Other') {
    fields.push({
      key: 'other_relationship_to_you_free_text',
      name: 'Other',
      page: '/application/parent-2?check=1',
    });
  }

  if (data?.parent_address_is === 'A different address') {
    fields.push(
      {
        key: 'different_parent_address_country',
        name: 'Country',
        page: '/application/parent-2?check=1',
      },
      {
        key: 'different_parent_address_line_1',
        name: 'Address Line 1',
        page: '/application/parent-2?check=1',
      },
      {
        key: 'different_parent_address_city',
        name: 'City',
        page: '/application/parent-2?check=1',
      },
      {
        key: 'different_parent_address_zip_code',
        name: 'Zip Code',
        page: '/application/parent-2?check=1',
      }
    );

    if (data?.different_parent_address_city !== 'United States of America') {
      fields.push({
        key: 'different_parent_address_province',
        name: 'Province',
        page: '/application/parent-2?check=1',
      });
    }

    if (data?.different_parent_address_city === 'United States of America') {
      fields.push({
        key: 'different_parent_address_state',
        name: 'State',
        page: '/application/parent-2?check=1',
      });
    }

    if (data?.college_or_university?.country === 'Canada') {
      fields.push({
        key: 'college_or_university.province',
        name: 'Province',
        page: '/application/parent-2?check=1',
      });
    }

    if (data?.college_or_university?.country === 'United States of America') {
      fields.push({
        key: 'college_or_university.state',
        name: 'State',
        page: '/application/parent-2?check=1',
      });
    }
  }

  if (['Employed', 'Retired'].includes(data.employment_status)) {
    fields.push({
      key: 'parent_currently_employed_or_retire_from_college',
      name: 'Is parent currently employed by or retired from a college or university?',
      page: '/application/parent-2?check=1',
    });

    if (
      ['Currently employed at a college/university', 'Retired from a college/university'].includes(
        data.parent_currently_employed_or_retire_from_college
      )
    ) {
      fields.push({
        key: 'college_or_university.name',
        name: 'College/University employer lookup',
        page: '/application/parent-2?check=1',
      });
    }
  }

  if (
    [
      'Some trade school or community college',
      'Graduated from a trade school or community college',
      'Some college/university',
      'Graduated from college/university',
      'Graduate school',
    ].includes(data?.education_level)
  ) {
    fields.push({
      key: 'total_number_institutions_attended',
      name: 'Total number of institutions attended',
      page: '/application/parent-2?check=1',
    });
  }

  if (
    data?.csu_info?.isCurrentPermanentResidentOfCalifornia === 'true' ||
    data?.csu_info?.isCurrentPermanentResidentOfCalifornia === true
  ) {
    fields.push({
      key: 'csu_info.isLivedInCaliforniaContinuouslySinceBirth',
      name: 'Has your parent/guradian lived in California continuously since birth?',
      page: '/application/parent-2?check=1',
    });
  }
  if (
    data?.csu_info?.isLivedInCaliforniaContinuouslySinceBirth === 'false' ||
    data?.csu_info?.isCurrentPermanentResidentOfCalifornia === false
  ) {
    fields.push(
      {
        key: 'csu_info.whenStayInCaliforniaBegin',
        name: 'When did his/her current stay in California begin?',
        page: '/application/parent-2?check=1',
      },
      {
        key: 'csu_info.priorCountry',
        name: 'Prior Country',
        page: '/application/parent-2?check=1',
      },
      {
        key: 'csu_info.priorState',
        name: 'Prior State/Territory',
        page: '/application/parent-2?check=1',
      },
      {
        key: 'csu_info.fromWhenToWhenLiveThere',
        name: 'From when to when did he/she live there',
        page: '/application/parent-2?check=1',
      },
      {
        key: 'csu_info.ToWhenToWhenLiveThere',
        name: 'To',
        page: '/application/parent-2?check=1',
      }
    );
  }
  return fields;
};

export const checkSiblingCondition = (data: any): RequiredField[] => {
  const fields = [...SIBLING_FIELDS_COMMON];
  return fields;
};

export const checkStepParentCondition = (data: any): RequiredField[] => {
  let fields: any = [];
  Object.keys(data).forEach((key, index) => {
    const stepParentFields = STEP_PARENT_FIELDS_COMMON(data[key]);

    const name = key === 'stepParent1' ? 'Step-Parent 1' : 'Step-Parent 2';
    if (
      data[key]?.parent_graduation_college_array &&
      data[key]?.parent_graduation_college_array?.length > 0
    ) {
      data[key]?.parent_graduation_college_array.forEach((item: any, subIndex: number) => {
        if (!item?.school?.id) {
          fields.push(
            {
              key: `${key}.parent_graduation_college_array[${subIndex}].school.name`,
              name: `${name}: Name of College/University #${subIndex + 1}`,
              page: `/application/step-parent-${index + 1}?check=1&index=${subIndex + 1}`,
            },
            {
              key: `${key}.parent_graduation_college_array[${subIndex}].school.school_type`,
              name: `${name}: School Type #${subIndex + 1}`,
              page: `/application/step-parent-${index + 1}?check=1&index=${subIndex + 1}`,
            },
            {
              key: `${key}.parent_graduation_college_array[${subIndex}].school.country`,
              name: `${name}: Country/Region/Territory #${subIndex + 1}`,
              page: `/application/step-parent-${index + 1}?check=1&index=${subIndex + 1}`,
            },
            {
              key: `${key}.parent_graduation_college_array[${subIndex}].school.zip`,
              name: `${name}: Zip Code #${subIndex + 1}`,
              page: `/application/step-parent-${index + 1}?check=1&index=${subIndex + 1}`,
            }
          );

          if (item?.school?.country === 'United States of America') {
            fields.push({
              key: `${key}.parent_graduation_college_array[${subIndex}].school.state`,
              name: `${name}: State #${subIndex + 1}`,
              page: `/application/step-parent-${index + 1}?check=1&index=${subIndex + 1}`,
            });
          }

          if (item?.school?.country === 'Canada') {
            fields.push({
              key: `${key}.parent_graduation_college_array[${subIndex}].school.province`,
              name: `${name}: Province #${subIndex + 1}`,
              page: `/application/step-parent-${index + 1}?check=1&index=${subIndex + 1}`,
            });
          }
        }
      });
    }

    if (
      data[key]?.step_parent_is_living === 'false' ||
      data[key]?.step_parent_is_living === false
    ) {
      stepParentFields.push({
        key: `${key}.date_deceased`,
        name: `${name}: Date deceased`,
        page: `/application/step-parent-${index + 1}?check=1`,
      });
    }

    if (data[key]?.college_or_university && !data[key]?.college_or_university?.id) {
      fields.push(
        {
          key: `${key}.college_or_university.name`,
          name: `${name}: Name of college/university`,
          page: `/application/step-parent-${index + 1}?check=1`,
        },
        {
          key: `${key}.college_or_university.country`,
          name: `${name}: Country/Region/Territory`,
          page: `/application/step-parent-${index + 1}?check=1`,
        },
        {
          key: `${key}.college_or_university.zip_code`,
          name: `${name}: Zip code`,
          page: `/application/step-parent-${index + 1}?check=1`,
        }
      );

      if (data?.college_or_university?.country === 'United States of America') {
        fields.push({
          key: `${key}.college_or_university.state`,
          name: `${name}: State`,
          page: `/application/step-parent-${index + 1}?check=1`,
        });
      }

      if (data?.college_or_university?.country === 'Canada') {
        fields.push({
          key: `${key}.college_or_university.province`,
          name: `${name}: Province`,
          page: `/application/step-parent-${index + 1}?check=1`,
        });
      }
    }

    if (data[key]?.csu_info?.parent_address_is === 'A different address') {
      fields.push(
        {
          key: `${key}.csu_info.different_parent_address_country`,
          name: `${name}: Country`,
          page: `/application/step-parent-${index + 1}?check=1`,
        },
        {
          key: `${key}.csu_info.different_parent_address_line_1`,
          name: 'Address Line 1',
          page: `/application/step-parent-${index + 1}?check=1`,
        },
        {
          key: `${key}.csu_info.different_parent_address_city`,
          name: `${name}: City`,
          page: `/application/step-parent-${index + 1}?check=1`,
        },
        {
          key: `${key}.csu_info.different_parent_address_zip_code`,
          name: `${name}: Zip Code`,
          page: `/application/step-parent-${index + 1}?check=1`,
        }
      );

      if (data[key]?.csu_info?.different_parent_address_country !== 'United States of America') {
        fields.push({
          key: `${key}.csu_info.different_parent_address_province`,
          name: `${name}: Province`,
          page: `/application/step-parent-${index + 1}?check=1`,
        });
      } else {
        fields.push({
          key: `${key}.csu_info.different_parent_address_state`,
          name: `${name}: State`,
          page: `/application/step-parent-${index + 1}?check=1`,
        });
      }
    }

    if (['Employed', 'Retired'].includes(data[key]?.employment_status)) {
      fields.push({
        key: `${key}.currently_employed_or_retire_from_college`,
        name: `${name}: Is parent currently employed by or retired from a college or university?`,
        page: `/application/step-parent-${index + 1}?check=1`,
      });

      if (
        [
          'Currently employed at a college/university',
          'Retired from a college/university',
        ].includes(data[key]?.currently_employed_or_retire_from_college)
      ) {
        fields.push({
          key: `${key}.college_or_university`,
          name: `${name}: College/University employer lookup`,
          page: `/application/step-parent-${index + 1}?check=1`,
        });
      }
    }

    if (
      [
        'Some trade school or community college',
        'Graduated from a trade school or community college',
        'Some college/university',
        'Graduated from college/university',
        'Graduate school',
      ].includes(data[key]?.education_level)
    ) {
      fields.push({
        key: `${key}.total_number_institutions_attended`,
        name: `${name}: Total number of institutions attended`,
        page: `/application/step-parent-${index + 1}?check=1`,
      });
    }

    if (
      data[key]?.csu_info?.isCurrentPermanentResidentOfCalifornia === 'true' ||
      data[key]?.csu_info?.isCurrentPermanentResidentOfCalifornia === true
    ) {
      fields.push({
        key: `${key}.csu_info.isLivedInCaliforniaContinuouslySinceBirth`,
        name: `${name}: Has your parent/guradian lived in California continuously since birth?`,
        page: `/application/step-parent-${index + 1}?check=1`,
      });
    }
    if (
      data[key]?.csu_info?.isLivedInCaliforniaContinuouslySinceBirth === `false` ||
      data[key]?.csu_info?.isCurrentPermanentResidentOfCalifornia === false
    ) {
      fields.push(
        {
          key: `${key}.csu_info.whenStayInCaliforniaBegin`,
          name: `${name}: When did his/her current stay in California begin?`,
          page: `/application/step-parent-${index + 1}?check=1`,
        },
        {
          key: `${key}.csu_info.priorCountry`,
          name: `${name}: Prior Country`,
          page: `/application/step-parent-${index + 1}?check=1`,
        },
        {
          key: `${key}.csu_info.priorState`,
          name: `${name}: Prior State/Territory`,
          page: `/application/step-parent-${index + 1}?check=1`,
        },
        {
          key: `${key}.csu_info.fromWhenToWhenLiveThere`,
          name: `${name}: From when to when did he/she live there`,
          page: `/application/step-parent-${index + 1}?check=1`,
        },
        {
          key: `${key}.csu_info.ToWhenToWhenLiveThere`,
          name: `${name}: To`,
          page: `/application/step-parent-${index + 1}?check=1`,
        }
      );
    }

    fields = [...fields, ...stepParentFields];
  });

  return fields;
};

export const checkHihtSchoolCondition = (data: any): RequiredField[] => {
  const fields = [...HIGH_SCHOOL_FIELDS_COMMON];
  const getAllSelectIndices = (item: any) => {
    const indices = item.grade
      .map((grade: any, index: number) => (grade === '- Select -' ? index : -1))
      .filter((index: number) => index !== -1);

    return indices;
  };

  if (data?.myHighSchool?.high_school_array && data?.myHighSchool?.high_school_array.length > 0) {
    if (data?.myHighSchool?.high_school_array.length > 1) {
      fields.push(
        {
          key: 'myHighSchool.high_school_info.left_highschool_reason',
          name: 'Why you left each secondary/high school',
          page: '/application/high-school?check=1',
        },
        {
          key: 'myHighSchool.high_school_info.academic_history',
          name: 'Why you have missing academic year courses, aka summer courses only, at aspecific high school listed above',
          page: '/application/high-school?check=1',
        }
      );
    }

    // start loopParent
    data?.myHighSchool.high_school_array.forEach((item: any, index: number) => {
      fields.push(
        {
          key: `myHighSchool.high_school_array[${index}].where_attend_high_school`,
          name: `Where do/did you attend high school? (${index + 1})`,
          page: `/application/high-school?check=1&index=${index + 1}`,
        },
        {
          key: `myHighSchool.high_school_array[${index}].start_date`,
          name: `Start date (${index + 1})`,
          page: `/application/high-school?check=1&index=${index + 1}`,
        },
        {
          key: `myHighSchool.high_school_array[${index}].end_date`,
          name: `End date (${index + 1})`,
          page: `/application/high-school?check=1&index=${index + 1}`,
        },
        {
          key: `myHighSchool.high_school_array[${index}].classes_schedule`,
          name: `What's its school system (${index + 1})`,
          page: `/application/high-school?check=1&index=${index + 1}`,
        },
        {
          key: `myHighSchool.high_school_array[${index}].is_graduate_from_this_school`,
          name: `Did or will you graduate from this school? (${index + 1})`,
          page: `/application/high-school?check=1&index=${index + 1}`,
        },
        {
          key: `myHighSchool.high_school_array[${index}].is_live_on_campus`,
          name: `Do you live on campus (${index + 1})`,
          page: `/application/high-school?check=1&index=${index + 1}`,
        },
        {
          key: `myHighSchool.high_school_array[${index}].is_boarding_school`,
          name: `Is this a boarding school? (${index + 1})`,
          page: `/application/high-school?check=1&index=${index + 1}`,
        }
      );

      if (
        item?.is_graduate_from_this_school === true ||
        item?.is_graduate_from_this_school === 'true'
      ) {
        fields.push({
          key: `myHighSchool.high_school_array[${index}].degree`,
          name: `Do you live on campus (${index + 1})`,
          page: `/application/high-school?check=1&index=${index + 1}`,
        });

        if (item?.degree !== 'No degree from this high school') {
          fields.push(
            {
              key: `myHighSchool.high_school_array[${index}].month`,
              name: `Moth (${index + 1})`,
              page: `/application/high-school?check=1&index=${index + 1}`,
            },
            {
              key: `myHighSchool.high_school_array[${index}].yaer`,
              name: `Year (${index + 1})`,
              page: `/application/high-school?check=1&index=${index + 1}`,
            },
            {
              key: `myHighSchool.high_school_array[${index}].day`,
              name: `Day (${index + 1})`,
              page: `/application/high-school?check=1&index=${index + 1}`,
            }
          );
        }
      }

      if (item?.grade && item?.grade.length > 0) {
        const selectIndices = getAllSelectIndices(item);

        selectIndices.forEach((indexGrade: any) => {
          fields.push({
            key: `grade[${indexGrade}]`,
            name: `School (${index + 1}) - Grade (${indexGrade + 1})`,
            page: `/application/high-school?check=1&index=${index + 1}`,
          });
        });
      }

      if (item?.academic_year && item?.academic_year.length > 0) {
        const selectIndices = getAllSelectIndices(item);

        selectIndices.forEach((indexGrade: any) => {
          fields.push({
            key: `academic_year[${indexGrade}]`,
            name: `School (${index + 1}) - Academic Year (${indexGrade + 1})`,
            page: `/application/high-school?check=1&index=${index + 1}`,
          });
        });
      }

      if (item?.grade?.length === 0) {
        fields.push({
          key: `grade_array`,
          name: `What grades did you attend this high school? (${index + 1})`,
          page: `/application/high-school?check=1&index=${index + 1}`,
        });
      }
      if (item?.where_attend_high_school === 'OUTSIDE_CA') {
        fields.push(
          {
            key: `myHighSchool.high_school_array[${index}].state`,
            name: `State/U.S. territory? (${index + 1})`,
            page: `/application/high-school?check=1&index=${index + 1}`,
          },
          {
            key: `myHighSchool.high_school_array[${index}].csu_city`,
            name: `City? (${index + 1})`,
            page: `/application/high-school?check=1&index=${index + 1}`,
          }
        );
      }

      if (item?.where_attend_high_school === 'OUTSIDE_USA') {
        fields.push(
          {
            key: `myHighSchool.high_school_array[${index}].country`,
            name: `Select a country (${index + 1})`,
            page: `/application/high-school?check=1&index=${index + 1}`,
          },
          {
            key: `myHighSchool.high_school_array[${index}].academic_performance`,
            name: `Academic Performance (High School GPA, Standing, Percentage, etc.)? (${
              index + 1
            })`,
            page: `/application/high-school?check=1&index=${index + 1}`,
          },
          {
            key: `myHighSchool.high_school_array[${index}].name_of_degree`,
            name: `Name of Degree/Diploma (e.g. Diploma, A-Level, Abitur, Certificado) (${
              index + 1
            })`,
            page: `/application/high-school?check=1&index=${index + 1}`,
          }
        );
      }

      if (item?.high_school_object?.is_manual) {
        fields.push(
          {
            key: `myHighSchool.high_school_array[${index}].high_school_name`,
            name: `Name of Home School (${index + 1})`,
            page: `/application/high-school?check=1&index=${index + 1}`,
          },
          {
            key: `myHighSchool.high_school_array[${index}].high_school_object.school_type`,
            name: `School Type (${index + 1})`,
            page: `/application/high-school?check=1&index=${index + 1}`,
          },
          {
            key: `myHighSchool.high_school_array[${index}].high_school_object.city`,
            name: `City (${index + 1})`,
            page: `/application/high-school?check=1&index=${index + 1}`,
          }
        );
      }

      if (item?.grading_scale_is_0_100) {
        fields.push(
          {
            key: `myHighSchool.high_school_array[${index}].lowest_number_grade`,
            name: `What is the lowest possible number grade at this school? (${index + 1})`,
            page: `/application/high-school?check=1&index=${index + 1}`,
          },
          {
            key: `myHighSchool.high_school_array[${index}].highest_number_grade`,
            name: `What is the highest possible number grade at this school? (${index + 1})`,
            page: `/application/high-school?check=1&index=${index + 1}`,
          }
        );
      }

      if (item?.grading_scale_is_other) {
        fields.push({
          key: `myHighSchool.high_school_array[${index}].other_grading_system`,
          name: `Specify the grading system (${index + 1})`,
          page: `/application/high-school?check=1&index=${index + 1}`,
        });
      }
    });
    //end of high school array
  }

  if (
    [
      'I have or will graduate with my high school diploma',
      'I have or will have a high school equivalency',
    ].includes(data?.myHighSchool?.graduating_school_array?.graduation_status)
  ) {
    fields.push({
      key: 'myHighSchool.graduating_school_array.graduating_date_received',
      name: 'Date Received/Expected',
      page: '/application/high-school?check=1',
    });
  }

  if (
    !data?.myHighSchool?.graduating_school_array?.progression_is_graduate_early &&
    !data?.myHighSchool?.graduating_school_array?.progression_is_graduate_late &&
    !data?.myHighSchool?.graduating_school_array?.progression_is_time_off &&
    !data?.myHighSchool?.graduating_school_array?.progression_is_take_gap_year &&
    !data?.myHighSchool?.graduating_school_array?.progression_is_no_change
  ) {
    fields.push({
      key: 'progression_is_take_gap_year',
      name: 'Please indicate if any of these options will have affected your progression through or since secondary/high school. Check all that apply',
      page: '/application/high-school?check=1',
    });
  }

  if (
    data?.myHighSchool?.graduating_school_array?.progression_is_graduate_early ||
    data?.myHighSchool?.graduating_school_array?.progression_is_graduate_late ||
    data?.myHighSchool?.graduating_school_array?.progression_is_time_off ||
    data?.myHighSchool?.graduating_school_array?.progression_is_take_gap_year
  ) {
    fields.push({
      key: 'myHighSchool.graduating_school_array.change_in_progression_through_highschool',
      name: 'Please use the space below to provide details about the change in progression through secondary/high school that you indicated above',
      page: '/application/high-school?check=1',
    });
  }

  if (
    data?.myHighSchool?.graduating_school_array?.graduation_status ===
    'I have or will graduate with my high school diploma'
  ) {
    fields.push({
      key: 'myHighSchool.graduating_school_array.graduating_high_school',
      name: 'Please select your graduating high school.',
      page: '/application/high-school?check=1',
    });
  }

  if (data?.myHighSchool?.graduating_school_array?.graduating_school_rank_report === 'Exact') {
    fields.push({
      key: 'myHighSchool.graduating_school_array.class_rank',
      name: 'Class Rank',
      page: '/application/high-school?check=1',
    });
  }

  if (data?.myHighSchool?.graduating_school_array?.graduating_school_rank_report === 'Decile') {
    fields.push({
      key: 'myHighSchool.graduating_school_array.class_rank',
      name: 'Decile rank',
      page: '/application/high-school?check=1',
    });
  }

  if (data?.myHighSchool?.graduating_school_array?.graduating_school_rank_report === 'Quintile') {
    fields.push({
      key: 'myHighSchool.graduating_school_array.class_rank',
      name: 'Quintile rank',
      page: '/application/high-school?check=1',
    });
  }

  if (data?.myHighSchool?.graduating_school_array?.graduating_school_rank_report === 'Quartile') {
    fields.push({
      key: 'myHighSchool.graduating_school_array.class_rank',
      name: 'Quartile rank',
      page: '/application/high-school?check=1',
    });
  }
  return fields;
};

export const checkFuturePlanCondition = (data: any): RequiredField[] => {
  const files = [...FUTURE_PLAN_FIELDS_COMMON];

  if (data?.career_interest === 'Other') {
    files.push({
      key: 'other_career_interest',
      name: 'Specify other organization',
      page: '/application/future-plans?check=1',
    });
  }

  return files;
};

export const checkCollegeCondition = (data: any): RequiredField[] => {
  const fields = [...COLLEGE_FIELDS_COMMON];

  if (data?.is_taken_college_courses === 'true' || data?.is_taken_college_courses === true) {
    fields.push(
      {
        key: 'is_currently_taking_courses_in_college',
        name: 'Are you currently taking courses in college?',
        page: '/application/college-and-university?check=1',
      },
      {
        key: 'number_of_college_credits_have_earned',
        name: 'How many college credits have you earned to date? * ',
        page: '/application/college-and-university?check=1',
      },
      {
        key: 'number_of_credits_earned_after_school',
        name: 'How many credits were earned after high school?',
        page: '/application/college-and-university?check=1',
      }
    );

    if (data?.number_of_colleges_taken_coursework === null) {
      fields.push({
        key: 'number_of_colleges_taken_coursework',
        name: "Indicate the number of colleges or universities you've taken a course at or received credit from?",
        page: '/application/college-and-university?check=1',
      });
    }

    // start loop
    if (data?.colleges_taken_coursework_array && data?.colleges_taken_coursework_array.length > 0) {
      data?.colleges_taken_coursework_array.forEach((item: any, index: number) => {
        fields.push(
          {
            key: `colleges_taken_coursework_array[${index}].college_info.where_attend_college`,
            name: `Where is the college you attended? (${index + 1})`,
            page: `/application/college-and-university?check=1&index=${index + 1}`,
          },
          {
            key: `colleges_taken_coursework_array[${index}].fromDate`,
            name: `College "${index}" from date`,
            page: `/application/college-and-university?check=1&index=${index + 1}`,
          },
          {
            key: `colleges_taken_coursework_array[${index}].toDate`,
            name: `College "${index}" to date`,
            page: `/application/college-and-university?check=1&index=${index + 1}`,
          },
          {
            key: `colleges_taken_coursework_array[${index}].expect_to_earn_degree_from_San_Diego_Mesa`,
            name: `Do you expect to earn a degree (${index + 1})?`,
            page: `/application/college-and-university?check=1&index=${index + 1}`,
          },
          {
            key: `colleges_taken_coursework_array[${index}].college_info.type_of_term_systerm`,
            name: `What type of term system does this college or university use (${index + 1})?`,
            page: `/application/college-and-university?check=1&index=${index + 1}`,
          },
          {
            key: `colleges_taken_coursework_array[${index}].college_info.your_tuition_status`,
            name: `What was your tuition status (resident or non-resident) at this college or university during the last term you attended (${
              index + 1
            })?`,
            page: `/application/college-and-university?check=1&index=${index + 1}`,
          },
          {
            key: `colleges_taken_coursework_array[${index}].school.name`,
            name: `Search for your College / University (${index + 1})`,
            page: `/application/college-and-university?check=1&index=${index + 1}`,
          }
        );

        if (item?.school?.is_manual) {
          fields.push(
            {
              key: `colleges_taken_coursework_array[${index}].school.country`,
              name: `Country/Region/Territory (${index + 1})`,
              page: `/application/college-and-university?check=1&index=${index + 1}`,
            },
            {
              key: `colleges_taken_coursework_array[${index}].school.school_type`,
              name: `School Type (${index + 1})`,
              page: `/application/college-and-university?check=1&index=${index + 1}`,
            },
            {
              key: `colleges_taken_coursework_array[${index}].school.zip`,
              name: `Zip Code (${index + 1})`,
              page: `/application/college-and-university?check=1&index=${index + 1}`,
            }
          );

          if (item?.school?.country === 'United States of America') {
            fields.push({
              key: `colleges_taken_coursework_array[${index}].school.state`,
              name: `State (${index + 1})`,
              page: `/application/college-and-university?check=1&index=${index + 1}`,
            });
          }

          if (item?.school?.country === 'Canada') {
            fields.push({
              key: `colleges_taken_coursework_array[${index}].school.state`,
              name: `Province (${index + 1})`,
              page: `/application/college-and-university?check=1&index=${index + 1}`,
            });
          }
        }
        if (
          item?.expect_to_earn_degree_from_San_Diego_Mesa === 'true' ||
          item?.expect_to_earn_degree_from_San_Diego_Mesa === true
        ) {
          fields.push(
            {
              key: `colleges_taken_coursework_array[${index}].degree_info`,
              name: `Degree Info (${index + 1})`,
              page: `/application/college-and-university?check=1&index=${index + 1}`,
            },
            {
              key: `colleges_taken_coursework_array[${index}].college_info.detail_degree_earned`,
              name: `Details of degree in progress or will be earned (${index + 1})`,
              page: `/application/college-and-university?check=1&index=${index + 1}`,
            },
            {
              key: `colleges_taken_coursework_array[${index}].college_info.when_you_earn_degree_month`,
              name: `When did you earn that degree (${index + 1})`,
              page: `/application/college-and-university?check=1&index=${index + 1}`,
            },
            {
              key: `colleges_taken_coursework_array[${index}].college_info.your_major`,
              name: `What was your major (${index + 1})`,
              page: `/application/college-and-university?check=1&index=${index + 1}`,
            }
          );
        }

        if (item?.college_grading_system_other) {
          fields.push({
            key: `colleges_taken_coursework_array[${index}].other_grading_system`,
            name: `Specify the grading system (${index + 1})`,
            page: `/application/college-and-university?check=1&index=${index + 1}`,
          });
        }

        if (!item.college_grading_system_alphabet && !item.college_grading_system_alphabet) {
          fields.push({
            key: `what_is_this_college_grading_system[${index}]`,
            name: `What is this college's grading system (${index + 1})`,
            page: `/application/college-and-university?check=1&index=${index + 1}`,
          });
        }

        if (
          ['OUTSIDE_USA', 'Outside the U.S.'].includes(
            data?.colleges_taken_coursework_array[index]?.college_info?.where_attend_college
          )
        ) {
          fields.push({
            key: `colleges_taken_coursework_array[${index}].college_info.country`,
            name: `Select a country (${index + 1})`,
            page: `/application/college-and-university?check=1&index=${index + 1}`,
          });
        }

        if (
          ['OUTSIDE_USA', 'Outside California (in the U.S.)'].includes(
            data?.colleges_taken_coursework_array[index]?.college_info?.where_attend_college
          )
        ) {
          fields.push({
            key: `colleges_taken_coursework_array[${index}].college_info.state`,
            name: `State/U.S. territory (${index + 1})`,
            page: `/application/college-and-university?check=1&index=${index + 1}`,
          });
        }

        if (item?.grades && item?.grades.length > 0) {
          item?.grades?.forEach((grade: any, indexGrade: number) => {
            if (!grade.trim()) {
              fields.push({
                key: `colleges_taken_coursework_array[${index}].grades[${indexGrade}]`,
                name: `College "${index}" - Grade "${indexGrade}"`,
                page: `/application/college-and-university?check=1&index=${index + 1}`,
              });
            }
          });
        }
      });
    }
    // end loop
  }

  return fields;
};

export const checkGenaralTestCondition = (data: any): RequiredField[] => {
  const fields = [...GENERAL_TEST_FIELDS_COMMON];

  if (data?.is_wish_self_report_scores === 'true' || data?.is_wish_self_report_scores === true) {
    fields.push({
      key: 'is_promotion_within_educational_system',
      name: 'International applicants: Is promotion within your educational system based upon standard leaving examinations given at the end of lower and/or senior secondary school by a state or national leaving examinations board? (Students studying in the US typically answer no to this question.)',
      page: '/application/general-info?check=1',
    });
  }

  return fields;
};

export const checkACTTestcondition = (data: any): RequiredField[] => {
  const files: any = [];

  if (data?.myGeneral?.all_tests_wish_report_array?.is_SAT) {
    files.push(...SAT_TEST_FIELDS_COMMON);
    if (
      data?.satTest?.taken_sat_essay_option === 'true' ||
      data?.satTest?.taken_sat_essay_option === true
    ) {
      files.push(
        {
          key: 'satTest.combined_essay_score',
          name: 'Highest combined essay score',
          page: '/application/sat-test?check=1',
        },
        {
          key: 'satTest.combined_essay_date',
          name: 'Combined essay date',
          page: '/application/sat-test?check=1',
        }
      );
    }
    if (data?.satTest?.csu_info?.sat_score && data?.satTest?.csu_info?.sat_score.length > 0) {
      data.satTest.csu_info.sat_score.forEach((item: any, index: number) => {
        files.push(
          {
            key: `satTest.csu_info.sat_score[${index}].test_date`,
            name: `Test Date (${index + 1})`,
            page: '/application/sat-test?check=1',
          },
          {
            key: `satTest.csu_info.sat_score[${index}].total_score`,
            name: `Total Score (${index + 1})`,
            page: '/application/sat-test?check=1',
          },
          {
            key: `satTest.csu_info.sat_score[${index}].reading_writing_score`,
            name: `Evidenced Based Reading/Writing Score (${index + 1})`,
            page: '/application/sat-test?check=1',
          },
          {
            key: `satTest.csu_info.sat_score[${index}].math_score`,
            name: `Math Score (${index + 1})`,
            page: '/application/sat-test?check=1',
          }
        );
      });
    }

    if (data?.satTest?.future_sat_tests_plan_to_take === 1) {
      files.push({
        key: 'satTest.future_testing_date_1.test1',
        name: 'Future testing date 1',
        page: '/application/sat-test?check=1',
      });
    }

    if (data?.satTest?.future_sat_tests_plan_to_take === 2) {
      files.push({
        key: 'satTest.future_testing_date_1.test2',
        name: 'Future testing date 2',
        page: '/application/sat-test?check=1',
      });
    }

    if (data?.satTest?.future_sat_tests_plan_to_take === 3) {
      files.push({
        key: 'satTest.future_testing_date_1.test3',
        name: 'Future testing date 3',
        page: '/application/sat-test?check=1',
      });
    }
  }

  if (data?.myGeneral?.all_tests_wish_report_array?.is_ACT) {
    files.push(...ACT_TEST_FIELDS_COMMON);
    if (data?.actTest?.csu_info?.act_score && data?.actTest?.csu_info?.act_score.length > 0) {
      data.actTest.csu_info.act_score.forEach((item: any, index: number) => {
        files.push(
          {
            key: `actTest.csu_info.act_score[${index}].test_date`,
            name: `Test Date (${index + 1})`,
            page: `/application/act-test?check=1&index=${index + 1}`,
          },
          {
            key: `actTest.csu_info.act_score[${index}].composite_score`,
            name: `Composite score (${index + 1})`,
            page: `/application/act-test?check=1&index=${index + 1}`,
          },
          {
            key: `actTest.csu_info.act_score[${index}].english`,
            name: `English (${index + 1})`,
            page: `/application/act-test?check=1&index=${index + 1}`,
          },
          {
            key: `actTest.csu_info.act_score[${index}].mathematics`,
            name: `Mathematics (${index + 1})`,
            page: `/application/act-test?check=1&index=${index + 1}`,
          },
          {
            key: `actTest.csu_info.act_score[${index}].reading`,
            name: `Reading (${index + 1})`,
            page: '/application/acts-test?check=1',
          },
          {
            key: `actTest.csu_info.act_score[${index}].science`,
            name: `Science (${index + 1})`,
            page: `/application/act-test?check=1&index=${index + 1}`,
          }
        );

        if (
          data?.actTest?.is_have_act_score_report === 'true' ||
          data?.actTest?.is_have_act_score_report === true
        ) {
          files.push({
            key: `actTest.csu_info.act_score[${index}].writing`,
            name: 'Writing Score',
            page: `/application/act-test?check=1&index=${index + 1}`,
          });
        }
      });
    }

    if (
      data?.actTest?.is_have_act_score_report === 'true' ||
      data?.actTest?.is_have_act_score_report === true
    ) {
      files.push(
        {
          key: 'actTest.highest_writing_score_sub',
          name: 'Highest Writing Score',
          page: '/application/act-test?check=1',
        },
        {
          key: 'actTest.highest_writing_test_day',
          name: 'Highest Writing Score Test Date',
          page: '/application/act-test?check=1',
        }
      );
    }

    if (
      data?.actTest?.future_act_test_plan_to_take &&
      Number(data?.actTest?.future_act_test_plan_to_take) === 1
    ) {
      files.push({
        key: 'actTest.future_testing_date_1.test1',
        name: 'Future testing date 1',
        page: '/application/act-test?check=1',
      });
    }

    if (
      data?.actTest?.future_act_test_plan_to_take &&
      Number(data?.actTest?.future_act_test_plan_to_take) === 2
    ) {
      files.push({
        key: 'actTest.future_testing_date_1.test2',
        name: 'Future testing date 2',
        page: '/application/act-test?check=1',
      });
    }

    if (
      data?.actTest?.future_act_test_plan_to_take &&
      Number(data?.actTest?.future_act_test_plan_to_take) === 3
    ) {
      files.push({
        key: 'actTest.future_testing_date_1.test3',
        name: 'Future testing date 3',
        page: '/application/act-test?check=1',
      });
    }
  }

  return files;
};

export const checkAPTestCondition = (data: any): RequiredField[] => {
  const files: any = [];

  if (data?.ap_test_array && data?.ap_test_array.length > 0) {
    data.ap_test_array.forEach((item: any, index: number) => {
      files.push(
        {
          key: `ap_test_array[${index}].subjectCM`,
          name: `AP exam (${index + 1})`,
          page: `/application/act-subject-test?check=1&index=${index + 1}`,
        },
        {
          key: `ap_test_array[${index}].examStatus`,
          name: `Is this exam completed or planned? (${index + 1})`,
          page: `/application/act-subject-test?check=1&index=${index + 1}`,
        }
      );

      if (item?.examStatus === 'Completed') {
        files.push(
          {
            key: `ap_test_array[${index}].dateTaken`,
            name: `When did you take this test? (${index + 1})`,
            page: `/application/act-subject-test?check=1&index=${index + 1}`,
          },
          {
            key: `ap_test_array[${index}].isReceivedScore`,
            name: `Have you received your score yet? (${index + 1})`,
            page: `/application/act-subject-test?check=1&index=${index + 1}`,
          }
        );
        if (item?.isReceivedScore === 'true' || item?.isReceivedScore === true) {
          files.push({
            key: `ap_test_array[${index}].score`,
            name: `Score (${index + 1})`,
            page: `/application/act-subject-test?check=1&index=${index + 1}`,
          });
        }
      } else {
        files.push({
          key: `ap_test_array[${index}].planToTakeExam`,
          name: `When do you plan to take the exam? (${index + 1})`,
          page: `/application/act-subject-test?check=1&index=${index + 1}`,
        });
      }
    });
  }

  return files;
};

export const checkCLEPTestCondition = (data: any): RequiredField[] => {
  const files: any = [];

  if (data?.csu_info && data?.csu_info.length > 0) {
    data.csu_info.forEach((item: any, index: number) => {
      files.push({
        key: `csu_info[${index}].is_taken_exam`,
        name: `Have your already taken this exam (${index + 1})`,
        page: `/application/CLEP-test?check=1&index=${index + 1}`,
      });

      if (item?.is_taken_exam === 'true' || item?.is_taken_exam === true) {
        files.push(
          {
            key: `csu_info[${index}].date_taken`,
            name: `CLEP Exam Date Taken (${index + 1})`,
            page: `/application/CLEP-test?check=1&index=${index + 1}`,
          },
          {
            key: `csu_info[${index}].score`,
            name: `CLEP Exam Score (${index + 1})`,
            page: `/application/CLEP-test?check=1&index=${index + 1}`,
          }
        );
      } else {
        files.push({
          key: `csu_info[${index}].plan_taking_on`,
          name: `When do you plan to take the exam? (${index + 1})`,
          page: `/application/CLEP-test?check=1&index=${index + 1}`,
        });
      }
    });
  }
  return files;
};
export const checkIBTestCondition = (data: any): RequiredField[] => {
  const files: any = [];

  if (data?.ib_test_array && data?.ib_test_array.length > 0) {
    data.ib_test_array.forEach((item: any, index: number) => {
      if (ibSubjectHaveLanguageQuestion.includes(item?.subject)) {
        files.push({
          key: `ib_test_array[${index}].language`,
          name: `Language (select the one that applies) (${index + 1})`,
          page: `/application/ib-subject-test?check=1&index=${index + 1}`,
        });
      }
      files.push(
        {
          key: `ib_test_array[${index}].examStatus`,
          name: `Is this exam completed or planned? (${index + 1})`,
          page: `/application/ib-subject-test?check=1&index=${index + 1}`,
        },
        {
          key: `ib_test_array[${index}].dateTaken`,
          name: `Date taken of planned? (${index + 1})`,
          page: `/application/ib-subject-test?check=1&index=${index + 1}`,
        },
        {
          key: `ib_test_array[${index}].subject`,
          name: `Subject (${index + 1})`,
          page: `/application/ib-subject-test?check=1&index=${index + 1}`,
        },
        {
          key: `ib_test_array[${index}].level`,
          name: `Level (select the one that applies) (${index + 1})`,
          page: `/application/ib-subject-test?check=1&index=${index + 1}`,
        },
        {
          key: `ib_test_array[${index}].ibExamName`,
          name: `IB exam name (${index + 1})`,
          page: `/application/ib-subject-test?check=1&index=${index + 1}`,
        }
      );

      if (item?.ibExamName === 'Other') {
        files.push({
          key: `ib_test_array[${index}].ibExamSubjectNameDescribeOther`,
          name: `Describe other (${index + 1})`,
          page: `/application/ib-subject-test?check=1&index=${index + 1}`,
        });
      }

      if (item?.examStatus === 'Completed') {
        files.push({
          key: `ib_test_array[${index}].isReceivedScore`,
          name: `Have you received your score yet? (${index + 1})`,
          page: `/application/ib-subject-test?check=1&index=${index + 1}`,
        });
      }
    });
  }

  return files;
};

export const checkActivitisCondition = (data: any): RequiredField[] => {
  const fields = [...ACTIVITIES_FIELDS_COMMON];
  const startIndex = data?.startIndex || 0;

  if (data?.myActivities && data?.myActivities?.length > 0) {
    data.myActivities.forEach((item: any, index: number) => {
      fields.push(
        {
          key: `myActivities[${index}].categoryUC`,
          name: `Category (${startIndex + (index + 1)})`,
          page: `/application/activities?check=1&index=${startIndex + (index + 1)}`,
        },
        {
          key: `myActivities[${index}].isIntendParticipateSimilarActivity`,
          name: `I intend to participate in a similar activity in college (${
            startIndex + (index + 1)
          })`,
          page: `/application/activities?check=1&index=${startIndex + (index + 1)}`,
        }
      );

      if (
        !item.isGrade9ParticipationLevels &&
        !item.isGrade10ParticipationLevels &&
        !item.isGrade11ParticipationLevels &&
        !item.isGrade12ParticipationLevels &&
        !item.isPostGraduateParticipationLevels
      ) {
        fields.push({
          key: `myActivities[${index}].participation`,
          name: `Participation grade levels (${startIndex + (index + 1)})`,
          page: `/application/activities?check=1&index=${startIndex + (index + 1)}`,
        });
      }

      if (
        !item.timmingOfParticipation_duringYear &&
        !item.timmingOfParticipation_duringBreak &&
        !item.timmingOfParticipation_allYear
      ) {
        fields.push({
          key: `myActivities[${index}].timmingOfParticipation`,
          name: `Timing of Participation (${startIndex + (index + 1)})`,
          page: `/application/activities?check=1&index=${startIndex + (index + 1)}`,
        });
      }

      if (!item?.weeksPerYear || item?.weeksPerYear === 0) {
        fields.push({
          key: `myActivities[${index}].weeksPerYearCustom`,
          name: `Weeks per Year (${startIndex + (index + 1)})`,
          page: `/application/activities?check=1&index=${startIndex + (index + 1)}`,
        });
      }

      if (!item?.hoursPerWeek || item?.hoursPerWeek === 0) {
        fields.push({
          key: `myActivities[${index}].hoursPerWeekCustom`,
          name: `Hours per Week (${startIndex + (index + 1)})`,
          page: `/application/activities?check=1&index=${startIndex + (index + 1)}`,
        });
      }

      if (item?.categoryUC === 'Educational Preparation Program') {
        fields.push({
          key: `myActivities[${index}].programNameForEducationPreparation`,
          name: `What was the program name? (${startIndex + (index + 1)})`,
          page: `/application/activities?check=1&index=${startIndex + (index + 1)}`,
        });

        if (item?.programNameForEducationPreparation === 'Other') {
          fields.push({
            key: `myActivities[${index}].programNameForEducationPreparationSpecify`,
            name: `Program Name (${startIndex + (index + 1)})`,
            page: `/application/activities?check=1&index=${startIndex + (index + 1)}`,
          });
        }
      }

      if (item?.categoryUC === 'Other Coursework') {
        fields.push({
          key: `myActivities[${index}].otherCoursewordName`,
          name: `What was the course name (${startIndex + (index + 1)})`,
          page: `/application/activities?check=1&index=${startIndex + (index + 1)}`,
        });
      }

      if (item?.categoryUC === 'Volunteer/Community Service') {
        fields.push(
          {
            key: `myActivities[${index}].organizationVolunteeredFor`,
            name: `What's the name of the organization, program, school or group you volunteered for (${
              startIndex + (index + 1)
            })`,
            page: `/application/activities?check=1&index=${startIndex + (index + 1)}`,
          },
          {
            key: `myActivities[${index}].describeOrganization`,
            name: `Please describe the organization, program, school or group. (${
              startIndex + (index + 1)
            })`,
            page: `/application/activities?check=1&index=${startIndex + (index + 1)}`,
          }
        );
      }

      if (item?.categoryUC === 'Extracurricular Activity') {
        fields.push({
          key: `myActivities[${index}].activityExperienceProgramName`,
          name: `What's the name of the activity (${startIndex + (index + 1)})`,
          page: `/application/activities?check=1&index=${startIndex + (index + 1)}`,
        });
      }

      if (item?.categoryUC === 'Work Experience') {
        fields.push(
          {
            key: `myActivities[${index}].workExperience_whereDidYouWork`,
            name: `Where did you work (${startIndex + (index + 1)})`,
            page: `/application/activities?check=1&index=${startIndex + (index + 1)}`,
          },
          {
            key: `myActivities[${index}].describeCompany`,
            name: `Please briefly describe the company or organization where you worked (${
              startIndex + (index + 1)
            })`,
            page: `/application/activities?check=1&index=${startIndex + (index + 1)}`,
          },
          {
            key: `myActivities[${index}].isStillWork`,
            name: `Do you still work at this job? (${startIndex + (index + 1)})`,
            page: `/application/activities?check=1&index=${startIndex + (index + 1)}`,
          },
          {
            key: `myActivities[${index}].workExperience_jobTitle`,
            name: `What was your job title/position (${startIndex + (index + 1)})`,
            page: `/application/activities?check=1&index=${startIndex + (index + 1)}`,
          }
        );

        if (
          !item?.workExperience_workAfterTwelfthGrade &&
          !item?.workExperience_workWhenEleventhGrade &&
          !item?.workExperience_workWhenNinthGrade &&
          !item?.workExperience_workWhenTenthGrade &&
          !item?.workExperience_workWhenTwelfthGrade
        ) {
          fields.push({
            key: `myActivities[${index}].workExperience_workWhen`,
            name: `When did you work? (${startIndex + (index + 1)})`,
            page: `/application/activities?check=1&index=${startIndex + (index + 1)}`,
          });
        }

        if (item?.workExperience_workAfterTwelfthGrade) {
          fields.push(
            {
              key: `myActivities[${index}].workExperience_afterTwelfthGradeSummerYear`,
              name: `Number of Years? (${startIndex + (index + 1)})`,
              page: `/application/activities?check=1&index=${startIndex + (index + 1)}`,
            },
            {
              key: `myActivities[${index}].workExperience_afterTwelfthGradeSummerMonth`,
              name: `Number of Months (${startIndex + (index + 1)})`,
              page: `/application/activities?check=1&index=${startIndex + (index + 1)}`,
            }
          );
        }
      }
      if (
        !['Other Coursework', 'Educational Preparation Program', 'Work Experience'].includes(
          item?.categoryUC
        )
      ) {
        fields.push({
          key: `myActivities[${index}].whatDidYouDo`,
          name: `What did you do? (${startIndex + (index + 1)})`,
          page: `/application/activities?check=1&index=${startIndex + (index + 1)}`,
        });
      }
    });
  }

  return fields;
};

export const checkHonorsCondition = (data: any): RequiredField[] => {
  const fields: any = [...HONORS_FIELDS_COMMON];

  if (data?.myHonors && data?.myHonors.length > 0) {
    data?.myHonors.forEach((item: any, index: number) => {
      fields.push(
        {
          key: `myHonors[${index}].honorTitle`,
          name: `What's the name of the award or honor? (${index + 1})`,
          page: `/application/honors?check=1&index=${index + 1}`,
        },
        {
          key: `myHonors[${index}].typeOfHonor`,
          name: `Type of award or honor (${index + 1})`,
          page: `/application/honors?check=1&index=${index + 1}`,
        },
        {
          key: `myHonors[${index}].eligibilityRequirementsHonor`,
          name: `What are the eligibility requirements for this award or honor? (${index + 1})`,
          page: `/application/honors?check=1&index=${index + 1}`,
        },
        {
          key: `myHonors[${index}].actionToAchieveHonor`,
          name: `What did you do to achieve this award or honor? (${index + 1})`,
          page: `/application/honors?check=1&index=${index + 1}`,
        }
      );
    });
  }

  return fields;
};

export const checkEOPCondition = (data: any): RequiredField[] => {
  const fields = [...EOP_FIELDS_COMMON];

  if (
    !(
      data?.average_hours_worked_per_week == 'Select' ||
      data?.average_hours_worked_per_week == '0 hours' ||
      data?.average_hours_worked_per_week == '16-20 hours' ||
      data?.average_hours_worked_per_week == '21 or more hours' ||
      data?.average_hours_worked_per_week === ''
    )
  ) {
    fields.push({
      key: 'is_more_25_percent_work_related_major',
      name: 'Was more than 25% of your work hours related to your major of choice',
      page: '/application/educational-programs?check=1',
    });
  }

  if (
    !(
      data?.average_hours_worked_per_week === 'Select' ||
      data?.average_hours_worked_per_week === '0 hours' ||
      data?.average_hours_worked_per_week === ''
    )
  ) {
    fields.push({
      key: 'is_leadership_positions',
      name: 'Did you hold or have you held any leadership positions in those activities, e.g. class president, club president, team captain, yearbook editor, etc',
      page: '/application/educational-programs?check=1',
    });
  }

  if (data?.wish_to_apply_EOP === 'Yes') {
    fields.push(
      {
        key: 'is_enrolled_EOP',
        name: 'If you attended a CSU were you enrolled in an EOP program?',
        page: '/application/educational-programs?check=1',
      },
      {
        key: 'where_plan_to_live',
        name: 'Where do you plan to live while attending college? (Select one)',
        page: '/application/educational-programs?check=1',
      },
      {
        key: 'number_brothers_and_sisters_k12',
        name: 'How many brothers and sisters are currently attending a K-12 school?',
        page: '/application/educational-programs?check=1',
      },
      {
        key: 'number_brothers_and_sisters_received_bachelor_degree',
        name: "How many brothers and sisters received a bachelor's degree?",
        page: '/application/educational-programs?check=1',
      },
      {
        key: 'number_brothers_and_sisters_college',
        name: 'How many brothers and sisters are currently attending or attended college?',
        page: '/application/educational-programs?check=1',
      },
      {
        key: 'languages_spoken_in_home',
        name: 'What languages are spoken in your home?',
        page: '/application/educational-programs?check=1',
      },
      {
        key: 'is_received_income_from_public_assistance_program',
        name: 'During the past 10 years, have you or your family received any income from a public assistance program such as CalWorks, CalFresh, welfare, AFDC, TANF, Social Security, disability, etc.?',
        page: '/application/educational-programs?check=1',
      }
    );

    if (data?.is_enrolled_EOP === 'Yes') {
      fields.push({
        key: 'campus_enrolled_EOP',
        name: 'Select the campus?',
        page: '/application/educational-programs?check=1',
      });
    }

    if (
      data?.is_participated_in_publicly_funded_programs === 'true' ||
      data?.is_participated_in_publicly_funded_programs === true
    ) {
      fields.push({
        key: 'work_primarily_to_contribute',
        name: 'If yes, explain:',
        page: '/application/educational-programs?check=1',
      });
    }

    if (
      data?.is_work_primarily_to_contribute === 'true' ||
      data?.is_work_primarily_to_contribute === true
    ) {
      fields.push({
        key: 'work_primarily_to_contribute',
        name: 'If yes, please explain:',
        page: '/application/educational-programs?check=1',
      });
    }
    if (
      data?.is_received_income_from_public_assistance_program === 'true' ||
      data?.is_received_income_from_public_assistance_program === true
    ) {
      fields.push(
        {
          key: 'number_year_received_income',
          name: 'If yes, how many years',
          page: '/application/educational-programs?check=1',
        },
        {
          key: 'type_of_aid',
          name: 'Type(s) of aid',
          page: '/application/educational-programs?check=1',
        }
      );
    }
  }
  return fields;
};
