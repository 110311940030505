/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Container, Form, Row, Spinner, Button, Col, Card } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import SelectInput from 'components/input/SelectInput';
import TextInput from 'components/input/textInput';
import {
  checkEnableParseJSON,
  csuCountry,
  fullSuffixOptions,
  jobCategory,
  occupationOptions,
  optionValues,
} from 'components/helper/helper';
import SelectPhoneCodeInput from 'components/input/SelectPhoneCodeInput';
import TextIconInput from 'components/input/TextIconInput';
import GroupRadioButton from 'components/input/GroupRadioButton';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import collegeServices from 'services/collegeServices';
import {
  getStepParent1,
  updateStepParent1,
  getStepParent2,
  updateStepParent2,
  getHousehold,
} from 'app/family/familySlice';
import Education from '../parent/components/Education';
import { useLocation } from 'react-router-dom';
import DatePickerInput from 'components/input/DatePicker';
import YesNoSwitch from 'components/input/YesNoSwitch';
import Residency from '../parent/components/Residency';
import { countryOptions } from 'components/helper/countryPhoneCode';
import CheckboxInput from 'components/input/CheckboxInput';
import { debounce, defer } from 'lodash';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AutocompleteInput from 'components/input/AutoCompleteInput';
import { canadaProvince, usaState } from 'components/helper/stateAndCounty';
import familyServices from 'services/familyServices';

type Props = {};

const StepParent = (props: Props) => {
  const [stateOptions, setStateOptions] = React.useState<any[]>([]);
  const [searchInput, setSearchInput] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [selectedEducation, setSelectedEducation] = React.useState<any>();
  const query = new URLSearchParams(useLocation().search);
  const check = query.get('check');
  const indexExpand = query.get('index');

  React.useEffect(() => {
    collegeServices.getState().then((res: any) => {
      const states = res.map((item: any) => {
        return item.descr;
      });
      setStateOptions(states);
    });
  }, []);

  const [loading, setLoading] = React.useState(true);
  const [stepParentNumber, setStepParentNumber] = React.useState('1');
  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const family = useAppSelector((store) => store.family);
  const [currentParentCollege, setCurrentParentCollege] = React.useState<any[]>([]);

  const formik = useFormik({
    initialValues: {
      step_parent_is_married: '',
      step_parent_type: '',
      step_parent_is_living: '',
      prefix: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      former_last_name: '',
      suffix: '',
      step_parent_relationship: '',
      step_parent_email: '',
      country_code: '',
      phone_number: '',
      phone_number_type: '',
      occupation: '',
      other_occupation_detail: '',
      employment_status: '',
      currently_employed_or_retire_from_college: '',
      college_or_university: {
        name: '',
        country: '',
        address_line_1: '',
        address_line_2: '',
        address_line_3: '',
        city: '',
        state: '',
        zip_code: '',
        province: '',
        state_or_province: '',
      },
      current_employer: '',
      education_level: '',
      college_code: '',
      college_state: '',
      college_city: '',
      step_parent_current_job_status: '',
      date_deceased: '',
      number_of_year_at_home: '',
      number_of_year_not_employed: '',
      number_of_year_retired: '',
      job_category: '',
      job_title: '',
      number_of_year_at_job: '',
      csu_info: {
        is_give_permission_communicate_to: '',
        is_give_permission_to_receive_information: '',
        authorize_parent_guardian_as_emergency_contact: '',
        isCurrentPermanentResidentOfCalifornia: '',
        isLivedInCaliforniaContinuouslySinceBirth: '',
        whenStayInCaliforniaBegin: '',
        priorCountry: '',
        priorState: '',
        fromWhenToWhenLiveThere: '',
        ToWhenToWhenLiveThere: '',
        stateRegardAsPermanentHome: '',
        parent_address_is: '',
        different_parent_address_country: '',
        different_parent_address_line_1: '',
        different_parent_address_line_2: '',
        different_parent_address_city: '',
        different_parent_address_state: '',
        different_parent_address_province: '',
        different_parent_address_zip_code: '',
        priorCountry_secondPriorResidency: '',
        priorState_secondPriorResidency: '',
        fromWhenToWhenLiveThere_secondPriorResidency: '',
        ToWhenToWhenLiveThere_secondPriorResidency: '',
        is_authorized_UC_to_share_contact_information: false,
      },
    },
    onSubmit: async (values) => {
      try {
        studentId = Cookies.get('userId');
        token = Cookies.get(authenticateSettings.tokenName);
        if (token && studentId) {
          const newCollegeArray = formItem.values.parent_graduation_college_array.filter((item) => {
            const noSchoolId = !item?.school?.id;
            const allNull = Object.values(item).every((value) => {
              if (value && typeof value === 'object') {
                return Object.values(value).every((val) => !val);
              }
              return !value;
            });

            const notInCurrentParentCollege = !currentParentCollege.some(
              (currentItem) => currentItem?.school?.name === item?.school?.name
            );
            return noSchoolId && notInCurrentParentCollege && !allNull;
          });

          const newValues = {
            ...values,
            csu_info: JSON.stringify(values.csu_info),
            date_deceased: values.step_parent_is_living === 'false' ? values.date_deceased : '',
            college_or_university: JSON.stringify(values.college_or_university),
            total_number_institutions_attended: formItem.values.total_number_institutions_attended,
            parent_graduation_college_array: JSON.stringify(formItem.values.parent_graduation_college_array),
            student_id: parseInt(studentId || ''),
          };

          location.pathname === routers.stepParent1.path
            ? await dispatch(updateStepParent1(newValues))
            : await dispatch(updateStepParent2(newValues));

          if (newCollegeArray.length > 0 && formItem.values.is_education_saved) {
            const dataSendmail = {
              student_id: parseInt(studentId || ''),
              parent_name:
                location.pathname === routers.stepParent1.path
                  ? 'Step Parent No.1'
                  : 'Step Parent No.2',
              collegesInfo: newCollegeArray,
            };
            await collegeServices.sendMailNewParentCollege(dataSendmail);
          }

        }
        else {
          handleLogout();
        }
      }
      catch (ex) {
        console.log('Error:', ex);
      }
    },
  });

  const formItem = useFormik({
    initialValues: {
      total_number_institutions_attended: '0',
      parent_graduation_college_array: [
        {
          degreesNumber: '',
          degreesReceived1: '',
          graduationYear1: '',
          degreesReceived2: '',
          graduationYear2: '',
          degreesReceived3: '',
          graduationYear3: '',
          degreesReceived4: '',
          graduationYear4: '',
          degreesReceived5: '',
          graduationYear5: '',
          school: {
            ceeb: '',
            fib_code: '',
            name: '',
            school_type: null,
            city: '',
            state: '',
            zip: '',
            country: '',
            csu_name: '',
            uc_name: '',
            cm_name: '',
            match_all: false,
            label: '',
            code: null,
            is_manual: false,
            id: '',
            // old fields :
            //   address_line_1: '',
            //   address_line_2: '',
            //   address_line_3: '',
            province: '',
            state_or_province: '',
            //   zip_code: '',
          } as any,
        },
      ],
      is_education_saved: false,
    },
    onSubmit: async (values) => {
      formik.handleSubmit();
    },
  });

  const checkCountry = () => {
    if (
      formik.values.college_or_university.country !== 'Canada' &&
      formik.values.college_or_university.country !== 'United States of America'
    ) {
      formik.setFieldValue('college_or_university.state', null);
      formik.setFieldValue('college_or_university.province', null);
      if (!formik.values.college_or_university.state_or_province) {
        formik.setFieldValue('college_or_university.state_or_province', '');
      }
    }
  };

  const location = useLocation();
  React.useEffect(() => {
    location.pathname === routers.stepParent1.path
      ? setStepParentNumber('1')
      : setStepParentNumber('2');
  }, [location]);

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = () => {
    const pageName = location.pathname === routers.stepParent1.path ? 'stepParent1' : 'stepParent2';
    const nextRoute = Object.keys(family.roleFamily).find(
      (key, index) =>
        index > Object.keys(family.roleFamily).indexOf(pageName) && family.roleFamily[key] === true
    );
    formik.handleSubmit();
    history.push(nextRoute ? routers?.[nextRoute]?.path : routers.enrolimentStatus.path);
  };

  const getDetail = async () => {
    try {
      studentId = Cookies.get('userId');
      token = Cookies.get(authenticateSettings.tokenName);
      if (token && studentId) {
        setLoading(true);
        await dispatch(getHousehold(studentId));
        let res = location.pathname === routers.stepParent1.path
          ? await dispatch(getStepParent1(studentId))
          : await dispatch(getStepParent2(studentId));
        const dataLoaded: any = res.payload;
        if (dataLoaded) {
          const college_or_university = checkEnableParseJSON(dataLoaded.college_or_university) || {
            name: '',
            country: '',
            address_line_1: '',
            address_line_2: '',
            city: '',
            state: '',
            zip_code: '',
            province: '',
            state_or_province: '',
            id: '',
          }

          if(college_or_university?.name === 'null') {
            college_or_university.name = '';
          }

          if(college_or_university?.id === 'null') {
            college_or_university.id = '';
          }

          formik.resetForm({
            values: {
              ...formik.values,
              ...dataLoaded,
              csu_info: {
                ...formik.values.csu_info,
                ...checkEnableParseJSON(dataLoaded.csu_info),
              },
              total_number_institutions_attended:
                dataLoaded.total_number_institutions_attended?.toString(),

              college_or_university,
              parent_graduation_college_array: checkEnableParseJSON(
                dataLoaded?.parent_graduation_college_array
              ),
            },
          });

          formItem.resetForm({
            values: {
              ...formItem.values,
              total_number_institutions_attended: dataLoaded.total_number_institutions_attended.toString(),
              parent_graduation_college_array: checkEnableParseJSON(dataLoaded.parent_graduation_college_array),
            },
          });

          setSelectedEducation(college_or_university);

          if (dataLoaded.parent_graduation_college_array) {
            setCurrentParentCollege(checkEnableParseJSON(dataLoaded.parent_graduation_college_array));
          }
        }

        if (formik.values.csu_info?.isLivedInCaliforniaContinuouslySinceBirth !== 'false') {
          getContries();
        }

        setLoading(false);
        formik.setStatus('idle');
      } else {
        handleLogout();
      }
    }
    catch (ex) {
      console.log('Error:', ex);
    }
  };

  const getContries = () => {
    familyServices.getContriesCSU()
      .then((res: any) => {
        let csuCountryTemp: any[] = [];
        res.data.map((item: any) => {
          csuCountryTemp.push(item.name);
        })
        formik.setFieldValue('csu_info.priorCountryOptions', csuCountryTemp);
        formik.setFieldValue('csu_info.objectCountries', res.data);
      })
      .catch((err: any) => {
        return err;
      });
  };

  React.useEffect(() => {
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [collegeData, setCollegeData] = React.useState<any>();

  const handleGetCollege = async (keyword: string) => {
    setIsLoading(true);
    const res: any = await collegeServices.getCollege({ keyword: keyword.trim() });
    const data = res.map((item: any) => ({
      ...item,
      id: item.id,
      label: item.name,
      value: item.name,
    }));
    setCollegeData(data);
    setIsLoading(false);
    setOpenMenu(true);
  };

  const handleSearchCollege = debounce((keyword: string) => {
    defer(() => {
      handleGetCollege(keyword);
    });
  }, 300);

  const handleFormChange = (e: any) => {
    checkCountry();
    formik.handleChange(e);
  };

  // Auto save function ==========
  React.useEffect(() => {
    if (location.pathname === routers.stepParent1.path) {
      if (family.stepParent1 !== null)
        if (formik.dirty && !loading) {
          debounceSaving();
        }
    } else
      if (location.pathname === routers.stepParent2.path) {
        if (family.stepParent2 !== null)
          if (formik.dirty && !loading) {
            debounceSaving();
          }
      }

  }, [formik.values]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      formik.handleSubmit();
    }, 1500),
    []
  );
  // Auto save function ==========
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[{ title: 'General', percentOnProcess: 25 }]}
        title="Family Information"
        process={1}
        icon={<PeopleAltOutlinedIcon />}
      />
      <Container className="main-container">
        <div className="px-2" id="General">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <Row className="m-0">
              <div className="text-field-mid fs-3 mb-4">Step Parent No. {stepParentNumber}</div>
              <GroupRadioButton
                logo="span-cm-logo"
                options={[{ value: 'Parent 1' }, { value: 'Parent 2' }]}
                onClear={() => formik.setFieldValue('step_parent_is_married', '')}
                label={`Step-parent ${stepParentNumber} is married to*`}
                name="step_parent_is_married"
                onChange={formik.handleChange}
                values={formik.values.step_parent_is_married}
                isMissing={!formik.values.step_parent_is_married && check}
              />
              <div />

              <GroupRadioButton
                logo="span-cm-logo"
                label={`Step-parent ${stepParentNumber} type*`}
                options={[{ value: 'Step-Mother' }, { value: 'Step-Father' }]}
                name="step_parent_type"
                values={formik.values.step_parent_type}
                onChange={formik.handleChange}
                isMissing={!formik.values.step_parent_type && check}
              />
              <div />
              <Col md={6}>
                <YesNoSwitch
                  onClear={() => formik.setFieldValue('step_parent_is_living', '')}
                  label={`Is Step-parent ${stepParentNumber} living?*`}
                  logo="span-cm-logo"
                  name="step_parent_is_living"
                  onChange={formik.handleChange}
                  values={formik.values.step_parent_is_living}
                  isMissing={!formik.values.step_parent_is_living && check}
                />
              </Col>
              <DatePickerInput
                hidden={formik.values.step_parent_is_living !== 'false'}
                label="Date deceased*"
                name="date_deceased"
                format="MM/yyyy"
                values={formik.values.date_deceased}
                onChange={(value) => formik.setFieldValue('date_deceased', value)}
                isMissing={!formik.values.date_deceased && check}
              />
              <div />
              <SelectInput
                form={formik}
                option={[{ value: 'Dr.' }, { value: 'Mr.' }, { value: 'Ms.' }, { value: 'Mrs.' }]}
                label="Prefix"
                onChange={formik.handleChange}
                name="prefix"
                values={formik.values.prefix}
              />

              <TextInput
                label="First/Given name*"
                logo="span-cm-logo"
                name="first_name"
                values={formik.values.first_name}
                onChange={formik.handleChange}
                countText={20}
                isMissing={!formik.values.first_name && check}
              />
              <TextInput
                label="Middle Initial"
                name="middle_name"
                values={formik.values.middle_name}
                onChange={formik.handleChange}
                countText={20}
              />
              <TextInput
                label="Last Name*"
                logo="span-cm-logo"
                name="last_name"
                values={formik.values.last_name}
                onChange={formik.handleChange}
                countText={20}
                isMissing={!formik.values.last_name && check}
              />
              <TextInput
                label="Former Last Name"
                name="former_last_name"
                values={formik.values.former_last_name}
                onChange={formik.handleChange}
                countText={20}
              />

              <SelectInput
                form={formik}
                option={fullSuffixOptions}
                label="Suffix"
                name="suffix"
                values={formik.values.suffix}
                onChange={formik.handleChange}
              />
              <TextInput
                label="Relationship to you*"
                logo="span-cm-logo"
                name="step_parent_relationship"
                values={formik.values.step_parent_relationship}
                onChange={formik.handleChange}
                countText={50}
                mdCol={'12'}
                isMissing={!formik.values.step_parent_relationship && check}
              />
              <TextInput
                label="Email Address *"
                name="step_parent_email"
                logo="span-cm-logo"
                values={formik.values.step_parent_email}
                onChange={formik.handleChange}
                countText={50}
                mdCol={'12'}
                isMissing={!formik.values.step_parent_email && check}
              />
              <GroupRadioButton
                mdCol={3}
                label="Preferred phone*"
                name="phone_number_type"
                logo="span-cm-logo"
                onChange={formik.handleChange}
                values={formik.values.phone_number_type}
                options={[
                  { value: 'Home' },
                  { value: 'Mobile' },
                  { value: 'Work' },
                  { value: 'Other' },
                ]}
                isMissing={!formik.values.phone_number_type && check}
              />
              <SelectPhoneCodeInput
                label="Country Code"
                logo="span-cm-logo label-14px"
                name="country_code"
                onChange={formik.handleChange}
                values={formik.values.country_code}
              />
              <TextIconInput
                logo="label-14px"
                type="number*"
                icon={<PhoneAndroidIcon />}
                name="phone_number"
                onChange={formik.handleChange}
                values={formik.values.phone_number}
                label="Phone Number"
              />

              <GroupRadioButton
                label="Parent Address is*"
                className="uc-flag mb-5 pt-3 "
                name="csu_info.parent_address_is"
                onChange={formik.handleChange}
                values={formik.values.csu_info?.parent_address_is}
                options={[
                  { value: 'The same as my home address', mdCol: 4 },
                  { value: 'A different address' },
                  { value: 'Unknown' },
                ]}
                isMissing={!formik.values.csu_info?.parent_address_is && check}
              />
              <div />
              {formik.values.csu_info?.parent_address_is === 'A different address' && (
                <>
                  <SelectInput
                    option={countryOptions}
                    label={'Country*'}
                    name={'csu_info.different_parent_address_country'}
                    values={formik.values.csu_info?.different_parent_address_country}
                    onChange={formik.handleChange}
                    isMissing={!formik.values.csu_info?.different_parent_address_country && check}
                  />
                  <TextInput
                    label={'Address Line 1*'}
                    name={'csu_info.different_parent_address_line_1'}
                    values={formik.values.csu_info?.different_parent_address_line_1}
                    onChange={formik.handleChange}
                    countText={36}
                    isMissing={!formik.values.csu_info?.different_parent_address_line_1 && check}
                  />
                  <TextInput
                    label={'Address Line 2'}
                    name={'csu_info.different_parent_address_line_2'}
                    values={formik.values.csu_info?.different_parent_address_line_2}
                    onChange={formik.handleChange}
                  />
                  <TextInput
                    label={'City*'}
                    name={'csu_info.different_parent_address_city'}
                    values={formik.values.csu_info?.different_parent_address_city}
                    onChange={formik.handleChange}
                    countText={25}
                    isMissing={!formik.values.csu_info?.different_parent_address_city && check}
                  />
                  {formik.values.csu_info?.different_parent_address_country ===
                    'United States of America' ? (
                    <SelectInput
                      option={stateOptions}
                      label={'State*'}
                      name={'csu_info.different_parent_address_state'}
                      values={formik.values.csu_info?.different_parent_address_state}
                      onChange={formik.handleChange}
                      isMissing={!formik.values.csu_info?.different_parent_address_state && check}
                    />
                  ) : (
                    <TextInput
                      label={'Province *'}
                      name={'csu_info.different_parent_address_province'}
                      values={formik.values.csu_info?.different_parent_address_province}
                      onChange={formik.handleChange}
                      countText={80}
                      isMissing={!formik.values.csu_info?.different_parent_address_province && check}
                    />
                  )}
                  <TextInput
                    countText={11}
                    label={'Zip Code*'}
                    name={'csu_info.different_parent_address_zip_code'}
                    values={formik.values.csu_info?.different_parent_address_zip_code}
                    onChange={formik.handleChange}
                  />
                </>
              )}
              <div className="fs-5 p-1">Authorization</div>
              <YesNoSwitch
                values={formik.values.csu_info?.is_give_permission_communicate_to}
                onChange={formik.handleChange}
                logo="span-csu-logo"
                name="csu_info.is_give_permission_communicate_to"
                label="Do you give permission for the admission office to communicate with your Parent/Guardian regarding your application?*"
                isMissing={!formik.values.csu_info?.is_give_permission_communicate_to && check}
              />

              <YesNoSwitch
                values={formik.values.csu_info?.is_give_permission_to_receive_information}
                onChange={formik.handleChange}
                className="py-3"
                logo="span-csu-logo"
                name="csu_info.is_give_permission_to_receive_information"
                label="Do you give permission for your parent/guardian to receive university communications? These may include new student and housing information, parent activities, volunteer and charitable opportunities, and general campus information.*"
                isMissing={!formik.values.csu_info?.is_give_permission_to_receive_information && check}
              />

              <YesNoSwitch
                values={formik.values.csu_info?.authorize_parent_guardian_as_emergency_contact}
                onChange={formik.handleChange}
                className="py-3"
                logo="span-csu-logo"
                name="csu_info.authorize_parent_guardian_as_emergency_contact"
                label="I authorize the use of this parent/guardian information to be used as my emergency contact when I enroll at a CSU."
              />

              <div className="px-1 py-1">
                <div className="d-flex flex-column">
                  <label className="label-14px pb-1 span-uc-logo">
                    Can we share your parent's contact information with UC campuses and programs?
                  </label>
                  <div className="py-1">
                    If you check this option, your parent may receive information about admissions
                    tips, updates and reminders from UC campuses (even ones you haven't applied to)
                    and outreach programs. They may contact them before or after you've submitted
                    the application.
                  </div>
                </div>
              </div>
              <CheckboxInput
                values={formik.values.csu_info?.is_authorized_UC_to_share_contact_information}
                onChange={formik.handleChange}
                name="csu_info.is_authorized_UC_to_share_contact_information"
                label="I authorize the University of California to share my parent's contact information."
                className="py-3"
              />

              <div className="fs-5 p-1">Employee</div>

              <GroupRadioButton
                label="What is this parent's current job status?"
                logo="span-uc-logo"
                mdCol={6}
                options={[
                  { value: 'Employed' },
                  { value: 'Stay-at-home parent/homemaker' },
                  { value: 'Not-employed' },
                  { value: 'Retired' },
                ]}
                name="step_parent_current_job_status"
                values={formik.values.step_parent_current_job_status}
                onChange={formik.handleChange}
              />

              <SelectInput
                form={formik}
                logo="span-cm-logo"
                name="employment_status"
                values={formik.values.employment_status}
                option={[
                  { value: 'Employed' },
                  { value: 'Unemployed' },
                  { value: 'Retired' },
                  { value: 'Self-Employed' },
                ]}
                label="Employment status*"
                onChange={formik.handleChange}
              />

              <TextInput
                hidden={formik.values.step_parent_current_job_status !== 'Employed'}
                label="Current Employer"
                name="current_employer"
                countText={40}
                values={formik.values.current_employer}
                onChange={formik.handleChange}
              />

              {formik.values.step_parent_current_job_status === 'Stay-at-home parent/homemaker' && (
                <SelectInput
                  option={optionValues(Array.from({ length: 70 }, (_, i) => i + 1))}
                  label="How many years has this parent been a stay-at-home parent?"
                  logo="span-uc-logo"
                  name="number_of_year_at_home"
                  values={formik.values.number_of_year_at_home}
                  onChange={formik.handleChange}
                />
              )}
              {formik.values.step_parent_current_job_status === 'Not-employed' && (
                <SelectInput
                  option={optionValues(Array.from({ length: 70 }, (_, i) => i + 1))}
                  label="How many years has this parent not been employed?"
                  logo="span-uc-logo"
                  name="number_of_year_not_employed"
                  values={formik.values.number_of_year_not_employed}
                  onChange={formik.handleChange}
                />
              )}
              {formik.values.step_parent_current_job_status === 'Retired' && (
                <SelectInput
                  option={optionValues(Array.from({ length: 70 }, (_, i) => i + 1))}
                  label="How many years has this parent been retired?"
                  logo="span-uc-logo"
                  name="number_of_year_retired"
                  values={formik.values.number_of_year_retired}
                  onChange={formik.handleChange}
                />
              )}

              <div className="p-1">Most recent/Former job held by this parent info:</div>

              <SelectInput
                form={formik}
                option={occupationOptions}
                label="Occupation (former occupation, if retired or deceased)"
                name="occupation"
                logo="span-cm-logo"
                values={formik.values.occupation}
                onChange={formik.handleChange}
              />

              <TextInput
                hidden={formik.values.occupation !== 'Other'}
                label="Other Occupation Details"
                logo="span-cm-logo"
                name="other_occupation_detail"
                values={formik.values.other_occupation_detail}
                onChange={formik.handleChange}
                countText={50}
              />

              <SelectInput
                logo="span-uc-logo"
                label="Job Category"
                name="job_category"
                values={formik.values.job_category}
                onChange={formik.handleChange}
                option={jobCategory}
              />

              <TextInput
                label="Job title"
                countText={20}
                name="job_title"
                values={formik.values.job_title}
                onChange={formik.handleChange}
              />
              <SelectInput
                option={optionValues(Array.from({ length: 70 }, (_, i) => i + 1))}
                logo="span-uc-logo"
                label="Number of years at job"
                name="number_of_year_at_job"
                values={formik.values.number_of_year_at_job}
                onChange={formik.handleChange}
              />

              <GroupRadioButton
                logo="span-cm-logo form-label"
                hidden={
                  formik.values.employment_status !== 'Employed' &&
                  formik.values.employment_status !== 'Retired'
                }
                label="Is parent currently employed by or retired from a college or university?*"
                name="currently_employed_or_retire_from_college"
                values={formik.values.currently_employed_or_retire_from_college}
                onChange={formik.handleChange}
                mdCol={12}
                options={[
                  { value: 'Never employed at a college/university' },
                  { value: 'Currently employed at a college/university' },
                  { value: 'Retired from a college/university' },
                ]}
                isMissing={!formik.values.currently_employed_or_retire_from_college && check}
              />

              {[
                'Currently employed at a college/university',
                'Retired from a college/university',
              ].includes(formik.values.currently_employed_or_retire_from_college) && (
                  <TextIconInput
                    label="College/University employer lookup*"
                    logo="span-cm-logo"
                    icon={<SearchIcon />}
                    mdCol="6"
                    onChange={(e: any) => {
                      setSearchInput(e.target.value);
                      handleSearchCollege(e.target.value);
                      // formik.setFieldValue('college_or_university.name', e.target.value);
                    }}
                    values={searchInput}
                    isMissing={!formik.values.college_or_university?.name && check}
                  />
                )}
              {isLoading && <LoadingContent />}
              {collegeData !== undefined &&
                !collegeData?.length &&
                !isLoading &&
                !formik.values.college_or_university?.name && (
                  <div className="text-center">Not found college</div>
                )}

              {openMenu &&
                (collegeData || []).map(
                  (
                    college: {
                      label:
                      | boolean
                      | React.ReactChild
                      | React.ReactFragment
                      | React.ReactPortal
                      | null
                      | undefined;
                      address_line_1:
                      | boolean
                      | React.ReactChild
                      | React.ReactFragment
                      | React.ReactPortal
                      | null
                      | undefined;
                    },
                    index: React.Key | null | undefined
                  ) =>
                    college?.label ? (
                      <Card className="border rounded my-1 pt-2 pb-1" key={index}>
                        <div className="d-flex align-items-end position-relative">
                          <div className="mx-3 pb-2 ">{college.label}</div>
                          <div className="text-field-light sized70 pb-2">
                            {college.address_line_1}
                          </div>
                          <span className="position-absolute h-100 d-flex align-items-center end-0">
                            <AddIcon
                              fontSize="small"
                              className="bg-app text-primary rounded-circle mx-3 pointer"
                              onClick={() => {
                                setSelectedEducation(college);
                                formik.setFieldValue('college_or_university', college);
                                setOpenMenu(false);
                                setSearchInput('');
                              }}
                            />
                          </span>
                        </div>
                      </Card>
                    ) : null
                )}

              <div className="my-2 text-field-light">Employer Graduation College</div>

              {(selectedEducation?.id && selectedEducation?.id !== 'null' ) ? (
                <div className="d-flex align-items-end position-relative">
                  <div
                    className="mx-3 pb-2 fw-bold"
                    style={{
                      color: '#4d88f0',
                    }}
                  >
                    {selectedEducation?.name}
                  </div>
                  <div className="text-field-light sized70  pb-2">
                    {selectedEducation?.address_line_1}
                  </div>
                  <span className="position-absolute h-100 d-flex align-items-center end-0">
                    <RemoveIcon
                      fontSize="small"
                      className="bg-light text-dart rounded-circle mx-4 pointer"
                      onClick={() => {
                        setSelectedEducation(undefined);
                        formik.setFieldValue('college_or_university', null);
                      }}
                    />
                  </span>
                </div>
              ) : (collegeData !== undefined && !collegeData?.length) || !selectedEducation?.id ? (
                <Row className="bg-light rounded p-3">
                  <TextInput
                    name={'college_or_university.name'}
                    values={formik.values?.college_or_university?.name}
                    onChange={handleFormChange}
                    label="Name of college/university*"
                    isMissing={!formik.values.college_or_university?.name && check}
                    countText={60}
                  />
                  <AutocompleteInput
                    name={'college_or_university.country'}
                    form={formik}
                    values={formik.values?.college_or_university?.country}
                    label="Country/Region/Territory*"
                    option={csuCountry}
                    isMissing={!formik.values.college_or_university?.country && check}
                    onChange={(e: any) => {
                      if (
                        formik.values?.college_or_university?.country !== 'Canada' &&
                        formik.values?.college_or_university?.country !== 'United States of America'
                      ) {
                        formik.setFieldValue('college_or_university.state', null);
                        formik.setFieldValue('college_or_university.province', null);
                        if (!formik.values?.college_or_university?.state_or_province) {
                          formik.setFieldValue('college_or_university.state_or_province', '');
                        }
                      }
                    }}
                  />
                  <TextInput
                    mdCol={12}
                    name={'college_or_university.address_line_1'}
                    values={formik.values?.college_or_university?.address_line_1}
                    onChange={handleFormChange}
                    label="Address Line 1"
                    countText={60}
                  />
                  <TextInput
                    mdCol={12}
                    name={'college_or_university.address_line_2'}
                    values={formik.values?.college_or_university?.address_line_2}
                    onChange={handleFormChange}
                    label="Address Line 2"
                    countText={60}
                  />
                  <TextInput
                    mdCol={12}
                    name={'college_or_university.address_line_3'}
                    values={formik.values?.college_or_university?.address_line_3}
                    onChange={handleFormChange}
                    label="Address Line 3"
                    countText={60}
                  />
                  <TextInput
                    name={'college_or_university.city'}
                    values={formik.values?.college_or_university?.city}
                    onChange={handleFormChange}
                    label="City"
                    countText={30}
                  />
                  <AutocompleteInput
                    option={usaState}
                    form={formik}
                    hidden={
                      formik.values?.college_or_university?.country !== 'United States of America'
                    }
                    label="State*"
                    name={'college_or_university.state'}
                    values={formik.values?.college_or_university?.state}
                    isMissing={!formik.values.college_or_university?.state && check}
                  />
                  <AutocompleteInput
                    form={formik}
                    option={canadaProvince}
                    hidden={formik.values?.college_or_university?.country !== 'Canada'}
                    label="Province*"
                    name={'college_or_university.province'}
                    values={formik.values?.college_or_university?.province}
                    isMissing={!formik.values.college_or_university?.province && check}
                  />
                  <TextInput
                    hidden={
                      formik.values?.college_or_university?.country === 'Canada' ||
                      formik.values?.college_or_university?.country === 'United States of America'
                    }
                    name={'college_or_university.state_or_province'}
                    values={formik.values?.college_or_university?.state_or_province}
                    onChange={(e: any) => {
                      formik.setFieldValue('college_or_university.state', null);
                      formik.setFieldValue('college_or_university.province', null);
                      formik.setFieldValue(
                        'college_or_university.state_or_province',
                        e.target.value
                      );
                      handleFormChange(e);
                    }}
                    label="State or Province"
                    countText={30}
                  />
                  <TextInput
                    name={'college_or_university.zip_code'}
                    values={formik.values?.college_or_university?.zip_code}
                    onChange={handleFormChange}
                    label="Zip Code*"
                    countText={10}
                    isMissing={!formik.values.college_or_university?.zip_code && check}
                  />
                </Row>
              ) : null}

              <Education formik={formik} formItem={formItem} check={check} indexExpand={indexExpand}/>
              <Residency formik={formik} check={check}/>
            </Row>
            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {family.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default StepParent;
