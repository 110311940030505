/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import { appRoutes, routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import YesNoSwitch from 'components/input/YesNoSwitch';
import SelectInput from 'components/input/SelectInput';
import TextInput from 'components/input/textInput';
import CollegeConfig, { initialCollegeValues } from './components/CollegeConfig';
import { getCollege, updateCollege } from 'app/highSchoolSlice/highSchoolSlice';
import {
  BoolToString,
  checkEnableParseJSON,
  formatBoolean,
  setArrayLength,
  userpilotDomain,
} from 'components/helper/helper';
import { debounce } from 'lodash';
import { Userpilot } from 'userpilot';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { ExpandMore } from 'screen/activitiesAndHonors/Activities';
import { scrollToObject } from '../highSchool/components/OtherSecondary';

interface Props { }

const CollegeAndUniversity = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [expandAll, setExpandAll] = React.useState(false);
  const [expandActive, setExpandActive] = React.useState<number>(0);
  const query = new URLSearchParams(useLocation().search);
  const check = query.get('check');
  const indexExpand = query.get('index');
  //const highSchool = useAppSelector((store) => store.highSchool);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);

  const information = useAppSelector((store) => store.basicInformation.myBasicInformation);
  const formik = useFormik({
    initialValues: {
      is_taken_college_courses: '',
      is_currently_taking_courses_in_college: '',
      number_of_college_credits_have_earned: '0',
      number_of_credits_earned_after_school: '0',
      number_of_colleges_taken_coursework: '0',
      colleges_taken_coursework_array: [],
    },
    onSubmit: async (values: any) => {
      try {
        setSaving(true);
        const newValues = {
          ...values,
          student_id: studentId,
          is_taken_college_courses: formatBoolean(values.is_taken_college_courses),
          is_currently_taking_courses_in_college: formatBoolean(
            values.is_currently_taking_courses_in_college
          ),
          colleges_taken_coursework_array: JSON.stringify(values.colleges_taken_coursework_array),
        };
        Userpilot.identify((studentId ?? 'undefined') + userpilotDomain, {
          name: information?.first_name ?? 'undefined',
          email: information?.email ?? 'undefined',
          created_at: information?.created_at ?? 'undefined',
          college: values?.colleges_taken_coursework_array
            .map((item: any) => item.college_name)
            .join(', '),
          termType: values?.colleges_taken_coursework_array
            .map((item: any) => item.type_of_term_systerm)
            .join(', '),
        });
        dispatch(updateCollege(newValues));
        setSaving(false);
      } catch (ex) {
        setSaving(false);
        console.log('Error:');
      }
    },
  });
  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleExpandAll = () => {
    setExpandAll(!expandAll);
    if (!expandAll) {
      setExpandActive(formik.values.colleges_taken_coursework_array.length);
    } else {
      setExpandActive(0);
    }
  };

  const handleExpandActive = (value: any) => {
    value
      ? setExpandActive(Math.abs(expandActive - 1))
      : setExpandActive(Math.abs(expandActive + 1));
  };

  React.useEffect(() => {
    if (expandActive === formik.values.colleges_taken_coursework_array.length) {
      setExpandAll(true);
    }

    if (expandActive === 0) {
      setExpandAll(false);
    }
  }, [expandActive, formik.values.colleges_taken_coursework_array.length]);

  const handleContinue = () => {
    formik.handleSubmit();
    history.push(appRoutes.futurePlan.path);
  };


  const AutoScrollAndExpand = () => {
    const expandDiv = (divId: string) => {
      let div = document.getElementById(divId);
      if (div) {
        if (div.getAttribute('aria-expanded') === 'false') {
          div.click();
        }
      }
    }
    try {
      const hash = location.hash.slice(1); // Remove the '#' character from the hash
      if (hash) {
        //console.log('hash:', hash);
        if (hash === 'number_of_colleges_taken_coursework') {
          setTimeout(() => {
            scrollToObject(hash, 200);
          }, 1500);
        } else
          if (hash.includes("collegeName_")) {
            let str = hash.replaceAll("collegeName_", "");
            let itemIndex = Number(str);
            setTimeout(() => {
              expandDiv(`collegeItem${itemIndex}`);
              setTimeout(() => {
                scrollToObject(hash, 100);
              }, 1500);

            }, 2000);
          } else
            if (hash.includes("fromDate_")) {
              let str = hash.replaceAll("fromDate_", "");
              let itemIndex = Number(str);
              setTimeout(() => {
                expandDiv(`collegeItem${itemIndex}`);
                setTimeout(() => {
                  scrollToObject(hash, 100);
                }, 1500);

              }, 2000);
            } else
              if (hash.includes("toDate_")) {
                let str = hash.replaceAll("toDate_", "");
                let itemIndex = Number(str);
                setTimeout(() => {
                  expandDiv(`collegeItem${itemIndex}`);
                  setTimeout(() => {
                    scrollToObject(hash, 100);
                  }, 1500);

                }, 2000);
              }
      }
    }
    catch (ex) {
      console.log('Error:', ex);
    }
  }

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      const res = await dispatch(getCollege(studentId));
      if (res.payload) {
        formik.resetForm({
          values: {
            ...formik.values,
            ...res.payload,
            is_taken_college_courses: BoolToString(res.payload.is_taken_college_courses),
            is_currently_taking_courses_in_college: BoolToString(
              res.payload.is_currently_taking_courses_in_college
            ),
            colleges_taken_coursework_array: checkEnableParseJSON(
              res.payload.colleges_taken_coursework_array,
              []
            ),
          },
        });
      }
      setTimeout(() => {
        AutoScrollAndExpand();
      }, 2000);
      setLoading(false);
      formik.setStatus('idle');
    } else {
      handleLogout();
    }
  };
  React.useEffect(() => {
    getDetail();
  }, []);

  // Auto save function ==========
  React.useEffect(() => {
    formik.dirty && debounceSaving();
  }, [formik.values]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (!loading) formik.handleSubmit();
    }, 1500),
    []
  );
  // Auto save function ==========

  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[]}
        title="Education"
        process={1}
        icon={<StickyNote2OutlinedIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <Row className="m-0">
              <div className="text-field-mid fs-3 mb-4">Colleges & Universities </div>

              <div className="p-1">College Courses or earned College Credit</div>

              <YesNoSwitch
                label="Have you taken college courses or earned college credit? *"
                name="is_taken_college_courses"
                values={formik.values.is_taken_college_courses}
                onChange={formik.handleChange}
                isMissing={!formik.values.is_taken_college_courses}
              />
              {formik.values.is_taken_college_courses === 'true' && (
                <>
                  <YesNoSwitch
                    label="Are you currently taking courses in college? *"
                    className="coallition-flag py-3"
                    name="is_currently_taking_courses_in_college"
                    values={formik.values.is_currently_taking_courses_in_college}
                    onChange={formik.handleChange}
                    isMissing={!formik.values.is_currently_taking_courses_in_college && check}
                  />
                  <div />
                  <TextInput
                    className="coallition-flag py-3"
                    type="number"
                    label="How many college credits have you earned to date? *"
                    name="number_of_college_credits_have_earned"
                    values={formik.values.number_of_college_credits_have_earned}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'number_of_college_credits_have_earned',
                        Math.round(e.target.value * 100) / 100
                      );
                    }}
                    decimalStep={0.1}
                    isMissing={!formik.values.number_of_college_credits_have_earned && check}
                  />
                  <TextInput
                    className="coallition-flag end-flag py-3"
                    type="number"
                    label="How many credits were earned after high school? *"
                    name="number_of_credits_earned_after_school"
                    values={formik.values.number_of_credits_earned_after_school}
                    onChange={(e) =>
                      formik.setFieldValue(
                        'number_of_credits_earned_after_school',
                        Math.round(e.target.value * 100) / 100
                      )
                    }
                    decimalStep={0.1}
                    isMissing={!formik.values.number_of_credits_earned_after_school && check}
                  />
                  <SelectInput
                    id="number_of_colleges_taken_coursework"
                    logo="span-cm-logo"
                    className="py-4"
                    form={formik}
                    mdCol="12"
                    option={[
                      { value: 0, name: '0 College' },
                      { value: 1, name: '1 College' },
                      { value: 2, name: '2 College' },
                      { value: 3, name: '3 College' },
                      { value: 4, name: '4 College' },
                      { value: 5, name: '5 College' },
                      { value: 6, name: '6 College' },
                    ]}
                    label="Indicate the number of colleges or universities you've taken a course at or received credit from*"
                    name="number_of_colleges_taken_coursework"
                    isSatTest={true}
                    values={formik.values.number_of_colleges_taken_coursework}
                    isMissing={formik.values.number_of_colleges_taken_coursework === 0 && check}
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.setFieldValue(
                        'colleges_taken_coursework_array',
                        setArrayLength(
                          formik.values.colleges_taken_coursework_array,
                          e.target.value,
                          initialCollegeValues
                        )
                      );
                    }}
                  />
                  {formik.values.colleges_taken_coursework_array.length > 0 && (
                    <div className="d-flex justify-content-end">
                      <ExpandMore
                        aria-expanded={expandAll}
                        aria-label="show all"
                        onClick={handleExpandAll}
                      >
                        {!expandAll ? (
                          <KeyboardDoubleArrowDownIcon />
                        ) : (
                          <KeyboardDoubleArrowUpIcon />
                        )}
                      </ExpandMore>
                    </div>
                  )}
                  {(formik.values.colleges_taken_coursework_array || []).map(
                    (item: any, index: number) => (
                      <CollegeConfig
                        indexExpand={indexExpand}
                        check={check}
                        expandAll={expandAll}
                        handleExpandActive={handleExpandActive}
                        key={index + '-' + formik.values.colleges_taken_coursework_array?.length}
                        handleSave={(values) => {
                          const newCollegeData: any = [
                            ...formik.values.colleges_taken_coursework_array,
                          ];
                          newCollegeData.splice(index, 1, values);
                          formik.setFieldValue('colleges_taken_coursework_array', newCollegeData);
                        }}
                        item={item}
                        index={index}
                        indexText={`${index + 1}/${formik.values.colleges_taken_coursework_array.length
                          }`}
                      />
                    )
                  )}
                </>
              )}
            </Row>
            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {saving ? <Spinner size="sm" animation="border" variant="light" /> : 'Save'}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default CollegeAndUniversity;
