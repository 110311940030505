/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { getGradeAndCourse, updateGradeAndCourse } from 'app/course/courseSlice';
import CheckboxInput from 'components/input/CheckboxInput';
import CourseConfig, { hasReOrderGradecourse, resetIndexGradecourse } from '../CourseConfig';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { HtmlTooltip } from 'screen/selectCollege/SelecCollegePage';
import { debounce } from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { reorder, tryParseJSON } from 'components/helper/helper';
import { getHighSchoolData } from 'app/highSchoolSlice/highSchoolSlice';
import { chkSameGrade, countSameGrade, getSchoolGradeItems } from '../SubjectConfig';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { ExpandMore } from 'screen/activitiesAndHonors/Activities';
import { scrollToObject } from 'components/helper/ui_helper';

interface Props {}

const TenthGrade = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const [showWarning, setShowWarning] = React.useState(false);
  const [errorArr, setErrorArr] = React.useState<any[]>([]);
  const [expandAll, setExpandAll] = React.useState(false);
  const [expandActive, setExpandActive] = React.useState<number>(0);
  const course = useAppSelector((store) => store.course);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const gradeLevel = '10th';
  const [courseData, setCourseData] = React.useState<any[]>([]);
  const query = new URLSearchParams(useLocation().search);
  const check = query.get('check');

  const formik = useFormik({
    initialValues: {
      grade_10_course_array: '',
      is_reported_all_grade_10: false,
    },
    onSubmit: async (values) => {
      studentId = Cookies.get('userId');
      token = Cookies.get(authenticateSettings.tokenName);
      if (token && studentId) {
        try {
          let gradeCourses:any[] = [...courseData];
          let bReorder = hasReOrderGradecourse(gradeCourses);

          let newValues = {
            ...values,
            update_grade_no: '10', // update grade 10th only
            student_id: studentId,
            grade_10_course_array: JSON.stringify(courseData),
          };
          await dispatch(updateGradeAndCourse(newValues)).unwrap();

          if (bReorder) { // update again after reorder item
            resetIndexGradecourse(gradeCourses);
            newValues = {
              ...values,
              update_grade_no: '10', // update grade 10th only
              student_id: studentId,
              grade_10_course_array: JSON.stringify(courseData),
            };

            await dispatch(updateGradeAndCourse(newValues)).unwrap();
          }
          await recheckInputSchool();
        }
        catch (ex) {
          console.log('Error: ', ex);
        }
      } else {
        handleLogout();
      }
    },
  });
  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = () => {
    formik.handleSubmit();
    history.push(routers.grade11.path);
  };

  const checkInputSchool = async(gradeCourses:any[], gradeLevel: string) => {
    const gradeMaxCount = 4;
    let arr: any[] = [];
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      const resHighSchool = await dispatch(getHighSchoolData(studentId)).unwrap();
      if (resHighSchool) {
        let highschoolData: any[] = resHighSchool.highschoolData;
        let itemArr: any[] | null = getSchoolGradeItems(highschoolData, gradeLevel);
        if (itemArr === null) {
          arr.push({
            value: 'AddHighSchool',
            name: 'Please add high school on the High School page.',
          });
        } else {
          if (itemArr && itemArr.length > 1) {
            for (let j = 0; j < itemArr.length; j++) {
              let item: any = itemArr[j].item;
              let item_idx: number = itemArr[j].index;
              if (item) {
                let countArr = countSameGrade(item, gradeLevel);

                if (countArr && countArr.length > 1) {
                  let gradeObj = countArr[countArr.length - 1];
                  arr.push({
                    value: `remove_term${item_idx}_${gradeObj?.index}`,
                    name: `Please remove asleast one term/year has same ${gradeLevel} grade on the High School page.`,
                  });
                }
              }
            }
          }
          //else
          if (highschoolData && highschoolData.length > 0) {
            let obj: any = null;
            for(let m=0; m < gradeCourses.length; m++) {
                let gradeCourse:any = gradeCourses[m];
                let schoolName = gradeCourse.schoolName ?? '';
                let schoolCode =  gradeCourse.schoolCode ?? '';
                for(let i=0; i<highschoolData.length;i++) {
                  let item: any = highschoolData[i];
                  if (item.high_school_name === schoolName &&
                      item.high_school_object.college_board_code === schoolCode) {
                        obj = chkSameGrade(item, gradeLevel);
                        if (obj) {
                          let gradeIdx: number = obj.index;
                          if (!item.high_school_object) {
                            arr.push({
                              value: `cardItem${i}`,
                              name: 'Please input high school information on the High School page.',
                            });
                          } else if (item.high_school_object && item.high_school_object.school_name === '') {
                            arr.push({
                              value: `cardItem${i}`,
                              name: 'Please input high school information on the High School page.',
                            });
                          } else if (!item.start_date) {
                            arr.push({
                              value: `start_date${i}`,
                              name: 'Please enter the Start date on the High School page.',
                            });
                          } else if (!item.end_date) {
                            arr.push({
                              value: `end_date${i}`,
                              name: 'Please input the End date on the High School page.',
                            });
                          }
                          if (
                            !item.academic_year[gradeIdx] ||
                            item.academic_year[gradeIdx] === '- Select -'
                          ) {
                            arr.push({
                              value: `academic_year${i}_${gradeIdx}`,
                              name: 'Please enter the Academic Year on the High School page.',
                            });
                          }
                          if (arr.length > 0) {
                            break;
                          }
                        } 
                        else {
                          if (item.grade && item.grade.length > 0) {
                            if (item.grade.length === gradeMaxCount) {
                              arr.push({
                                value: `grade${i}_${item.grade.length - 1}`,
                                name: `Please select the Grade ${gradeLevel} on the High School page.`,
                              });
                            } else {
                              arr.push({
                                value: `AddTermYear${i}`,
                                name: `Please add another term/year and then select the ${gradeLevel} Grade on the High School page.`,
                              });
                            }
                          } else {
                            arr.push({
                              value: `AddTermYear${i}`,
                              name: `Please add another term/year and then select the ${gradeLevel} Grade on the High School page.`,
                            });
                          }
                        }
                  }
                }
            }

          } else {
            arr.push({
              value: 'AddHighSchool',
              name: 'Please add high school on the High School page.',
            });
          }
        }

        if (arr.length > 0) {
          setShowWarning(true);
          setErrorArr(arr);
        }
        else {
          setShowWarning(false);
        }
      }
    } else {
      handleLogout();
    }
  };
  const recheckInputSchool = async() => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      const res:any = await dispatch(getGradeAndCourse(studentId));
      let gradeCourses:any[] = JSON.parse(res.payload.grade_10_course_array) || [];
      await checkInputSchool(gradeCourses, gradeLevel);
    }
    else {
      handleLogout();
    }
  };

  const AutoScrollAndExpand = () => {
    const expandDiv = (divId:string) => {
      let div = document.getElementById(divId);
      if (div) {
        if (div.getAttribute('aria-expanded') === 'false') {
         div.click();
        }
      }
    }
    try {
      const hash = location.hash.slice(1); // Remove the '#' character from the hash
      if (hash) {
        //setExpandAll(true);
        //console.log('hash:', hash);       
        if (hash==='AddHighSchool') {
          setTimeout(() => {
            scrollToObject(hash, 200);
          }, 1500);
        } else
        if (hash==='is_reported_all_grade_10') {
          setTimeout(() => {
            scrollToObject(hash, 100);
          }, 1500);
        } else
        if (hash.includes("schoolName_")) {
          let str = hash.replaceAll("schoolName_","");
          let itemIndex = Number(str);
          setTimeout(() => {
            expandDiv(`schoolItem${itemIndex}`);
              setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
 
          }, 2000);
        } else
        if (hash.includes("schoolYear_")) {
          let str = hash.replaceAll("schoolYear_","");
          let itemIndex = Number(str);
          setTimeout(() => {
            expandDiv(`schoolItem${itemIndex}`);
            setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
          }, 2000);
        } else
        if (hash.includes("schedule_")) {
          let str = hash.replaceAll("schedule_","");
          let itemIndex = Number(str);
          setTimeout(() => {
            expandDiv(`schoolItem${itemIndex}`);
            setTimeout(() => {
              scrollToObject(hash, 100);
            }, 1500);
          }, 2000);
        } else
        if (hash.includes("courseName_")) {
          let str = hash.replaceAll("courseName_","");
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`schoolItem${itemIndex}`);
            try {
              let sid = Number(ar[1]);
              setTimeout(() => {
                expandDiv(`subjectItem${sid}`);
                  setTimeout(() => {
                  scrollToObject(hash, 100);
                }, 1500);
              }, 2000);
            }
            catch(ex2){
              //skip
            }
          }, 2000);
        } else
        if (hash.includes("typeOfClass_")) {
          let str = hash.replaceAll("typeOfClass_","");
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`schoolItem${itemIndex}`);
            try {
              let sid = Number(ar[1]);
              setTimeout(() => {
                expandDiv(`subjectItem${sid}`);
                  setTimeout(() => {
                  scrollToObject(hash, 100);
                }, 1500);
               }, 2000);
            }
            catch(ex2){
              //skip
            }
           }, 2000);
        } else
        if (hash.includes("collegeTookClass_")) {
          let str = hash.replaceAll('collegeTookClass_','');
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`schoolItem${itemIndex}`);
            try {
              let sid = Number(ar[1]);
              setTimeout(() => {
                expandDiv(`subjectItem${sid}`);
                setTimeout(() => {
                  scrollToObject(hash, 100);
                }, 1500);
               }, 2000);
            }
            catch(ex2){
              //skip
            }
           }, 2000);
        } else
        if (hash.includes("CNGradingScale_")) {
          let str = hash.replaceAll('CNGradingScale_','');
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`schoolItem${itemIndex}`);
            try {
              let sid = Number(ar[1]);
              setTimeout(() => {
                expandDiv(`subjectItem${sid}`);
                setTimeout(() => {
                  scrollToObject(hash, 100);
                }, 1500);
               }, 2000);
            }
            catch(ex2){
              //skip
            }
           }, 2000);
        } else
        if (hash.includes("lowestGrade_")) {
          let str = hash.replaceAll('lowestGrade_','');
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`schoolItem${itemIndex}`);
            try {
              let sid = Number(ar[1]);
              setTimeout(() => {
                expandDiv(`subjectItem${sid}`);
                setTimeout(() => {
                  scrollToObject(hash, 100);
                }, 1500);
               }, 2000);
            }
            catch(ex2){
              //skip
            }
           }, 2000);
        } else
        if (hash.includes("highestGrade_")) {
          let str = hash.replaceAll('highestGrade_','');
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`schoolItem${itemIndex}`);
            try {
              let sid = Number(ar[1]);
              setTimeout(() => {
                expandDiv(`subjectItem${sid}`);
                setTimeout(() => {
                  scrollToObject(hash, 100);
                }, 1500);
               }, 2000);
            }
            catch(ex2){
              //skip
            }
           }, 2000);
        } else
        if (hash.includes("otherGradeScale_")) {
          let str = hash.replaceAll('otherGradeScale_','');
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`schoolItem${itemIndex}`);
            try {
              let sid = Number(ar[1]);
              setTimeout(() => {
                expandDiv(`subjectItem${sid}`);
                setTimeout(() => {
                  scrollToObject(hash, 100);
                }, 1500);
               }, 2000);
            }
            catch(ex2){
              //skip
            }
           }, 2000);
        } else
        if (hash.includes("takeSemester_")) {
          let str = hash.replaceAll("takeSemester_","");
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`schoolItem${itemIndex}`);
            try {
              let sid = Number(ar[1]);
              setTimeout(() => {
                expandDiv(`subjectItem${sid}`);
                setTimeout(() => {
                  scrollToObject(hash, 100);
                }, 1500);
               }, 2000);
            }
            catch(ex2){
              //skip
            }
           }, 2000);
        } else
        if (hash.includes("scheduleTranscript_")) {
          let str = hash.replaceAll('scheduleTranscript_','');
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`schoolItem${itemIndex}`);
            try {
              let sid = Number(ar[1]);
              setTimeout(() => {
                expandDiv(`subjectItem${sid}`);
                setTimeout(() => {
                  scrollToObject(hash, 100);
                }, 1500);
               }, 2000);
            }
            catch(ex2){
              //skip
            }
           }, 2000);
        } else
        if (hash.includes("finalGrade_")) {
          let str = hash.replaceAll('finalGrade_','');
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`schoolItem${itemIndex}`);
            try {
              let sid = Number(ar[1]);
              setTimeout(() => {
                expandDiv(`subjectItem${sid}`);
                setTimeout(() => {
                  scrollToObject(hash, 100);
                }, 1500);
               }, 2000);
            }
            catch(ex2){
              //skip
            }
           }, 2000);
        } else
        if (hash.includes("finalCredits_")) {
          let str = hash.replaceAll('finalCredits_','');
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`schoolItem${itemIndex}`);
            try {
              let sid = Number(ar[1]);
              setTimeout(() => {
                expandDiv(`subjectItem${sid}`);
                setTimeout(() => {
                  scrollToObject(hash, 100);
                }, 1500);
               }, 2000);
            }
            catch(ex2){
              //skip
            }
           }, 2000);
        } else
        if (hash.includes("grade_")) {
          let str = hash.replaceAll('grade_','');
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`schoolItem${itemIndex}`);
            try {
              let sid = Number(ar[1]);
              setTimeout(() => {
                expandDiv(`subjectItem${sid}`);
                setTimeout(() => {
                  scrollToObject(hash, 100);
                }, 1500);
               }, 2000);
            }
            catch(ex2){
              //skip
            }
           }, 2000);
        } else
        if (hash.includes("credits_")) {
          let str = hash.replaceAll('credits_','');
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`schoolItem${itemIndex}`);
            try {
              let sid = Number(ar[1]);
              setTimeout(() => {
                expandDiv(`subjectItem${sid}`);
                setTimeout(() => {
                  scrollToObject(hash, 100);
                }, 1500);
               }, 2000);
            }
            catch(ex2){
              //skip
            }
           }, 2000);
        } else
        if (hash.includes("finalCredits_")) {
          let str = hash.replaceAll('finalCredits_','');
          let ar = str.split('_');
          let itemIndex = Number(ar[0]);
          setTimeout(() => {
            expandDiv(`schoolItem${itemIndex}`);
            try {
              let sid = Number(ar[1]);
              setTimeout(() => {
                expandDiv(`subjectItem${sid}`);
                setTimeout(() => {
                  scrollToObject(hash, 100);
                }, 1500);
               }, 2000);
            }
            catch(ex2){
              //skip
            }
           }, 2000);
        }
      } 
    }
    catch(ex){
      console.log('Error:', ex);
    }
  }

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      try {
        setLoading(true);
        dispatch(getHighSchoolData(studentId));
        const res = await dispatch(getGradeAndCourse(studentId));
        const dataLoaded = res.payload;
        if (dataLoaded) {
          formik.setValues({
            ...dataLoaded,
            is_reported_all_grade_10: dataLoaded.is_reported_all_grade_10,
          });

          let gradeCourses:any[] = tryParseJSON(dataLoaded.grade_10_course_array) || [];
          resetIndexGradecourse(gradeCourses);
          setCourseData(gradeCourses);

          await checkInputSchool(gradeCourses, gradeLevel);
          setTimeout(() => {
            AutoScrollAndExpand();
          }, 2000);
        }
        setLoading(false);
        formik.setStatus('idle');
      }
      catch(ex){
        console.log('Error:', ex);
      }
      setLoading(false);
    }
    else {
      handleLogout();
    }
  };
  React.useEffect(() => {
    getDetail();
    localStorage.setItem('lastGradePage', "10");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const hash = location.hash.slice(1); // Remove the '#' character from the hash
    if (hash) {
      AutoScrollAndExpand();
    }

  }, [location]);


  const handleAddCourse = () => {
    if (courseData.length > 8) return;
    setCourseData([...courseData, {}]);
  };

  const handleExpandAll = () => {
    if (!check) {
      setExpandAll(!expandAll);
      if (!expandAll) {
        setExpandActive(courseData.length);
      } else {
        setExpandActive(0);
      }
    }
    else {
      setExpandAll(!expandAll);
    }
  };

  const handleExpandActive = (value: any) => {
    if (!check) {
      value
        ? setExpandActive(Math.abs(expandActive - 1))
        : setExpandActive(Math.abs(expandActive + 1));
    }
  };


  // React.useEffect(() => {
  //   if (expandActive === courseData.length) {
  //     setExpandAll(true);
  //   }

  //   if (expandActive === 0) {
  //     setExpandAll(false);
  //   }
  // }, [expandActive, courseData.length]);

  // Auto save function ==========
  const notInitialRender = React.useRef(false);
  React.useEffect(() => {
    debounceSaving();
  }, [formik.values, courseData]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (notInitialRender.current) {
        if (!loading) formik.handleSubmit();
      } else {
        notInitialRender.current = true;
      }
    }, 1500),
    []
  );
  // Auto save function ==========
  const [forceRenderKey, setForceRenderKey] = React.useState(0);

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const dataList = reorder(courseData, result.source.index, result.destination.index);
    setCourseData([...dataList]);
    setForceRenderKey(forceRenderKey + 1);
  };
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[{ percentOnProcess: 30, title: '' }]}
        title="Courses & Grades"
        process={1}
        icon={<StackedLineChartIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <div className="fs-6 text-dark my-2 fw-bold">
                    <InfoOutlinedIcon fontSize="small" className="mx-1" />
                    Courses & Grades
                  </div>
                  <div className="d-flex flex-wrap text-field-light">
                    Many college applications will want you to list your Courses and Grades and/or
                    GPA in various formats. Enter your information as you complete each of your high
                    school courses to make it easy for you during college application season.
                  </div>
                </React.Fragment>
              }
              placement="right-start"
            >
              <Row className="m-0">
                <div className="text-field-mid fs-3 mb-4">10th Grade</div>
                {showWarning && (
                  <div className="row">
                    <div className="col-md-12 mb-4">
                      <div className="alert alert-danger" role="alert">
                        {errorArr && errorArr.length > 0 && (
                          <>
                            Prior to entering your coursework by grade year, go to Education &gt;
                            <a
                              href={'/#/application/high-school/#' + errorArr[0].value}
                              title="click to return the high school page"
                            >
                              {' '}
                              High School{' '}
                            </a>
                            &nbsp; and enter the following information for each of the high
                            school(s) you attended.
                            {` ${errorArr[0].name}`}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {courseData.length > 0 && (
                  <div className="d-flex justify-content-end">
                    <ExpandMore
                      aria-expanded={expandAll}
                      aria-label="show all"
                      onClick={handleExpandAll}
                    >
                      {!expandAll ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
                    </ExpandMore>
                  </div>
                )}
                <DragDropContext onDragEnd={onDragEnd} key={forceRenderKey}>
                  <Droppable droppableId="list">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {(courseData || []).map((item, index) => (
                          <div key={index + '-' + courseData.length}>
                            <Draggable draggableId={index + '-' + courseData.length} index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div
                                    key={index + '-' + courseData.length}
                                    className="position-relative"
                                  >
                                    <CourseConfig
                                      expandAll={expandAll}
                                      handleExpandActive={handleExpandActive}
                                      defaultGradeValue="11th Grade"
                                      handleSave={(values) => {
                                        const newCourseData = [...courseData];
                                        newCourseData.splice(index, 1, values);
                                        setCourseData(newCourseData);
                                      }}
                                      item={item}
                                      total={courseData.length}
                                      index={index + 1}
                                    />
                                    <RemoveCircleOutlineIcon
                                      className="position-absolute text-field-light pointer"
                                      style={{ right: '-24px', top: '12px' }}
                                      onClick={() => {
                                        const newCourseData = [...courseData];
                                        newCourseData.splice(index, 1);
                                        setCourseData(newCourseData);
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          </div>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <div className="my-3 w-100 d-flex flex-column align-items-center">
                  <div className="text-field-light sized70">You can add up to 9 high schools</div>
                  <Button variant="primary" className="p-2 px-5" onClick={handleAddCourse}>
                    Add Additional High Schools
                  </Button>
                </div>
                <div className="common-flag pt-3">
                  <div id="is_reported_all_grade_10" className="text-field-light">
                    Please indicate that you have reported all courses and grades for 10th grade.*
                  </div>
                  <CheckboxInput
                    name="is_reported_all_grade_10"
                    values={formik.values.is_reported_all_grade_10}
                    onChange={formik.handleChange}
                    label="I have reported all of my courses for this grade."
                    isMissing={!formik.values.is_reported_all_grade_10 && check ? true : false}
                  />
                </div>
              </Row>
            </HtmlTooltip>

            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {course.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default TenthGrade;
