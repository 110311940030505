/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { checkEnableParseJSON, reorder, setArrayLength } from 'components/helper/helper';
import HonorsConfig from './components/HonorsConfig';
import {
  getActivitiesAndHonors,
  updateActivitiesAndHonors,
} from 'app/activitiesAndHonors/activitiesAndHonorsSlice';
import { debounce } from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { ExpandMore } from './Activities';

interface Props {}

const Honors = (props: Props) => {
  const [honorsData, setHonorsData] = React.useState<any[]>([{}]);
  const [loading, setLoading] = React.useState(false);
  const activitiesAndHonors = useAppSelector((store) => store.activitiesAndHonors);
  
  const [expandAll, setExpandAll] = React.useState(false);
  const [expandActive, setExpandActive] = React.useState<number>(0);

  const query = new URLSearchParams(useLocation().search);
  const check = query.get('check');
  const indexEpand = query.get('index');
  
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      number_of_honor: '0',
      honor_array: '',
    },
    onSubmit: async (values) => {
      studentId = Cookies.get('userId');
      token = Cookies.get(authenticateSettings.tokenName);
      if (token && studentId) {
        const newValues = {
          ...values,
          student_id: studentId,
          honor_array: JSON.stringify(honorsData),
        };
        await dispatch(updateActivitiesAndHonors(newValues));
      } else {
        handleLogout();
      }
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleExpandAll = () => {
    setExpandAll(!expandAll);
    if (!expandAll) {
      setExpandActive(honorsData.length);
    } else {
      setExpandActive(0);
    }
  };

  const handleExpandActive = (value: any) => {
    value
      ? setExpandActive(Math.abs(expandActive - 1))
      : setExpandActive(Math.abs(expandActive + 1));
  };

  useEffect(() => {
    if (expandActive === honorsData.length) {
      setExpandAll(true);
    }

    if (expandActive === 0) {
      setExpandAll(false);
    }
  }, [expandActive, honorsData.length]);

  const handleContinue = () => {
    formik.handleSubmit();
    history.push(routers.educationalPrograms.path);
  };

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      const res = await dispatch(getActivitiesAndHonors(studentId));
      const dataLoaded = res.payload;
      if (dataLoaded) {
        formik.setValues({ ...formik.values, ...dataLoaded });
        if (dataLoaded.honor_array) {
          setHonorsData(checkEnableParseJSON(dataLoaded.honor_array));
        }
      }
      setLoading(false);
      formik.setStatus('idle');
    } else {
      handleLogout();
    }
  };

  React.useEffect(() => {
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setHonorsData(setArrayLength(honorsData, formik.values.number_of_honor));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.number_of_honor]);

  const [forceRenderKey, setForceRenderKey] = React.useState(0);

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const dataList = reorder(honorsData, result.source.index, result.destination.index);
    setHonorsData([...dataList]);
    setForceRenderKey(forceRenderKey + 1);
  };

  // Auto save function ==========
  React.useEffect(() => {
    debounceSaving();
  }, [formik.values, honorsData]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (!loading) formik.handleSubmit();
    }, 1500),
    []
  );
  // Auto save function ==========

  const handleAddHonors = () => {
    if (honorsData.length > 9) return;
    setHonorsData([...honorsData, {}]);
  };
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[{ percentOnProcess: 30, title: '' }]}
        title="Activites & Honors"
        process={1}
        icon={<StackedLineChartIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <Row className="m-0">
              <div className="text-field-mid fs-3 mb-4">Honors</div>
              <div className="p-3" style={{fontSize: '1rem'}}>
                If you have received any honors or awards related beginning with the ninth grade or
                international equivalent, please indicate them here.
              </div>
              <div className="bg-light p-3 rounded" style={{fontSize: '1rem'}}>
                <div>App Notes:</div>
                <div>
                  Common App - Allows you to report any honors related to your academic achievements
                  beginning with the ninth grade or international equivalent. If you received more
                  than five, please add the five most important to you.*
                </div>
                <div>UC App - Allow you to report up to 20 activities and honors combined.</div>
              </div>
              {/* <SelectInput
                className="common-flag"
                form={formik}
                mdCol="12"
                option={optionValues2(9)}
                label="If you have received any honors related to your academic achievements beginning with the ninth grade or international equivalent, please indicate number of honors. If you received more than five, please add the five most important to you.*"
                name="number_of_honor"
                values={formik.values.number_of_honor}
                onChange={formik.handleChange}
              /> */}
              <DragDropContext onDragEnd={onDragEnd} key={forceRenderKey}>
               {honorsData.length > 0 && <div className="d-flex justify-content-end mt-3">
                  <ExpandMore
                    aria-expanded={expandAll}
                    aria-label="show all"
                    onClick={handleExpandAll}
                  >
                    {!expandAll ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
                  </ExpandMore>
                </div>}
                <Droppable droppableId="list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {honorsData.map((item, index) => (
                        <div key={index + '-' + honorsData.length}>
                          <Draggable draggableId={index + '-' + honorsData.length} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="position-relative">
                                  <HonorsConfig
                                    handleSave={(values) => {
                                      const newActivitiesData = [...honorsData];
                                      newActivitiesData.splice(index, 1, values);
                                      setHonorsData(newActivitiesData);
                                    }}
                                    item={item}
                                    indexNumber={`${index + 1}/${honorsData.length}`}
                                    expandAll={expandAll}
                                    handleExpandActive={handleExpandActive}
                                    check={check}
                                    indexExpand={`${indexEpand}/${honorsData.length}`}
                                  />
                                  <RemoveCircleOutlineIcon
                                    className="position-absolute text-field-light pointer"
                                    style={{ right: '-24px', top: '12px' }}
                                    onClick={() => {
                                      const newActivitiesData = [...honorsData];
                                      newActivitiesData.splice(index, 1);
                                      setHonorsData(newActivitiesData);
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        </div>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className="my-3 w-100 d-flex flex-column align-items-center">
                {/* <div className="text-field-light sized70">You can add up to 10 honor or award</div> */}

                <Button variant="primary" className="p-2 px-5" onClick={handleAddHonors}>
                  Add Honor or Award
                </Button>
              </div>
            </Row>
            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {activitiesAndHonors.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default Honors;
