/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import SchoolConfig from './SchoolConfig';
import { Button } from 'react-bootstrap';
import { reorder } from 'components/helper/helper';
import { useLocation } from 'react-router-dom';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { ExpandMore } from 'screen/activitiesAndHonors/Activities';

export const scrollToObject = (eleId: any, offset: number) => {
  let ele: any = document.getElementById(eleId);
  if (ele) {
    ele.style.scrollMarginTop = `${offset}px`;
    ele.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

interface Props {
  formik: any;
  setOtherSchoolData: (values: any[]) => void;
  otherSchoolData: any[];
  setLoading: (status: boolean) => void;
  check?: any;
  indexExpand?: any;
}

const OtherSecondary = (props: Props) => {
  const [schools, setSchools] = React.useState(props.otherSchoolData ?? []);
  const [expandAll, setExpandAll] = React.useState(false);
  const [expandActive, setExpandActive] = React.useState<number>(0);

  const handleAddSchool = () => {
    props.otherSchoolData.length < 6 && setSchools([...schools, {}]);
  };

  React.useEffect(() => {
    try {
      props.setOtherSchoolData(schools);
    } catch (ex) {
      console.log('Error:', ex);
    }
  }, [schools]);

  const handleExpandAll = () => {
    setExpandAll(!expandAll);
    if (!expandAll) {
      setExpandActive(schools.length);
    } else {
      setExpandActive(0);
    }
  };

  const handleExpandActive = (value: any) => {
    value
      ? setExpandActive(Math.abs(expandActive - 1))
      : setExpandActive(Math.abs(expandActive + 1));
  };

  React.useEffect(() => {
    if (expandActive === schools.length) {
      setExpandAll(true);
    }

    if (expandActive === 0) {
      setExpandAll(false);
    }
  }, [expandActive, schools.length]);

  return (
    <>
      <div className="fs-5 py-1">All high schools attending/attended</div>
      <div>
        Add in all the high schools you attended/attended or even take/took courses for credit at.
      </div>

      <div className="common-flag">
        {schools.length > 0 && (
          <div className="d-flex justify-content-end">
            <ExpandMore aria-expanded={expandAll} aria-label="show all" onClick={handleExpandAll}>
              {!expandAll ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
            </ExpandMore>
          </div>
        )}

        {schools.map((item, index) => {
          return (
            <SchoolConfig
              expandAll={expandAll}
              handleExpandActive={handleExpandActive}
              key={index + 1}
              index={index}
              indexText={`${index + 1}/${schools.length}`}
              handleSave={(values) => {
                try {
                  const idx = (schools ?? []).findIndex(
                    (school) => school.high_school_name === values?.high_school_name
                  );

                  if (idx === 0) {
                    window.Intercom('update', {
                      current_high_school_term: values?.classes_schedule,
                    });
                  }

                  const newOtherSchoolData = [...schools].map((school) =>
                    values?.is_graduate_from_this_school === 'true'
                      ? {
                          ...school,
                          is_graduate_from_this_school: 'false',
                          degree: '',
                          degreeFreeText: '',
                          year: null,
                          month: null,
                          day: null,
                        }
                      : school
                  );
                  newOtherSchoolData.splice(index, 1, values);
                  setSchools([...newOtherSchoolData]);

                  props.formik.handleSubmit();
                } catch (ex) {
                  console.log('Error: ', ex);
                }
              }}
              item={item}
              onRemove={() => {
                const newOtherSchoolData = [...schools];
                newOtherSchoolData.splice(index, 1);
                setSchools(newOtherSchoolData);
              }}
              handleCheckCurrent={(name) => {
                const index = (schools ?? []).findIndex(
                  (school) => school.high_school_name === name
                );
                if (index !== 0) {
                  const dataList = reorder(schools, index, 0);
                  setSchools([...dataList]);
                }

                window.Intercom('update', {
                  current_high_school: schools[0]?.high_school_name,
                });
              }}
              check={props.check}
              indexExpand={`${props.indexExpand}/${schools.length}`}
            />
          );
        })}
        <div id="AddHighSchool" className="my-3 w-100 d-flex flex-column align-items-center">
          <div className="text-field-light sized70">You can add up to 6 other high schools</div>
          <Button variant="primary" className="p-2 px-5" onClick={handleAddSchool}>
            Add additional high schools
          </Button>
        </div>
      </div>
    </>
  );
};

export default OtherSecondary;
