/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import ImportContactsTwoToneIcon from '@mui/icons-material/ImportContactsTwoTone';
import YesNoSwitch from 'components/input/YesNoSwitch';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ActivitiesConfig from './components/ActivitiesConfig';
import {
  BoolToString,
  checkEnableParseJSON,
  formatBoolean,
  reorder,
  userpilotDomain,
} from 'components/helper/helper';
import {
  getActivitiesAndHonors,
  updateActivitiesAndHonors,
} from 'app/activitiesAndHonors/activitiesAndHonorsSlice';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { debounce } from 'lodash';
import TextAreaInput from 'components/input/TextAreaInput';
import { Userpilot } from 'userpilot';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

interface Props {}

interface ExpandMoreProps extends IconButtonProps {}

export const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Activities = (props: Props) => {
  const [activitiesData, setActivitiesData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const activitiesAndHonors = useAppSelector((store) => store.activitiesAndHonors);
  const [expandAll, setExpandAll] = React.useState(false);
  const [expandActive, setExpandActive] = React.useState<number>(0);
  const query = new URLSearchParams(useLocation().search);
  const check = query.get('check');
  const indexExpand = query.get('index') || 0;
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const information = useAppSelector((store) => store.basicInformation.myBasicInformation);
  const formik = useFormik({
    initialValues: {
      is_have_any_activity_to_report: '',
      activity_array: '',
      howHaveYouUsedYourEarnings: '',
    },
    onSubmit: async (values) => {
      studentId = Cookies.get('userId');
      token = Cookies.get(authenticateSettings.tokenName);
      if (token && studentId) {
        try {
          const activitiesDataMapped = activitiesData.map((item) => {
            return {
              ...item,
              howHaveYouUsedYourEarnings: values.howHaveYouUsedYourEarnings,
            };
          });
          const newValues = {
            ...values,
            student_id: studentId,
            is_have_any_activity_to_report: formatBoolean(values.is_have_any_activity_to_report),
            activity_array: JSON.stringify(activitiesDataMapped),
          };
          Userpilot.identify((studentId ?? 'undefined') + userpilotDomain, {
            name: information?.first_name ?? 'undefined',
            email: information?.email ?? 'undefined',
            created_at: information?.created_at ?? 'undefined',
            activityTypes: activitiesDataMapped.map((item) => item.activityType).join(', '),
          });
          await dispatch(updateActivitiesAndHonors(newValues)).unwrap();
        } catch (ex) {
          console.log('Error: ', ex);
        }
      } else {
        handleLogout();
      }
    },
  });

  const handleExpandAll = () => {
    setExpandAll(!expandAll);
    if (!expandAll) {
      setExpandActive(activitiesData.length);
    } else {
      setExpandActive(0);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = () => {
    formik.handleSubmit();
    history.push(routers.honors.path);
  };

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      try {
        setLoading(true);
        const res = await dispatch(getActivitiesAndHonors(studentId)).unwrap();
        const dataLoaded = res; //res.payload;
        if (dataLoaded) {
          const howHaveYouUsedYourEarnings =
            checkEnableParseJSON(dataLoaded.activity_array, [])?.[0]?.howHaveYouUsedYourEarnings ??
            '';
          formik.resetForm({
            values: {
              ...formik.values,
              ...dataLoaded,
              howHaveYouUsedYourEarnings,
              is_have_any_activity_to_report: BoolToString(
                dataLoaded.is_have_any_activity_to_report
              ),
            },
          });
          setActivitiesData(checkEnableParseJSON(dataLoaded.activity_array, []));
        }
        formik.setStatus('idle');
      } catch (ex) {
        console.log('Error: ', ex);
      }
      setLoading(false);
    } else {
      handleLogout();
    }
  };

  React.useEffect(() => {
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddActivities = () => {
    if (activitiesData.length > 29) return;
    setActivitiesData([...activitiesData, {}]);
  };

  const handleExpandActive = (value: any) => {
    value
      ? setExpandActive(Math.abs(expandActive - 1))
      : setExpandActive(Math.abs(expandActive + 1));
  };

  useEffect(() => {
    if (expandActive === activitiesData.length) {
      setExpandAll(true);
    }

    if (expandActive === 0) {
      setExpandAll(false);
    }
  }, [expandActive, activitiesData.length]);

  // Drag and drop
  const [forceRenderKey, setForceRenderKey] = React.useState(0);
  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const dataList = reorder(activitiesData, result.source.index, result.destination.index);
    setActivitiesData([...dataList]);
    setForceRenderKey(forceRenderKey + 1);
  };

  // BEGIN - Auto save function ==========
  React.useEffect(() => {
    if (!!formik.status) debounceSaving();
  }, [formik.values, activitiesData]);

  const debounceSaving = React.useCallback(
    debounce(() => {
      if (!loading) formik.handleSubmit();
    }, 1500),
    []
  );
  // END - Auto save function ==========

  let showEarning = activitiesData.filter((i) => i?.categoryUC === 'Work Experience')?.length > 0;
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[{ percentOnProcess: 30, title: '' }]}
        title="Activites & Honors"
        process={1}
        icon={<ImportContactsTwoToneIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <Row className="m-0">
              <div className="text-field-mid fs-3 mb-4">Activities</div>
              <div className='mb-2' style={{fontSize: '1rem'}}>
                Reporting activities can help colleges better understand your life outside of the
                classroom. Examples of activities might include: Arts or music, Clubs, Community
                engagement, Family responsibilities, Hobbies Sports, Work or volunteering, Other
                experiences that have been meaningful to you.
              </div>
              <div style={{fontSize: '1rem'}}>Please list your activities in the order of their importance to you.</div>
              <div className="bg-light rounded p-2 mb-4" style={{fontSize: '1rem'}}>
                <div>App Notes:</div>
                <div> Common Application allows for up to 10 Activities + 5 Honors or Awards</div>
                <div> UC Application allows for a total of 20 Activites, Honors or Awards</div>
              </div>
              <YesNoSwitch
                label="Do you have any activities that you wish to report? *"
                className="common-flag"
                name="is_have_any_activity_to_report"
                values={formik.values.is_have_any_activity_to_report}
                onChange={(e: any) => {
                  formik.handleChange(e);
                }}
              />
              <DragDropContext onDragEnd={onDragEnd} key={forceRenderKey}>
               {activitiesData.length > 0 && <div className="d-flex justify-content-end">
                  <ExpandMore
                    aria-expanded={expandAll}
                    aria-label="show all"
                    onClick={handleExpandAll}
                  >
                    {!expandAll ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
                  </ExpandMore>
                </div>}
                <Droppable droppableId="list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {activitiesData.map((item, index) => (
                        <div key={index + '-' + activitiesData.length}>
                          <Draggable
                            draggableId={index + '-' + activitiesData.length}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="position-relative">
                                  <ActivitiesConfig
                                    handleSave={(values) => {
                                      const newActivitiesData = [...activitiesData];
                                      newActivitiesData.splice(index, 1, values);
                                      setActivitiesData(newActivitiesData);
                                    }}
                                    item={item}
                                    indexNumber={`${index + 1}/${activitiesData.length}`}
                                    expandAll={expandAll}
                                    handleExpandActive={handleExpandActive}
                                    check={check}
                                    indexExpand={`${indexExpand}/${activitiesData.length}`}
                                  />
                                  <RemoveCircleOutlineIcon
                                    className="position-absolute text-field-light pointer"
                                    style={{ right: '-24px', top: '12px' }}
                                    onClick={() => {
                                      const newActivitiesData = [...activitiesData];
                                      newActivitiesData.splice(index, 1);
                                      setActivitiesData(newActivitiesData);
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        </div>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className="my-3 w-100 d-flex flex-column align-items-center">
                <div className="text-field-light sized70">You can add up to 30 activities</div>
                <Button variant="primary" className="p-2 px-5" onClick={handleAddActivities}>
                  Add another Activity/Experience
                </Button>
              </div>
              <div className={!showEarning ? 'd-none' : ''}>
                Please tell us how you've used your earnings from all of the jobs you've listed.
                This information will NOT be used to determine financial aid — rather, it gives us
                more context about you and your experiences.
              </div>
              <TextAreaInput
                hidden={!showEarning}
                logo="span-uc-logo"
                mdCol="12"
                countText={250}
                label="How have you used or will you use your earnings?"
                name="howHaveYouUsedYourEarnings"
                values={formik.values.howHaveYouUsedYourEarnings}
                onChange={(e: any) => {
                  formik.handleChange(e);
                }}
              />
            </Row>

            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {activitiesAndHonors.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default Activities;
