import { useEffect, useState, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';
import {
  checkActivitisCondition,
  checkEOPCondition,
  checkHonorsCondition,
} from 'screen/common/helper/checkFieldsMissingCondition';

interface ModalCheckFieldProps {
  show?: boolean;
  onClose: () => void;
  data: any;
  page: string;
}

export const ModalCheckActivitiesFields = (props: ModalCheckFieldProps) => {
  const { show, onClose, data, page } = props;
  const [filedsFail, setFieldsFail] = useState<any[]>([]);

  const requiredFields = useMemo(() => {
    switch (page) {
      case 'activities': {
        return checkActivitisCondition(data);
      }

      case 'honors': {
        return checkHonorsCondition(data);
      }

      case 'EOP': {
        return checkEOPCondition(data);
      }
      default:
        return [];
    }
  }, [data]);

  const checkRequiredFields = (data: any) => {
    const result: any = [];

    requiredFields.forEach((field) => {
      try {
        // Parse path với regex để xử lý cả mảng
        const path = field.key;
        let value = data;
        let valid = true;

        if (page === 'EOP') {
          if (
            path === 'average_hours_worked_per_week' &&
            (!data?.average_hours_worked_per_week ||
              data?.average_hours_worked_per_week === 'Select')
          ) {
            result.push(field);
          }

          if (
            path === 'average_hours_activities_per_week' &&
            data?.average_hours_activities_per_week === 'Select' &&
            !data?.average_hours_activities_per_week
          ) {
            result.push(field);
          }
        }

        if (!path.includes('.') && !path.includes('[')) {
          // Trường hợp đơn giản nhất - không có cấu trúc lồng nhau
          if (!data[path] && typeof data[path] !== 'boolean') {
            result.push(field);
          }
          return; // Tiếp tục với field tiếp theo
        }

        // Regex để xử lý cả mẫu như: a.b[0].c
        const parts = path.match(/\w+|\[\d+\]/g);

        if (parts) {
          for (const part of parts) {
            if (part.startsWith('[') && part.endsWith(']')) {
              // Đây là một phần tử mảng
              const index = parseInt(part.slice(1, -1));
              if (!value || !Array.isArray(value) || value.length <= index) {
                valid = false;
                break;
              }
              value = value[index];
            } else {
              // Đây là thuộc tính thông thường
              if (!value || typeof value !== 'object') {
                valid = false;
                break;
              }
              value = value[part];
            }
          }
        }

        if (!valid || value === undefined || value === null || value === '') {
          if (typeof value !== 'boolean') {
            result.push(field);
          }
        }
      } catch (error) {
        // Nếu có lỗi trong quá trình truy cập, coi như field không hợp lệ
        result.push(field);
      }
    });

    setFieldsFail(result);
  };

  useEffect(() => {
    checkRequiredFields(data);
  }, [data, requiredFields, page]);

  const history = useHistory();

  const handleClick = (field: any) => {
    history.push(field.page);
    onClose();
  };

  return (
    <Modal show={show} onHide={() => onClose()}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {data && filedsFail.length > 0 ? (
          <div>
            <h3>These fields are not yet valid, please complete them: </h3>
            <ul style={{ fontSize: '1rem', color: 'red' }}>
              {filedsFail.map((field, index) => (
                <li key={index} onClick={() => handleClick(field)} style={{ cursor: 'pointer' }}>
                  {field.name}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div>
            <h3>All required fields are filled</h3>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
