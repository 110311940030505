import * as React from 'react';
import SelectInput from 'components/input/SelectInput';
import TextInput from 'components/input/textInput';
import { collegeCourseSubjectOption } from './collegeCourseContant';
import CheckboxInput from 'components/input/CheckboxInput';
import CardConfig from 'components/CardConfig';
import SelectSearchInput from 'components/input/SelectSearchInput';
import { useLocation } from 'react-router-dom';

interface CourseSubjectConfigProps {
  courseIndex:number;
  form: any
  i: number; // start index 0
  total: number; 
}

export const CourseSubjectConfig = ({ courseIndex, form, i, total }: CourseSubjectConfigProps) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const check = query.get('check');

  const nameBuilded = `collegeCourseArray[${i}].`;
  return (
    <CardConfig 
      id={`subjectItem${i+1}`}
      handleSave={() => { }}
      headerElement={<div className="w-100 d-flex justify-content-between">
        <span className="p-1">
          {[
            form.values.collegeCourseArray[i]?.dept,
            form?.values?.collegeCourseArray[i]?.courseNumber,
            form?.values?.collegeCourseArray[i]?.courseName,
            form?.values?.collegeCourseArray[i]?.collegeCourseSubject,
            form?.values?.collegeCourseArray[i]?.honorStatus,
            form?.values?.collegeCourseArray[i]?.grade,
            form?.values?.collegeCourseArray[i]?.creditsPerHours,
          ]
            .filter((i) => !!i)
            .join(' - ')}
        </span>

        <strong className="text-field-light">
          {i + 1}/{total}
        </strong>
      </div>}
    >
      <TextInput
        id={`dept_${courseIndex}_${i+1}`}
        countText={20}
        label="Dept. Abbreviation - Ex. ENG"
        name={`${nameBuilded}dept`}
        values={form.values.collegeCourseArray[i]?.dept}
        onChange={form.handleChange}
        isMissing={!form.values.collegeCourseArray[i]?.dept && check ? true : false}
      />
      <TextInput
        id={`courseNumber_${courseIndex}_${i+1}`}
        countText={50}
        label="Course Number - Ex. 100"
        name={`${nameBuilded}courseNumber`}
        values={form.values.collegeCourseArray[i]?.courseNumber}
        onChange={form.handleChange}
        isMissing={!form.values.collegeCourseArray[i]?.courseNumber && check ? true : false}
      />

      <TextInput
        id={`courseName_${courseIndex}_${i+1}`}
        countText={50}
        mdCol={'12'}
        label="Course Name*"
        name={`${nameBuilded}courseName`}
        values={form.values.collegeCourseArray[i]?.courseName}
        onChange={form.handleChange}
        isMissing={!form.values.collegeCourseArray[i]?.courseName && check ? true : false}
      />

      <SelectSearchInput
        form={form}
        option={collegeCourseSubjectOption.map((e, index) => ({
          label: e,
          value: e,
          code: e,
          id: index,
        }))}
        label="College Course Subject"
        values={form.values.collegeCourseArray[i]?.collegeCourseSubject
          ? {
            label: form.values.collegeCourseArray[i]?.collegeCourseSubject,
            value: form.values.collegeCourseArray[i]?.collegeCourseSubject,
            code: form.values.collegeCourseArray[i]?.collegeCourseSubject,
            id: collegeCourseSubjectOption.indexOf(
              form.values.collegeCourseArray[i]?.collegeCourseSubject
            ),
          }
          : undefined}
        name={`${nameBuilded}collegeCourseSubject`}
        onChangeItem={(e) => {
          form.setFieldValue(`${nameBuilded}collegeCourseSubject`, e.value);
        }}
        onSearch={(keyword: string) => { }} 
      />

      <SelectInput
        id={`subject_${courseIndex}_${i+1}`}
        option={[
          { name: '--', value: '' },
          { name: 'Civics/American Government', value: 'Civics/American Government' },
          { name: 'U.S. History', value: 'U.S. History' }, 
          { name: 'World History/Cultures/Hist. Geography', value: 'World History/Cultures/Hist. Geography' },
          { name: 'English', value: 'English' },
          { name: 'English-ESL/ELD', value: 'English-ESL/ELD' },
          { name: 'Algebra I', value: 'Algebra I' },
          { name: 'Geometry', value: 'Geometry' },
          { name: 'Algebra II', value: 'Algebra II' },
          { name: 'Mathematics I (Integrated)', value: 'Mathematics I (Integrated)' },
          { name: 'Mathematics II (Integrated)', value: 'Mathematics II (Integrated)' },
          { name: 'Mathematics III (Integrated)', value: 'Mathematics III (Integrated)' },
          { name: 'Algebra II/Trigonometry', value: 'Algebra II/Trigonometry' },
          { name: 'Statistics', value: 'Statistics' }, 
          { name: 'Pre-Calculus', value: 'Pre-Calculus' },
          { name: 'Calculus', value: 'Calculus' }, 
          { name: 'Other Advanced Mathematics', value: 'Other Advanced Mathematics' },
          { name: 'Computer Science', value: 'Computer Science' },
          { name: 'Biology/Life Sciences', value: 'Biology/Life Sciences' },
          { name: 'Biology/Earth & Space Sciences', value: 'Biology/Earth & Space Sciences' },
          { name: 'Chemistry', value: 'Chemistry' },
          { name: 'Chemistry/Earth & Space Sciences', value: 'Chemistry/Earth & Space Sciences' },
          { name: 'Physics', value: 'Physics' },
          { name: 'Physics/Earth & Space Sciences', value: 'Physics/Earth & Space Sciences' },
          { name: 'Integrated Science 1', value: 'Integrated Science 1' },
          { name: 'Integrated Science 2', value: 'Integrated Science 2' },
          { name: 'Integrated Science 3', value: 'Integrated Science 3' },
          { name: 'Applied Science', value: 'Applied Science' },
          { name: 'Computer Science', value: 'Computer Science' },
          { name: 'Earth & Space Sciences', value: 'Earth & Space Sciences' },
          { name: 'Engineering', value: 'Engineering' },
          { name: 'Interdisciplinary Sciences', value: 'Interdisciplinary Sciences' },
          { name: 'Yr 1 Other Than English', value: 'Yr 1 Other Than English' }, 
          { name: 'Yr 2 Other Than English', value: 'Yr 2 Other Than English' },
          { name: 'Yr 3 Other Than English', value: 'Yr 3 Other Than English' },
          { name:'Yr 4+ Other Than English', value: 'Yr 4+ Other Than English' },
          { name:'Dance ', value: 'Dance ' },
          { name: 'Music', value: 'Music' },
          { name: 'Theater', value: 'Theater' }, 
          { name: 'Visual Arts', value: 'Visual Arts' }, 
          { name: 'Interdisciplinary Arts', value: 'Interdisciplinary Arts' }, 
          { name: 'Elective -English', value: 'Elective -English' }, 
          { name: 'Elective -ESL/ELD', value: 'Elective -ESL/ELD' }, 
          { name: 'Elective -History/Social Science', value: 'Elective -History/Social Science' },
          { name: 'Elective -Interdisciplinary', value: 'Elective -Interdisciplinary' }, 
          { name: 'Elective -Language Other than English', value: 'Elective -Language Other than English' },
          { name: 'Elective -Mathematics', value: 'Elective -Mathematics' },
          { name: 'Elective -Math -Computer Science', value: 'Elective -Math -Computer Science' }, 
          { name: 'Elective -Science-Biology/Life Sciences', value: 'Elective -Science-Biology/Life Sciences' },
          { name: 'Elective -Science-Integrated Science', value: 'Elective -Science-Integrated Science' }, 
          { name: 'Elective -Science-Physical Science', value: 'Elective -Science-Physical Science' },
          { name: 'Elective -Visual & Performing Arts', value: 'Elective -Visual & Performing Arts' }, 
          { name: 'Physical Education/Health', value: 'Physical Education/Health' }, 
          { name: 'Religion', value: 'Religion' } 
        ]}
        label="A-G Subject Area"
        name={`${nameBuilded}subject`}
        values={form.values.collegeCourseArray[i]?.subject}
        onChange={form.handleChange}
        isMissing={!form.values.collegeCourseArray[i]?.subject && check ? true : false}
      />
      {form.values.collegeCourseArray[i]?.subject?.includes('Other Than English') && (
        <SelectInput
          option={[
            '--',
            'American Sign Language',
            'Arabic',
            'Armenian',
            'Chinese',
            'Farsi',
            'Filipino',
            'French',
            'German',
            'Greek',
            'Hebrew',
            'Hindi',
            'Hmong',
            'Italian',
            'Japanese',
            'Khmer',
            'Korean',
            'Latin',
            'Other Language',
            'Portuguese',
            'Punjabi',
            'Russian',
            'Spanish',
            'Tamil',
            'Turkish',
            'Vietnamese',
          ]}
          label="Language Name"
          name={`${nameBuilded}languageName`}
          values={form.values.collegeCourseArray[i]?.languageName}
          onChange={form.handleChange} />
      )}

      <TextInput
        type="number"
        decimalStep={0.1}
        label="Credits/hours"
        max={9999}
        min={1}
        name={`${nameBuilded}creditsPerHours`}
        values={form.values.collegeCourseArray[i]?.creditsPerHours}
        onChange={(e) => form.setFieldValue(`${nameBuilded}creditsPerHours`, Math.round(e.target.value * 10) / 10)}
      />
      <SelectInput
        option={[
          { name: '--', value: ''},
          { name: 'NH', value: 'NH' }, 
          { name: 'CL', value: 'CL' }
        ]}
        label="Honors Status"
        name={`${nameBuilded}honorStatus`}
        values={form.values.collegeCourseArray[i]?.honorStatus}
        onChange={form.handleChange}
      />
      <SelectInput
        id={`completionStatus_${courseIndex}_${i+1}`}
        option={[
          { name: '- Select -', value: '' },
          { name: 'In Progress/Planned', value: 'In Progress/Planned' },
          { name: 'Completed', value: 'Completed' } 
        ]}
        label="Completion Status"
        name={`${nameBuilded}completionStatus`}
        values={form.values.collegeCourseArray[i]?.completionStatus}
        onChange={form.handleChange}
        isMissing={!form.values.collegeCourseArray[i]?.completionStatus && check ? true : false}
      />
      <SelectInput
        id={`grade_${courseIndex}_${i+1}`}
        option={form.values.collegeCourseArray[i]?.completionStatus === 'In Progress/Planned'
          ? [
            { name: '--', value: '' },
            { name: 'IP', value: 'IP' },
            { name: 'PL', value: 'PL' },
            { name: 'A', value: 'A' },
            { name: 'B', value: 'B' },
            { name: 'C', value: 'C' },
            { name: 'D', value: 'D' },
            { name: 'F', value: 'F' },
            { name: 'PS', value: 'PS' },
            { name: 'NP', value: 'NP' },
            { name: 'CR', value: 'CR' },
            { name: 'NC', value: 'NC' }, 
            { name: 'EW', value: 'EW' },
            { name: 'WI', value: 'WI' },
            { name: 'WF', value: 'WF' },
            { name: 'WU', value: 'WU' },
            { name: 'IN', value: 'IN' },
          ]
          : [
            { name: '--', value: '' },
            { name: 'A+', value: 'A+' },
            { name: 'A', value: 'A' },
            { name: 'A-', value: 'A-' },
            { name: 'B+', value: 'B+' },
            { name: 'B', value: 'B' },
            { name: 'B-', value: 'B-' },
            { name: 'C+', value: 'C+' },
            { name: 'C', value: 'C' },
            { name: 'C-', value: 'C-' },
            { name: 'D+', value: 'D+' },
            { name: 'D', value: 'D' },
            { name: 'D-', value: 'D-' },
            { name: 'F', value: 'F' },
            { name: 'PS', value: 'PS' },
            { name: 'NP', value: 'NP' }, 
            { name: 'CR', value: 'CR' },
            { name: 'NC', value: 'NC' },
            { name: 'EW', value: 'EW' }, 
            { name: 'WI', value: 'WI' },
            { name: 'WF', value: 'WF' },
            { name: 'WU', value: 'WU' },
            { name: 'IN', value: 'IN' },
          ]}
        label="Grade*"
        name={`${nameBuilded}grade`}
        values={form.values.collegeCourseArray[i]?.grade}
        onChange={form.handleChange}
        isMissing={!form.values.collegeCourseArray[i]?.grade && check ? true : false}
      />
      <div className="mx-1">
        <CheckboxInput
          name={`${nameBuilded}transferable`}
          values={form.values.collegeCourseArray[i]?.transferable}
          onChange={form.handleChange}
          label="Transferable" />
      </div>
    </CardConfig>
  );
};
