/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import SelectInput from 'components/input/SelectInput';
import { occupationOptions } from 'components/helper/helper';
import TextInput from 'components/input/textInput';
import GroupRadioButton from 'components/input/GroupRadioButton';
import { debounce } from 'lodash';
import { getFuturePlan, updateFuturePlan } from 'app/highSchoolSlice/highSchoolSlice';

//Which best describes you? I am: *
export const describesOptions = [
  {
    value: '1',
    label: 'Applying as a first-year student and plan to start college in 2024 or 2025',
  },
  { value: '2', label: 'Planning to start college in 2026' },
  { value: '3', label: 'Planning to start college in 2027 or beyond' },
  { value: '4', label: 'Already a college student' },
];

interface FuturePlanProp {
  career_interest: string;
  other_career_interest: string;
  highest_degree_intend_to_earn: string;
  which_best_describe_you: string;
}

const defaultValues: FuturePlanProp = {
  career_interest: '',
  other_career_interest: '',
  highest_degree_intend_to_earn: '',
  which_best_describe_you: '',
};

interface Props { }

const FuturePlan = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const highSchool = useAppSelector((store) => store.highSchool);
  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const query = new URLSearchParams(useLocation().search);
  const check = query.get('check');
  const formik = useFormik({
    initialValues: defaultValues,
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        student_id: studentId,
      };
      await dispatch(updateFuturePlan(newValues));
    },
  });
  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = () => {
    formik.handleSubmit();
    history.push(routers.generalCourse.path);
  };

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      const res = await dispatch(getFuturePlan(studentId));
      if (res.payload) {
        formik.setValues({ ...formik.values, ...res.payload });
      }
      setLoading(false);
      formik.setStatus('idle');
    } else {
      handleLogout();
    }
  };

  React.useEffect(() => {
    getDetail();
  }, []);
  // Auto save function ==========
  const notInitialRender = React.useRef(false);
  React.useEffect(() => {
    debounceSaving();
  }, [formik.values]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (notInitialRender.current) {
        if (!loading) formik.handleSubmit();
      } else {
        notInitialRender.current = true;
      }
    }, 2000),
    []
  );
  // Auto save function ==========

  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[]}
        title="Education"
        process={1}
        icon={<StickyNote2OutlinedIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form
            style={{ maxWidth: '900px' }}
            onSubmit={(e) => {
              formik.handleSubmit(e);
            }}
          >
            <Row className="m-0">
              <div className="text-field-mid fs-3 mb-4">Future Plans</div>
              <div>
                Don't worry, this is not set in stone. They just want to know your future interest.
                College will not hold you to this.
              </div>

              <div className="row my-2">
                <GroupRadioButton
                  name="which_best_describe_you"
                  onChange={formik.handleChange}
                  values={formik.values.which_best_describe_you}
                  mdCol={12}
                  logo="span-cm-logo text-field-dark"
                  label="Which best describes you? I am: *"
                  options={describesOptions}
                  isMissing={!formik.values.which_best_describe_you && check}
                />
              </div>
            </Row>
            <Row>
              <SelectInput
                logo="span-cm-logo"
                form={formik}
                option={occupationOptions}
                name="career_interest"
                values={formik.values.career_interest}
                label="Career Interest *"
                isSatTest={true}
                onChange={formik.handleChange}
                isMissing={
                  (!formik.values.career_interest ||
                    formik.values.career_interest === 'Choose an option') &&
                  check
                }
              />
              {formik.values.career_interest === 'Other' && (
                <TextInput
                  countText={100}
                  values={formik.values.other_career_interest}
                  onChange={formik.handleChange}
                  label="Specify other organization *"
                  name="other_career_interest"
                />
              )}

              <SelectInput
                logo="span-cm-logo"
                form={formik}
                option={[
                  { value: `Associate's (AA,AS)` },
                  { value: `Bachelor's (BA,BS)` },
                  { value: `Master's (MA,MS)` },
                  { value: 'Business (MBA, MAcc)' },
                  { value: 'Law (JD, LLM)' },
                  { value: 'Medicine (MD,DO,DVM,DDS)' },
                  { value: 'Doctorate (PhD,EdD, etc)' },
                  { value: 'Other' },
                  { value: 'Undecided' },
                ]}
                name="highest_degree_intend_to_earn"
                values={formik.values.highest_degree_intend_to_earn}
                label="Highest Degree you intend to earn "
                isSatTest={true}
                onChange={formik.handleChange}
                isMissing={!formik.values.highest_degree_intend_to_earn && check}
              />
            </Row>
            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {highSchool.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default FuturePlan;
