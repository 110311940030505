/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { Button, Container, Row } from 'react-bootstrap';
import ItemContent, { CheckboxContent } from './components/ItemContent';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  getCurrentBasicDetail,
  getCitizenship,
  getContactDetail,
  getDemoGraphics,
} from 'app/basicInformationSlice/basicInformationSlice';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import LoadingContent from 'components/LoadingContent';
import EthnicitySum from './components/EthnicitySum';
import CheckboxInput from 'components/input/CheckboxInput';
import { militaryOptions } from 'screen/personal/demographics/Demographics';
import { checkEnableParseJSON, formatDate, renderDate } from 'components/helper/helper';

interface Props {}

export const buildSexValue = (dataValue: any, isPronouns?: boolean) => {
  if (!dataValue) return;
  const data = [
    { value: 'is_different_first_name_pronouns_as_he', label: 'He/Him' },
    { value: 'is_different_first_name_pronouns_as_she', label: 'She/Her' },
    { value: 'is_different_first_name_pronouns_as_they', label: 'They/Them' },
    {
      value: 'is_different_first_name_pronouns_as_other',
      label: 'Add another pronoun set',
    },
  ];
  const sexData = [
    {
      value: 'isFemale',
      label: 'Female',
    },
    {
      value: 'isMale',
      label: 'Male',
    },

    {
      value: 'isNonbinary',
      label: 'Nonbinary',
    },
    {
      value: 'isAnother',
      label: 'Another gender',
    },
  ];
  return isPronouns ? (
    <Row>
      {data.map((option, index) => {
        const keyName = option.value;
        return (
          <CheckboxContent key={index} col={12} label={option.label} values={dataValue[keyName]} />
        );
      })}

      {dataValue.is_different_first_name_pronouns_as_other && (
        <ItemContent
          mdCol={12}
          label="My pronouns*"
          values={dataValue.other_different_first_name_pronouns}
        />
      )}
    </Row>
  ) : (
    <Row>
      {sexData.map((option, index) => {
        const keyName = option?.value;
        return (
          <CheckboxContent key={index} col={12} label={option.label} values={dataValue[keyName]} />
        );
      })}

      {dataValue.isAnother && (
        <ItemContent mdCol={12} label="My gender*" values={dataValue.anotherGender} />
      )}
    </Row>
  );
};

export const capitalizedString = (str: string) => {
  const parseTrueFalse = str === 'true' ? 'Yes' : str === 'false' ? 'No' : str;
  return parseTrueFalse.charAt(0).toUpperCase() + parseTrueFalse.slice(1);
};

const PersonalSumRemoved = (props: Props) => {
  const [loading, setLoading] = React.useState(true);
  const personalStore = useAppSelector((store) => store.basicInformation);
  const dispatch = useAppDispatch();
  const history = useHistory();  
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);

  const personalData = {
    ...personalStore.myBasicInformation,
    demographics_additional_data: checkEnableParseJSON(
      personalStore.myBasicInformation?.demographics_additional_data
    ),
    csu_info: checkEnableParseJSON(personalStore.myBasicInformation?.csu_info),
  };
  const contactData = personalStore.myContactDetail;
  const demographicData = {
    ...personalStore.myDemographics,
    demographics_additional_data: checkEnableParseJSON(
      personalStore.myDemographics?.demographics_additional_data
    ),
  };
  const citizenshipData = {
    ...personalStore.myCitizenship,
    csu_info: checkEnableParseJSON(personalStore.myCitizenship?.csu_info),
    statement_legal_resident: checkEnableParseJSON(
      personalStore.myCitizenship?.statement_legal_resident
    ),
    indicator_economic_fee_waiver_array: checkEnableParseJSON(
      personalStore.myCitizenship?.indicator_economic_fee_waiver_array
    ),
  };


  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };  

  const getData = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      await Promise.all([
        dispatch(getCurrentBasicDetail(studentId)),
        dispatch(getDemoGraphics(studentId)),
        dispatch(getContactDetail(studentId)),
        dispatch(getCitizenship(studentId)),
      ]);
      setLoading(false);        
    }
    else {
      handleLogout();
    }
  };  

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar stepProcess={[]} title="Summary" process={1} icon={<NoteAddOutlinedIcon />} />
      <Container className="main-container">
        <div className="px-2">
          <Row className="m-0 p-3 w-100 bg-mid rounded">
            <div className="text-field-mid fs-3 mb-4">Personal Information</div>
            <div className="w-100 d-flex justify-content-between align-items-center">
              <div className="fs-5">Basic Information </div>
              <Button
                onClick={() => history.push(routers.basicInformation.path)}
                variant="outline-primary"
              >
                Edit
              </Button>
            </div>
            {personalData && (
              <>
                <ItemContent label="First/given name*" values={personalData?.first_name} />

                <ItemContent label="Middle name" values={personalData?.middle_name} />
                <ItemContent label="Last/family/surname*" values={personalData?.last_name} />
                <ItemContent label="Sufix" values={personalData?.label} />
                <ItemContent label="Email" values={personalData?.email} />
                <ItemContent
                  label="Email Address Type"
                  values={personalData?.csu_info?.emailType}
                />
                <ItemContent label="Date of Birth" values={personalData?.dob} />

                <ItemContent label="Prefered Name (Nickname)" values={personalData?.nick_name} />

                <ItemContent
                  label="Have you ever had a different legal name?"
                  values={personalData?.is_have_legal_name}
                  mdCol={6}
                />
                {personalData?.is_have_legal_name && (
                  <>
                    <ItemContent
                      label="Former First Name"
                      values={personalData?.legal_first_name}
                    />
                    <ItemContent
                      label="Former Middle Name"
                      values={personalData?.legal_middle_name}
                    />
                    <ItemContent
                      label="Former Last Name"
                      values={personalData?.legal_family_name}
                    />
                    <ItemContent
                      label="Former Suffix"
                      values={personalData?.legal_first_name_you_go_by}
                    />
                  </>
                )}
                <div className="border w-100 my-3" />
                <div className="fs-5 text-field-mid">Gender and Sexual Orientation</div>
                <ItemContent
                  mdCol={12}
                  label="Sex*"
                  values={buildSexValue(checkEnableParseJSON(personalData?.sex))}
                />
                <ItemContent
                  mdCol={12}
                  label="Pronouns"
                  values={buildSexValue(personalData, true)}
                />
                <ItemContent label="Legal sex" values={personalData?.csu_info?.legal_sex} />
                <ItemContent
                  label="How do you describe yourself?"
                  values={personalData?.sex_self_describe}
                />
                <ItemContent
                  label="What do you consider to be?"
                  values={personalData?.sex_self_consider}
                />
                {personalData?.sex_self_consider === 'Another Orientation' && (
                  <ItemContent
                    label="Please describe"
                    values={personalData?.sex_self_consider_describe}
                  />
                )}

                <ItemContent
                  values={personalData?.csu_info?.gender_identity}
                  label="How do you describe the way you express your gender identity in terms of behavior, appearance, speech, and movement?"
                />
                <ItemContent
                  hidden={personalData?.csu_info?.gender_identity !== 'None of the Above'}
                  label="Another identity expression (please specify)"
                  values={personalData?.csu_info?.another_gender_identity}
                />
                <div className="border w-100 my-3" />
                <div className="fs-5 text-field-mid">Address</div>
                <ItemContent
                  mdCol={6}
                  label={'Address Line 1 (including Apartment or Suite Number)*'}
                  values={personalData.address_line_1}
                />
                <ItemContent
                  mdCol={6}
                  label={'Address Line 2'}
                  values={personalData.address_line_2}
                />

                <ItemContent label="City" values={personalData?.city} />
                <ItemContent label="State" values={personalData?.state} />
                <ItemContent label="County*" values={personalData?.csu_info?.address_county} />
                <ItemContent label="Country" values={personalData?.country} />

                <ItemContent label="Zip Code" values={personalData?.zip_code} />
                <ItemContent
                  label="Approximate date through which current address is valid"
                  values={formatDate(personalData?.csu_info?.approximate_date)}
                />
                <div className="text-field-mid">
                  Is this your permanent address and where we should send mail?
                </div>
                <ItemContent
                  mdCol={12}
                  label="UC's need your current mailing address to send mail correspondences about the
                  admissions process"
                  values={personalData?.is_should_send_mail}
                />
                <div className="text-field-mid">Do you have an alternate mailing address? </div>
                <ItemContent
                  mdCol={12}
                  label=" If you attend a boarding school or use a different mailing address"
                  values={personalData?.is_have_alternate_address}
                />
                <ItemContent
                  label="Is this a temporary address?"
                  values={personalData?.is_temporary_address}
                />
                {/* {personalData?.is_have_alternate_address === 'true' && ( */}
                {/* <> */}
                <div>Mailing Address</div>

                <ItemContent
                  label={'Address Line 1 (including Apartment or Suite Number)'}
                  values={personalData?.alternate_address_line_1 ?? personalData.address_line_1}
                />
                <ItemContent
                  label={'Address Line 2'}
                  values={personalData?.alternate_address_line_2 ?? personalData.address_line_2}
                />
                <ItemContent
                  label={'City'}
                  values={personalData?.alternate_city ?? personalData?.city}
                />
                <ItemContent
                  label={'State'}
                  values={personalData?.alternate_state ?? personalData?.state}
                />
                <ItemContent
                  label={'County*'}
                  values={personalData?.csu_info?.mailing_address_county ?? personalData?.country}
                />
                <ItemContent
                  label={'Zip Code'}
                  values={personalData?.alternate_zip_code ?? personalData?.zip_code}
                />
                <ItemContent
                  label="Is this a temporary address?"
                  mdCol={12}
                  values={personalData?.is_temporary_address}
                />

                {personalData?.is_temporary_address === 'true' && (
                  <>
                    <ItemContent label="From date" values={personalData?.temporary_from_date} />

                    <ItemContent label="To date" values={personalData?.temporary_to_date} />
                  </>
                )}
                {/* </> */}
                {/* )} */}
              </>
            )}
            <div className="rich-border w-100 my-3" />
            <div className="w-100 d-flex justify-content-between align-items-center">
              <div className="fs-5">Contact Information</div>
              <Button
                onClick={() => history.push(routers.contactDetail.path)}
                variant="outline-primary"
              >
                Edit
              </Button>
            </div>
            {contactData && (
              <>
                <ItemContent label="Main Phone Number" values={contactData?.phone_number} />
                <ItemContent label="Type of Number" values={contactData?.phone_number_type} />
                <ItemContent
                  label="Secondary Phone Number"
                  values={contactData?.alternative_phone_number}
                />
                <ItemContent
                  label="Type of Number"
                  values={contactData?.alternative_phone_number_type}
                />
                <ItemContent
                  label="Can we share your contact information with UC campus and programs?"
                  values={contactData?.is_allowed_share_contact}
                  mdCol={12}
                />
              </>
            )}
            <div className="rich-border w-100 my-3" />
            <div className="w-100 d-flex justify-content-between align-items-center">
              <div className="fs-5">Demographics</div>
              <Button
                onClick={() => history.push(routers.demographics.path)}
                variant="outline-primary"
              >
                Edit
              </Button>
            </div>
            {demographicData && (
              <>
                <div>Check all that apply to you: *</div>
                {militaryOptions.map((option, index) => {
                  const keyName = option.value;
                  return (
                    <CheckboxInput
                      className="mx-4"
                      readOnly
                      key={index}
                      label={<span className="mt-1 text-field-mid">{option.label}</span>}
                      name={option.value}
                      values={checkEnableParseJSON(demographicData?.all_apply_array, {})[keyName]}
                    />
                  );
                })}
                <ItemContent
                  label="Anticipated status at the time you enroll"
                  mdCol={12}
                  values={demographicData.anticipated_status}
                />
                {demographicData.anticipated_status === 'On Active Duty' && (
                  <ItemContent
                    label="Are you permanently stationed in California? *"
                    values={demographicData.demographics_additional_data?.isPermanentlyStationed}
                    mdCol={12}
                  />
                )}
                {/* <ItemContent
                  label=""
                  values={demographicData?.demographics_additional_data?.usMilitaryStatus}
                  mdCol={12}
                />

                <ItemContent
                  hidden={
                    demographicData?.demographics_additional_data?.usMilitaryStatus !==
                    'On active duty'
                  }
                  label="Are you permanently stationed in California? *"
                  values={demographicData?.demographics_additional_data?.isPermanentlyStationed}
                  mdCol={12}
                /> */}
                {demographicData.is_foster_care && (
                  <ItemContent
                    label="Are you a ward of the court or in foster care in California? *"
                    values={demographicData?.demographics_additional_data?.isInFosterCare}
                    mdCol={12}
                  />
                )}

                <div className="fs-5 text-field-mid">Military Affilliation</div>
                <ItemContent
                  label="U.S. Armed Forces Status"
                  values={demographicData?.us_armed_forces_status}
                  mdCol={6}
                />
                <ItemContent
                  label="Are you a dependent of a U.S. military veteran or service member?"
                  values={demographicData?.is_dependent_us_military}
                  mdCol={6}
                />

                <ItemContent
                  label="Anticipated status at the time you enroll"
                  values={demographicData?.anticipated_status}
                  mdCol={12}
                />
                <div className="fs-5 text-field-mid">Military experience</div>
                <ItemContent
                  label="Number of Military experience you have"
                  values={demographicData?.number_military_experience}
                  mdCol={12}
                />
                {(checkEnableParseJSON(demographicData?.military_experience_array) || []).map(
                  (item: any, index: number) => (
                    <ItemContent
                      key={index}
                      label="Branch"
                      values={
                        item?.branch +
                        ' (' +
                        renderDate(item.startDate) +
                        ' - ' +
                        renderDate(item.endDate) +
                        ')'
                      }
                      mdCol={12}
                    />
                  )
                )}
                {demographicData?.us_armed_forces_status === 'Current Dependent' && (
                  <>
                    <ItemContent
                      label="What is the status of the person you are a dependent of who is currently serving or has previously served?"
                      values={demographicData?.statusOfPersonYouAreDependance}
                    />
                    <ItemContent
                      label="Is the U.S. military service member on active duty? *"
                      values={
                        demographicData?.demographics_additional_data
                          ?.is_US_military_service_on_active_duty
                      }
                    />
                    <ItemContent
                      label="Is the U.S. military service member permanently stationed in California? *"
                      values={
                        demographicData?.demographics_additional_data
                          ?.is_US_military_service_permanently_stationed_in_California
                      }
                    />
                    <CheckboxContent
                      values={
                        demographicData?.demographics_additional_data
                          ?.is_plan_to_submit_us_military_transcript
                      }
                      label="Check if you plan to submit an official U.S. military transcript (AARTS, SMART, JST or CGI)?"
                    />
                  </>
                )}
                <div className="border w-100 my-3" />
                <div className="fs-5 text-field-mid">Ethnicity</div>
                <ItemContent
                  mdCol={12}
                  label="Do you consider yourself Hispanic or Latino?"
                  values={demographicData?.is_consider_hispanic_latino}
                />

                <EthnicitySum formValues={demographicData} />
                <div className="border w-100 my-3" />
                <div className="fs-5 text-field-mid">Language</div>
                <ItemContent label="Language" values={demographicData?.language} mdCol={6} />
                <ItemContent
                  label="Specify other language"
                  values={demographicData?.other_religion_language}
                  mdCol={6}
                />
                <ItemContent
                  label="Number of languages you are proficient in*"
                  values={demographicData?.number_language_proficient}
                  mdCol={6}
                />
                <ItemContent
                  label=""
                  values={checkEnableParseJSON(demographicData?.language_array, []).map(
                    (i: any) => i.language
                  )}
                  mdCol={6}
                />
              </>
            )}
            <div className="rich-border w-100 my-3" />
            <div className="w-100 d-flex justify-content-between align-items-center">
              <div className="fs-5">Citizenship</div>
              <Button
                onClick={() => history.push(routers.citizenship.path)}
                variant="outline-primary"
              >
                Edit
              </Button>
            </div>
            {citizenshipData && (
              <>
                <ItemContent
                  label="Citizenship status"
                  values={citizenshipData?.current_citizenship_status}
                  mdCol={6}
                />
                <ItemContent
                  label="Is U.S. Dual Citizen?"
                  values={citizenshipData?.is_dual_citizenship}
                  mdCol={6}
                />
                <ItemContent
                  label="List Citizenship(s)"
                  values={(checkEnableParseJSON(citizenshipData?.list_citizenship) || []).map(
                    (item: any) => item
                  )}
                  mdCol={12}
                />

                <ItemContent label="Country" values={citizenshipData?.country} mdCol={6} />
                {citizenshipData?.country !== 'United States of America' && (
                  <>
                    <ItemContent
                      label="What will your citizenship status be on the date you submit this application? *"
                      values={citizenshipData?.citizenship_status}
                      mdCol={6}
                    />
                    {citizenshipData?.citizenship_status ===
                      'Legal U.S. permanent resident (green card)' && (
                      <>
                        <ItemContent
                          label="Country of Citizenship"
                          values={citizenshipData?.csu_info?.country}
                        />
                        <ItemContent
                          label="Permanent Resident / Green Card Date Issued*"
                          values={formatDate(citizenshipData?.csu_info?.date)}
                        />
                      </>
                    )}
                    {citizenshipData?.citizenship_status === 'Refugee' && (
                      <ItemContent
                        label="Country of Citizenship*"
                        values={citizenshipData?.csu_info?.country}
                      />
                    )}
                    {citizenshipData?.citizenship_status ===
                      'Non-immigrant/foreign visa (you have a visa or will be applying for one)' && (
                      <>
                        <ItemContent
                          values={citizenshipData?.have_us_visa}
                          label="Do you currently have a U.S. visa? *"
                        />
                        <div />
                        {citizenshipData?.have_us_visa === 'false' && (
                          <>
                            <ItemContent
                              label="Which type of U.S. visa will you apply for (or have applied for)? *"
                              values={citizenshipData?.type_of_apply_for_us_visa}
                            />
                            <ItemContent
                              label="Country of Citizenship"
                              values={citizenshipData?.csu_info?.country}
                            />
                          </>
                        )}
                        {citizenshipData?.have_us_visa === 'true' && (
                          <>
                            <ItemContent
                              label="Current visa type *"
                              values={citizenshipData?.type_of_current_us_visa}
                            />

                            <ItemContent
                              values={citizenshipData?.csu_info?.require_F1_Visa_or_J1_visa}
                              label="Do you have or will you require an F1 Visa (student) or J1 Visa (exchange) to study at the California State University (CSU)?*"
                            />

                            {citizenshipData?.csu_info?.require_F1_Visa_or_J1_visa === 'true' ? (
                              <>
                                <ItemContent
                                  values={citizenshipData?.csu_info?.visa_you_have_at_CSU}
                                  label="Which student visa do you have, or will you have when you study at the CSU?*"
                                />
                                <ItemContent
                                  label="Date Issued (if you have already been issued a student visa)"
                                  values={formatDate(
                                    citizenshipData?.csu_info?.visa_you_have_at_CSU_date_issued
                                  )}
                                />
                              </>
                            ) : (
                              <ItemContent
                                label="What U.S. State/Possession do you regard as your permanent home?*"
                                values={
                                  citizenshipData?.csu_info?.us_state_you_regard_as_permanent_home
                                }
                              />
                            )}
                          </>
                        )}
                        <div />
                        <ItemContent
                          label="Visa Date Issued"
                          values={formatDate(citizenshipData?.csu_info?.visa_date_issued)}
                        />

                        <ItemContent
                          values={citizenshipData?.will_you_have_different_visa}
                          label="By the time you attend UC, will you have a different visa?"
                        />
                        {citizenshipData?.will_you_have_different_visa === 'true' && (
                          <ItemContent
                            label="Planned visa type *"
                            values={citizenshipData?.planned_visa_type}
                          />
                        )}
                      </>
                    )}
                  </>
                )}

                <div className="text-field-mid fw-bold ">International Agent</div>
                <ItemContent
                  label="Are you currently working with a representative or agency?*"
                  values={citizenshipData?.csu_info?.current_working_with_representative_or_agency}
                />
                {citizenshipData?.csu_info?.current_working_with_representative_or_agency ===
                  'true' && (
                  <>
                    <ItemContent
                      label="Representative Type*"
                      values={citizenshipData?.csu_info?.representative_type}
                    />
                    <ItemContent
                      label="Representative Name*"
                      values={citizenshipData?.csu_info?.representative_name}
                    />
                    <ItemContent
                      label="Representative Street Address"
                      values={citizenshipData?.csu_info?.representative_street_address}
                    />
                    <ItemContent
                      label="Representative Street Address Line 2"
                      values={citizenshipData?.csu_info?.representative_street_address_line_2}
                    />
                    <ItemContent
                      label="Representative City"
                      values={citizenshipData?.csu_info?.representative_city}
                    />
                    <ItemContent
                      label="Representative State/Province"
                      values={citizenshipData?.csu_info?.representative_state}
                    />
                    <ItemContent
                      label="Representative Postal Code"
                      values={citizenshipData?.csu_info?.representative_postal_code}
                    />
                    <ItemContent
                      label="Representative Country*"
                      values={citizenshipData?.csu_info?.representative_country}
                    />
                    <ItemContent
                      label="Representative Phone*"
                      values={citizenshipData?.csu_info?.representative_phone}
                    />
                    <ItemContent
                      label="Representative Email*"
                      values={citizenshipData?.csu_info?.representative_email}
                    />
                  </>
                )}

                <ItemContent
                  label="What year did you (or do you plan to) move to the U.S.?*"
                  values={citizenshipData?.csu_info?.year_move_to_US}
                />
                <ItemContent
                  mdCol={12}
                  label={`If you apply for financial aid, we use the Social Security number or ITIN you provide to match your application to your Free Application for Federal Student Aid (FAFSA) or California Dream Act application. See 'Help' for more information on the additional uses of the Social Security number.`}
                  values={citizenshipData?.is_have_us_social_security_number}
                />

                <ItemContent
                  label="By the time you attend UC, will you have graduated from a California high school?*"
                  values={citizenshipData?.is_graduated_california_high_school}
                />
                <ItemContent
                  label="Are you currently living in California?"
                  values={citizenshipData?.is_currently_live_california}
                  mdCol={6}
                />
                {citizenshipData?.is_currently_live_california === 'true' && (
                  <>
                    <ItemContent
                      label="Have you lived in California your entire life?"
                      values={citizenshipData?.is_live_california_entire_life}
                      mdCol={6}
                    />
                    <ItemContent label="Month" values={citizenshipData?.month_live_here_since} />
                    <ItemContent label="Year" values={citizenshipData?.year_live_here_since} />
                    <ItemContent
                      label="Is one of your parents or legal guardians a permanent resident of California?"
                      values={citizenshipData?.is_one_of_parent_permanent_resident_california}
                    />
                    <div className="text-field-mid"></div>
                    <ItemContent
                      label="Is your parent, legal guardian, spouse or registered domestic partner an employee of the University of California or a UC-affiliated national laboratory (Los Alamos National Security LLC or Lawrence Livermore National Security LLC)?*"
                      values={citizenshipData?.is_parent_employee_university_california}
                    />
                    {citizenshipData?.is_one_of_parent_permanent_resident_california === 'true' && (
                      <>
                        <div className="text-field-mid">
                          When did your parent or legal guardian become a permanent resident of
                          California? *
                        </div>
                        <ItemContent
                          values={citizenshipData?.month_parent_become_permanent_residence}
                          label="Month"
                        />
                        <ItemContent
                          label="Year"
                          values={citizenshipData?.year_parent_become_permanent_residence}
                        />
                      </>
                    )}
                    {citizenshipData?.is_one_of_parent_permanent_resident_california ===
                      'false' && (
                      <>
                        <ItemContent
                          label="Which U.S State/possession does your parent/guardian regard as her permanent home ?"
                          values={
                            citizenshipData?.csu_info?.state_your_parent_as_her_permanent_home
                          }
                        />
                      </>
                    )}
                  </>
                )}

                <ItemContent
                  label="Country / Region / Territory"
                  values={citizenshipData?.country_birthplace}
                />

                {citizenshipData?.country_birthplace !== 'United States of America' && (
                  <ItemContent
                    label="If you were born outside of the U.S., what year did you or will you move to the U.S."
                    values={citizenshipData?.csu_info?.year_move_to_us}
                  />
                )}

                <ItemContent label="City of birth*" values={citizenshipData?.city_birthplace} />
                <ItemContent label="State" values={citizenshipData?.state_birthplace} />
                <ItemContent label="When did you move to California?" values={'-'}/>

                <ItemContent
                  label="Number of years you have lived in the United States*"
                  values={citizenshipData?.number_of_year_in_us}
                />
                <ItemContent
                  label="Number of years you have lived outside the United States*"
                  values={citizenshipData?.number_of_year_outside_us}
                />
                <ItemContent
                  label="By the time you attend UC, will you have graduated from a California high school?"
                  values={citizenshipData?.is_graduated_california_high_school}
                  mdCol={6}
                />
                <ItemContent
                  label="By the time you attend UC, will you have attended a California school for three or more years during grades K through 12?"
                  values={
                    citizenshipData?.is_three_or_more_year_graduated_california_high_school
                      ? 'Yes'
                      : 'No'
                  }
                  mdCol={6}
                />
                <ItemContent
                  label="What is your current enrollment status?*"
                  values={citizenshipData?.current_enrollment_status}
                />
                <ItemContent
                  label="When will you graduate from high school or receive high school equivalency? *"
                  values={formatDate(citizenshipData?.when_graduate_high_school)}
                />
                <ItemContent
                  label="Year entering college "
                  values={citizenshipData?.year_plan_college_studies}
                />
                <ItemContent
                  label="What degree, credential or certificate are you applying for?"
                  values={citizenshipData?.csu_info?.degree_applying_for}
                />

                {citizenshipData?.csu_info?.degree_applying_for ===
                  `First Bachelor's Degree (Seeking your first bachelor's degree and have not earned a prior bachelor's degree)` && (
                  <>
                    <ItemContent
                      label="Which of the following best describes your current educational status?"
                      values={citizenshipData?.csu_info?.educational_status}
                    />
                    {citizenshipData?.csu_info?.educational_status ===
                    'Graduating High School Senior or equivalent (with or without college coursework prior to High School graduation)' ? (
                      <ItemContent
                        values={citizenshipData?.csu_info?.is_take_college_credit}
                        label="Have you taken and earned college credits through a community college or university?"
                      />
                    ) : null}
                  </>
                )}

                <ItemContent
                  values={
                    citizenshipData?.statement_legal_resident
                      ?.is_like_to_be_evaluated_for_tuition_purposes
                  }
                  label="Would you like to be evaluated for California residency for tuition purposes? *"
                />
                {citizenshipData?.statement_legal_resident
                  ?.is_like_to_be_evaluated_for_tuition_purposes === 'true' && (
                  <>
                    <ItemContent
                      values={
                        citizenshipData?.statement_legal_resident?.is_physically_present_for_3_years
                      }
                      label="Have you been physically present in California for the last 3 years? *"
                    />

                    <ItemContent
                      values={
                        citizenshipData?.statement_legal_resident
                          ?.is_attend_highschool_for_3_more_years
                      }
                      label="Did you attend high school in California for three or more years, and will you graduate or have you graduated from a high school in California? *"
                    />
                    <ItemContent
                      values={
                        citizenshipData?.statement_legal_resident?.is_established_as_permanent_home
                      }
                      label="Have you and your parents established California as your permanent home for the past 366 days or more? *"
                    />
                    <ItemContent
                      values={
                        citizenshipData?.statement_legal_resident?.is_parent_1_physically_present
                      }
                      label="Has Parent 1 been physically present in California for the last 3 years? *"
                    />

                    {citizenshipData?.statement_legal_resident?.is_parent_1_physically_present &&
                      citizenshipData?.statement_legal_resident?.is_parent_1_physically_present !==
                        'My Parent is Deceased' && (
                        <ItemContent
                          values={citizenshipData?.statement_legal_resident?.is_parent_1_US_citizen}
                          label="Is Parent 1 a U.S. Citizen? *"
                        />
                      )}
                    <div />
                    <ItemContent
                      values={
                        citizenshipData?.statement_legal_resident?.is_parent_2_physically_present
                      }
                      label="Has Parent 2 been physically present in California for the last 3 years? *"
                    />

                    {citizenshipData?.statement_legal_resident?.is_parent_2_physically_present &&
                      citizenshipData?.statement_legal_resident?.is_parent_2_physically_present !==
                        'My Parent is Deceased' && (
                        <ItemContent
                          values={citizenshipData?.statement_legal_resident?.is_parent_2_US_citizen}
                          label="Is Parent 2 a U.S. Citizen? *"
                        />
                      )}
                    <CheckboxContent
                      col={12}
                      values={
                        citizenshipData?.statement_legal_resident
                          ?.is_declare_under_penalty_of_perjury
                      }
                      label="I declare under penalty of perjury under the laws governing the State of California that the statements I have provided on this Statement of Legal Residence are, and each of them is, true and correct."
                    />
                    <CheckboxContent
                      col={12}
                      values={
                        citizenshipData?.statement_legal_resident
                          ?.is_understood_to_be_asked_submit_document
                      }
                      label="I understand that as part of the process to issue a Residency Classification, I may be asked to submit additional documentation or information to a University of California Residence Deputy to supplement my Statement of Legal Residence. Failure to provide the requested information in a timely manner can affect my residence classification. Further, I understand that completion of the Statement of Legal Residence does not guarantee a Resident Classification for UC tuition."
                    />
                  </>
                )}

                <ItemContent
                  label="Are you participating in a Community-Based Organization (CBO)?"
                  values={citizenshipData?.is_participate_cbo}
                  mdCol={6}
                />
                <div className="rich-border w-100 my-3" />

                <div className="w-100 d-flex justify-content-between align-items-center">
                  <div className="fs-5">CBOs & Fee Waiver</div>
                  <Button onClick={() => history.push(routers.cbo.path)} variant="outline-primary">
                    Edit
                  </Button>
                </div>

                <ItemContent
                  label="Your CBOs"
                  values={(checkEnableParseJSON(citizenshipData?.cbo_info_array) || []).map(
                    (e: any) => e?.organization ?? e?.otherOrganization
                  )}
                  mdCol={12}
                />
                <div className="border w-100 my-3" />
                {/* DISABLE BY USERSNAP #763 */}
                {/* <div className="fs-5 text-field-mid">Counselor / Advisor / Mentor</div>

                <ItemContent label="Prefix" values={citizenshipData?.advisor_prefix} />
                <ItemContent label="First Name" values={citizenshipData?.advisor_first_name} />
                <ItemContent label="Last Name" values={citizenshipData?.advisor_last_name} />
                <ItemContent label="Phone Number" values={citizenshipData?.advisor_phone_number} />
                <ItemContent label="Email" values={citizenshipData?.advisor_email} />
                <div className="border w-100 my-3" /> */}
                <div className="fs-5 text-field-mid">Fee Waiver</div>
                <ItemContent
                  mdCol={12}
                  label="Do you meet one or more of the Common App fee waiver, Opens in new tab, eligibility criteria?*"
                  values={citizenshipData?.is_financial_qualify_fee_waiver}
                />

                {citizenshipData?.is_financial_qualify_fee_waiver === 'true' && (
                  <>
                    <ItemContent
                      label="Your  Fee Waiver Signature"
                      values={citizenshipData?.fee_waiver_signature}
                    />
                  </>
                )}
                <ItemContent
                  label="Would you like to connect with a UStrive mentor?"
                  values={
                    citizenshipData?.indicator_economic_fee_waiver_array
                      ?.is_like_to_receive_information_from_strive_for_college
                  }
                />
              </>
            )}
            <div className="rich-border w-100 my-3" />

            <div className="w-100 d-flex justify-content-between align-items-center">
              <div className="fs-5">Other Information</div>
              <Button
                onClick={() => history.push(routers.otherInformation.path)}
                variant="outline-primary"
              >
                Edit
              </Button>
            </div>

            <CheckboxContent
              col={12}
              label={
                'Your certification of this statement serves the same purpose as a legal signature, and is binding.'
              }
              values={citizenshipData?.csu_info?.is_certify}
            />
            <ItemContent
              mdCol={12}
              values={citizenshipData?.csu_info?.hereby_authorize_CD_release_CAASPP}
              label="I hereby authorize the California Department of Education to release my California Assessment of Student Performance and Progress (CAASPP) score report to the California State University for the purpose of credit, placement, determination of readiness for college-level coursework, or admission."
            />
            <CheckboxContent
              col={12}
              label="Check box to Acknowledge"
              values={citizenshipData?.csu_info?.is_authorize_CSU_release_contact_information}
            />
            <ItemContent
              mdCol={12}
              values={citizenshipData?.csu_info?.is_interested_on_campus_space_available}
              label="Are you interested in on campus housing should space be available? Note: housing availability is subject to your submission of a separate campus housing application and availability."
            />
            <ItemContent
              mdCol={12}
              values={citizenshipData?.csu_info?.in_foster_care}
              label="At any time since you turned age 13, were both your parents deceased, were you in foster care or were you a dependent or ward of the court?"
            />
            <ItemContent
              mdCol={12}
              values={citizenshipData?.csu_info?.is_emancipated_minor}
              label="As determined by a court in your state of legal residence, are you or were you an emancipated minor?"
            />
            <ItemContent
              mdCol={12}
              values={citizenshipData?.csu_info?.is_homeless_liaison_determine}
              label="At any time on or after July 1, 2023, did your high school or school district homeless liaison determine that you were an unaccompanied youth who was homeless or were self-supporting and at risk of being homeless?"
            />
            <ItemContent
              mdCol={12}
              values={citizenshipData?.csu_info?.is_director_or_transitional_determine}
              label="At any time on or after July 1, 2023, did the director of an emergency shelter or transitional housing program funded by the U.S. Department of Housing and Urban Development determine that you were an unaccompanied youth who was homeless or were self-supporting and at risk of being homeless?"
            />
            <ItemContent
              mdCol={12}
              values={citizenshipData?.csu_info?.is_director_of_runaway_or_homeless_youth_basic}
              label="At any time on or after July 1, 2023, did the director of a runaway or homeless youth basic center or transitional living program determine that you were an unaccompanied youth who was homeless or were self-supporting and at risk of being homeless?"
            />
            <ItemContent
              mdCol={12}
              label="Have you ever attended a California public school?"
              values={citizenshipData?.csu_info?.attended_California_public_school}
            />
            {citizenshipData?.csu_info?.attended_California_public_school === 'true' && (
              <>
                <ItemContent
                  mdCol={12}
                  values={citizenshipData?.csu_info?.ssid}
                  label="California Statewide Student Identifier (SSID)"
                />

                <CheckboxContent
                  col={12}
                  values={
                    citizenshipData?.csu_info?.authorize_release_CASSID_for_tracking_UC_application
                  }
                  label="I authorize the release of my CA SSID Number and application status to California Guidance College Initiative (CCGI) for the purpose of tracking my UC application. I understand that the information will only be shared if my school has provided my transcript to CCGI."
                />
              </>
            )}
            <ItemContent
              mdCol={12}
              label="Are you in good standing with your last school attended?"
              values={citizenshipData?.csu_info?.is_in_good_standing}
            />
            <ItemContent
              mdCol={12}
              label="Have you ever been disciplined for academic performance (e.g. academic probation, disqualification, etc.) by any college or university?"
              values={citizenshipData?.csu_info?.is_disciplined_for_academic_performance}
            />
            <ItemContent
              mdCol={12}
              label="Have you been academically disqualified at any college or university?"
              values={citizenshipData?.csu_info?.is_academically_disqualified}
            />
            <ItemContent
              mdCol={12}
              label="Have you ever been disciplined for student conduct violations (e.g. suspension, expulsion, etc.) by any college or university?"
              values={citizenshipData?.csu_info?.is_disciplined_for_student_conduct_violations}
            />
            {citizenshipData?.csu_info?.is_disciplined_for_student_conduct_violations ===
              'true' && (
              <ItemContent
                mdCol={12}
                values={citizenshipData?.csu_info?.explain_for_each_violation}
                label="If yes, please explain and disclose institution for each violation."
              />
            )}
            <ItemContent
              mdCol={12}
              values={citizenshipData?.csu_info?.interest_in_teacher_or_educational_credential}
              label="Select your interest in obtaining your teacher or other educational credential."
            />
            <h6>CalFresh</h6>
            <ItemContent
              mdCol={12}
              label="Are you interested in checking your eligibility for monthly food assistance to be used towards groceries through the CalFresh Program?"
              values={citizenshipData?.csu_info?.is_interested_eligibility_assistance_CalFresh}
            />

            <ItemContent
              mdCol={12}
              label="Are you interested in participating in the CA Promise?"
              values={citizenshipData?.csu_info?.is_interested_participating_CA_Promise}
            />
            <ItemContent
              mdCol={12}
              values={citizenshipData?.csu_info?.how_hear_about_CalState}
              label="How did you hear about CalState.edu/apply?"
            />
          </Row>

          <div className="m-3 d-flex justify-content-end border-top pt-3">
            <div className="col col-md-3">
              <Button
                variant="primary"
                onClick={() => history.push(routers.familySum.path)}
                className="p-2 px-5 w-100"
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default PersonalSumRemoved;
