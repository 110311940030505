import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Row, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import TextInput from 'components/input/textInput';
import TextAreaInput from 'components/input/TextAreaInput';
import GroupCheckbox from 'components/input/GroupCheckbox';
import GroupRadioButton from 'components/input/GroupRadioButton';
import { useLocation } from 'react-router-dom';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Props {
  handleSave: (value: any) => void;
  item: any;
  indexNumber: String;
  expandAll?: boolean;
  handleExpandActive: (value: any) => void;
  indexExpand?: any;
  check?: any;
}
const HonorsConfig = (props: Props) => {
  const { check } = props;

  const formItem = useFormik({
    initialValues: {
      typeOfHonor: 'Academic',
      honorTitle: '',
      actionToAchieveHonor: '',
      eligibilityRequirementsHonor: '',
      isSchoolLevelRecognition: false,
      isNational: false,
      isStateLevelRecognition: false,
      isInternationalLevelRecognition: false,
      isGradeNinthLevel: false,
      isGradeTenthLevel: false,
      isGradeEleventhLevel: false,
      isGradeTwelvethLevel: false,
      isPostGraduateLevel: false,
      isIncludeIntoCommonApp: false,
      isIncludeIntoUCApp: false,
      isIncludeIntoCSUApp: false,
    },
    onSubmit: (values) => { },
  });
  const renderFillAppStatus = () => {
    return (
      <span style={{ float: 'right' }}>
        {formItem.values.isIncludeIntoCommonApp && (
          <img
            alt="cm"
            src="/assets/icons/icon-cm-5.svg"
            style={{ marginTop: '-8px', marginLeft: '4px' }}
          />
        )}
        {formItem.values.isIncludeIntoUCApp && (
          <img
            alt="uc"
            src="/assets/icons/icon-uc-5.svg"
            style={{ marginTop: '-8px', marginLeft: '4px' }}
          />
        )}
        {formItem.values.isIncludeIntoCSUApp && (
          <img
            alt="csu"
            src="./assets/icons/icon-csu-5.svg"
            style={{ marginTop: '-8px', marginLeft: '4px' }}
          />
        )}
      </span>
    );
  };
  const [expanded, setExpanded] = React.useState(props.expandAll || false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
    props.handleExpandActive(expanded);
  };
  React.useEffect(() => {
    if (check && props.indexExpand === props.indexNumber) {
      setExpanded(true);
    } else {
      setExpanded(props.expandAll || false);
    }
    if (props.item?.honorTitle) {
      formItem.setValues({ ...formItem.values, ...props.item });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.expandAll]);
  React.useEffect(() => {
    if (formItem.dirty) {
      props.handleSave({ ...formItem.values });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formItem.values]);
  return (
    <div className="m-0">
      <Card className="w-100 my-3 px-0">
        <CardActions
          disableSpacing
          onClick={handleExpandClick}
          sx={{
            backgroundColor: expanded ? '#AAEBF7' : 'none',
            borderBottom: expanded ? '2px solid #4D88F0' : 'none',
          }}
        >
          <div className="w-100 d-flex justify-content-between">
            <span className="p-1 w-100">
              {formItem.values.honorTitle} {renderFillAppStatus()}
            </span>
            <span className="p-1 text-field-light fw-bold">{props.indexNumber}</span>
          </div>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Row className="px-3">
              <TextInput
                label="What's the name of the award or honor? *"
                name="honorTitle"
                values={formItem.values.honorTitle}
                onChange={formItem.handleChange}
                countText={60}
                mdCol={'12'}
                isMissing={!formItem.values.honorTitle && check}
              />

              <div className="text-field-light">Level(s) of recognition</div>
              <GroupCheckbox
                form={formItem}
                mdCol={2}
                options={[
                  {
                    value: 'isSchoolLevelRecognition',
                    label: 'School',
                  },
                  {
                    value: 'isStateLevelRecognition',
                    label: 'State/Regional',
                  },
                  {
                    value: 'isNational',
                    label: 'National',
                  },
                  {
                    value: 'isInternationalLevelRecognition',
                    label: 'International',
                  },
                ]}
              />
              <GroupCheckbox
                label="Grade Level"
                form={formItem}
                mdCol={2}
                options={[
                  {
                    value: 'isGradeNinthLevel',
                    label: '9',
                  },
                  {
                    value: 'isGradeTenthLevel',
                    label: '10',
                  },
                  {
                    value: 'isGradeEleventhLevel',
                    label: '11',
                  },
                  {
                    value: 'isGradeTwelvethLevel',
                    label: '12',
                  },
                  {
                    value: 'isPostGraduateLevel',
                    label: 'Post-graduate (can select mulitple)',
                    mdCol: 12,
                  },
                ]}
              />
              <GroupRadioButton
                className="uc-flag"
                options={['Academic', 'Non-academic']}
                label="Type of award or honor *"
                values={formItem.values.typeOfHonor}
                name="typeOfHonor"
                onChange={formItem.handleChange}
                isMissing={!formItem.values.typeOfHonor && check}
              />
              {/* <SelectInput
                className="uc-flag"
                form={formItem}
                option={optionFromArray(['Academic', 'Non-academic'])}
                label="Type of award or honor *"
                values={formItem.values.typeOfHonor}
                name="typeOfHonor"
                onChange={formItem.handleChange}
              /> */}
              <TextAreaInput
                logo="span-uc-logo"
                mdCol="12"
                label="What are the eligibility requirements for this award or honor? *"
                isCMLogo={true}
                name="eligibilityRequirementsHonor"
                values={formItem.values.eligibilityRequirementsHonor}
                onChange={formItem.handleChange}
                countText={250}
                isOverCount={true}
                placeholder="For example: How are award recipients chosen? How many people are selected to
                  receive the award? Is there an application or nomination for the award?"
                isMissing={!formItem.values.eligibilityRequirementsHonor && check}
              />
              <TextAreaInput
                logo="span-uc-logo"
                mdCol="12"
                label="What did you do to achieve this award or honor? *"
                isCMLogo={true}
                name="actionToAchieveHonor"
                values={formItem.values.actionToAchieveHonor}
                onChange={formItem.handleChange}
                isOverCount={true}
                countText={350}
                placeholder="We'd like to understand what it took - on your part - to achieve this award. For instance: Were there multiple competitions that you had to participate in? How much time did you dedicate to winning this award?"
                isMissing={!formItem.values.actionToAchieveHonor && check}
              />
              <GroupCheckbox
                label="Include honor or award into these applications"
                form={formItem}
                tooltips="Common app is asking for honors related to your academic achievement, not all honors or awards"
                mdCol={3}
                options={[
                  {
                    value: 'isIncludeIntoCommonApp',
                    label: 'Common App',
                  },
                  {
                    value: 'isIncludeIntoUCApp',
                    label: 'UC App',
                  },
                  // {
                  //   value: 'isIncludeIntoCSUApp',
                  //   label: 'CSU App',
                  // },
                ]}
              />
              <Col xs={6} md={9} />
              <Col xs={6} md={3} className="p-3">
                <Button
                  className="w-100"
                  onClick={() => {
                    props.handleSave({
                      ...formItem.values,
                    });
                    setExpanded(false);
                  }}
                >
                  Save Info
                </Button>
              </Col>
            </Row>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
};

export default HonorsConfig;
