export interface FormCourse {
  index: number;
  lastIndex: number | null | undefined;
  CNGradingScale: String;
  grade: number[];
  finalGrade: String;
  credits: number[];
  finalCredits: String;
  checkedNA: false;
  otherGradeScale: String;
  highestGrade: String;
  lowestGrade: String;
  subject: String;
  courseName: String;
  class: String;
  otherClass: String;
  typeOfClass: String;
  isTakeClassAtCollege: boolean;
  collegeTookClass: String;
  schedule_transcript: String;
  isFirstSemester: boolean;
  isSecondSemester: boolean;
  isThirdSemester: boolean;
  isFourthSemester: boolean;
  isTakeIn678Grade: boolean;
  isUsedBlockScheduling: boolean;
  classOccurredDuringTime: String; // x
  classOccurredDuringGrade: String; //x
  schoolName: String; //x
  schoolYear: String; //x
  schoolCode: String;
  CMGradingScale: String; //x
  schedule: String; // x
  otherSchedule: String; // x
  subjectDetails: SubjectDetail[];
  number_of_summer_courses: number;
  have12thGradeCourses: string;
  allNoneAGCourses: boolean;
}

export interface SubjectDetail {
  index: number;
  lastIndex: number | null | undefined;
  isWeightedCourse: string;
  languageName: string;
  CNGradingScale: String;
  grade: (number | string)[];
  finalGrade: String;
  credits: (number | string)[];
  finalCredits: String;
  checkedNA: false;
  otherGradeScale: String;
  highestGrade: String;
  lowestGrade: String;
  subject: String;
  AGSubjectArea: String;
  courseName: String;
  courseId?: number;
  class: String;
  otherClass: String;
  schedule_transcript: String;
  isFirstSemester: boolean;
  isSecondSemester: boolean;
  isThirdSemester: boolean;
  isFourthSemester: boolean;
  isTakeIn678Grade: boolean;
  isUsedBlockScheduling: boolean;
  typeOfClass: String;
  isTakeClassAtCollege: boolean;
  collegeTookClass: String;
  isSummerCourse: boolean;
  isNew?: boolean;

}

export const subjectDetailModal: SubjectDetail = {
  index: -1,
  isWeightedCourse: '',
  CNGradingScale: '',
  grade: ['', '', '', ''],
  finalGrade: '',
  credits: ['', '', '', ''],
  finalCredits: '',
  checkedNA: false,
  otherGradeScale: '',
  highestGrade: '',
  lowestGrade: '',
  subject: '--',
  AGSubjectArea: '',
  courseName: '',
  class: '',
  otherClass: '',
  schedule_transcript: '',
  isFirstSemester: false,
  isSecondSemester: false,
  isThirdSemester: false,
  isFourthSemester: false,
  isTakeIn678Grade: false,
  isUsedBlockScheduling: false,
  typeOfClass: '',
  isTakeClassAtCollege: false,
  collegeTookClass: '',
  languageName: '',
  isSummerCourse: false,
  lastIndex: null
};
