import { dataPhoneCode } from 'components/helper/countryPhoneCode';
import React from 'react';
import { Col, Form } from 'react-bootstrap';

interface Props {
  label?: string;
  mdCol?: string;
  name?: string;
  values?: any;
  onChange?: (e?: any) => void;
  isValid?: boolean;
  feedback?: any;
  placeholder?: string;
  className?: string;
  logo?: string;
  isMissing?: any;
}

const SelectPhoneCodeInput = (props: Props) => {
  return (
    <Form.Group
      className={`${props.className ?? ''} p-1 p-md-1`.trim()}
      name={props.name}
      as={Col}
      md={props.mdCol || '6'}
      controlId="formGridState"
    >
      <Form.Label
        className={`${props?.logo}`}
        style={{
          fontSize: '1rem',
          color: '#4E5A6F',
          marginBottom: '10px',
        }}
      >
        <div
          style={{
            display: 'inline-block',
            backgroundColor: props.isMissing ? '#FFEBEB' : '',
          }}
        >
          {props.label}
        </div>
      </Form.Label>
      <Form.Select
        onChange={(values) => {
          if (props.onChange) props.onChange(values);
        }}
        name={props.name}
        value={props.values ?? '+1'}
        className=""
        size="sm"
      >
        {dataPhoneCode.map((item, index) => (
          <option key={index} value={item.dial_code}>
            {item.name} ({item.dial_code})
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};

export default SelectPhoneCodeInput;
