/* eslint-disable react-hooks/exhaustive-deps */
import { reorder, setArrayLength } from 'components/helper/helper';
import SelectInput from 'components/input/SelectInput';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import LanguageConfig from './components/LanguageConfig';

interface Props {
  formik: any;
  languageArray: any;
  check: any;
}

export interface languageOptionProps {
  language: String;
  isFirstLanguage: boolean;
  isSpeak: boolean;
  isRead: boolean;
  isWrite: boolean;
  isSpokenAtHome: boolean;
  proficiencyLevel: string;
}

const Language = (props: Props) => {
  const [moreLanguageOption, setMoreLanguageOption] = React.useState<languageOptionProps[]>([]);

  const { check } = props;

  React.useEffect(() => {
    if (props.languageArray) setMoreLanguageOption(props.languageArray);
  }, []);

  const setNumberLanguage = (number: any) => {
    setMoreLanguageOption(
      setArrayLength(moreLanguageOption, number, {
        language: 'English',
        isFirstLanguage: false,
        isSpeak: false,
        isRead: false,
        isWrite: false,
        isSpokenAtHome: false,
      })
    );
  };

  React.useEffect(() => {
    props.formik.setFieldValue('language_array', JSON.stringify(moreLanguageOption));
  }, [moreLanguageOption]);

  const handleCheckFirstLanguage = async (index: number) => {
    const newMoreLanguageOption = reorder(moreLanguageOption, index, 0).map((item, i) =>
      i === 0 ? { ...item, isFirstLanguage: true } : { ...item, isFirstLanguage: false }
    );

    setMoreLanguageOption(newMoreLanguageOption);
  };

  return (
    <Row className="m-0">
      <div className="fs-5 p-1">Language</div>

      <SelectInput
        logo="span-cm-logo"
        form={props.formik}
        option={[
          // { value: 0 },
          { value: 1 },
          { value: 2 },
          { value: 3 },
          { value: 4 },
          { value: 5 },
        ]}
        mdCol={'6'}
        label={'Number of languages you are proficient in*'}
        isSatTest={true}
        name={'number_language_proficient'}
        values={props.formik.values.number_language_proficient}
        isMissing={!props.formik.values.number_language_proficient && check ? true : false}
        onChange={(e) => {
          props.formik.handleChange(e);
          setNumberLanguage(e.target.value);
        }}
        feedback={
          props.formik.touched.number_language_proficient &&
          props.formik.errors.number_language_proficient && (
            <span className="text-danger">{props.formik.errors.number_language_proficient}</span>
          )
        }
      />
      <Col xs={12}>
        <div>What language did you learn to speak first?*</div>

        {(moreLanguageOption || []).map((option, index) => (
          <LanguageConfig
            check={check}
            handleCheckFirstLanguage={() => {
              handleCheckFirstLanguage(index);
            }}
            key={index}
            information={option}
            titleIndex={`${index + 1}/${props.formik.values.number_language_proficient}`}
            index={index}
            handleSave={(newMoreLanguage) => {
              const newMoreLanguageOption = [...moreLanguageOption];
              newMoreLanguageOption[index] = newMoreLanguage;
              setMoreLanguageOption(newMoreLanguageOption);
            }}
            handleRemove={() => {
              const newMoreLanguageOption = [...moreLanguageOption];
              newMoreLanguageOption.splice(index, 1);
              setMoreLanguageOption(newMoreLanguageOption);
            }}
          />
        ))}
      </Col>
    </Row>
  );
};

export default Language;
