/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Row, Button } from 'react-bootstrap';
import SelectInput from 'components/input/SelectInput';
import SearchIcon from '@mui/icons-material/Search';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import TextIconInput from 'components/input/TextIconInput';
import { csuCountry, yearOptions } from 'components/helper/helper';
import collegeServices from 'services/collegeServices';
import { debounce, defer } from 'lodash';
import LoadingContent from 'components/LoadingContent';
import TextInput from 'components/input/textInput';
import AutocompleteInput from 'components/input/AutoCompleteInput';
import { canadaProvince, usaState } from 'components/helper/stateAndCounty';
import { states_usa } from 'components/helper/state_usa';

export const fixHCollegeObject = (school: any) => {
  let college: any = {
    ceeb: '',
    fib_code: '',
    name: '',
    school_type: null,
    city: '',
    state: '',
    zip: '',
    country: '',
    csu_name: '',
    uc_name: '',
    cm_name: '',
    match_all: false,
    label: '',
    code: null,
    is_manual: false,
  };

  try {
    let isOld = false;
    if (school.hasOwnProperty('ceeb')) {
      if (school.ceeb && school.ceeb?.trim().length > 0) {
        college.ceeb = school.ceeb;
        college.code = college.ceeb;
      }
    }
    if (school.hasOwnProperty('fib_code')) {
      if (school.fib_code && school.fib_code?.trim().length > 0) {
        college.fib_code = school.fib_code;
      }
    }
    if (school.hasOwnProperty('name')) {
      if (school.name && school.name?.trim().length > 0) {
        college.name = school.name;
      }
    }
    if (school.hasOwnProperty('school_type')) {
      if (school.school_type && school.school_type?.trim().length > 0) {
        college.school_type = school.school_type;
      }
    }
    if (school.hasOwnProperty('city')) {
      if (school.city && school.city?.trim().length > 0) {
        college.city = school.city;
      }
    }
    if (school.hasOwnProperty('state')) {
      if (school.state && school.state?.trim().length > 0) {
        college.state = school.state;
      }
    }
    if (school.hasOwnProperty('zip')) {
      if (school.zip && school.zip?.trim().length > 0) {
        college.zip = school.zip;
      }
    }
    if (school.hasOwnProperty('country')) {
      if (school.country && school.country?.trim().length > 0) {
        college.country = school.country;
      }
    }

    if (school.hasOwnProperty('csu_name')) {
      if (school.csu_name && school.csu_name?.trim().length > 0) {
        college.csu_name = school.csu_name;
      }
    }

    if (school.hasOwnProperty('uc_name')) {
      if (school.uc_name && school.uc_name?.trim().length > 0) {
        college.uc_name = school.uc_name;
      }
    } else {
      isOld = true;
    }

    if (school.hasOwnProperty('cm_name')) {
      if (school.cm_name && school.cm_name?.trim().length > 0) {
        college.cm_name = school.cm_name;
      }
    } else {
      isOld = true;
    }

    if (school.hasOwnProperty('match_all')) {
      college.match_all = school.match_all;
    } else {
      isOld = true;
    }

    if (school.hasOwnProperty('label')) {
      college.label = school.label;
    }
    if (school.hasOwnProperty('value')) {
      college.value = school.value;
    }
    if (school.hasOwnProperty('code')) {
      college.code = school.code;

      // fix high_school_object.college_board_code is empty
      if (college.ceeb?.trim().length === 0) {
        if (school.code !== null) {
          college.ceeb = `${school.code}`; // force is string value
        }
      }
    }

    if (school.hasOwnProperty('is_manual')) {
      college.is_manual = school.is_manual;
    }

    if (school.hasOwnProperty('id')) {
      college.id = school.id;
      isOld = true;
    }

    if (isOld) {
      college.is_manual = false;

      // Fix state: get state Name from stateC ode
      college.state = getStateName(college.state);

      // fix school type
      if (school.hasOwnProperty('year_type')) {
        switch (school.year_type) {
          case '2YEAR':
            college.school_type = '2-year college or university';
            break;
          case '4YEAR':
            college.school_type = '4-year college or university';
            break;
        }
      }
    }
    return college;
  } catch (ex) {
    console.log('Error: ', ex);
  }
  return school;
};

export const AliasNames = [{ alias: 'miracosta', name: 'Mira Costa' }];

export const UnlistedTypes = [
  'Unlisted English Speaking Canadian Institution',
  'Unlisted US Institution',
  'Unlisted French Speaking Canadian or Other Foreign Institution',
];

export const CollegeSchoolType = ['2-year or community college', '4-year college or university'];

export const getStateName = (stateCode: string) => {
  for (let i = 0; i < states_usa.length; i++) {
    if (states_usa[i].value === stateCode) return states_usa[i].name;
  }
  return stateCode;
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export type MaskEditCollegeInfo = {
  ceeb: boolean;
  name: boolean;
  school_type: boolean;
  city: boolean;
  state: boolean;
  zip: boolean;
  country: boolean;
};

interface Props {
  formik: any;
  indexText: string;
  item: any;
  index: number;
  check?: any;
  indexExpand?: any;
}

const EducationConfig = ({ check, formik, indexText, item, index, indexExpand }: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState('');
  const [showNotFound, setShowNotFound] = React.useState(false);

  const getEditMask = (item: any) => {
    let mask: MaskEditCollegeInfo = {
      ceeb: true,
      name: true,
      school_type: true,
      city: true,
      state: true,
      zip: true,
      country: true,
    };

    if (item) {
      if (item.hasOwnProperty('match_all')) {
        mask.ceeb = false;
        mask.name = false;

        if (item.match_all) {
          mask.school_type = false;
          mask.city = false;
          mask.state = false;
          mask.zip = false;
          mask.country = false;
        } else {
          mask.school_type = false;
          mask.city = false;
          mask.state = false;
          mask.zip = false;
          mask.country = false;
          if (item.hasOwnProperty('ceeb') && (item.ceeb === null || item.ceeb?.trim() === '')) {
            mask.ceeb = true;
          }
          if (
            item.hasOwnProperty('school_type') &&
            (item.school_type === null || item.ceeb?.trim() === '')
          ) {
            mask.school_type = true;
          }
          if (item.hasOwnProperty('city') && (item.city === null || item.city?.trim() === '')) {
            mask.city = true;
          }
          if (item.hasOwnProperty('state') && (item.state === null || item.state?.trim() === '')) {
            mask.state = true;
          }
          if (item.hasOwnProperty('zip') && (item.zip === null || item.zip?.trim() === '')) {
            mask.zip = true;
          }
          if (
            item.hasOwnProperty('country') &&
            (item.country === null || item.country?.trim() === '')
          ) {
            mask.country = true;
          }

          // old school object
          if (item.hasOwnProperty('zip-code') && (item.zip === null || item.zip.trimm() === '')) {
            mask.zip = true;
          }
        }
      }
    }

    return mask;
  };
  const isNewItem = () => {
    if (item.school) {
      item.school = Object.assign({}, fixHCollegeObject(item.school));
    }

    let mask = getEditMask(item.school);
    return (
      !(
        mask.ceeb &&
        mask.name &&
        mask.school_type &&
        mask.city &&
        mask.state &&
        mask.zip &&
        mask.country
      ) && item.school?.name === ''
    );
  };
  const initEditMask = () => {
    return isNewItem()
      ? {
          ceeb: false,
          name: false,
          school_type: false,
          city: false,
          state: false,
          zip: false,
          country: false,
        }
      : {
          ceeb: true,
          name: true,
          school_type: true,
          city: true,
          state: true,
          zip: true,
          country: true,
        };
  };
  const [editMask, setEditMask] = React.useState<MaskEditCollegeInfo>(initEditMask());
  const [editManual, setEditManual] = React.useState(false);
  const [collegeName, setCollegeName] = React.useState(item?.school?.name ?? '');
  let searchCtrl = new AbortController();

  React.useEffect(() => {
    setCollegeData(undefined);
    let isNew = isNewItem();
    if (item.school) {
      if (item.school.is_manual) {
        setEditManual(true);
      } else {
        handleChangeItem(item.school);
        setEditMask(getEditMask(item.school));
      }
    } else {
      setEditManual(false);
    }

    if (isNew) {
      setEditMask({
        ceeb: false,
        name: false,
        school_type: false,
        city: false,
        state: false,
        zip: false,
        country: false,
      });
      setEditManual(false);
    }
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const elementRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (check && indexExpand === indexText) {
      setExpanded(true);

      setTimeout(() => {
        if (elementRef.current) {
          elementRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }, 100);
    }
  }, [indexExpand]);

  const [selectedEducation, setSelectedEducation] = React.useState<any>();
  const [collegeData, setCollegeData] = React.useState<any>();

  const handleGetCollege = async (keyword: string) => {
    try {
      if (keyword && keyword.length > 0) {
        setCollegeData([]);
        setShowNotFound(false);
        if (keyword?.trim().length === 0) return;
        searchCtrl.abort();
        searchCtrl = new AbortController();
        setIsLoading(true);
        let res: any = await collegeServices.findCollege(keyword, searchCtrl);
        const data = res.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.name,
        }));

        if (res && res.length === 0) {
          setShowNotFound(true);
        } else {
          setShowNotFound(false);
        }

        setCollegeData(data);
        setIsLoading(false);
        setOpenMenu(true);
      }
    } catch (ex) {
      console.log('Error: ', ex);
    }
  };

  const handleSearchCollege = debounce((keyword: string) => {
    defer(() => {
      handleGetCollege(keyword);
    });
  }, 300);

  const handleAliasValue = (value: string) => {
    if (value) {
      try {
        let keyword = value.toLowerCase().trim();
        // search keyword as alias name
        for (let i = 0; i < AliasNames.length; i++) {
          if (keyword === AliasNames[i].alias) {
            return AliasNames[i].name;
          }
        }
      } catch (ex) {
        console.log('Error:', ex);
      }
    }
    return value;
  };

  const resetCollegeInfo = () => {
    try {
      formik.setFieldValue(NAME + 'school.ceeb', '');
      formik.setFieldValue(NAME + 'school.fib_code', '');
      formik.setFieldValue(NAME + 'school.name', '');
      formik.setFieldValue(NAME + 'school.school_type', null);
      formik.setFieldValue(NAME + 'school.city', '');
      formik.setFieldValue(NAME + 'school.state', '');
      formik.setFieldValue(NAME + 'school.zip', '');
      formik.setFieldValue(NAME + 'school.country', '');
      formik.setFieldValue(NAME + 'school.csu_name', '');
      formik.setFieldValue(NAME + 'school.uc_name', '');
      formik.setFieldValue(NAME + 'school.cm_name', '');
      formik.setFieldValue(NAME + 'school.match_all', false);
      formik.setFieldValue(NAME + 'school.value', '');
      formik.setFieldValue(NAME + 'school.label', '');
      formik.setFieldValue(NAME + 'school.code', null);
      formik.setFieldValue(NAME + 'school.is_manual', true);
      formik.setFieldValue(NAME + 'school.id', null);
    } catch (ex) {
      console.log('Error:', ex);
    }
  };

  const handleChangeItem = (item: any) => {
    setCollegeData([]);
    let college: any = Object.assign({}, item);
    college.is_manual = false;
    setSelectedEducation(college);
    setEditMask(getEditMask(college));
    setCollegeName(college.name);
    setOpenMenu(false);
    setSearchInput('');
    formik.setFieldValue(NAME + 'school', college);
    setEditManual(true);
  };

  const handleNotFound = (e: any) => {
    setCollegeName(searchInput);
    setShowNotFound(false);
    setEditManual(true);
    resetCollegeInfo();
    setSelectedEducation(undefined);
    formik.setFieldValue(NAME + 'school.name', searchInput);
    formik.setFieldValue(NAME + 'school.is_manual', true);
    setEditMask(getEditMask(null));
  };

  const clearManualForm = () => {
    resetCollegeInfo();
    setCollegeName('');
    setEditMask(getEditMask(null));
  };

  const handleRemove = () => {
    setSelectedEducation(undefined);
    clearManualForm();
    setEditManual(false);
  };

  const checkEditMask = () => {
    return (
      editMask.ceeb ||
      editMask.name ||
      editMask.school_type ||
      editMask.city ||
      editMask.state ||
      editMask.zip ||
      editMask.country
    );
  };

  const NAME = `parent_graduation_college_array[${index}]`;

  return (
    <Card className="w-100 my-2 px-0" key={indexText} ref={elementRef}>
      <CardActions
        disableSpacing
        onClick={handleExpandClick}
        sx={{
          backgroundColor: expanded ? '#AAEBF7' : 'none',
          borderBottom: expanded ? '2px solid #4D88F0' : 'none',
        }}
      >
        <div className="w-100 d-flex justify-content-between">
          <span className="p-1" style={{ color: selectedEducation?.id ? '#4d88f0' : 'black' }}>
            {item?.school?.name ?? ''}
          </span>
          <strong className="text-field-light">{indexText}</strong>
        </div>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Row className="p-3">
            <TextIconInput
              label="Search for your parent graduation College / University"
              logo="span-cm-logo"
              icon={<SearchIcon />}
              mdCol="12"
              onChange={(e: any) => {
                let val = handleAliasValue(e.target.value);
                setSearchInput(val);
                handleSearchCollege(val);
                if (!selectedEducation) {
                  setCollegeName(val);
                  formik.setFieldValue(NAME + 'school.is_manual', true);
                  formik.setFieldValue(NAME + 'school.name', e.target.value);
                }

                if (val.length === 0) {
                  setCollegeData([]);
                }
              }}
              values={searchInput}
              otherProps={{
                onKeyDown: (evt: any) => {
                  if (evt.key === 'Escape') {
                    setCollegeData([]);
                  }
                },
              }}
            />
            {isLoading && <LoadingContent />}
            {openMenu &&
              (collegeData || []).map((college: any, index: React.Key | null | undefined) =>
                college?.label ? (
                  <Card className="border rounded my-1 pt-2 pb-1" key={index}>
                    <div className="d-flex align-items-end position-relative cursor-pointer">
                      <div
                        className="mx-3 pb-1 text-overflow-ellipses"
                        onClick={() => {
                          handleChangeItem(college);
                        }}
                      >
                        <span>{college.label}</span>
                        <span
                          className="mx-1 text-field-light sized70"
                          title={`(ceeb: ${college?.ceeb ?? ''}, city: ${
                            college?.city ?? ''
                          }, state: ${getStateName(college?.state) ?? ''}, country: ${
                            college?.country ?? ''
                          })`}
                        >
                          {`(ceeb: ${college?.ceeb ?? ''}, city: ${college?.city ?? ''}, state: ${
                            getStateName(college?.state) ?? ''
                          }, country: ${college?.country ?? ''})`}
                        </span>
                      </div>

                      <span className="position-absolute h-100 d-flex align-items-center end-0">
                        <AddIcon
                          fontSize="small"
                          className="bg-app text-primary rounded-circle mx-3 pointer"
                          onClick={() => {
                            handleChangeItem(college);
                          }}
                        />
                      </span>
                    </div>
                  </Card>
                ) : null
              )}

            {showNotFound && (
              <div id="not-found" className="d-block not-found">
                <div className="text-center">
                  <button type="button" className="btn-link btn-not-found" onClick={handleNotFound}>
                    Desired College/University not found, I want to enter my College/University
                    manually
                  </button>
                </div>
              </div>
            )}

            <div className="my-2 text-field-light">Parent Graduation College / University</div>
            {selectedEducation?.id && (
              <>
                <div className="d-flex align-items-end position-relative">
                  <div className="mx-3 fw-bold">{selectedEducation?.name}</div>
                  <div className="position-absolute h-100 d-flex align-items-center end-0">
                    <RemoveIcon
                      fontSize="small"
                      className="bg-light text-dart rounded-circle mx-4 pointer"
                      onClick={handleRemove}
                    />
                  </div>
                </div>
                <div className="d-block mx-3 pb-3">
                  <div className="text-field-light sized70">
                    {`(ceeb: ${selectedEducation?.ceeb ?? ''}, fib code: ${
                      selectedEducation?.fib_code ?? ''
                    },  state: ${selectedEducation?.state ?? ''}, city: ${
                      selectedEducation?.city ?? ''
                    }, country: ${selectedEducation?.country ?? ''})`}
                  </div>
                </div>
              </>
            )}

            {(collegeData !== undefined && !collegeData?.length) ||
            !item.school?.match_all ||
            item.school?.match_all === false ? (
              <>
                {editManual && checkEditMask() && (
                  <Row className="bg-light rounded p-3">
                    <TextInput
                      mdCol={12}
                      hidden={!editMask.name}
                      name={NAME + `school.name`}
                      values={collegeName}
                      onChange={(e) => {
                        try {
                          setCollegeName(e.target.value);
                          formik.setFieldValue(NAME + 'school.name', e.target.value);
                          formik.setFieldValue(NAME + 'school.is_manual', true);
                        } catch (ex) {
                          console.log('Error: ', ex);
                        }
                      }}
                      label="Name of College/University*"
                      countText={60}
                      isMissing={!collegeName && check}
                    />

                    <TextInput
                      name={NAME + `school.city`}
                      hidden={!editMask.city}
                      values={item?.school?.city}
                      onChange={formik.handleChange}
                      label="City"
                      countText={30}
                    />
                    <SelectInput
                      name={NAME + `school.school_type`}
                      hidden={!editMask.school_type}
                      values={item?.school?.school_type ?? ''}
                      onChange={formik.handleChange}
                      label="School Type *"
                      option={CollegeSchoolType}
                      isMissing={!item?.school?.school_type && check}
                    />
                    <AutocompleteInput
                      name={NAME + `school.country`}
                      hidden={!editMask.country}
                      form={formik}
                      defautlValues={item.school?.country?.trim() ?? ''}
                      values={item.school?.country?.trim() ?? ''}
                      label="Country/Region/Territory*"
                      option={csuCountry}
                      isMissing={!item.school?.country?.trim() && check}
                    />
                    {item.school?.country === 'United States of America' ? (
                      <AutocompleteInput
                        hidden={!editMask.state}
                        option={usaState}
                        form={formik}
                        label="State*"
                        name={NAME + `school.state`}
                        values={getStateName(item.school?.state)}
                        isMissing={!getStateName(item.school?.state) && check}
                      />
                    ) : item.school?.country === 'Canada' ? (
                      <AutocompleteInput
                      hidden={!editMask.state}
                      form={formik}
                        option={canadaProvince}
                        label="Province*"
                        name={NAME + `school.state`}
                        values={item.school?.state ?? ''}
                        isMissing={!item.school?.state && check}
                      />
                    ) : (
                      <TextInput
                        name={NAME + `school.state`}
                        hidden={!editMask.state}
                        values={item.school?.state ?? ''}
                        onChange={formik.handleChange}
                        label="State or Province"
                        countText={30}
                      />
                    )}
                    <TextInput
                      name={NAME + `school.zip`}
                      hidden={!editMask.zip}
                      values={item.school?.zip}
                      onChange={formik.handleChange}
                      label="Zip Code"
                      countText={10}
                      isMissing={!item.school?.zip && check}
                    />
                    <TextInput
                      name={NAME + `school.ceeb`}
                      hidden={!editMask.ceeb}
                      values={item.school?.ceeb}
                      onChange={formik.handleChange}
                      label="CEEB Code"
                      countText={10}
                    />
                  </Row>
                )}
              </>
            ) : (
              <></>
            )}

            <SelectInput
              form={formik}
              option={[
                { value: 'No degree' },
                { value: '1' },
                { value: '2' },
                { value: '3' },
                { value: '4' },
                { value: '5' },
              ]}
              label={'Degrees Number'}
              name={NAME + 'degreesNumber'}
              values={item?.degreesNumber}
              onChange={formik.handleChange}
            />
            <div />

            {parseInt(item?.degreesNumber ?? '0') >= 1 && (
              <>
                <SelectInput
                  option={yearOptions}
                  name={NAME + `graduationYear1`}
                  values={item?.graduationYear1}
                  onChange={formik.handleChange}
                  label={`Graduation Year (1)`}
                />
                <SelectInput
                  form={formik}
                  option={[
                    { value: `Associate's (AA, AS)` },
                    { value: `Bachelor's (BA, BS)` },
                    { value: `Master's (MA, MS)` },
                    { value: `Business (MBA, MAcc)` },
                    { value: `Law (JD, LLM)` },
                    { value: `Medicine (MD, DO, DVM, DDS)` },
                    { value: `Doctorate (PhD, EdD, etc)` },
                    { value: 'Other' },
                  ]}
                  label={'Degree Received (1)'}
                  name={NAME + 'degreesReceived1'}
                  values={item?.degreesReceived1}
                  onChange={formik.handleChange}
                />
              </>
            )}
            {parseInt(item?.degreesNumber) >= 2 && (
              <>
                <SelectInput
                  option={yearOptions}
                  name={NAME + `graduationYear2`}
                  values={item?.graduationYear2}
                  onChange={formik.handleChange}
                  label={`Graduation Year (2)`}
                />
                <SelectInput
                  form={formik}
                  option={[
                    { value: `Associate's (AA, AS)` },
                    { value: `Bachelor's (BA, BS)` },
                    { value: `Master's (MA, MS)` },
                    { value: `Business (MBA, MAcc)` },
                    { value: `Law (JD, LLM)` },
                    { value: `Medicine (MD, DO, DVM, DDS)` },
                    { value: `Doctorate (PhD, EdD, etc)` },
                    { value: 'Other' },
                  ]}
                  label={'Degree Received (2)'}
                  name={NAME + 'degreesReceived2'}
                  values={item?.degreesReceived2}
                  onChange={formik.handleChange}
                />
              </>
            )}
            {parseInt(item?.degreesNumber) >= 3 && (
              <>
                <SelectInput
                  option={yearOptions}
                  name={NAME + `graduationYear3`}
                  values={item?.graduationYear3}
                  onChange={formik.handleChange}
                  label={`Graduation Year (3)`}
                />
                <SelectInput
                  form={formik}
                  option={[
                    { value: `Associate's (AA, AS)` },
                    { value: `Bachelor's (BA, BS)` },
                    { value: `Master's (MA, MS)` },
                    { value: `Business (MBA, MAcc)` },
                    { value: `Law (JD, LLM)` },
                    { value: `Medicine (MD, DO, DVM, DDS)` },
                    { value: `Doctorate (PhD, EdD, etc)` },
                    { value: 'Other' },
                  ]}
                  label={'Degree Received (3)'}
                  name={NAME + 'degreesReceived3'}
                  values={item?.degreesReceived3}
                  onChange={formik.handleChange}
                />
              </>
            )}
            {parseInt(item?.degreesNumber) >= 4 && (
              <>
                <SelectInput
                  option={yearOptions}
                  name={NAME + `graduationYear4`}
                  values={item?.graduationYear4}
                  onChange={formik.handleChange}
                  label={`Graduation Year (4)`}
                />
                <SelectInput
                  form={formik}
                  option={[
                    { value: `Associate's (AA, AS)` },
                    { value: `Bachelor's (BA, BS)` },
                    { value: `Master's (MA, MS)` },
                    { value: `Business (MBA, MAcc)` },
                    { value: `Law (JD, LLM)` },
                    { value: `Medicine (MD, DO, DVM, DDS)` },
                    { value: `Doctorate (PhD, EdD, etc)` },
                    { value: 'Other' },
                  ]}
                  label={'Degree Received (4)'}
                  name={NAME + 'degreesReceived4'}
                  values={item?.degreesReceived4}
                  onChange={formik.handleChange}
                />
              </>
            )}
            {parseInt(item?.degreesNumber) >= 5 && (
              <>
                <SelectInput
                  option={yearOptions}
                  name={NAME + `graduationYear5`}
                  values={item?.graduationYear5}
                  onChange={formik.handleChange}
                  label={`Graduation Year (5)`}
                />
                <SelectInput
                  form={formik}
                  option={[
                    { value: `Associate's (AA, AS)` },
                    { value: `Bachelor's (BA, BS)` },
                    { value: `Master's (MA, MS)` },
                    { value: `Business (MBA, MAcc)` },
                    { value: `Law (JD, LLM)` },
                    { value: `Medicine (MD, DO, DVM, DDS)` },
                    { value: `Doctorate (PhD, EdD, etc)` },
                    { value: 'Other' },
                  ]}
                  label={'Degree Received (5)'}
                  name={NAME + 'degreesReceived5'}
                  values={item?.degreesReceived5}
                  onChange={formik.handleChange}
                />
              </>
            )}
            <Col xs={6} md={9} />

            <Col xs={6} md={3} className="p-3">
              <Button
                className="w-100"
                onClick={() => {
                  setExpanded(false);
                  formik.setFieldValue('is_education_saved', true);
                  formik.handleSubmit();
                }}
              >
                Save Info
              </Button>
            </Col>
          </Row>
        </CardContent>
      </Collapse>
      <style scoped>{` 
button.btn-link.btn-not-found {
  color: #0d6efd!important;
  border: none!important;
  background: transparent;
  text-decoration: underline!important;
  text-decoration-color: #0d6efd!important;
  font-weight: 300;
  text-decoration-style: solid;
}
.text-overflow-ellipses {
  white-space: nowrap!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  padding-right:25px;
  cursor: pointer;
  caret-color: transparent; 
}
.cursor-pointer {
  cursor: pointer;
}
      `}</style>
    </Card>
  );
};

export default EducationConfig;
