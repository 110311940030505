/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Row, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import SelectInput from 'components/input/SelectInput';
import GroupRadioButtonRounded from 'components/input/GroupRadioButtonRounded';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import {
  tryParseJSON,
  optionValues,
  reorder,
  getArrayYears,
} from 'components/helper/helper';
import { getCollege } from 'app/highSchoolSlice/highSchoolSlice';
import GroupRadioButton from 'components/input/GroupRadioButton';
import { CollegeCourseConfigInitialType } from './collegeCourse.type';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { CourseSubjectConfig } from './CourseSubjectConfig';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Props {
  handleSave: (value: any) => void;
  item: any;
  index: number;
  total: number;
  expandAll?: boolean;
  handleExpandActive: (value: any) => void;
}
export const CollegeCourseConfig = (props: Props) => {
  const MAX_COURSES = 9;
  const [termOptions, setTermOptions] = React.useState<any[]>([
    'Fall',
    'Winter',
    'Spring',
    'Summer',
  ]);
  const [yearOptions, setYearOptions] = React.useState<any[]>(
    optionValues(Array.from({ length: 78 }, (_, i) => i + 1950)).reverse()
  );
  const [collegeOptions, setCollegeOptions] = React.useState<any[]>([]);
  const myColleges = useAppSelector((store) => store.highSchool.myCollege);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const query = new URLSearchParams(location.search);
  const check = query.get('check');

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  React.useEffect(() => {
    setExpanded(props.expandAll || false);
  }, [props?.expandAll]);
  
  React.useEffect(() => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      if (!myColleges) {
        dispatch(getCollege(studentId));
      }
      let colleges_taken_coursework_array = tryParseJSON(myColleges?.colleges_taken_coursework_array);

      if (colleges_taken_coursework_array) {
        for (let i = 0; i < colleges_taken_coursework_array.length; i++) {
          let obj = colleges_taken_coursework_array[i];
          if (!obj.fullName) {
            obj.fullName = `${obj?.college_name} (ceeb: ${obj?.college_ceeb}, state: ${obj?.college_state}, city: ${obj?.college_city}, country: ${obj?.college_country})`;
          }
        }
        setCollegeOptions(colleges_taken_coursework_array);
        handlePickCollege(props.item?.collegeName, colleges_taken_coursework_array);
      }
    } else {
      handleLogout();
    }
  }, [myColleges]);
  // dept, collegeCourseSubject, courseName, courseNumber, subject, languageName , creditsPerHours, honorStatus, completionStatus, grade, transferable
  const formItem = useFormik({
    initialValues: {
      collegeName: '',
      collegeInformation: null as any,
      scheduleSystem: '',
      academicYear: '',
      term: '',
      academicStatus: '',
      collegeCourseArray: [],
    } as CollegeCourseConfigInitialType,
    onSubmit: (values) => {},
  });
  const [expanded, setExpanded] = React.useState(props.expandAll || false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
    props.handleExpandActive(expanded);
  };

  React.useEffect(() => {
    if (props.item?.collegeName) {
      formItem.setValues({ ...formItem.values, ...props.item });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (formItem.dirty) {
      props.handleSave({
        ...formItem.values,
        // TODO
        // languageName: formItem.values.subject.includes('Other Than English')
        //   ? ''
        //   : formItem.values.languageName,
      });
    }
  }, [formItem.values]);
  const renderTitle = () => {
    const values: string[] = [];
    formItem.values.collegeName.length && values.push(formItem.values.collegeName);
    formItem.values.term.length && values.push(formItem.values.term);
    formItem.values.academicYear && values.push(formItem.values.academicYear);
    return <span className="p-1">{values.join(' - ')}</span>;
  };

  const [forceRenderKey, setForceRenderKey] = React.useState(0);

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const dataList = reorder(
      formItem.values.collegeCourseArray,
      result.source.index,
      result.destination.index
    );
    formItem.setFieldValue('collegeCourseArray', [...dataList]);
    setForceRenderKey(forceRenderKey + 1);
  };

  const handleAddCourse = () => {
    if (formItem.values.collegeCourseArray.length > MAX_COURSES - 1) return;
    formItem.setFieldValue('collegeCourseArray', [
      ...formItem.values.collegeCourseArray,
      {
        dept: '',
        collegeCourseSubject: '',
        courseName: '',
        courseNumber: '',
        subject: '',
        languageName: '',
        creditsPerHours: '',
        grade: '--',
        honorStatus: '--',
        completionStatus: 'Select',
        transferable: false,
      },
    ]);
  };

  const handlePickCollege = (collegeName: string, collegeDate: any[]) => {
    let collegeInformation = collegeDate.find((item) => item.fullName === collegeName);
    if (collegeInformation === undefined) {
      //try to find by college_name
      collegeInformation = collegeDate.find(
        (item) => item.college_info.college_name === collegeName
      );
    }
    const years = getArrayYears(collegeInformation?.fromDate, collegeInformation?.toDate);
    formItem.setFieldValue('collegeInformation', collegeInformation);
    if (years.length > 0) {
      setYearOptions(years);
    }
  };
  
  return (
    <div className="m-0">
      <Card className="w-100 my-3 px-0">
        <CardActions
          id={`collegeItem${props.index}`}
          aria-expanded={expanded}
          disableSpacing
          onClick={handleExpandClick}
          sx={{
            backgroundColor: expanded ? '#AAEBF7' : 'none',
            borderBottom: expanded ? '2px solid #4D88F0' : 'none',
          }}
        >
          <div className="w-100 d-flex justify-content-between">
            <span className="p-1">{renderTitle()}</span>
            <strong className="text-field-light">
              {props.index}/{props.total}
            </strong>
          </div>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Row className="px-3">
              <SelectInput
                id={`collegeName_${props.index}`}
                option={collegeOptions.map((i) => {
                  return { name: i.fullName, value: i.college_name };
                })}
                mdCol="12"
                name="collegeName"
                label="College"
                values={formItem.values.collegeName}
                onChange={(e) => {
                  formItem.handleChange(e);
                  handlePickCollege(e.target.value, collegeOptions);
                }}
                isMissing={!formItem.values.collegeName && check ? true : false}
              />
              <SelectInput
                id={`academicYear_${props.index}`}
                className="coallition-flag"
                form={formItem}
                option={yearOptions}
                label="Academic year *"
                values={formItem.values.academicYear}
                name="academicYear"
                onChange={(e) => {
                  formItem.handleChange(e);
                  let options = [];
                  const collegeInformation = collegeOptions.find(
                    (item) => item.fullName === formItem.values.collegeName
                  );
                  if (collegeInformation) {
                    if (collegeInformation.grades_spring.includes(e.target.value)) {
                      options.push('Spring');
                    }

                    if (collegeInformation.grades_summer.includes(e.target.value)) {
                      options.push('Summer');
                    }
                    if (collegeInformation.grades_fall.includes(e.target.value)) {
                      options.push('Fall');
                    }
                    if (collegeInformation.grades_winter.includes(e.target.value)) {
                      options.push('Winter');
                    }
                  }
                  setTermOptions(options);
                }}
                isMissing={!formItem.values.academicYear && check ? true : false}
              />
              <SelectInput
                id={`academicStatus_${props.index}`}
                form={formItem}
                option={[
                  { name: '- Select -', value: '' },
                  { name: 'Freshman', value: 'Freshman' },
                  { name: 'Sophomore', value: 'Sophomore' },
                  { name: 'Junior', value: 'Junior' },
                  { name: 'Senior', value: 'Senior' },
                  { name: 'Graduate', value: 'Graduate' },
                  { name: 'Post-Baccalaureate', value: 'Post-Baccalaureate' },
                ]}
                label="College Academic Status"
                values={formItem.values.academicStatus}
                name="academicStatus"
                onChange={formItem.handleChange}
                isMissing={!formItem.values.academicStatus && check ? true : false}
              />
              <div />
              <GroupRadioButtonRounded
                id={`scheduleSystem_${props.index}`}
                label="Schedule System *"
                className="py-4"
                mdCol={2}
                options={[{ value: 'Semester' }, { value: 'Quarter' }]}
                name="scheduleSystem"
                values={formItem.values.scheduleSystem}
                onChange={formItem.handleChange}
                isMissing={!formItem.values.scheduleSystem && check ? true : false}
              />

              <GroupRadioButton
                id={`term_${props.index}`}
                options={termOptions}
                label="Term *"
                values={formItem.values.term}
                name="term"
                onChange={formItem.handleChange}
                isMissing={!formItem.values.term && check ? true : false}
              />
              <div />

              <DragDropContext onDragEnd={onDragEnd} key={forceRenderKey}>
                <Droppable droppableId="list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {(formItem.values.collegeCourseArray ?? []).map((item, index) => (
                        <div key={index + '-' + formItem.values.collegeCourseArray.length}>
                          <Draggable
                            draggableId={index + '-' + formItem.values.collegeCourseArray.length}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  key={index + '-' + formItem.values.collegeCourseArray.length}
                                  className="position-relative"
                                >
                                  <CourseSubjectConfig
                                    courseIndex={props.index}
                                    form={formItem}
                                    i={index}
                                    total={formItem.values.collegeCourseArray?.length??0}
                                  />
                                  <RemoveCircleOutlineIcon
                                    className="position-absolute text-field-light pointer"
                                    style={{ right: '-24px', top: '12px' }}
                                    onClick={() => {
                                      const newData = [...formItem.values.collegeCourseArray];
                                      newData.splice(index, 1);
                                      formItem.setFieldValue('collegeCourseArray', newData);
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        </div>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className="my-3 w-100 d-flex flex-column align-items-center">
                <div className="text-field-light sized70">{`You can add up to ${MAX_COURSES} courses per term`}</div>
                <Button variant="primary" className="p-2 px-5" onClick={handleAddCourse}>
                  Add Subject
                </Button>
              </div>
            </Row>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
};

export default CollegeCourseConfig;
