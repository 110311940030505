import { Card, Stack, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import CustomTabPanel from './TabPanel';
import LoadingContent from 'components/LoadingContent';
import { Button, Row } from 'react-bootstrap';
import { buildContent, buildContentCollege } from 'screen/summary/EducationSum';
import { useParams } from 'react-router-dom';
import highSchoolServices from 'services/highSchoolServices';
import { checkEnableParseJSON, formatDate, progressionHighSchool } from 'components/helper/helper';
import { describesOptions } from 'screen/education/FuturePlan/FuturePlan';
import basicInformationServices from 'services/basicInformationApi';
import { useAppSelector } from 'app/hooks';
import { buildClassName } from './RPersonalInformation';
import ItemContent, { CheckboxContent } from 'screen/summary/components/ItemContent';
import { USER_ROLE } from 'constants/common';
import { ModalCheckEducationFields } from 'screen/education/common/ModalCheckMissingFields';

const REducation = ({ setCurrentModuleId }: { setCurrentModuleId: (id: string) => void }) => {
  const [value, setValue] = React.useState(1);
  const checkModule = useAppSelector((store) => store.basicInformation.checkModule);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setCurrentModuleId(`3.${newValue}`);
  };
  return (
    <div>
      <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
        <Tab value={1} label="High Schools" className={buildClassName('3.1', checkModule)} />
        <Tab
          value={2}
          label="Colleges & Universities"
          className={buildClassName('3.2', checkModule)}
        />
        <Tab value={3} label="Future Plans" className={buildClassName('3.3', checkModule)} />
      </Tabs>
      <CustomTabPanel value={value} index={1}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryHighSchool />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
         
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryColleges />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryFuturePlan />
          </Stack>
        </Card>
      </CustomTabPanel>
    </div>
  );
};

export default REducation;

const SummaryHighSchool = () => {
  const [myHighSchool, setMyHighSchool] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();
  const [citizenshipData, setCitizenshipData] = React.useState<any>(null);
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await highSchoolServices.getHighSchool(
        params.id === ':id' ? null : params.id
      );
      setMyHighSchool({
        ...res,
        graduating_school_array: checkEnableParseJSON(res?.graduating_school_array) ?? [],
        high_school_array: checkEnableParseJSON(res?.high_school_array) ?? [],
        high_school_info: checkEnableParseJSON(res?.high_school_info),
      });

      setLoading(false);
    };
    const getCitizenshipData = async () => {
      setLoading(true);
      const res: any = await basicInformationServices.getCitizenship(
        params.id === ':id' ? null : params.id
      );

      setCitizenshipData({
        ...res,
        csu_info: checkEnableParseJSON(res?.csu_info),
        statement_legal_resident: checkEnableParseJSON(res?.statement_legal_resident),
        indicator_economic_fee_waiver_array: checkEnableParseJSON(
          res?.indicator_economic_fee_waiver_array
        ),
      });
      setLoading(false);
    };
    params.id !== ':id' && getData();
    params.id !== ':id' && getCitizenshipData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      <div className="d-flex justify-content-between mb-3">
        <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
          High Schools
        </Typography>
        {localStorage.getItem('userRole') === USER_ROLE.student && (
          <Button variant="primary" onClick={() => setShow(true)}>
            Check missing values
          </Button>
        )}
      </div>
      <div className="fs-5 text-field-light">Enrollment Status</div>
      <ItemContent
        label="What is your current enrollment status?*"
        values={citizenshipData?.current_enrollment_status}
        mdCol={6}
      />
      <ItemContent
        label="When will you graduate from high school or receive high school equivalency? *"
        values={formatDate(citizenshipData?.when_graduate_high_school)}
        mdCol={6}
      />
      <ItemContent
        label="Year entering college "
        values={citizenshipData?.year_plan_college_studies}
        mdCol={6}
      />
      <ItemContent
        label="What degree, credential or certificate are you applying for?"
        values={citizenshipData?.csu_info?.degree_applying_for}
        mdCol={6}
      />

      <ItemContent
        mdCol={6}
        label="Which of the following best describes your current educational status?"
        values={citizenshipData?.csu_info?.educational_status}
      />
      <ItemContent
        mdCol={6}
        values={citizenshipData?.csu_info?.is_take_college_credit}
        label="Have you taken and earned college credits through a community college or university?"
      />

      <div className="border w-100 my-3" />
      <div className="fs-5 text-field-light">High Schools</div>

      <div>Current or Most Recent High School</div>
      <div className="px-2 pt-3">{buildContent(myHighSchool?.high_school_array[0], -1)}</div>

      <div className="border w-100 my-3" />
      <div className="fs-5 text-field-light">Other Secondary/High School(s)</div>
      <ItemContent
        label="Number of other secondary/high school(s) you have attended"
        values={myHighSchool?.high_school_array.length - 1 || 0}
        mdCol={12}
      />
      <div className="border w-100 my-3" />
      <div className="px-2 pt-3">
        {myHighSchool?.high_school_array?.map((school: any, index: any) =>
          index !== 0 ? buildContent(school, index) : null
        )}
      </div>
      <ItemContent
        mdCol={12}
        label="Please provide details about why you left each of the above secondary/high schools"
        values={myHighSchool?.high_school_info?.left_highschool_reason}
      />

      <div className="border w-100 my-3" />
      <div className="fs-5 text-field-light">Graduation Status</div>

      <div className=" text-field-light mt-4">
        Please indicate if any of these options will have affected your progression through or since
        secondary/high school. Check all that apply.*
      </div>
      <div className="mb-3">
        {progressionHighSchool?.map((progressItem, progressIndex) => (
          <CheckboxContent
            key={progressIndex}
            label={progressItem.label}
            // values={item[`${progressItem.value}`]}
            values={myHighSchool?.graduating_school_array[progressItem.value]}
          />
        ))}
      </div>
      <ItemContent
        mdCol={12}
        label="Please use the space below to provide details about the change in progression through secondary/high school that you indicated above.*"
        values={myHighSchool?.graduating_school_array?.change_in_progression_through_highschool}
      />

      <ItemContent
        label="Select your anticipated or current graduation status from high school or equivalent?*"
        mdCol={6}
        values={myHighSchool?.graduating_school_array?.graduation_status}
      />
      <ItemContent
        label="Date Received/Expected*"
        mdCol={6}
        values={formatDate(
          myHighSchool?.graduating_school_array?.graduating_date_received,
          'MM-DD-YYYY'
        )}
      />

      <div className="border w-100 my-3" />
      <div className="fs-5 text-field-light">Graduating School</div>
      <ItemContent
        label="Most recent academic year *"
        values={myHighSchool?.graduating_school_array?.graduating_school_year}
      />
      <ItemContent
        label="Class Rank Report*"
        values={myHighSchool?.graduating_school_array?.graduating_school_rank_report}
      />
      <ItemContent label="Class Rank*" values={myHighSchool?.graduating_school_array?.class_rank} />
      <ItemContent
        label="Graduating Class Size (approx.)*"
        values={myHighSchool?.graduating_school_array?.graduating_school_class_size}
      />
      <ItemContent
        label="GPA Weighting*"
        values={myHighSchool?.graduating_school_array?.graduating_school_gpa_type}
      />
      <ItemContent
        label="GPA Scale*"
        values={myHighSchool?.graduating_school_array?.graduating_school_gpa_scale}
      />
      <ItemContent
        label="Cumulative GPA*"
        values={myHighSchool?.graduating_school_array?.graduating_school_cumulative_gpa}
      />

      <ItemContent
        label="How are the grades for this class reported on your transcript?"
        values={myHighSchool?.graduating_school_array?.rank_weighting}
      />

      <div className="border w-100 my-3" />
      <div className="fs-5 text-field-light">High School Counselor Information</div>
      <ItemContent
        mdCol={4}
        label="Counselor's First Name"
        values={myHighSchool?.counselor_first_name}
      />
      <ItemContent
        mdCol={4}
        label="Counselor's Last Name"
        values={myHighSchool?.counselor_last_name}
      />
      <ItemContent mdCol={4} label="Counselor's Email" values={myHighSchool?.counselor_email} />

      {localStorage.getItem('userRole') === USER_ROLE.student && (
        <ModalCheckEducationFields
          show={show}
          onClose={handleClose}
          data={{
            myHighSchool: { ...myHighSchool },
            citizenshipData,
          }}
          page="highschool"
        />
      )}
    </Row>
  );
};

const SummaryColleges = () => {
  const [myCollege, setMyCollege] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await highSchoolServices.getCollege(params.id);
      setMyCollege({
        ...res,
        colleges_taken_coursework_array:
          checkEnableParseJSON(res?.colleges_taken_coursework_array) ?? [],
      });
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      <div className="d-flex justify-content-between mb-3">
        <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
          Colleges & Universities
        </Typography>
        {localStorage.getItem('userRole') === USER_ROLE.student && (
          <Button variant="primary" onClick={() => setShow(true)}>
            Check missing values
          </Button>
        )}
      </div>
      {myCollege && (
        <>
          <div className="text-field-light fs-5">College Courses or earned College Credit</div>
          <ItemContent
            mdCol={6}
            label="Have you taken college courses or earned college credit? *"
            values={myCollege?.is_taken_college_courses}
          />

          <ItemContent
            mdCol={6}
            label="Are you currently taking courses in college? *"
            values={myCollege?.is_currently_taking_courses_in_college}
          />
          <ItemContent
            mdCol={6}
            label="How many college credits have you earned to date? *"
            values={myCollege?.number_of_college_credits_have_earned}
          />
          <ItemContent
            mdCol={6}
            label="How many credits were earned after high school? *"
            values={myCollege?.number_of_credits_earned_after_school}
          />
          <div className="border w-100 my-3" />
          <div className="text-field-light fs-5">Colleges & Universities</div>

          <ItemContent
            mdCol={12}
            label="Indicate the number of colleges of universities you've taken a course at or received credit from"
            values={myCollege?.number_of_colleges_taken_coursework}
          />

          <div className="px-2">
            {myCollege?.colleges_taken_coursework_array?.map((item: any, index: number) =>
              buildContentCollege(item, index)
            )}
          </div>

          {
          localStorage.getItem('userRole') === USER_ROLE.student && (
            <ModalCheckEducationFields
              show={show}
              onClose={handleClose}
              data={myCollege}
              page="college"
            />
          )
          }
        </>
      )}
    </Row>
  );
};

const SummaryFuturePlan = () => {
  const [data, setData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();

  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await highSchoolServices.getFuturePlan(params.id);

      setData(res);
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      <div className='d-flex justify-content-between mb-3'>
        <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
          Future Plans
        </Typography>

        {localStorage.getItem('userRole') === USER_ROLE.student && (
          <Button variant="primary" onClick={() => setShow(true)}>
            Check missing values
          </Button>
        )}

      </div>
      {data && (
        <>
          <ItemContent
            values={data.which_best_describe_you}
            options={describesOptions}
            mdCol={12}
            label="Which best describes you? I am: *"
          />
          <ItemContent label="Career Interest" values={data?.career_interest} mdCol={6} />
          <ItemContent
            mdCol={6}
            values={data?.other_career_interest}
            label="Specify other organization *"
          />
          <ItemContent
            label="Highest Degree you intend to earn *"
            values={data?.highest_degree_intend_to_earn}
            mdCol={6}
          />

          {
          localStorage.getItem('userRole') === USER_ROLE.student && (
            <ModalCheckEducationFields
              show={show}
              onClose={handleClose}
              data={data}
              page="futureplan"
            />
          )
          }
        </>
      )}
    </Row>
  );
};
