import { countryOptions } from 'components/helper/countryPhoneCode';
import SelectInput from 'components/input/SelectInput';
import { Row } from 'react-bootstrap';
import GroupRadioButton from 'components/input/GroupRadioButton';
import { visaTypeOptions } from 'components/helper/appConfig';
import YesNoSwitch from 'components/input/YesNoSwitch';
import DatePickerInput from 'components/input/DatePicker';
import { csuCountry } from 'components/helper/helper';
import TextInput from 'components/input/textInput';
import { stateAndCounty } from 'components/helper/stateAndCounty';
import AutocompleteInput from 'components/input/AutoCompleteInput';

interface Props {
  formik: any;
  check: any;
}

const General = (props: Props) => {
  const { check } = props;
  return (
    <Row className="m-0">
      <SelectInput
        logo="span-cm-logo"
        form={props.formik}
        option={[
          'U.S. Citizen or U.S. National',
          'U.S. Dual Citizen',
          'U.S. Permanent Resident',
          'U.S. Refugee or Asylee',
          'DACA, undocumented, Deferred Enforced Departure or Temporary Protected Status',
          'Citizen of non-U.S. country',
        ]}
        label="Select your citizenship status*"
        isSatTest={true}
        name={'current_citizenship_status'}
        values={props.formik.values.current_citizenship_status}
        onChange={props.formik.handleChange}
        isMissing={!props.formik.values.current_citizenship_status && check}
      />

      {[
        'U.S. Dual Citizen',
        'U.S. Permanent Resident',
        'U.S. Refugee or Asylee',
        'DACA, undocumented, Deferred Enforced Departure or Temporary Protected Status',
        'Citizen of non-U.S. country',
      ].includes(props.formik.values.current_citizenship_status) && (
          <>
            <AutocompleteInput
              logo="span-cm-logo"
              multiple={true}
              onChange={(val: any) => {
                props.formik.setFieldValue('list_citizenship', val);
              }}
              option={countryOptions}
              mdCol={'12'}
              label={'List Citizenship(s) other than the U.S. *'}
              name={'list_citizenship'}
              values={props.formik.values.list_citizenship}
              isMissing={!props.formik.values?.list_citizenship?.length && check ? true : false}
            />
            <div className="text-field-light fw-bold span-csu-logo">International Agent</div>
            <YesNoSwitch
              // logo="span-csu-logo"
              label="Are you currently working with a representative or agency?*"
              onChange={props.formik.handleChange}
              name="csu_info.current_working_with_representative_or_agency"
              values={props.formik.values.csu_info?.current_working_with_representative_or_agency}
              isMissing={
                !props.formik.values.csu_info?.current_working_with_representative_or_agency && check
              }
            />
            {props.formik.values.csu_info?.current_working_with_representative_or_agency ===
              'true' && (
                <>
                  <SelectInput
                    className="mt-3"
                    logo="span-csu-logo"
                    label="Representative Type*"
                    isSatTest={true}
                    option={['', 'Agency Sponsored', 'Government', 'Other Sponsored', 'University']}
                    onChange={props.formik.handleChange}
                    name="csu_info.representative_type"
                    values={props.formik.values.csu_info?.representative_type}
                    isMissing={!props.formik.values.csu_info?.representative_type && check}
                  />
                  <TextInput
                    logo="span-csu-logo"
                    countText={49}
                    label="Representative Name*"
                    onChange={props.formik.handleChange}
                    name="csu_info.representative_name"
                    values={props.formik.values.csu_info?.representative_name}
                    isMissing={!props.formik.values.csu_info?.representative_name && check}
                    className='mt-3'
                  />
                  <TextInput
                    logo="span-csu-logo"
                    countText={49}
                    label="Representative Street Address"
                    onChange={props.formik.handleChange}
                    name="csu_info.representative_street_address"
                    values={props.formik.values.csu_info?.representative_street_address}
                  />
                  <TextInput
                    logo="span-csu-logo"
                    countText={49}
                    label="Representative Street Address Line 2"
                    onChange={props.formik.handleChange}
                    name="csu_info.representative_street_address_line_2"
                    values={props.formik.values.csu_info?.representative_street_address_line_2}
                  />
                  <TextInput
                    logo="span-csu-logo"
                    countText={49}
                    label="Representative City"
                    onChange={props.formik.handleChange}
                    name="csu_info.representative_city"
                    values={props.formik.values.csu_info?.representative_city}
                  />
                  <TextInput
                    logo="span-csu-logo"
                    countText={49}
                    label="Representative State/Province"
                    onChange={props.formik.handleChange}
                    name="csu_info.representative_state"
                    values={props.formik.values.csu_info?.representative_state}
                  />
                  <TextInput
                    logo="span-csu-logo"
                    countText={10}
                    type="number"
                    label="Representative Postal Code"
                    onChange={props.formik.handleChange}
                    name="csu_info.representative_postal_code"
                    values={props.formik.values.csu_info?.representative_postal_code}
                  />
                  <SelectInput
                    logo="span-csu-logo"
                    label="Representative Country*"
                    option={csuCountry}
                    onChange={props.formik.handleChange}
                    isMissing={!props.formik.values.csu_info?.representative_country && check}
                    name="csu_info.representative_country"
                    values={props.formik.values.csu_info?.representative_country}
                  />
                  <TextInput
                    logo="span-csu-logo"
                    countText={20}
                    label="Representative Phone*"
                    type="phoneNumber"
                    onChange={props.formik.handleChange}
                    name="csu_info.representative_phone"
                    values={props.formik.values.csu_info?.representative_phone}
                    isMissing={!props.formik.values.csu_info?.representative_phone && check}
                  />
                  <TextInput
                    logo="span-csu-logo"
                    countText={49}
                    label="Representative Email*"
                    onChange={props.formik.handleChange}
                    name="csu_info.representative_email"
                    values={props.formik.values.csu_info?.representative_email}
                    isMissing={!props.formik.values.csu_info?.representative_email && check}
                  />
                </>
              )}

            <div className="text-field-light fw-bold mt-3">International Applicant Details</div>
            <TextInput
              countText={4}
              className="py-3"
              logo="span-csu-logo"
              label="What year did you (or do you plan to) move to the U.S.?"
              type="number"
              onChange={props.formik.handleChange}
              name="csu_info.year_move_to_US"
              values={props.formik.values.csu_info?.year_move_to_US}
              isMissing={!props.formik.values.csu_info?.year_move_to_US && check}
            />
            <div />
            <AutocompleteInput
              logo="span-uc-csu-logo"
              form={props.formik}
              option={countryOptions}
              label={'What is your main country of Citizenship*'}
              isCuScuLogo={true}
              name={'country'}
              values={props.formik.values.country}
              feedback={
                props.formik.touched.country &&
                props.formik.errors.country && (
                  <span className="text-danger">{props.formik.errors.country}</span>
                )
              }
              isMissing={!props.formik.values.country && check}
            />
            {props.formik.values.country !== 'United States of America' && (
              <>
                <div />
                <GroupRadioButton
                  name="citizenship_status"
                  mdCol={12}
                  values={props.formik.values.citizenship_status}
                  onChange={props.formik.handleChange}
                  options={[
                    {
                      value: 'Legal U.S. permanent resident (green card)',
                    },
                    {
                      value: 'Refugee',
                    },
                    {
                      value:
                        'Non-immigrant/foreign visa (you have a visa or will be applying for one)',
                    },
                  ]}
                  label="What will your citizenship status be on the date you submit this application? *"
                  isMissing={!props.formik.values.citizenship_status && check}
                />
                <div />
                {props.formik.values.citizenship_status ===
                  'Legal U.S. permanent resident (green card)' && (
                    <>
                      <SelectInput
                        option={csuCountry}
                        name="csu_info.country"
                        label="Country of Citizenship"
                        values={props.formik.values.csu_info?.country}
                        onChange={props.formik.handleChange}
                      />
                      <DatePickerInput
                        name="csu_info.date"
                        label="Permanent Resident / Green Card Date Issued*"
                        values={props.formik.values.csu_info?.date}
                        onChange={(e) => props.formik.setFieldValue('csu_info.date', e)}
                        isMissing={!props.formik.values.csu_info?.date && check}
                      />
                    </>
                  )}
                {props.formik.values.citizenship_status === 'Refugee' && (
                  <SelectInput
                    option={csuCountry}
                    name="csu_info.country"
                    label="Country of Citizenship*"
                    values={props.formik.values.csu_info?.country}
                    onChange={props.formik.handleChange}
                    isMissing={!props.formik.values.csu_info?.country && check}
                  />
                )}
                {props.formik.values.citizenship_status ===
                  'Non-immigrant/foreign visa (you have a visa or will be applying for one)' && (
                    <>
                      <YesNoSwitch
                        values={props.formik.values.have_us_visa}
                        onChange={props.formik.handleChange}
                        label="Do you currently have a U.S. visa? *"
                        name="have_us_visa"
                        isMissing={!props.formik.values.have_us_visa && check}
                      />
                      <div />
                      {props.formik.values.have_us_visa === 'false' && (
                        <>
                          <SelectInput
                            label="Which type of U.S. visa will you apply for (or have applied for)? *"
                            option={visaTypeOptions}
                            name="type_of_apply_for_us_visa"
                            onChange={props.formik.handleChange}
                            values={props.formik.values.type_of_apply_for_us_visa}
                            isMissing={!props.formik.values.type_of_apply_for_us_visa && check}
                          />
                          <SelectInput
                            option={csuCountry}
                            name="csu_info.country"
                            label="Country of Citizenship"
                            values={props.formik.values.csu_info?.country}
                            onChange={props.formik.handleChange}
                          />
                        </>
                      )}
                      {props.formik.values.have_us_visa === 'true' && (
                        <>
                          <SelectInput
                            label="Current visa type *"
                            option={visaTypeOptions}
                            name="type_of_current_us_visa"
                            onChange={props.formik.handleChange}
                            values={props.formik.values.type_of_current_us_visa}
                            isMissing={!props.formik.values.type_of_current_us_visa && check}
                          />

                          <YesNoSwitch
                            logo="span-csu-logo"
                            values={props.formik.values.csu_info?.require_F1_Visa_or_J1_visa}
                            onChange={props.formik.handleChange}
                            label="Do you have or will you require an F1 Visa (student) or J1 Visa (exchange) to study at the California State University (CSU)?*"
                            name="csu_info.require_F1_Visa_or_J1_visa"
                            isMissing={!props.formik.values.csu_info?.require_F1_Visa_or_J1_visa && check}
                          />

                          {props.formik.values.csu_info?.require_F1_Visa_or_J1_visa === 'true' ? (
                            <>
                              <GroupRadioButton
                                logo="span-csu-logo"
                                values={props.formik.values.csu_info?.visa_you_have_at_CSU}
                                onChange={props.formik.handleChange}
                                name="csu_info.visa_you_have_at_CSU"
                                label="Which student visa do you have, or will you have when you study at the CSU?"
                                options={['F1 (Student)', 'J1 (Exchange)']}
                              />
                              <DatePickerInput
                                logo="span-csu-logo"
                                label="Date Issued (if you have already been issued a student visa)"
                                name="csu_info.visa_you_have_at_CSU_date_issued"
                                values={props.formik.values.csu_info?.visa_you_have_at_CSU_date_issued}
                                onChange={(e) =>
                                  props.formik.setFieldValue(
                                    'csu_info.visa_you_have_at_CSU_date_issued',
                                    e
                                  )
                                }
                              />
                            </>
                          ) : (
                            <SelectInput
                              logo="span-csu-logo"
                              label="What U.S. State/Possession do you regard as your permanent home?*"
                              option={Object.keys(stateAndCounty)}
                              name="csu_info.us_state_you_regard_as_permanent_home"
                              onChange={props.formik.handleChange}
                              values={
                                props.formik.values.csu_info?.us_state_you_regard_as_permanent_home
                              }
                              isMissing={props.formik.values.csu_info?.us_state_you_regard_as_permanent_home}
                            />
                          )}
                        </>
                      )}
                      <div />
                      <DatePickerInput
                        name="csu_info.visa_date_issued"
                        label="Visa Date Issued"
                        values={props.formik.values.csu_info?.visa_date_issued}
                        onChange={(e) => props.formik.setFieldValue('csu_info.visa_date_issued', e)}
                      />

                      <YesNoSwitch
                        onClear={() => props.formik.setFieldValue('will_you_have_different_visa', '')}
                        name="will_you_have_different_visa"
                        values={props.formik.values.will_you_have_different_visa}
                        onChange={props.formik.handleChange}
                        label="By the time you attend UC, will you have a different visa?*"
                        isMissing={!props.formik.values.will_you_have_different_visa && check}
                      />
                      {props.formik.values.will_you_have_different_visa === 'true' && (
                        <SelectInput
                          onChange={props.formik.handleChange}
                          label="Planned visa type *"
                          option={visaTypeOptions}
                          name="planned_visa_type"
                          values={props.formik.values.planned_visa_type}
                          isMissing={!props.formik.values.planned_visa_type && check}
                        />
                      )}
                    </>
                  )}
              </>
            )}
          </>
        )}

      <div className="text-dark span-csu-logo">
        If you are currently an active F-1 student, enter your SEVIS ID:
      </div>
      <div className="text-dark">Student Must enter this information manually on the app</div>
      <div className="border w-100 my-3" id="CaliforniaResidency" />
      <div className="fs-5 py-1">Social Security</div>
      <div
        className="text-field-light mt-3"
        style={{
          backgroundColor:
            !props.formik.values.is_have_us_social_security_number && check ? '#FFEBEB' : '',
          display: 'inline-block',
        }}
      >
        Do you have a U.S. Social Security number or an Individual Taxpayer Identification Number
        (ITIN)?*
      </div>
      <div className="text-field-light uc-coallition-flag">
        If you apply for financial aid, we use the Social Security number or ITIN you provide to
        match your application to your Free Application for Federal Student Aid (FAFSA) or
        California Dream Act application. See 'Help' for more information on the additional uses of
        the Social Security number.
      </div>
      <YesNoSwitch
        className="my-3 uc-coallition-flag"
        name={'is_have_us_social_security_number'}
        values={props.formik.values.is_have_us_social_security_number}
        onChange={props.formik.handleChange}
      />
      <div className="text-field-light">
        To protect your privacy, we do not ask for your social security number, however you'll need
        to input this information directly on the application when it is requested.
      </div>
    </Row>
  );
};

export default General;
