import { countryOptions } from 'components/helper/countryPhoneCode';
import DatePickerInput from 'components/input/DatePicker';
import SelectInput from 'components/input/SelectInput';
import YesNoSwitch from 'components/input/YesNoSwitch';
import TextInput from 'components/input/textInput';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import collegeServices from 'services/collegeServices';

interface Props {
  formik: any;
  check?: any;
}

const BirthPalace = (props: Props) => {
  const { check } = props;
  const [stateOptions, setStateOptions] = React.useState<any[]>([]);
  React.useEffect(() => {
    collegeServices.getState().then((res: any) => {
      const states = res.map((item: any) => {
        return item.descr;
      });
      setStateOptions(states);
    });
    return () => {
      setStateOptions([]);
    };
  }, []);

  return (
    <Row className="m-0">
      <div className="fs-5 p-1">Birthplace</div>
      <SelectInput
        form={props.formik}
        option={countryOptions}
        name="country_birthplace"
        values={props.formik.values.country_birthplace}
        onChange={props.formik.handleChange}
        label="Country / Region / Territory*"
        isMissing={!props.formik.values.country_birthplace && check}
      />
      <SelectInput
        hidden={props.formik.values.country_birthplace !== 'United States of America'}
        className="common-flag"
        name="number_of_year_in_us"
        values={props.formik.values.number_of_year_in_us}
        isMissing={!props.formik.values.number_of_year_in_us && check}
        onChange={props.formik.handleChange}
        label="Number of years you have lived in the United States*"
        option={[
          '0',
          '< 1 years',
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '> 20 years',
        ]}
      />
      <TextInput
        name="city_birthplace"
        values={props.formik.values.city_birthplace}
        onChange={props.formik.handleChange}
        label="City of birth*"
        countText={20}
        isMissing={!props.formik.values.city_birthplace && check}
      />

      <SelectInput
        hidden={props.formik.values.country_birthplace !== 'United States of America'}
        option={stateOptions}
        name="state_birthplace"
        values={props.formik.values.state_birthplace}
        onChange={props.formik.handleChange}
        label="State*"
        mdCol={'6'}
        isMissing={!props.formik.values.state_birthplace && check}
      />

      <Col md={6}>
        <YesNoSwitch
          name="statement_legal_resident.have_lived_in_California_continuously_since_birth"
          values={
            props.formik.values.statement_legal_resident
              ?.have_lived_in_California_continuously_since_birth
          }
          onChange={props.formik.handleChange}
          label="Have you lived in California continuously since birth?*"
          isMissing={
            !props.formik.values.statement_legal_resident
              ?.have_lived_in_California_continuously_since_birth && check
          }
        />
      </Col>

      <DatePickerInput
        hidden={
          props.formik.values.statement_legal_resident
            ?.have_lived_in_California_continuously_since_birth === 'true'
        }
        name="statement_legal_resident.when_your_present_stay_in_California_begin"
        values={
          props.formik.values.statement_legal_resident?.when_your_present_stay_in_California_begin
        }
        onChange={(value) =>
          props.formik.setFieldValue(
            'statement_legal_resident.when_your_present_stay_in_California_begin',
            value
          )
        }
        label="When did your present stay in California begin?*"
        mdCol="6"
        isMissing={
          !props.formik.values.statement_legal_resident?.when_your_present_stay_in_California_begin && check
        }
      />
      {props.formik.values.country_birthplace !== 'United States of America' && (
        <>
          <div className="text-field-light">Years inside and outside the United States</div>
          <TextInput
            mdCol={12}
            logo="span-csu-logo"
            name="csu_info.year_move_to_us"
            label="If you were born outside of the U.S., what year did you or will you move to the U.S."
            isMissing={!props.formik.values.csu_info?.year_move_to_us && check}
            onChange={props.formik.handleChange}
            values={props.formik.values.csu_info?.year_move_to_us}
          />

          <SelectInput
            option={[
              '0',
              '< 1 years',
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16',
              '17',
              '18',
              '19',
              '20',
              '> 20 years',
            ]}
            className="common-flag end-flag"
            name="number_of_year_outside_us"
            values={props.formik.values.number_of_year_outside_us}
            onChange={props.formik.handleChange}
            label="Number of years you have lived outside the United States*"
            isMissing={!props.formik.values.number_of_year_outside_us && check}
          />
        </>
      )}
    </Row>
  );
};

export default BirthPalace;
