import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';

type Props = {
  data: any[];
  column: any[];
  minWidth?: any;
  width?: any;
  equalColumns?: boolean; 
}

const SimpleTable = (props: Props) => {
  const {data, column, equalColumns = true} = props;
  
  const columnWidth = equalColumns ? `${100 / column.length}%` : undefined;
  
  const renderColum = (row:any[], col:any) =>{
    if (col.formatDate) {
      return moment(row[col.value]).format('MM-DD-YYYY');
    }
    if (col.isYesNo) {
      return row[col.value] ? 'Yes' : 'No'
    }
    if (col.isBoolean) {
      return row[col.value] ? 'True' : 'False'
    }
    if (col.fontsize5) {
      return <div className="fs-5">{row[col.value]}</div>
    }
    return row[col.value];
  }

  return (
    <TableContainer component={Paper}>
      <Table 
        sx={{ 
          minWidth: props.minWidth ?? 640, 
          width: props.width ?? '100%',
          tableLayout: equalColumns ? 'fixed' : 'auto'
        }} 
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            {column.map((col: { label: string; value: any; sx?: any }, index: number) => (
              <TableCell 
                key={index} 
                sx={{ 
                  width: columnWidth, 
                  textAlign: 'center',
                  ...col.sx 
                }}
              >
                {col.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(data && data.length>0) ? (
            <>
            {(data ?? []).map((row: any, index: number) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {column.map((col: { 
                  name: string; 
                  value: any; 
                  formatDate?: boolean, 
                  align?: any, 
                  sx?: any 
                }) => (
                  <TableCell 
                    key={col.value} 
                    align={col.align || 'center'} 
                    sx={{ 
                      width: columnWidth,
                      ...col.sx
                    }}
                  >
                    {renderColum(row, col)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            </>
          ):(
            <TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell colSpan={column.length} align="center">
                No data available in table
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SimpleTable;