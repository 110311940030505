/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import {
  BoolToString,
  checkEnableParseJSON,
  formatBoolean,
  reorder,
  setArrayLength,
} from 'components/helper/helper';
import { getIBSubjectTest, updateIBSubjectTest } from 'app/testingSlice/testingSlice';
import IBSubjectConfig from './components/IBSubjectConfig';
import { debounce } from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CheckboxInput from 'components/input/CheckboxInput';
import { ExpandMore } from 'screen/activitiesAndHonors/Activities';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
type Props = {};

const IBSubjectTest = (props: Props) => {
  const MAX_IB_TESTS = 20;
  const [loading, setLoading] = React.useState(false);
  const testing = useAppSelector((store) => store.testing);
  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const [ACTData, setACTData] = React.useState<any[]>([]);
  const query = new URLSearchParams(useLocation().search);
  const check = query.get('check');
  const indexExpanded = query.get('index');
  const [expandAll, setExpandAll] = React.useState(false);
  const [expandActive, setExpandActive] = React.useState<number>(0);

  const formik = useFormik({
    initialValues: {
      is_have_ib_exam_report: '',
      number_of_ib_test_report: '0',
      ib_test_array: '',
      is_completed_full_ib: false,
    },
    onSubmit: async (values) => {
      studentId = Cookies.get('userId');
      token = Cookies.get(authenticateSettings.tokenName);
      if (token && studentId) {
        try {
          const newValues = {
            ...values,
            student_id: studentId,
            is_have_ib_exam_report: formatBoolean(values.is_have_ib_exam_report),
            ib_test_array: JSON.stringify(ACTData),
          };
          await dispatch(updateIBSubjectTest(newValues)).unwrap();
        } catch (ex) {
          console.log('Error: ', ex);
        }
      } else {
        handleLogout();
      }
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = () => {
    formik.handleSubmit();
    const nextRoute = Object.keys(testing.roleTest).find(
      (key, index) =>
        index > Object.keys(testing.roleTest).indexOf('ibSubjectTest') &&
        testing.roleTest[key] === true
    );
    history.push(nextRoute ? routers?.[nextRoute]?.path : routers.otherTest.path);
  };

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      try {
        let res = await dispatch(getIBSubjectTest(studentId)).unwrap();
        const dataLoaded: any = res;
        if (dataLoaded) {
          const values: any = {
            ...dataLoaded,
            is_have_ib_exam_report: BoolToString(dataLoaded.is_have_ib_exam_report),
          };
          formik.setValues(values);
          setACTData(checkEnableParseJSON(dataLoaded.ib_test_array) ?? []);
        }
        setLoading(false);
        formik.setStatus('idle');
      } catch (ex) {
        console.log('Error: ', ex);
      }
      setLoading(false);
    } else {
      handleLogout();
    }
  };

  React.useEffect(() => {
    getDetail();
  }, []);

  React.useEffect(() => {
    setACTData(setArrayLength(ACTData, formik.values.number_of_ib_test_report));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.number_of_ib_test_report]);
  // Auto save function ==========
  const notInitialRender = React.useRef(false);
  React.useEffect(() => {
    debounceSaving();
  }, [formik.values, ACTData]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (notInitialRender.current) {
        if (!loading) formik.handleSubmit();
      } else {
        notInitialRender.current = true;
      }
    }, 1500),
    []
  );
  // Auto save function ==========
  const handleAddIB = () => {
    if (ACTData.length > 19) return;
    setACTData([...ACTData, {}]);
  };

  const [forceRenderKey, setForceRenderKey] = React.useState(0);

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const dataList = reorder(ACTData, result.source.index, result.destination.index);
    setACTData([...dataList]);
    setForceRenderKey(forceRenderKey + 1);
  };

  const handleExpandAll = () => {
    if (!check) {
      setExpandAll(!expandAll);
      if (!expandAll) {
        setExpandActive(ACTData.length);
      } else {
        setExpandActive(0);
      }
    } else {
      setExpandAll(!expandAll);
    }
  };

  React.useEffect(() => {
    if (expandActive === ACTData.length) {
      setExpandAll(true);
    }

    if (expandActive === 0) {
      setExpandAll(false);
    }
  }, [expandActive, ACTData.length]);
  const handleExpandActive = (value: any) => {
    value
      ? setExpandActive(Math.abs(expandActive - 1))
      : setExpandActive(Math.abs(expandActive + 1));
  };

  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[]}
        title="Standardized Testing"
        process={1}
        icon={<CalculateOutlinedIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <Row className="m-0">
              <div className="text-field-mid fs-3 mb-4">IB Subject Tests</div>

              <div className="pl-5 mb-4">
                <CheckboxInput
                  className="pl-5"
                  label="Check if you have completed or plan to complete the full IB diploma"
                  values={formik.values.is_completed_full_ib}
                  name="is_completed_full_ib"
                  onChange={formik.handleChange}
                />
              </div>

            
              {ACTData.length > 0 && (
                <div className="d-flex justify-content-end">
                  <ExpandMore
                    aria-expanded={expandAll}
                    aria-label="show all"
                    onClick={handleExpandAll}
                  >
                    {!expandAll ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
                  </ExpandMore>
                </div>
              )}

              <DragDropContext onDragEnd={onDragEnd} key={forceRenderKey}>
                <Droppable droppableId="list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {ACTData.map((item, index) => (
                        <div key={index + '-' + ACTData.length}>
                          <Draggable draggableId={index + '-' + ACTData.length} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  key={index + '-' + ACTData.length}
                                  className="position-relative"
                                >
                                  <IBSubjectConfig
                                    key={index}
                                    handleSave={(values) => {
                                      const newACTData = [...ACTData];
                                      newACTData.splice(index, 1, values);
                                      setACTData(newACTData);
                                    }}
                                    item={item}
                                    indexText={index + 1 + '/' + ACTData.length}
                                    check={check}
                                    indexExpanded={indexExpanded + `/` + ACTData.length}
                                    expandAll={expandAll}
                                    handleExpandActive={handleExpandActive}
                                  />
                                  <RemoveCircleOutlineIcon
                                    className="position-absolute text-field-light pointer"
                                    style={{ right: '-24px', top: '12px' }}
                                    onClick={() => {
                                      const newACTData = [...ACTData];
                                      newACTData.splice(index, 1);
                                      setACTData(newACTData);
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        </div>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className="my-3 w-100 d-flex flex-column align-items-center">
                <div className="text-field-light sized70">{`You can add up to ${MAX_IB_TESTS} IB Tests`}</div>
                <Button variant="primary" className="p-2 px-5" onClick={handleAddIB}>
                  Add IB Test
                </Button>
              </div>
              {/* </>
              )} */}
            </Row>
            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {testing.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default IBSubjectTest;
