/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';
import { RequiredField} from './helper/checkFieldsMissingCondition';

interface ModalCheckCollgeCourseFieldProps {
  show?: boolean;
  onClose: () => void;
  data: any[];
}

export const ModalCheckCollgeCourseField = (props: ModalCheckCollgeCourseFieldProps) => {
  const page = 'college-course';
  const { show, onClose, data } = props;
  const [fieldsFail, setFieldsFail] = useState<any[]>([]);
  const history = useHistory();

  const checkRequiredFields = (data:any) =>{   
    const result: RequiredField[]  = [];
    try {
      if (data && data.length>0) {
        for (let i=0; i< data.length; i++) {
            let item:any = data[i];
            //console.log('item:', item);
            if (!item?.collegeName ||item?.collegeName === '') {
              result.push({
                key: 'collegeName',
                name: 'Select the school for this coursework.',
                page: `/application/${page}/?check=1#collegeName_${i+1}`
              })
            }
            if (!item?.academicYear || item?.academicYear === '') {
              result.push({
                key: 'academicYear',
                name: 'Academic year *',
                page: `/application/${page}/?check=1#academicYear_${i+1}`
              })
            }
            if (!item?.academicStatus || item?.academicStatus === '') {
              result.push({
                key: 'academicStatus',
                name: 'College Academic Status',
                page: `/application/${page}/?check=1#academicStatus_${i+1}`
              })
            }
            if (!item?.scheduleSystem || item?.scheduleSystem === '') {
              result.push({
                key: 'scheduleSystem',
                name: 'Schedule System *',
                page: `/application/${page}/?check=1#scheduleSystem_${i+1}`
              })
            }
            if (!item?.term || item?.term === '') {
              result.push({
                key: 'term',
                name: 'Term *',
                page: `/application/${page}/?check=1#term_${i+1}`
              })
            }

            let collegeCourseArray:any[] = item.collegeCourseArray;
            if (collegeCourseArray && collegeCourseArray.length>0) {
              for(let j=0; j<collegeCourseArray.length;j++) {
                let subject:any = collegeCourseArray[j];
                if (!subject?.dept) {
                  result.push({
                    key: 'dept',
                    name: 'Dept. Abbreviation - Ex. ENG',
                    page: `/application/${page}/?check=1#dept_${i+1}_${j+1}`
                  })
                }
                if (!subject?.courseNumber) {
                  result.push({
                    key: 'courseNumber',
                    name: 'Course Number - Ex. 100',
                    page: `/application/${page}/?check=1#courseNumber_${i+1}_${j+1}`
                  })
                }
                if (!subject?.courseName) {
                  result.push({
                    key: 'courseName',
                    name: 'Course Name*',
                    page: `/application/${page}/?check=1#courseName_${i+1}_${j+1}`
                  })
                }
                if (!subject?.subject) {
                  result.push({
                    key: 'subject',
                    name: 'A-G Subject Area',
                    page: `/application/${page}/?check=1#subject_${i+1}_${j+1}`
                  })
                }
                if (!subject?.completionStatus) {
                  result.push({
                    key: 'completionStatus',
                    name: 'Completion Status',
                    page: `/application/${page}/?check=1#completionStatus_${i+1}_${j+1}`
                  })
                }
                if (!subject?.grade) {
                  result.push({
                    key: 'grade',
                    name: 'Grade*',
                    page: `/application/${page}/?check=1#grade_${i+1}_${j+1}`
                  })
                }



              }
            }

        }
      }

    }
    catch(ex){
      console.log('Error:', ex);
    }

    setFieldsFail(result);
  }

  useEffect(() => {
    checkRequiredFields(data);
  }, [data]);


  const handleClick = (field: any) => {
    history.push(field.page);
    onClose();
  };

  return (
    <Modal show={show} onHide={() => onClose()}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {data && fieldsFail.length > 0 ? (
          <div>
            <h3>These fields are not yet valid, please complete them: </h3>
            <ul style={{ fontSize: '1rem', color: 'red' }}>
              {fieldsFail.map((field, index) => (
                <li key={index} onClick={() => handleClick(field)} style={{ cursor: 'pointer' }}>
                  {field.name}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div>
            <h3>All required fields are filled</h3>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}