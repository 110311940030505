import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Row } from 'react-bootstrap';
import DatePickerInput from 'components/input/DatePicker';
import SelectInput from 'components/input/SelectInput';
import YesNoSwitch from 'components/input/YesNoSwitch';
import TextInput from 'components/input/textInput';
import CheckboxInput from 'components/input/CheckboxInput';
import { formatDate } from 'components/helper/helper';
import { useLocation } from 'react-router-dom';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Props {
  formik: any;
  index: number;
}

const CLEPConfig = ({ formik, index }: Props) => {
  const [expanded, setExpanded] = React.useState(false);
  const query = new URLSearchParams(useLocation().search);
  const check = query.get('check');
  const indexExpand = query.get('index');
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const elementRef = React.useRef<HTMLDivElement>(null);


  React.useEffect(() => {
    if (check && indexExpand && indexExpand === (index + 1).toString()) {
      setExpanded(true);

      setTimeout(() => {
        if (elementRef.current) {
          elementRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }, 100);
    }
  }, [indexExpand]);


  const [errorText, setErrorText] = React.useState('');

  const buildHeader = () => {
    let title = formik.values.csu_info[index]?.CLEPExam;
    const details = [];

    if (formik.values.csu_info[index]?.is_taken_exam === 'true' && formik.values.csu_info[index]?.date_taken) {
      details.push(
        formatDate(formik.values.csu_info[index]?.date_taken, 'MM/DD/YYYY'),
      );
    }

    if (formik.values.csu_info[index]?.is_taken_exam === 'true' && formik.values.csu_info[index]?.score && formik.values.csu_info[index]?.score !== "") {
      details.push(`Score: ${formik.values.csu_info[index]?.score}`);
    }

    if (formik.values.csu_info[index]?.is_taken_exam === 'false' && formik.values.csu_info[index]?.plan_taking_on) {
      details.push(
        formatDate(formik.values.csu_info[index]?.plan_taking_on, 'MM/DD/YYYY'),
      );
    }

    return !details.length ? title : `${title} - ${details.join(' - ')}`;
  }
  return (
    <Card className="w-100 my-3 px-0" ref={elementRef}>
      <CardActions
        disableSpacing
        onClick={handleExpandClick}
        sx={{
          backgroundColor: expanded ? '#AAEBF7' : 'none',
          borderBottom: expanded ? '2px solid #4D88F0' : 'none',

          marginLeft: '-8px',
        }}
      >
        <div className="w-100 d-flex justify-content-between ms-2">
          <span className={`p-1`}>{buildHeader()}</span>
          <strong className="text-field-light">{index + 1}</strong>
        </div>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Row className="m-2">
            <SelectInput
              logo="span-csu-logo"
              option={[
                'American Government',
                'American Literature',
                'Analyzing and Interpreting Literature',
                'Biology',
                'Calculus',
                'Chemistry',
                'College Algebra',
                'College Competition',
                'College Competition Modular',
                'College Mathematics',
                'English Literature',
                'Financial Accounting',
                'French Level I',
                'French Level II',
                'German Level I',
                'German Level II',
                'History of the United States I: Early Colonization to 1877',
                'History of the United States II: 1865 to Present',
                'Human Growth and Development',
                'Humanities',
                'Information Systems',
                'Introduction to Educational Opportunities',
                'Introduction to Educational Psychology',
                'Introductory to Business Law',
                'Introductory to Psychology',
                'Introductory to Sociology',
                'Natural Sciences',
                'Precalculus',
                'Principles of Macroeconomics',
                'Principles of Management',
                'Principles of Marketing',
                'Principles of Microeconomics',
                'Social Sciences and History',
                'Spanish Level I',
                'Spanish Level II',
                'Spanish and Writing Level I',
                'Spanish and Writing Level II',
                'Western Civilization I: Ancient Near East to 1648',
                'Western Civilization II: 1648 to Present',
              ]}
              label="CLEP Exam"
              isSatTest={true}
              values={formik.values.csu_info[index]?.CLEPExam}
              name={`csu_info[${index}].CLEPExam`}
              onChange={formik.handleChange}
            />
            <YesNoSwitch
              className="mb-3"
              logo="span-csu-logo"
              label="Have your already taken this exam?*"
              values={formik.values.csu_info[index]?.is_taken_exam}
              name={`csu_info[${index}].is_taken_exam`}
              onChange={formik.handleChange}
              isMissing={formik.values.csu_info[index]?.is_taken_exam === '' && check}
            />
            {formik.values.csu_info[index]?.is_taken_exam === 'true' ? (
              <>
                <DatePickerInput
                  logo="span-csu-logo"
                  label="CLEP Exam Date Taken*"
                  values={formik.values.csu_info[index]?.date_taken}
                  isLepTestLogo={true}
                  name={`csu_info[${index}].date_taken`}
                  onChange={(val) => formik.setFieldValue(`csu_info[${index}].date_taken`, val)}
                  isMissing={formik.values.csu_info[index]?.date_taken === '' && check}
                />
                <TextInput
                  logo="span-csu-logo"
                  type="number"
                  label="CLEP Exam Score*"
                  values={formik.values.csu_info[index]?.score}
                  name={`csu_info[${index}].score`}
                  decimalStep={1}
                  onChange={(e) => {
                    formik.handleChange(e);
                    e.target.value > 80 || e.target.value < 20 || e.target.value % 1 !== 0
                      ? setErrorText('Invalid Score')
                      : setErrorText('');
                  }}
                  feedback={errorText ? <span className="text-danger">{errorText}</span> : null}
                  isMissing={formik.values.csu_info[index]?.score === '' && check}
                />
                <CheckboxInput
                  logo="span-csu-logo"
                  className="mx-4"
                  label="Score Not Yet Received"
                  values={formik.values.csu_info[index]?.is_not_received}
                  name={`csu_info[${index}].is_not_received`}
                  onChange={formik.handleChange}
                />
              </>
            ) : (
              <DatePickerInput
                logo="span-csu-logo"
                label="When do your plan on taking this exam?"
                values={formik.values.csu_info[index]?.plan_taking_on}
                name={`csu_info[${index}].plan_taking_on`}
                onChange={(val) => formik.setFieldValue(`csu_info[${index}].plan_taking_on`, val)}
                isMissing={formik.values.csu_info[index]?.plan_taking_on === '' && check}
              />
            )}
          </Row>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default CLEPConfig;
