/* eslint-disable react-hooks/exhaustive-deps */
import { getCollegeData } from 'app/highSchoolSlice/highSchoolSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import CardConfig from 'components/CardConfig';
import { checkEnableParseJSON, setArrayLength } from 'components/helper/helper';
import CheckboxInput from 'components/input/CheckboxInput';
import GroupRadioButton from 'components/input/GroupRadioButton';
import SelectInput from 'components/input/SelectInput';
import TextInput from 'components/input/textInput';
import { FormikProps } from 'formik';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import { FormCourse, SubjectDetail } from 'modal/course';
import React from 'react';
import {
  gradingScale2Options,
  classTypeOption,
  subjectAreaOptions,
  getSubjectsByType,
} from './courseConstant';
import SuggestCourse from './SuggestCourse';
import courseServices from 'services/courseServices';
import { checkCouserNameIncludes, agSubjectAreaValues } from 'components/helper/helper';
import { usePreviousProps } from '@mui/utils';
import {
  getACTSubjectTest,
  getIBSubjectTest,
  updateACTSubjectTest,
  updateIBSubjectTest,
} from 'app/testingSlice/testingSlice';

type Props = {
  courseIndex: number; // 1,2,3...
  form: FormikProps<FormCourse>;
  item: SubjectDetail;
  index: number; // 0.1.2.
  total: number;
  is12?: boolean;
  gradingOptions: any[];
  expandAll?: boolean;
};

const SubjectConfig = (props: Props) => {
  const [title, setTitle] = React.useState('');
  const [isExist, setIsExist] = React.useState<any>();
  const [apTestArray, setApTestArray] = React.useState<any>([]);
  const [ibTestArray, setIbTestArray] = React.useState<any>([]);
  const collegeData = useAppSelector((store) => store.highSchool.collegeData);
  const buildName = (name: string) => {
    return `subjectDetails[${props.index}].${name}`;
  };

  const formItemValues = props.form.values.subjectDetails[props.index];
  const highschoolData = useAppSelector((store) => store.highSchool.highschoolListObject)
    .filter((item) => item.high_school_name === props.form.values.schoolName)
    .map((item) => {
      return typeof item.high_school_object === 'object'
        ? item.high_school_object
        : checkEnableParseJSON(item.high_school_object, {});
    })[0];

  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const check = query.get('check');

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const getTestArray = async (studentId: string) => {
    const res = await dispatch(getACTSubjectTest(studentId));
    const dataLoaded: any = res.payload;

    if (dataLoaded) {
      const APTestArray = checkEnableParseJSON(dataLoaded.ap_test_array, []) ?? [];
      setApTestArray(APTestArray);
    }
  };

  const getIBArray = async (studentId: string) => {
    let res = await dispatch(getIBSubjectTest(studentId)).unwrap();
    const dataLoaded: any = res;
    if (dataLoaded) {
      const IBTestArray = checkEnableParseJSON(dataLoaded.ib_test_array, []) ?? [];
      setIbTestArray(IBTestArray);
    }
  };

  React.useEffect(() => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      if (props.form && !collegeData.length) dispatch(getCollegeData(studentId));
      getTestArray(studentId);
      getIBArray(studentId);
    } else {
      handleLogout();
    }
  }, []);

  const [semesterOption, setSemesterOption] = React.useState<any[]>([]);
  const initSchedule = () => {
    switch (props.form.values.schedule) {
      case 'Semester':
        setSemesterOption([
          { value: 'isFirstSemester', label: 'First Semester', fieldIndex: 0 },
          { value: 'isSecondSemester', label: 'Second Semester', fieldIndex: 1 },
        ]);
        props.form.setFieldValue(
          buildName('grade'),
          setArrayLength(props.form.values.subjectDetails[props.index]?.grade, 2, '')
        );
        props.form.setFieldValue(
          buildName('credits'),
          setArrayLength(props.form.values.subjectDetails[props.index]?.credits, 2, '')
        );
        break;
      case 'Trimester':
        setSemesterOption([
          { value: 'isFirstSemester', label: 'First Trimester', fieldIndex: 0 },
          { value: 'isSecondSemester', label: 'Second Trimester', fieldIndex: 1 },
          { value: 'isThirdSemester', label: 'Third Trimester', fieldIndex: 2 },
        ]);
        props.form.setFieldValue(
          buildName('grade'),
          setArrayLength(props.form.values.subjectDetails[props.index]?.grade, 3, '')
        );
        props.form.setFieldValue(
          buildName('credits'),
          setArrayLength(props.form.values.subjectDetails[props.index]?.credits, 3, '')
        );
        break;
      case 'Quarters':
        setSemesterOption([
          { value: 'isFirstSemester', label: 'First Quarter', fieldIndex: 0 },
          { value: 'isSecondSemester', label: 'Second Quarter', fieldIndex: 1 },
          { value: 'isThirdSemester', label: 'Third Quarter', fieldIndex: 2 },
          { value: 'isFourthSemester', label: 'Fourth Quarter', fieldIndex: 3 },
        ]);
        props.form.setFieldValue(
          buildName('grade'),
          setArrayLength(props.form.values.subjectDetails[props.index]?.grade, 4, '')
        );
        props.form.setFieldValue(
          buildName('credits'),
          setArrayLength(props.form.values.subjectDetails[props.index]?.credits, 4, '')
        );
        break;
      default:
        setSemesterOption([]);
        props.form.setFieldValue(buildName('grade'), []);
        props.form.setFieldValue(buildName('credits'), []);
    }
  };
  React.useEffect(() => {
    initSchedule();
  }, [props.form.values.schedule]);

  const [searchKey, setSearchKey] = React.useState('');

  const renderSC = (b: boolean) => {
    return b ? 'Summer Course' : '';
  };

  const buildStringFromArray = (arr: (string | number)[]) => {
    if (arr && arr.length > 0) {
      return arr
        .filter((item, index) => {
          if (index === 0 && !props.form.values?.subjectDetails[props.index]?.isFirstSemester)
            return false;
          if (index === 1 && !props.form.values?.subjectDetails[props.index]?.isSecondSemester)
            return false;
          if (index === 2 && !props.form.values?.subjectDetails[props.index]?.isThirdSemester)
            return false;
          if (index === 3 && !props.form.values?.subjectDetails[props.index]?.isFourthSemester)
            return false;
          return true;
        })
        .filter((i) => !!i)
        .join(', ');
    } else {
      return '';
    }
  };

  const genTitle = () => {
    let title = '';
    try {
      title += `${formItemValues.courseName ?? ''} - `;
      title += `${formItemValues.subject ?? ''} : `;
      title += `${buildStringFromArray(props.form.values.subjectDetails[props.index]?.grade)} `;

      if (formItemValues?.schedule_transcript !== 'One grade per term') {
        title += `${formItemValues.finalGrade ?? ''}`;
      }

      title += ` - Credits: ${buildStringFromArray(
        props.form.values.subjectDetails[props.index]?.credits
      )} `;

      if (formItemValues?.schedule_transcript !== 'One grade per term') {
        title += `${formItemValues.finalCredits ?? ''}`;
      }
    } catch (ex) {
      console.log('Error:', ex);
    }
    return title;
  };

  const checkCouserNameExist = async (courseName: String) => {
    const params = {
      school_id: highschoolData?.id,
      course_title: courseName,
      course_list_year: props.form.values.schoolYear,
    };

    const res = await courseServices.getCourseSuggestSignal(
      { ...params, limit_item: 100 },
      new AbortController()
    );

    return res.filter((item: any) => item.course_title === courseName);
  };

  //1840 - auto select subject area and subject : D2 - Physical Science
  React.useEffect(() => {
    try {
      const itemSubject = checkCouserNameIncludes(
        `${props.form.values.subjectDetails[props.index]?.courseName ?? ''}`,
        'D2'
      );
      if (itemSubject.length > 0) {
        props.form.setFieldValue(
          `subjectDetails[${props.index}].AGSubjectArea`,
          agSubjectAreaValues['D2']
        );
        getSubjectsByType(props.form.values.subjectDetails[props.index]?.AGSubjectArea);
        props.form.setFieldValue(`subjectDetails[${props.index}].subject`, itemSubject[0]);
      }
    } catch (ex) {
      console.log('Error:', ex);
    }
  }, [props.form.values.subjectDetails[props.index]?.courseName]);

  React.useEffect(() => {
    setTitle(genTitle());

    checkCouserNameExist(props.form.values.subjectDetails[props.index]?.courseName).then((res) => {
      if (res.length > 0) {
        setIsExist(true);
        props.form.setFieldValue(`subjectDetails[${props.index}].isNew`, false);
      } else {
        setIsExist(false);
        props.form.setFieldValue(`subjectDetails[${props.index}].isNew`, true);
      }
    });
  }, []);

  React.useEffect(() => {
    setTitle(genTitle());
  }, [props.form.values.CMGradingScale, props.form.values.subjectDetails[props.index]]);

  React.useEffect(() => {
    if (props.form.values.subjectDetails[props.index]?.typeOfClass === 'Dual Enrollment') {
      props.form.setFieldValue(`subjectDetails[${props.index}].isTakeClassAtCollege`, true);
    }
  }, [props.form.values.subjectDetails[props.index]?.typeOfClass]);
  React.useEffect(() => {
    if (props.form.values.subjectDetails[props.index]?.schedule_transcript === 'One final Grade') {
      props.form.setFieldValue(`subjectDetails[${props.index}].credits`, ['', '', '', '']);
      props.form.setFieldValue(`subjectDetails[${props.index}].grade`, ['', '', '', '']);
    }
  }, [props.form.values.subjectDetails[props.index]?.schedule_transcript]);

  const textColor = () => {
    if (!props.form.values.subjectDetails[props.index]?.isNew && isExist) {
      return '#4d88f0';
    } else {
      if (
        props.form.values.subjectDetails[props.index]?.isNew === false &&
        props.form.values.subjectDetails[props.index]?.courseName
      ) {
        return '#4d88f0';
      } else {
        return 'black';
      }
    }
  };

  const handleSelectCourse = async (courseName: string) => {
    studentId = Cookies.get('userId');
    if (
      props.form.values.subjectDetails[props.index]?.isNew &&
      courseName &&
      courseName.length > 0
    ) {
      if (`${courseName}`.substring(0, 3) === 'AP ') {
        const newAPTest = {
          isReceivedScore: '',
          examStatus: 'Planned',
          subject: courseName,
          subjectCM: courseName,
          dateTaken: null,
          dateTakenCSUApp: '',
          dateTakenMonth: '',
          dateTakenYear: '',
          score: '',
          planToTakeExamMonth: '',
          planToTakeExamYear: '',
          planToTakeExam: '',
          isIncludeIntoCommonApp: false,
          isIncludeIntoUCApp: false,
          isIncludeIntoCSUApp: false,
        };
        const newAPTestArray = [...apTestArray, newAPTest];
        await dispatch(
          updateACTSubjectTest({
            student_id: studentId,
            ap_test_array: JSON.stringify(newAPTestArray),
          })
        ).unwrap();
        props.form.setFieldValue(`subjectDetails[${props.index}].isNew`, false);
      }

      if (`${courseName}`.split(' ').includes('IB')) {
        const newIBTest = {
          isReceivedScore: '',
          examStatus: 'Planned',
          subject: courseName,
          dateTaken: null,
          score: '',
          ibExam: '',
          ibExamName: '', // for UC App only
          level: '',
          ibExamSubjectName: '', // for UC App only
          ibExamSubjectNameDescribeOther: '', // for UC App only
          ibExamSubjectNameCSUApp: '', // for CSU App only
          ibExamSpecifyLanguageCSUApp: '', // for CSU App only
          language: '',
          isIncludeIntoCommonApp: false,
          isIncludeIntoUCApp: false,
          isIncludeIntoCSUApp: false,
        };
        const newIBTestArray = [...ibTestArray, newIBTest];
        await dispatch(
          updateIBSubjectTest({
            student_id: studentId,
            ib_test_array: JSON.stringify(newIBTestArray),
          })
        ).unwrap();
      }
    }
  };
  return (
    <CardConfig
      id={`subjectItem${props.index + 1}`}
      expandAll={props.expandAll}
      headerElement={
        <>
          <span
            className="p-1"
            style={{
              color: textColor(),
            }}
          >
            {title}
          </span>
          <span className="floatRight" style={{ fontStyle: 'italic' }}>
            {props.form.values.subjectDetails[props.index]?.isSummerCourse &&
              `(${renderSC(props.form.values.subjectDetails[props.index]?.isSummerCourse)})`}
          </span>
          <span className="floatRight">
            <strong className="text-field-light">
              {props.index + 1}/{props.total}
            </strong>
          </span>
        </>
      }
    >
      <CheckboxInput
        logo="span-csu-logo"
        label="Summer Course - Taken After This Grade Year"
        name="isSummerCourse"
        values={props.form.values.subjectDetails[props.index]?.isSummerCourse}
        onChange={(e) => {
          props.form.setFieldValue(
            `subjectDetails[${props.index}].isSummerCourse`,
            e.target.checked
          );
        }}
      />

      <TextInput
        id={`courseName_${props.courseIndex}_${props.index + 1}`}
        className="common-flag"
        mdCol="12"
        label="Course Name*"
        countText={100}
        name={`subjectDetails[${props.index}].courseName`}
        values={props.form.values.subjectDetails[props.index]?.courseName}
        onChange={(e) => {
          setSearchKey(e.target.value);
          props.form.handleChange(e);
          props.form.setFieldValue(`subjectDetails[${props.index}].isNew`, true);
        }}
        isMissing={
          !props.form.values.subjectDetails[props.index]?.courseName && check ? true : false
        }
      />
      {/* New code here for course base on highschool ===*/}
      {searchKey?.length > 0 ? (
        <SuggestCourse
          searchKey={searchKey}
          schoolYear={props.form.values.schoolYear}
          currentHighschool={highschoolData}
          onUpdateCourseTitle={(course) => {
            handleSelectCourse(course.course_title);
            props.form.setFieldValue(
              `subjectDetails[${props.index}].courseName`,
              course.course_title
            );
            props.form.setFieldValue(
              `subjectDetails[${props.index}].AGSubjectArea`,
              course.subject_area
            );
            props.form.setFieldValue(`schoolYear`, course.course_list_year);
            props.form.setFieldValue(`subjectDetails[${props.index}].otherClass`, '');
            props.form.setFieldValue(`subjectDetails[${props.index}].isNew`, false);
            //console.log('course:', course);
            if (highschoolData && highschoolData.state === 'CA') {
              if (`${course.course_title}`.substring(0, 3) === 'AP ') {
                props.form.setFieldValue(
                  `subjectDetails[${props.index}].typeOfClass`,
                  'Advanced Placement (AP)'
                );
                props.form.setFieldValue(`subjectDetails[${props.index}].isWeightedCourse`, 'Yes');
              }
              if (`${course.course_title}`.split(' ').includes('IB')) {
                props.form.setFieldValue(
                  `subjectDetails[${props.index}].typeOfClass`,
                  'International Baccalaureate (IB)'
                );
                props.form.setFieldValue(`subjectDetails[${props.index}].isWeightedCourse`, 'Yes');
              }
            }
          }}
        />
      ) : null}

      {/* === New code here for course base on highschool */}
      <SelectInput
        mdCol="12"
        form={props.form}
        option={subjectAreaOptions}
        label="A-G Subject Area"
        values={props.form.values.subjectDetails[props.index]?.AGSubjectArea}
        name={`subjectDetails[${props.index}].AGSubjectArea`}
        onChange={(e) => {
          props.form.setFieldValue(`subjectDetails[${props.index}].subject`, '');
          props.form.handleChange(e);
        }}
        feedback={
          (props.form.values.subjectDetails[props.index]?.AGSubjectArea?.length
            ? ''
            : `Course Does Not Satisfy an A-G Requirement. `) +
          'If Science, please select D1 - Biological Science or D2 - Physical Science.'
        }
      />
      <SelectInput
        mdCol="12"
        className="common-flag"
        form={props.form}
        option={getSubjectsByType(props.form.values.subjectDetails[props.index]?.AGSubjectArea)}
        label="Subject/Discipline"
        values={props.form.values.subjectDetails[props.index]?.subject}
        name={`subjectDetails[${props.index}].subject`}
        onChange={(e) => {
          props.form.handleChange(e);
        }}
        feedback={`Please choose the closest matching name or "Other' if your class is not on the list.`}
      />

      {props.form.values.subjectDetails[props.index]?.subject?.includes('Other Than English') && (
        <SelectInput
          mdCol="12"
          form={props.form}
          option={[
            'American Sign Language',
            'Arabic',
            'Armenian',
            'Chinese',
            'Farsi',
            'Filipino',
            'French',
            'German',
            'Greek',
            'Hebrew',
            'Hindi',
            'Hmong',
            'Italian',
            'Japanese',
            'Khmer',
            'Korean',
            'Latin',
            'Other Language',
            'Portuguese',
            'Punjabi',
            'Russian',
            'Spanish',
            'Tamil',
            'Turkish',
            'Vietnamese',
          ]}
          label="Language Name"
          values={props.form.values.subjectDetails[props.index]?.languageName}
          name={`subjectDetails[${props.index}].languageName`}
          onChange={props.form.handleChange}
        />
      )}

      <SelectInput
        id={`typeOfClass_${props.courseIndex}_${props.index + 1}`}
        name={`subjectDetails[${props.index}].typeOfClass`}
        label="Type of Class *"
        option={classTypeOption}
        values={props.form.values.subjectDetails[props.index]?.typeOfClass}
        onChange={(e) => {
          props.form.handleChange(e);
        }}
        isMissing={
          !props.form.values.subjectDetails[props.index]?.typeOfClass && check ? true : false
        }
      />
      <SelectInput
        label="Is this a weighted course?"
        option={['', 'Yes', 'No']}
        name={`subjectDetails[${props.index}].isWeightedCourse`}
        values={props.form.values.subjectDetails[props.index]?.isWeightedCourse}
        onChange={props.form.handleChange}
      />

      {
        // props.form.values.subjectDetails[props.index]?.subject === 'Art (Visual or Performing)' ||
        // props.form.values.subjectDetails[props.index]?.subject === 'Computer Science ' ||
        // props.form.values.subjectDetails[props.index]?.subject === 'Religion' ||
        props.form.values.subjectDetails[props.index]?.subject === 'Other' && (
          <TextInput
            label="Class Name on Transcript *"
            countText={50}
            name={`subjectDetails[${props.index}].otherClass`}
            values={props.form.values.subjectDetails[props.index]?.otherClass}
            onChange={props.form.handleChange}
          />
        )
      }

      <div className="border w-100 my-3" />
      <CheckboxInput
        label="I took this class at a college and/or I received college credit for this class."
        name={`subjectDetails[${props.index}].isTakeClassAtCollege`}
        values={props.form.values.subjectDetails[props.index]?.isTakeClassAtCollege}
        onChange={props.form.handleChange}
      />
      {props.form.values.subjectDetails[props.index].isTakeClassAtCollege && (
        <>
          <SelectInput
            id={`collegeTookClass_${props.courseIndex}_${props.index + 1}`}
            label="Select College *"
            option={collegeData}
            mdCol="12"
            name={`subjectDetails[${props.index}].collegeTookClass`}
            values={props.form.values.subjectDetails[props.index]?.collegeTookClass}
            onChange={props.form.handleChange}
            isMissing={
              !props.form.values.subjectDetails[props.index]?.collegeTookClass && check
                ? true
                : false
            }
          />
          <GroupRadioButton
            id={`CNGradingScale_${props.courseIndex}_${props.index + 1}`}
            mdCol={6}
            label="Grading Scale *"
            name={`subjectDetails[${props.index}].CNGradingScale`}
            onChange={props.form.handleChange}
            options={gradingScale2Options}
            values={props.form.values.subjectDetails[props.index]?.CNGradingScale}
            isMissing={
              !props.form.values.subjectDetails[props.index]?.CNGradingScale && check ? true : false
            }
          />
          <div className="text-field-light span-uc-csu-logo">
            Please add the details of this course under your College Courses. Colleges may not allow
            for the course to be listed under the high school coursework on their admission
            application.
          </div>
          {props.form.values.subjectDetails[props.index]?.CNGradingScale === 'Numbers' && (
            <>
              <TextInput
                id={`lowestGrade_${props.courseIndex}_${props.index + 1}`}
                className="custom_input"
                label="What is the lowest possible number grade at this school?*"
                type="number"
                name={`subjectDetails[${props.index}].lowestGrade`}
                values={props.form.values.subjectDetails[props.index]?.lowestGrade}
                onChange={props.form.handleChange}
                isMissing={
                  !props.form.values.subjectDetails[props.index]?.lowestGrade && check
                    ? true
                    : false
                }
              />
              <TextInput
                id={`highestGrade_${props.courseIndex}_${props.index + 1}`}
                className="custom_input"
                label="What is the highest possible number grade at this school?*"
                type="number"
                name={`subjectDetails[${props.index}].highestGrade`}
                values={props.form.values.subjectDetails[props.index]?.highestGrade}
                onChange={props.form.handleChange}
                isMissing={
                  !props.form.values.subjectDetails[props.index]?.highestGrade && check
                    ? true
                    : false
                }
              />
            </>
          )}
          {props.form.values.subjectDetails[props.index]?.CNGradingScale === 'Other' && (
            <TextInput
              id={`otherGradeScale_${props.courseIndex}_${props.index + 1}`}
              label="Other Grading Scale *"
              countText={60}
              name={`subjectDetails[${props.index}].otherGradeScale`}
              values={props.form.values.subjectDetails[props.index]?.otherGradeScale}
              onChange={props.form.handleChange}
              isMissing={
                !props.form.values.subjectDetails[props.index]?.otherGradeScale && check
                  ? true
                  : false
              }
            />
          )}
        </>
      )}

      <div className="border w-100 my-3" />
      {(props.form.values.schedule === 'Semester' ||
        props.form.values.schedule === 'Trimester' ||
        props.form.values.schedule === 'Quarters') && (
        <div>
          <div id={`takeSemester_${props.courseIndex}_${props.index + 1}`} className="text-dark">
            During which {props.form.values.schedule.toLowerCase() ?? 'term'}(s) did you take this
            class? *
          </div>

          {semesterOption.map((opt, index) => {
            let key = opt.value as keyof SubjectDetail;
            const handleUncheck = () => {
              let credits = props.form.values.subjectDetails[props.index]?.credits;
              credits[index] = '';
              props.form.setFieldValue(`subjectDetails[${props.index}].credits`, credits);
              let grade = props.form.values.subjectDetails[props.index]?.grade;
              grade[index] = '';
              props.form.setFieldValue(`subjectDetails[${props.index}].grade`, grade);
            };
            return (
              <CheckboxInput
                key={index}
                name={`subjectDetails[${props.index}].[${key}]`}
                label={opt.label}
                onChange={(e: any) => {
                  props.form.handleChange(e);
                  e.target.value === false && handleUncheck();
                }}
                values={props.form.values.subjectDetails[props.index]?.[key]}
                isMissing={
                  index === 0
                    ? !props.form.values.subjectDetails[props.index]?.[key] &&
                      (props.form.values.subjectDetails[props.index]?.schedule_transcript ===
                        'One grade per term' ||
                        props.form.values.subjectDetails[props.index]?.schedule_transcript ===
                          'One grade per term and one final grade') &&
                      check
                      ? true
                      : false
                    : !props.form.values.subjectDetails[props.index]?.isFirstSemester &&
                      !props.form.values.subjectDetails[props.index]?.[key] &&
                      (props.form.values.subjectDetails[props.index]?.schedule_transcript ===
                        'One grade per term' ||
                        props.form.values.subjectDetails[props.index]?.schedule_transcript ===
                          'One grade per term and one final grade') &&
                      check
                    ? true
                    : false
                }
              />
            );
          })}
          <GroupRadioButton
            id={`scheduleTranscript_${props.courseIndex}_${props.index + 1}`}
            className="py-3"
            mdCol={12}
            options={[
              'One final Grade',
              'One grade per term',
              'One grade per term and one final grade',
            ]}
            label="How are the grades for this class reported on your transcript? *"
            name={`subjectDetails[${props.index}].schedule_transcript`}
            values={props.form.values.subjectDetails[props.index]?.schedule_transcript}
            onChange={props.form.handleChange}
            onClear={(e: any) => {
              props.form.setFieldValue(`subjectDetails[${props.index}].schedule_transcript`, '');
              initSchedule();
            }}
            isMissing={
              !props.form.values.subjectDetails[props.index]?.schedule_transcript && check
                ? true
                : false
            }
          />
        </div>
      )}
      {props.form.values.CMGradingScale !== 'Other' && (
        <>
          {(semesterOption || []).map((opt, index) => {
            let key = opt.value as keyof SubjectDetail;
            let formik = props.form.values.subjectDetails[props.index];
            if (index === 0 && !formik?.isFirstSemester) return <div key={index} />;
            if (index === 1 && !formik?.isSecondSemester) return <div key={index} />;
            if (index === 2 && !formik?.isThirdSemester) return <div key={index} />;
            if (index === 3 && !formik?.isFourthSemester) return <div key={index} />;
            return (
              <React.Fragment key={index}>
                <SelectInput
                  id={`grade_${props.courseIndex}_${props.index + 1}_${index}`}
                  hidden={!formik?.[key] || formik?.schedule_transcript === 'One final Grade'}
                  form={props.form}
                  option={props.gradingOptions}
                  label={`${props.form.values.schedule} ${index + 1} Grade *`}
                  defautlValues={''}
                  values={formik?.grade[index]}
                  name={`subjectDetails[${props.index}].grade[${index}]`}
                  onChange={props.form.handleChange}
                  isMissing={!formik?.grade[index] && check ? true : false}
                />
                <TextInput
                  id={`credits_${props.courseIndex}_${props.index + 1}_${index}`}
                  hidden={!formik?.[key] || formik?.schedule_transcript === 'One final Grade'}
                  label={`${props.form.values.schedule} ${index + 1} Credits *`}
                  type="number"
                  decimalStep={0.01}
                  name={`subjectDetails[${props.index}].credits[${index}]`}
                  values={formik?.credits[index]}
                  onChange={(e) =>
                    props.form.setFieldValue(
                      `subjectDetails[${props.index}].credits[${index}]`,
                      Math.round(e.target.value * 100) / 100
                    )
                  }
                  isMissing={!formik?.credits[index] && check ? true : false}
                />
              </React.Fragment>
            );
          })}
        </>
      )}
      <SelectInput
        id={`finalGrade_${props.courseIndex}_${props.index + 1}`}
        hidden={
          props.form.values.subjectDetails[props.index]?.schedule_transcript ===
          'One grade per term'
        }
        form={props.form}
        option={props.gradingOptions}
        label="Final Grade *"
        defautlValues={''}
        values={props.form.values.subjectDetails[props.index]?.finalGrade}
        name={`subjectDetails[${props.index}].finalGrade`}
        onChange={props.form.handleChange}
        isMissing={
          !props.form.values.subjectDetails[props.index]?.finalGrade && check ? true : false
        }
      />
      <TextInput
        id={`finalCredits_${props.courseIndex}_${props.index + 1}`}
        hidden={
          props.form.values.subjectDetails[props.index]?.schedule_transcript ===
          'One grade per term'
        }
        label="Final Credits *"
        decimalStep={0.01}
        type="number"
        name={`subjectDetails[${props.index}].finalCredits`}
        values={props.form.values.subjectDetails[props.index]?.finalCredits}
        onChange={(e) =>
          props.form.setFieldValue(
            `subjectDetails[${props.index}].finalCredits`,
            Math.round(e.target.value * 100) / 100
          )
        }
        isMissing={
          !(props.form.values.subjectDetails[props.index]?.finalCredits !== '') && check
            ? true
            : false
        }
      />
      <CheckboxInput
        label="N/A"
        name={`subjectDetails[${props.index}].checkedNA`}
        values={props.form.values.subjectDetails[props.index]?.checkedNA}
        onChange={props.form.handleChange}
      />
      <div className="border w-100 my-3" />
      {/* <CheckboxInput
        label={`${props.form.values.schoolName} used block scheduling for this class.`}
        name={`subjectDetails[${props.index}].isUsedBlockScheduling`}
        values={props.form.values.subjectDetails[props.index]?.isUsedBlockScheduling}
        onChange={props.form.handleChange}
      /> */}
      <style scoped>{`
div.custom_input label.form-label {
  letter-spacing: 0;
}
     `}</style>
    </CardConfig>
  );
};

export const chkSameGrade = (schoolItem: any, level: string) => {
  if (schoolItem === null) return false;
  if (schoolItem && schoolItem.grade && schoolItem.grade.length > 0) {
    for (let i = 0; i < schoolItem.grade.length; i++) {
      let grade = schoolItem.grade[i];
      if (grade === level) {
        return { grade: grade as any, index: i as number };
      }
    }
  }
  return false;
};

export const countSameGrade = (schoolItem: any, level: string) => {
  let arr: any[] = [];
  if (schoolItem && schoolItem.grade && schoolItem.grade.length > 0) {
    for (let i = 0; i < schoolItem.grade.length; i++) {
      let grade = schoolItem.grade[i];
      if (grade === level) {
        arr.push({ grade: grade as any, index: i as number });
      }
    }
    return arr;
  }
  return null;
};

export const getSchoolGradeItems = (highschoolData: any[], level: string) => {
  let arr: any[] = [];
  if (highschoolData && highschoolData.length > 0) {
    for (let i = 0; i < highschoolData.length; i++) {
      let item: any = highschoolData[i];
      if (chkSameGrade(item, level)) {
        arr.push({ item: item as any, index: i as number });
      }
    }
    return arr;
  }
  return null;
};

export default SubjectConfig;
