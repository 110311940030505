import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import toast from 'react-hot-toast';
import jsCookie from 'js-cookie';
import { authenticateSettings } from 'components/helper/appConfig';
import Cookies from 'js-cookie';
const http = axios.create({
  // baseURL: 'https://studentsguidetocollegeplanning.com/v1-1/',
  baseURL: 'https://studentsguidetocollegeplanning.com/',
  // baseURL: 'https://portal.gradmap.com/', // PRODUCTION,
  //baseURL: 'http://localhost:3020/v1-1/',
  // baseURL: 'http://localhost:3001/',

  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,OPTIONS,PATCH,DELETE,POST,PUT',
    'Access-Control-Allow-Headers':
      'X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version, Authorization',
  },
});

http.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    const auToken = jsCookie.get(authenticateSettings.tokenName);
    if (!config) {
      config = {};
    }
    if (!config.headers) {
      config.headers = {};
    }
    config.headers.Authorization = 'Bearer ' + auToken;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  function (response: AxiosResponse) {
    // check response  have status code error
    let returnData: any = response.data;

    if (returnData.statusCode >= 400 || returnData.status >= 400) {
      toast.error(returnData?.message || returnData?.moreInfo);
      return Promise.reject();
    }

    return response.data;
  },
  (res) => {
    const { message, statusCode } = res?.response?.data;
    if (message) {
      toast.error(message, {
        style: {
          padding: 10,
        },
      });
    }
    if (statusCode === 401) {
      Cookies.remove('userId');
      Cookies.remove(authenticateSettings.tokenName);
      window.location.href = '/#/public/login';
    }
    return Promise.reject(message);
  }
);

export default http;
