import React, { useRef } from 'react';
import PasswordInput from 'components/input/PasswordInput';
import TextIconInput from 'components/input/TextIconInput';
import { useFormik } from 'formik';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Form, Row, Button, Spinner } from 'react-bootstrap';
import { routers } from 'router/router';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getStudent, login, setStudentId, googleLogin } from 'app/authorization/authorizationSlice';
import {
  getStripeSubscriptionInfo,
  getCurrentBasicDetail,
  getContactDetail,
} from 'app/basicInformationSlice/basicInformationSlice';
import Cookies from 'js-cookie';
import { getHighSchoolData } from 'app/highSchoolSlice/highSchoolSlice';
import { checkEnableParseJSON, userpilotDomain } from 'components/helper/helper';
import { get } from 'lodash';
import { Userpilot } from 'userpilot';
import { STRIPE_SUBSCRIPTION_STATUS } from 'constants/common';
import { GoogleLogin } from '@react-oauth/google';
import toast from 'react-hot-toast';

// Component nút Google tùy chỉnh
const CustomGoogleButton = ({ onGoogleLoginSuccess, onGoogleLoginError }: any) => {
  const googleLoginRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = React.useState(false);
  
  // Xử lý khi người dùng nhấp vào nút tùy chỉnh
  const handleCustomButtonClick = () => {
    // Tìm và kích hoạt nút Google ẩn
    if (googleLoginRef.current) {
      const googleButton = googleLoginRef.current.querySelector('div[role="button"]');
      if (googleButton instanceof HTMLElement) {
        googleButton.click();
      } else {
        toast.error('Không thể kết nối với Google. Vui lòng thử lại sau.');
      }
    }
  };
  
  return (
    <>
      {/* Nút tùy chỉnh */}
      <Button
        className="w-100 my-3 custom-google-btn"
        variant="light"
        onClick={handleCustomButtonClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          height: 42,
          borderRadius: '8px',
          border: '1px solid #dadce0',
          boxShadow: isHovered ? '0 1px 3px rgba(0, 0, 0, 0.12)' : '0 1px 3px rgba(0, 0, 0, 0.08)',
          transition: 'all 0.3s',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 12px',
          backgroundColor: isHovered ? '#f8f9fa' : '#ffffff',
        }}
      >
        <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          width: '100%' 
        }}>
          <img 
            src="https://developers.google.com/identity/images/g-logo.png" 
            alt="Google logo"
            style={{ width: 18, height: 18, marginRight: 10 }}
          />
          <span style={{ 
            fontSize: '14px', 
            fontWeight: '500',
            fontFamily: 'Roboto, Arial, sans-serif',
            color: '#3c4043'
          }}>
            Continue with Google
          </span>
        </div>
      </Button>
      
      {/* Component Google Login ẩn */}
      <div ref={googleLoginRef} style={{ display: 'none', position: 'absolute', left: '-9999px' }}>
        <GoogleLogin
          onSuccess={onGoogleLoginSuccess}
          onError={() => {
            if (onGoogleLoginError) {
              onGoogleLoginError();
            } else {
              toast.error('Google login failed');
            }
          }}
          type="standard"
          theme="outline"
          text="signin_with"
          shape="rectangular"
          logo_alignment="center"
        />
      </div>
    </>
  );
};

interface Props {}

const FormLogin = (props: Props) => {
  const dispatch = useAppDispatch();
  const authorizationStore = useAppSelector((store) => store.authorization);

  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email').required('Required'),
      password: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        const res: any = await dispatch(login(values));

        if (res?.error?.message === 'User not found') {
          history.push(routers.register.path);
          return;
        }

        if (res.payload) {
          if (res.payload && res.payload.is_confirmed_by_phone) {
            const info = await dispatch(getCurrentBasicDetail(res.payload.student_id));
            let highschoolName = await getHighSchoolName(res.payload.student_id);
            let userPlan = '';
            localStorage.setItem('currentUserEmail', res.payload.email);
            localStorage.setItem('userRole', res.payload.user_role);
            sessionStorage.setItem('session_student_id', res.payload.student_id);
            const subStripeInfo: any = await dispatch(
              getStripeSubscriptionInfo(res.payload?.student_id)
            );
            let stripeStatus = get(subStripeInfo, 'payload.subcription_status', '');

            if (res.payload.user_role === 'counselor') {
              history.push(routers.viewStudent.path);
            } else {
              if (subStripeInfo.payload?.subcription_plan === '') {
                Cookies.set('request_id', res.payload.student_id);
                Cookies.set('userEmail', res.payload.email);
                history.push(routers.payment.path);
              } else {
                const student = await dispatch(getStudent(res.payload.student_id));
                const contactDetail: any = await dispatch(getContactDetail(res.payload.student_id));
                const additionalInfo = checkEnableParseJSON(student.payload.additional_info, {});

                if (stripeStatus === 'trialing') {
                  stripeStatus = 'Trial Mode';
                }

                if (
                  student.payload?.trial_count > 0 &&
                  stripeStatus === STRIPE_SUBSCRIPTION_STATUS.canceled
                ) {
                  stripeStatus = 'Expired Trial';
                }

                if (window.Intercom && info.payload) {
                  if (
                    info.payload.use_extension &&
                    subStripeInfo.payload?.subcription_plan.includes('gold')
                  ) {
                    userPlan = 'gold';
                  }

                  if (
                    info.payload.use_extension &&
                    subStripeInfo.payload?.subcription_plan.includes('silver_plus')
                  ) {
                    userPlan = 'silver-plus';
                  }

                  if (!info.payload.use_extension) {
                    userPlan = 'silver';
                  }

                  window.Intercom('boot', {
                    name: info.payload.last_name,
                    email: info.payload.email,
                    plan: userPlan,
                    user_hash: info.payload.user_hash,
                    phone: contactDetail.payload?.phone_number,
                    gm_user_id: res.payload.student_id,
                    student_first_name: info.payload.first_name,
                    student_last_name: info.payload.last_name,
                    student_email: info.payload.email,
                    current_high_school: highschoolName,
                    home_address_state: info.payload.state,
                    user_type: info.payload.user_role,
                    environment: process.env.REACT_APP_ENV,
                    major_1: get(additionalInfo, 'majors_interest_1', ''),
                    major_2: get(additionalInfo, 'majors_interest_2', ''),
                    parent_first_name: get(additionalInfo, 'parent_first_name', ''),
                    parent_last_name: get(additionalInfo, 'parent_last_name', ''),
                    parent_relationship: get(additionalInfo, 'relationship_to_you', ''),
                    parent_email: get(additionalInfo, 'prefered_email', ''),
                    accept_contact: get(additionalInfo, 'accept_contact', ''),
                    graduation_year: info.payload.year_finish_high_school,
                    stripe_status: stripeStatus ?? '',
                  });
                }
                history.push({
                  pathname: routers.dashBoard.path,
                  search: `?role=${res.payload.user_role}`,
                });
              }
            }

            Userpilot.identify((res.payload.student_id ?? 'undefined') + userpilotDomain, {
              plan: userPlan ?? 'undefined',
              stripeStatus: stripeStatus ?? 'undefined',
              userType: info.payload.user_role ?? 'undefined',
              name: info.payload.first_name ?? 'undefined',
              created_at: info.payload.created_at ?? 'undefined',
              firstName: info.payload.first_name ?? 'undefined',
              lastName: info.payload.last_name ?? 'undefined',
              email: info.payload.email ?? 'undefined',
              graduationYear: info.payload.year_finish_high_school ?? 'undefined',
              school: highschoolName ?? 'undefined',
            });
          } else if (!res.payload.is_confirmed_by_phone) {
            dispatch(setStudentId(res.payload.student_id));
            history.push({ pathname: routers.register.path, search: '?step=4' });
          }
          if (res.payload?.student_id) {
            dispatch(getStudent(res.payload?.student_id));
          }
        }
      } catch (error) {
        console.log({ error });
      }
    },
  });

  async function getHighSchoolName(studentId: any) {
    try {
      let hightSchoolData = await dispatch(getHighSchoolData(studentId)).unwrap();
      let currentHighSchool = checkEnableParseJSON(hightSchoolData.high_school_array, []);
      if (currentHighSchool) {
        return currentHighSchool[0]?.high_school_name;
      }
    } catch (ex) {
      console.log('Error:', ex);
    }

    return '';
  }

  const handleGoogleLogin = async (credentialResponse: any) => {
    try {
      // Extract the credential (ID token) from the response
      const tokenId = credentialResponse.credential;

      const res: any = await dispatch(
        googleLogin({
          tokenId: tokenId,
        })
      );

      if (res.payload) {
        if (res.payload?.should_register) {
          history.push({
            pathname: routers.register.path,
            search: `?email=${res.payload.email}&first_name=${
              res.payload?.name?.first_name || ''
            }&last_name=${res.payload?.name?.last_name || ''}&google=true`,
          });
          return;
        }
        if (res.payload && res.payload.is_confirmed_by_phone) {
          const info = await dispatch(getCurrentBasicDetail(res.payload.student_id));
          let highschoolName = await getHighSchoolName(res.payload.student_id);
          let userPlan = '';
          localStorage.setItem('currentUserEmail', res.payload.email);
          localStorage.setItem('userRole', res.payload.user_role);
          sessionStorage.setItem('session_student_id', res.payload.student_id);
          const subStripeInfo: any = await dispatch(
            getStripeSubscriptionInfo(res.payload?.student_id)
          );
          let stripeStatus = get(subStripeInfo, 'payload.subcription_status', '');

          if (res.payload.user_role === 'counselor') {
            history.push(routers.viewStudent.path);
          } else {
            if (subStripeInfo.payload?.subcription_plan === '') {
              Cookies.set('request_id', res.payload.student_id);
              Cookies.set('userEmail', res.payload.email);
              history.push(routers.payment.path);
            } else {
              const student = await dispatch(getStudent(res.payload.student_id));
              const contactDetail: any = await dispatch(getContactDetail(res.payload.student_id));
              const additionalInfo = checkEnableParseJSON(student.payload.additional_info, {});

              if (stripeStatus === 'trialing') {
                stripeStatus = 'Trial Mode';
              }

              if (
                student.payload?.trial_count > 0 &&
                stripeStatus === STRIPE_SUBSCRIPTION_STATUS.canceled
              ) {
                stripeStatus = 'Expired Trial';
              }

              if (window.Intercom && info.payload) {
                if (
                  info.payload.use_extension &&
                  subStripeInfo.payload?.subcription_plan.includes('gold')
                ) {
                  userPlan = 'gold';
                }

                if (
                  info.payload.use_extension &&
                  subStripeInfo.payload?.subcription_plan.includes('silver_plus')
                ) {
                  userPlan = 'silver-plus';
                }

                if (!info.payload.use_extension) {
                  userPlan = 'silver';
                }

                window.Intercom('boot', {
                  name: info.payload.last_name,
                  email: info.payload.email,
                  plan: userPlan,
                  user_hash: info.payload.user_hash,
                  phone: contactDetail.payload?.phone_number,
                  gm_user_id: res.payload.student_id,
                  student_first_name: info.payload.first_name,
                  student_last_name: info.payload.last_name,
                  student_email: info.payload.email,
                  current_high_school: highschoolName,
                  home_address_state: info.payload.state,
                  user_type: info.payload.user_role,
                  environment: process.env.REACT_APP_ENV,
                  major_1: get(additionalInfo, 'majors_interest_1', ''),
                  major_2: get(additionalInfo, 'majors_interest_2', ''),
                  parent_first_name: get(additionalInfo, 'parent_first_name', ''),
                  parent_last_name: get(additionalInfo, 'parent_last_name', ''),
                  parent_relationship: get(additionalInfo, 'relationship_to_you', ''),
                  parent_email: get(additionalInfo, 'prefered_email', ''),
                  accept_contact: get(additionalInfo, 'accept_contact', ''),
                  graduation_year: info.payload.year_finish_high_school,
                  stripe_status: stripeStatus ?? '',
                });
              }

              history.push({
                pathname: routers.dashBoard.path,
                search: `?role=${res.payload.user_role}`,
              });
            }
          }

          Userpilot.identify((res.payload.student_id ?? 'undefined') + userpilotDomain, {
            plan: userPlan ?? 'undefined',
            stripeStatus: stripeStatus ?? 'undefined',
            userType: info.payload.user_role ?? 'undefined',
            name: info.payload.first_name ?? 'undefined',
            created_at: info.payload.created_at ?? 'undefined',
            firstName: info.payload.first_name ?? 'undefined',
            lastName: info.payload.last_name ?? 'undefined',
            email: info.payload.email ?? 'undefined',
            graduationYear: info.payload.year_finish_high_school ?? 'undefined',
            school: highschoolName ?? 'undefined',
          });
        } else if (!res.payload.is_confirmed_by_phone) {
          dispatch(setStudentId(res.payload.student_id));
          history.push({ pathname: routers.register.path, search: '?step=4' });
        }
        if (res.payload?.student_id) {
          dispatch(getStudent(res.payload?.student_id));
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const handleGoogleLoginError = () => {
    toast.error('Google login failed');
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row className="mb-3 p-0 mx-0">
        <TextIconInput
          icon={<MailOutlineIcon />}
          mdCol={'12'}
          label={'Email'}
          name={'email'}
          values={formik.values.email}
          onChange={formik.handleChange}
          feedback={
            formik.touched.email &&
            formik.errors.email && <span className="text-danger">{formik.errors.email}</span>
          }
        />

        <PasswordInput
          mdCol={'12'}
          label={'Password'}
          name={'password'}
          values={formik.values.password}
          onChange={formik.handleChange}
          feedback={
            formik.touched.password &&
            formik.errors.password && <span className="text-danger">{formik.errors.password}</span>
          }
        />
      </Row>
      <Button
        className="m-0 p-0"
        variant="link"
        onClick={() => history.push(routers.forgotPassword.path)}
      >
        Forgot your password?
      </Button>

      {authorizationStore.status !== 'loading' ? (
        <Button
          className="w-100 my-3"
          type="submit"
          variant="primary"
          style={{
            height: 42,
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            transition: 'all 0.3s ease',
          }}
        >
          <span className="h5 fw-bold">Login</span>
        </Button>
      ) : (
        <Button
          className="w-100 my-3"
          variant="primary"
          style={{
            height: 42,
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Spinner style={{ fontSize: 16 }} size="sm" animation="border" variant="light" />
        </Button>
      )}

      <div className="w-100 d-flex align-items-center my-3">
        <div className="border mb-1" style={{ width: 'calc(50% - 20px)' }} />
        <div className="text-field-light text-center" style={{ width: '40px' }}>
          OR
        </div>
        <div className="border mb-1" style={{ width: 'calc(50% - 20px)' }} />
      </div>

      {/* Sử dụng nút Google tùy chỉnh thay vì nút GoogleLogin mặc định */}
      <CustomGoogleButton 
        onGoogleLoginSuccess={handleGoogleLogin}
        onGoogleLoginError={handleGoogleLoginError}
      />
      
      {/* Thêm CSS toàn cục để đảm bảo nút hoạt động đúng trong mọi môi trường */}
      <style>{`
        .custom-google-btn {
          width: 100% !important;
        }
        
        /* Ngăn các style của Google ghi đè */
        .custom-google-btn > div {
          width: 100% !important;
        }
      `}</style>
    </Form>
  );
};

export default FormLogin;