/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';
import { RequiredField} from './helper/checkFieldsMissingCondition';
import { tryParseJSON } from 'components/helper/helper';

interface ModalCheckGradeFieldProps {

  show?: boolean;
  onClose: () => void;
  data: any[];
  page: string;
  myGradeAndCourse:any;
}

export const ModalCheckGradeField = (props: ModalCheckGradeFieldProps) => {
  const { show, onClose, data, page, myGradeAndCourse } = props;
  const [fieldsFail, setFieldsFail] = useState<any[]>([]);
  const history = useHistory();

  const checkRequiredFields = (data:any) =>{
    console.log('myGradeAndCourse:', myGradeAndCourse);
    const result: RequiredField[]  = [];
    try {
      switch(page) {
        case '9th-grade':
          if (!myGradeAndCourse.is_reported_all_grade_9){
            result.push({
              key: 'is_reported_all_grade_9',
              name: 'I have reported all of my courses for this grade.',
              page: `/application/${page}/?check=1#is_reported_all_grade_9`
            })
          }
          break;
        case '10th-grade':
          if (!myGradeAndCourse.is_reported_all_grade_10){
            result.push({
              key: 'is_reported_all_grade_10',
              name: 'I have reported all of my courses for this grade.',
              page: `/application/${page}/?check=1#is_reported_all_grade_10`
            })
          }
          break;
        case '11th-grade':
          if (!myGradeAndCourse.is_reported_all_grade_11){
            result.push({
              key: 'is_reported_all_grade_11',
              name: 'I have reported all of my courses for this grade.',
              page: `/application/${page}/?check=1#is_reported_all_grade_11`
            })
          }
          break;
        case '12th-grade':
          if (!myGradeAndCourse.is_reported_all_grade_12){
            result.push({
              key: 'is_reported_all_grade_12',
              name: 'I have reported all of my courses for this grade.',
              page: `/application/${page}/?check=1#is_reported_all_grade_12`
            })
          }
          break;
      }

      if (data && data.length>0) {
        for (let i=0; i< data.length; i++) {
            let item:any = data[i];
            if (!item?.schoolName ||item?.schoolName === '') {
              result.push({
                key: 'schoolName',
                name: 'Select the school for this coursework.*',
                page: `/application/${page}/?check=1#schoolName_${i+1}`
              })
            }
            if (!item?.schoolYear || item?.schoolYear === '') {
              result.push({
                key: 'schoolYear',
                name: 'School Year *',
                page: `/application/${page}/?check=1#schoolYear_${i+1}`
              })
            }
            if (!item?.schedule || item?.schedule === '') {
              result.push({
                key: 'schedule *',
                name: 'Schedule *',
                page: `/application/${page}/?check=1#schedule_${i+1}`
              })
            }

            let schedule:any = item.schedule;
            let subjectDetails:any[] = item.subjectDetails;
            if (subjectDetails && subjectDetails.length>0) {
              for(let j=0; j<subjectDetails.length;j++) {
                let subject:any = subjectDetails[j];
                console.log('subject:', subject);
                if (!subject?.courseName) {
                  result.push({
                    key: 'courseName*',
                    name: 'Course Name *',
                    page: `/application/${page}/?check=1#courseName_${i+1}_${j+1}`
                  })
                }
                if (!subject?.typeOfClass) {
                  result.push({
                    key: 'typeOfClass',
                    name: 'Type of Class *',
                    page: `/application/${page}/?check=1#typeOfClass_${i+1}_${j+1}`
                  })
                }

                if (subject?.isTakeClassAtCollege) {
                  if (!subject?.collegeTookClass) {
                    result.push({
                      key: 'collegeTookClass',
                      name: `Select College *`,
                      page: `/application/${page}/?check=1#collegeTookClass_${i+1}_${j+1}`
                    });
                  }
                  if (!subject?.CNGradingScale) {
                    result.push({
                      key: 'CNGradingScale',
                      name: `Grading Scale *`,
                      page: `/application/${page}/?check=1#CNGradingScale_${i+1}_${j+1}`
                    });
                  }
                  if (subject?.CNGradingScale === 'Numbers') {
                    if (!subject?.lowestGrade) {
                      result.push({
                        key: 'lowestGrade',
                        name: 'What is the lowest possible number grade at this school? *',
                        page: `/application/${page}/?check=1#lowestGrade_${i+1}_${j+1}`
                      });
                    }
                    if (!subject?.highestGrade) {
                      result.push({
                        key: 'highestGrade',
                        name: 'What is the highest possible number grade at this school? *',
                        page: `/application/${page}/?check=1#highestGrade_${i+1}_${j+1}`
                      });
                    }

                  } else
                  if (subject?.CNGradingScale === 'Other') {
                    if (!subject?.otherGradeScale) {
                      result.push({
                        key: 'otherGradeScale',
                        name: 'Other Grading Scale *',
                        page: `/application/${page}/?check=1#otherGradeScale_${i+1}_${j+1}`
                      });
                    }
                  }
                }

                switch (subject.schedule_transcript) {
                  case "One grade per term":
                  case "One grade per term and one final grade":
                      let bFirstSemester = false;
                      switch(schedule){
                        case 'Semester':
                          if (!subject?.isFirstSemester) {
                            bFirstSemester = true;
                            result.push({
                              key: 'isFirstSemester',
                              name: 'First Semester',
                              page: `/application/${page}/?check=1#takeSemester_${i+1}_${j+1}`
                            })
                          }
                          if (bFirstSemester) {
                            if (!subject?.isSecondSemester) {
                              result.push({
                                key: 'isSecondSemester',
                                name: 'Second Semester',
                                page: `/application/${page}/?check=1#takeSemester_${i+1}_${j+1}`
                              })
                            }
                          }
                          break;
                        case 'Trimester':
                          if (!subject?.isFirstSemester) {
                            bFirstSemester = true;
                            result.push({
                              key: 'isFirstSemester',
                              name: 'First Semester',
                              page: `/application/${page}/?check=1#takeSemester_${i+1}_${j+1}`
                            })
                          }
                          if (bFirstSemester) {
                            if (!subject?.isSecondSemester) {
                              result.push({
                                key: 'isSecondSemester',
                                name: 'Second Semester',
                                page: `/application/${page}/?check=1#takeSemester_${i+1}_${j+1}`
                              })
                            }
                            if (!subject?.isThirdSemester) {
                              result.push({
                                key: 'isThirdSemester',
                                name: 'Third Semester',
                                page: `/application/${page}/?check=1#takeSemester_${i+1}_${j+1}`
                              })
                            }
                          }
                          break;
                        case 'Quarters':
                          if (!subject?.isFirstSemester) {
                            bFirstSemester = true
                            result.push({
                              key: 'isFirstSemester',
                              name: 'First Semester',
                              page: `/application/${page}/?check=1#takeSemester_${i+1}_${j+1}`
                            })
                          }
                          if (bFirstSemester) {
                            if (!subject?.isSecondSemester) {
                              result.push({
                                key: 'isSecondSemester',
                                name: 'Second Semester',
                                page: `/application/${page}/?check=1#takeSemester_${i+1}_${j+1}`
                              })
                            }
                            if (!subject?.isThirdSemester) {
                              result.push({
                                key: 'isThirdSemester',
                                name: 'Third Semester',
                                page: `/application/${page}/?check=1#takeSemester_${i+1}_${j+1}`
                              })
                            }
                            if (!subject?.isFourthSemester) {
                              result.push({
                                key: 'isFourthSemester',
                                name: 'Fourth Semester',
                                page: `/application/${page}/?check=1#takeSemester_${i+1}_${j+1}`
                              })
                            }
                          }
                          break;
                      }
                      break;
                  case "One final Grade":
                  default:
                    break;
                }

                if (schedule === 'Semester' ||
                    schedule === 'Trimester' ||
                    schedule === 'Quarters') {
                  if (!subject?.schedule_transcript || subject?.schedule_transcript==='') {
                    result.push({
                      key: 'scheduleTranscript_',
                      name: 'How are the grades for this class reported on your transcript? *',
                      page: `/application/${page}/?check=1#scheduleTranscript_${i+1}_${j+1}`
                    });
                  }
                }

                if (subject.schedule_transcript === 'One grade per term' ||
                    subject.schedule_transcript === 'One grade per term and one final grade') {
                  let  bFirst = false;
                  switch (item.schedule) {
                    case 'Semester':
                      if (subject?.isFirstSemester) {
                        if (!subject.grade[0]) {
                          bFirst = true;
                          result.push({
                            key: 'grade',
                            name: `${item.schedule} ${1} Grade *`,
                            page: `/application/${page}/?check=1#grade_${i+1}_${j+1}_0`
                          });
                        }
                        if (!subject.credits[0]) {
                          result.push({
                            key: 'credits',
                            name: `${item.schedule} ${1} Credits *`,
                            page: `/application/${page}/?check=1#credits_${i+1}_${j+1}_0`
                          });
                        }
                      }
                      if (!bFirst) {
                        if (subject?.isSecondSemester) {
                          if (!subject.grade[1]) {
                            result.push({
                              key: 'grade',
                              name: `${item.schedule} ${2} Grade *`,
                              page: `/application/${page}/?check=1#grade_${i+1}_${j+1}_1`
                            });
                          }
                          if (!subject.credits[1]) {
                            result.push({
                              key: 'credits',
                              name: `${item.schedule} ${2} Credits *`,
                              page: `/application/${page}/?check=1#credits_${i+1}_${j+1}_1`
                            });
                          }
                        }
                      }
                      break;
                    case 'Trimester':
                      if (subject?.isFirstSemester) {
                        bFirst = true;
                        if (!subject.grade[0]) {
                          result.push({
                            key: 'grade',
                            name: `${item.schedule} ${1} Grade *`,
                            page: `/application/${page}/?check=1#grade_${i+1}_${j+1}_0`
                          });
                        }
                        if (!subject.credits[0]) {
                          result.push({
                            key: 'credits',
                            name: `${item.schedule} ${1} Credits *`,
                            page: `/application/${page}/?check=1#credits_${i+1}_${j+1}_0`
                          });
                        }
                      }
                      if (!bFirst) {
                        if (subject?.isSecondSemester) {
                          if (!subject.grade[1]) {
                            result.push({
                              key: 'grade',
                              name: `${item.schedule} ${2} Grade *`,
                              page: `/application/${page}/?check=1#grade_${i+1}_${j+1}_1`
                            });
                          }
                          if (!subject.credits[1]) {
                            result.push({
                              key: 'credits',
                              name: `${item.schedule} ${2} Credits *`,
                              page: `/application/${page}/?check=1#credits_${i+1}_${j+1}_1`
                            });
                          }
                        }
                        if (subject?.isThirdSemester) {
                          if (!subject.grade[2]) {
                            result.push({
                              key: 'grade',
                              name: `${item.schedule} ${3} Grade *`,
                              page: `/application/${page}/?check=1#grade_${i+1}_${j+1}_2`
                            });
                          }
                          if (!subject.credits[2]) {
                            result.push({
                              key: 'credits',
                              name: `${item.schedule} ${3} Credits *`,
                              page: `/application/${page}/?check=1#credits_${i+1}_${j+1}_2`
                            });
                          }
                        }
                      }
                      break;
                    case 'Quarters':
                      if (subject?.isFirstSemester) {
                        bFirst = true;
                        if (!subject.grade[0]) {
                          result.push({
                            key: 'grade',
                            name: `${item.schedule} ${1} Grade *`,
                            page: `/application/${page}/?check=1#grade_${i+1}_${j+1}_0`
                          });
                        }
                        if (!subject.credits[0]) {
                          result.push({
                            key: 'credits',
                            name: `${item.schedule} ${1} Credits *`,
                            page: `/application/${page}/?check=1#credits_${i+1}_${j+1}_0`
                          });
                        }
                      }
                      if (!bFirst) {
                        if (subject?.isSecondSemester) {
                          if (!subject.grade[1]) {
                            result.push({
                              key: 'grade',
                              name: `${item.schedule} ${2} Grade *`,
                              page: `/application/${page}/?check=1#grade_${i+1}_${j+1}_1`
                            });
                          }
                          if (!subject.credits[1]) {
                            result.push({
                              key: 'credits',
                              name: `${item.schedule} ${2} Credits *`,
                              page: `/application/${page}/?check=1#credits_${i+1}_${j+1}_1`
                            });
                          }
                        }
                        if (subject?.isThirdSemester) {
                          if (!subject.grade[2]) {
                            result.push({
                              key: 'grade',
                              name: `${item.schedule} ${3} Grade *`,
                              page: `/application/${page}/?check=1#grade_${i+1}_${j+1}_2`
                            });
                          }
                          if (!subject.credits[2]) {
                            result.push({
                              key: 'credits',
                              name: `${item.schedule} ${3} Credits *`,
                              page: `/application/${page}/?check=1#credits_${i+1}_${j+1}_2`
                            });
                          }
                        }
                        if (subject?.isFourthSemester) {
                          if (!subject.grade[3]) {
                            result.push({
                              key: 'grade',
                              name: `${item.schedule} ${4} Grade *`,
                              page: `/application/${page}/?check=1#grade_${i+1}_${j+1}_3`
                            });
                          }
                          if (!subject.credits[3]) {
                            result.push({
                              key: 'credits',
                              name: `${item.schedule} ${4} Credits *`,
                              page: `/application/${page}/?check=1#credits_${i+1}_${j+1}_3`
                            });
                          }
                        }
                      }
                      break;
                  }
                }

                if (subject.schedule_transcript !== 'One grade per term') {
                  if (!subject.finalGrade) {
                    result.push({
                      key: 'finalGrade',
                      name: `Final Grade *`,
                      page: `/application/${page}/?check=1#finalGrade_${i+1}_${j+1}`
                    });
                  }
                  if (subject.finalCredits==='' || subject.finalCredits === null || subject.finalCredits === undefined) {
                    result.push({
                      key: 'finalGrade',
                      name: `Final Credits *`,
                      page: `/application/${page}/?check=1#finalCredits_${i+1}_${j+1}`
                    });
                  }
                }
              }
            }
        }
      }

      if (page==="12th-grade") {
        let courseWorkData:any[] = tryParseJSON(myGradeAndCourse.course_array) || [];
        if (courseWorkData && courseWorkData.length>0) {
          for(let i=0; i<courseWorkData.length; i++) {
            let course = courseWorkData[i];
            //console.log('coursework:', course);
            if (!course?.course_scheduling_system_is_using || course?.course_scheduling_system_is_using ==='') {
              result.push({
                key: 'course_scheduling_system_is_using',
                name: 'Please select the course scheduling system your institution is using.*',
                page: `/application/${page}/?check=1#courseworkSchedulingSystem_${i+1}`
              })
            }
            if (!course?.title || course?.title ==='') {
              result.push({
                key: 'title',
                name: 'Course Title *',
                page: `/application/${page}/?check=1#courseworkTitle_${i+1}`
              })
            }
            if (!course?.level || course?.level ==='') {
              result.push({
                key: 'level',
                name: 'Course Level *',
                page: `/application/${page}/?check=1#courseworkLevel_${i+1}`
              })
            }
            if (!course?.schedule || course?.schedule.length === 0) {
              result.push({
                key: 'schedule',
                name: 'Course Schedule *',
                page: `/application/${page}/?check=1#courseworkSchedule_${i+1}`
              })
            }
            if (!course?.subject || course?.subject === '--') {
              result.push({
                key: 'subject',
                name: 'Subject *',
                page: `/application/${page}/?check=1#courseworkSchedule_${i+1}`
              })
            }
          }
        }
      }
    }
    catch(ex){
      console.log('Error:', ex);
    }

    setFieldsFail(result);
  }

  useEffect(() => {
    checkRequiredFields(data);
  }, [data]);


  const handleClick = (field: any) => {
    history.push(field.page);
    onClose();
  };

  return (
    <Modal show={show} onHide={() => onClose()}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {data && fieldsFail.length > 0 ? (
          <div>
            <h3>These fields are not yet valid, please complete them: </h3>
            <ul style={{ fontSize: '1rem', color: 'red' }}>
              {fieldsFail.map((field, index) => (
                <li key={index} onClick={() => handleClick(field)} style={{ cursor: 'pointer' }}>
                  {field.name}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div>
            <h3>All required fields are filled</h3>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}