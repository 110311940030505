/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import './App.css';
import { HashRouter as Router } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import RouterConfig from 'router';
import Cookies from 'js-cookie';
import { authenticateSettings } from 'components/helper/appConfig';

declare global {
  interface Window {
    Intercom: any;
  }
}

const checkForInactivity = () => {
  //get Expire time from local storage
  const expireTime = localStorage.getItem('expireTime');

  //if Expire time is earlier than current time, remove cookies and logout
  if (expireTime && expireTime < Date.now().toString()) {
    handleRemoveCookies();
    window.location.href = '/';
  }
};

//function  to update Expire time in local storage
const updateExpireTime = () => {
  //set Expire time to 10 phut (600 giay) from now (1 giây = 1000 mili giây)
  const expireTime = Date.now() + 600000;
  localStorage.setItem('expireTime', expireTime.toString());
};

const handleRemoveCookies = () => {
  Cookies.remove(authenticateSettings.tokenName);
  Cookies.remove('userId');
  localStorage.removeItem('isDashBoardLoaded');
};

function App() {
  React.useEffect(() => {
    if (window.location?.hostname !== 'localhost' && window.location?.hostname !== '3.14.196.144') {
      Userpilot.initialize(process.env.REACT_APP_USERPILOTOKEN || 'STG-NX-9ce94dc6');
    }

    //set Interval to check for inactivity every 10 minutes
    const interval = setInterval(() => {
      //Check for inactivity every 10 minutes
      let isDashBoardLoaded = localStorage.getItem('isDashBoardLoaded');
      if (isDashBoardLoaded === 'true') {
        checkForInactivity();
      }
    }, 600000);

    //Clear interval on unmount
    return () => {
      clearInterval(interval);
    };

  }, []);

  React.useEffect(() => {

    //set Interval to check for inactivity every 10 minutes
    updateExpireTime();

    //set event listener to update Expire time on user activity
    window.addEventListener('click', updateExpireTime);
    window.addEventListener('mousemove', updateExpireTime);
    window.addEventListener('keypress', updateExpireTime);
    window.addEventListener('scroll', updateExpireTime);

    //clear interval on unmount
    return () => {
      window.removeEventListener('click', updateExpireTime);
      window.removeEventListener('mousemove', updateExpireTime);
      window.removeEventListener('keypress', updateExpireTime);
      window.removeEventListener('scroll', updateExpireTime);
    };
  }, []);

  const handleRemoveCookiesWithSession = () => {
    const isSessionStorage = sessionStorage.getItem('session_student_id');
    const accessToken = Cookies.get(authenticateSettings.tokenName);
    const userId = Cookies.get('userId');
    
    // Only remove cookies if there's no session AND we're not on the login page
    if (!isSessionStorage && accessToken && userId && !window.location.hash.includes('/login')) {
      handleRemoveCookies();
    }
  };

  React.useEffect(() => {
    handleRemoveCookiesWithSession();
  }, []);

  return (
    <Router>
      <RouterConfig />
    </Router>
  );
}

export default App;
