import { setArrayLength } from 'components/helper/helper';
import SelectInput from 'components/input/SelectInput';
import YesNoSwitch from 'components/input/YesNoSwitch';
import React from 'react';
import { Row } from 'react-bootstrap';
import MilitaryConfig from './MilitaryConfig';
import CheckboxInput from 'components/input/CheckboxInput';
import GroupRadioButton from 'components/input/GroupRadioButton';

interface Props {
  formik: any;
  militaryExperience: any[];
  setMilitaryExperience: (values: any) => void;
  check: any;
}

const Military = (props: Props) => {
  const { check } = props;
  const status = [
    { value: 'None' },
    { value: 'Currently Serving' },
    { value: 'Previously Served' },
    { value: 'Current Dependent' },
  ];

  React.useEffect(() => {
    if (props.formik.values.us_armed_forces_status === 'None') {
      props.formik.setFieldValue('is_dependent_us_military', 'false');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.formik.values.us_armed_forces_status]);
  React.useEffect(() => {
    props.setMilitaryExperience(
      setArrayLength(props.militaryExperience ?? [], props.formik.values.number_military_experience)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.formik.values.number_military_experience]);

  return (
    <Row className="m-0">
      <div className="fs-5 p-1 mt-3" style={{ marginLeft: '-4px' }}>
        Military Affilliation
      </div>
      <SelectInput
        className="common-coallition-flag"
        form={props.formik}
        option={status}
        mdCol={'6'}
        label={'U.S. Armed Forces Status*'}
        name={'us_armed_forces_status'}
        values={props.formik.values.us_armed_forces_status}
        onChange={props.formik.handleChange}
        isMissing={!props.formik.values.us_armed_forces_status && check ? true : false}
        feedback={
          props.formik.touched.us_armed_forces_status &&
          props.formik.errors.us_armed_forces_status && (
            <span className="text-danger">{props.formik.errors.us_armed_forces_status}</span>
          )
        }
      />

      {props.formik.values.us_armed_forces_status !== 'None' && (
        <YesNoSwitch
          label="Are you a dependent of a U.S. military veteran or service member?"
          disabled={props.formik.values.us_armed_forces_status === 'None'}
          name="is_dependent_us_military"
          values={props.formik.values.is_dependent_us_military}
          onChange={props.formik.handleChange}
        />
      )}

      <div className="my-2" />
      {(props.formik.values.us_armed_forces_status === 'Currently Serving' ||
        props.formik.values.us_armed_forces_status === 'Previously Served') && (
        <>
          <div className="fs-5 p-1 fw-bold my-2">Military experience</div>
          <GroupRadioButton
            label="Anticipated status at the time you enroll*"
            mdCol={12}
            options={[
              { value: 'On Active Duty' },
              { value: 'Veteran of U.S. Armed Forces' },
              { value: 'U.S. Reserves or National Guard' },
            ]}
            name="anticipated_status"
            values={props.formik.values.anticipated_status}
            onChange={props.formik.handleChange}
            isMissing={!props.formik.values.anticipated_status && check ? true : false}
          />
          {props.formik.values.anticipated_status === 'On Active Duty' && (
            <YesNoSwitch
              label="Are you permanently stationed in California? *"
              name="demographics_additional_data.isPermanentlyStationed"
              values={props.formik.values.demographics_additional_data?.isPermanentlyStationed}
              isMissing={
                !props.formik.values.demographics_additional_data?.isPermanentlyStationed && check
                  ? true
                  : false
              }
              onChange={props.formik.handleChange}
            />
          )}
          <SelectInput
            form={props.formik}
            name="number_military_experience"
            values={props.formik.values.number_military_experience ?? 0}
            onChange={props.formik.handleChange}
            label="Please specify number of Military experience you have*"
            option={[{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }]}
            isMissing={!props.formik.values.number_military_experience && check ? true : false}
          />
          {(props.militaryExperience || []).map((item, index) => (
            <MilitaryConfig
              index={index}
              key={index}
              item={item}
              handleSave={(values) => {
                const newArray = [...props.militaryExperience];
                newArray.splice(index, 1, values);
                props.setMilitaryExperience(newArray);
              }}
            />
          ))}
        </>
      )}
      {props.formik.values.us_armed_forces_status === 'Current Dependent' && (
        <>
          <SelectInput
            form={props.formik}
            name="demographics_additional_data.statusOfPersonYouAreDependance"
            values={
              props.formik.values.demographics_additional_data?.statusOfPersonYouAreDependance
            }
            onChange={props.formik.handleChange}
            label="What is the status of the person you are a dependent of who is currently serving or has previously served?"
            option={['Active Duty', 'National Guardsman', 'Reservist', 'Veteran', 'Clear']}
          />
          <YesNoSwitch
            name={'demographics_additional_data.is_US_military_service_on_active_duty'}
            values={
              props.formik.values.demographics_additional_data
                ?.is_US_military_service_on_active_duty
            }
            onChange={props.formik.handleChange}
            label="Is the U.S. military service member on active duty? *"
            isMissing={
              !props.formik.values.demographics_additional_data
                ?.is_US_military_service_on_active_duty && check
                ? true
                : false
            }
          />
          {props.formik.values.demographics_additional_data
            ?.is_US_military_service_on_active_duty === 'true' && (
            <YesNoSwitch
              name={
                'demographics_additional_data.is_US_military_service_permanently_stationed_in_California'
              }
              values={
                props.formik.values.demographics_additional_data
                  ?.is_US_military_service_permanently_stationed_in_California
              }
              onChange={props.formik.handleChange}
              label="Is the U.S. military service member permanently stationed in California? *"
              isMissing={
                !props.formik.values.demographics_additional_data
                  ?.is_US_military_service_permanently_stationed_in_California && check
                  ? true
                  : false
              }
            />
          )}
          <CheckboxInput
            name="demographics_additional_data.is_plan_to_submit_us_military_transcript"
            values={
              props.formik.values.demographics_additional_data
                ?.is_plan_to_submit_us_military_transcript
            }
            onChange={props.formik.handleChange}
            label="Check if you plan to submit an official U.S. military transcript (AARTS, SMART, JST or CGI)?"
          />
        </>
      )}
    </Row>
  );
};

export default Military;
