import { useEffect, useState, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';
import {
  checkHouseholdCondition,
  checkParent1Condition,
  checkParent2Condition,
  checkSiblingCondition,
  checkStepParentCondition,
} from 'screen/common/helper/checkFieldsMissingCondition';

interface ModalCheckFieldProps {
  show?: boolean;
  onClose: () => void;
  data: any;
  page: string;
}

export const ModalCheckFamilyField = (props: ModalCheckFieldProps) => {
  const { show, onClose, data, page } = props;
  const [filedsFail, setFieldsFail] = useState<any[]>([]);

  const requiredFields = useMemo(() => {
    switch (page) {
      case 'household': {
        return checkHouseholdCondition(data);
      }
      case 'parent-1': {
        return checkParent1Condition(data);
      }
      case 'parent-2': {
        return checkParent2Condition(data);
      }
      case 'siblings': {
        return checkSiblingCondition(data);
      }

      case 'step-parent': {
        return checkStepParentCondition(data);
      }

      default:
        return [];
    }
  }, [data]);

  const checkRequiredFields = (data: any) => {
    const result: any = [];

    if (page === 'household') {
      if (data?.who_in_household_array) {
        const allFalse = Object.values(data?.who_in_household_array).some(
          (item: any) => item === true
        );
        if (!allFalse) {
          result.push({
            key: 'who_in_household_array',
            name: 'Who is in your household? (select all that apply)',
            page: '/application/household?check=1',
          });
        }
      }

      if (
        data?.highest_level_education_parent === 'Select' ||
        !data?.highest_level_education_parent
      ) {
        result.push({
          key: 'highest_level_education_parent',
          name: 'What is the highest level of education earned by any of your parents?',
          page: '/application/household?check=1',
        });
      }

      if (
        data?.csu_info?.parent_1_highest_level_education === 'Select' ||
        !data?.csu_info?.parent_1_highest_level_education
      ) {
        result.push({
          key: 'csu_info.parent_1_highest_level_education',
          name: 'What is your Parent/Guardian #1 highest level of education?',
          page: '/application/household?check=1',
        });
      }

      if (
        data?.csu_info?.parent_2_highest_level_education === 'Select' ||
        !data?.csu_info?.parent_2_highest_level_education
      ) {
        result.push({
          key: 'csu_info.parent_2_highest_level_education',
          name: 'What is your Parent/Guardian #2 highest level of education?',
          page: '/application/household?check=1',
        });
      }
    }

    if (page === 'siblings' && data?.number_of_siblings !== 0) {
      if (data?.siblings_array && data?.siblings_array.length > 0) {
        data.siblings_array.forEach((langObj: any, index: any) => {
          if (!langObj.firstname) {
            result.push({
              key: `firstname-${index + 1}`,
              name: `Sibling ${index + 1} First Name`,
              page: '/application/siblings?check=1',
            });
          }

          if (!langObj.lastName) {
            result.push({
              key: `lastName-${index + 1}`,
              name: `Sibling ${index + 1} Last Name`,
              page: '/application/siblings?check=1',
            });
          }

          if (!langObj.age) {
            result.push({
              key: `age-${index + 1}`,
              name: `Sibling ${index + 1} Age`,
              page: '/application/siblings?check=1',
            });
          }

          if (!langObj.relationship) {
            result.push({
              key: `relationship-${index + 1}`,
              name: `Sibling ${index + 1} Relationship`,
              page: '/application/siblings?check=1',
            });
          }

          if (!langObj.educationLevel || langObj.educationLevel === 'None') {
            result.push({
              key: `educationLevel-${index + 1}`,
              name: `Sibling ${index + 1} Education Level`,
              page: '/application/siblings?check=1',
            });
          }
        });
      }
    }

    
    requiredFields.forEach((field) => {
      const path = field.key;
      let value = data;
      let valid = true;

      try {
        if (!path.includes('.') && !path.includes('[')) {
          // Trường hợp đơn giản nhất - không có cấu trúc lồng nhau
          if (!data[path] && typeof data[path] !== 'boolean') {
            result.push(field);
          }
          return; // Tiếp tục với field tiếp theo
        }

        // Regex để xử lý cả mẫu như: a.b[0].c
        const parts = path.match(/\w+|\[\d+\]/g);

        if (parts) {
          for (const part of parts) {
            if (part.startsWith('[') && part.endsWith(']')) {
              // Đây là một phần tử mảng
              const index = parseInt(part.slice(1, -1));
              if (!value || !Array.isArray(value) || value.length <= index) {
                valid = false;
                break;
              }
              value = value[index];
            } else {
              // Đây là thuộc tính thông thường
              if (!value || typeof value !== 'object') {
                valid = false;
                break;
              }
              value = value[part];
            }
          }
        }

        if (!valid || value === undefined || value === null || value === '') {
          if (typeof value !== 'boolean') {
            result.push(field);
          }
        }
      } catch (error) {
        result.push(field);
      }
    });

    setFieldsFail(result);
  };

  useEffect(() => {
    checkRequiredFields(data);
  }, [data, requiredFields, page]);

  const history = useHistory();

  const handleClick = (field: any) => {
    history.push(field.page);
    onClose();
  };

  return (
    <Modal show={show} onHide={() => onClose()}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {data && filedsFail.length > 0 ? (
          <div>
            <h3>These fields are not yet valid, please complete them: </h3>
            <ul style={{ fontSize: '1rem', color: 'red' }}>
              {filedsFail.map((field, index) => (
                <li key={index} onClick={() => handleClick(field)} style={{ cursor: 'pointer' }}>
                  {field.name}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div>
            <h3>All required fields are filled</h3>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
