import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Row, Button } from 'react-bootstrap';
import SelectInput from 'components/input/SelectInput';
import { CBOData } from 'components/helper/appConfig';
import TextInput from 'components/input/textInput';
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Props {
  handleRemove: any;
  handleSave: (value: any) => void;
  indexText: string;
  item: any;
  check?: any;
}

const CBOConfig = (props: Props) => {
  const { check } = props;
  const [expanded, setExpanded] = React.useState(false);
  const [organization, setOrganization] = React.useState('');
  const [otherOrganization, setOtherOrganization] = React.useState('');
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  React.useEffect(() => {
    if (props.item) {
      setOrganization(props.item.organization);
      setOtherOrganization(props.item.otherOrganization);
    }
  }, [props.item]);
  return (
    <Row className="w-100">
      <Col xs={12} className="p-2">
        <Card className="w-100">
          <CardActions
            disableSpacing
            onClick={handleExpandClick}
            sx={{
              backgroundColor: expanded ? '#AAEBF7' : 'none',
              borderBottom: expanded ? '2px solid #4D88F0' : 'none',
            }}
          >
            <div className="w-100 d-flex justify-content-between">
              <span className="p-1">
                {organization && organization !== 'Other' ? organization : ``}
                {organization === 'Other' && otherOrganization}
              </span>
              <strong className="text-field-light">{props.indexText}</strong>
            </div>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Row>
                <SelectInput
                  values={organization}
                  option={CBOData}
                  mdCol={'12'}
                  name="organization"
                  label={'Organization from which you received the most help*'}
                  onChange={(e) => setOrganization(e.target.value)}
                  isMissing={!organization && check}
                />
                {organization === 'Other' && (
                  <TextInput
                    name="otherOrganization"
                    values={otherOrganization}
                    mdCol={'12'}
                    label={'Specify other organization'}
                    onChange={(e) => setOtherOrganization(e.target.value)}
                  />
                )}
                <Col xs={6} md={9} />

                <Col xs={6} md={3} className="p-3">
                  <Button
                    className="w-100"
                    onClick={() => {
                      props.handleSave({
                        organization,
                        otherOrganization,
                      });
                      setExpanded(false);
                    }}
                  >
                    Save Info
                  </Button>
                </Col>
              </Row>
            </CardContent>
          </Collapse>
        </Card>
      </Col>
    </Row>
  );
};

export default CBOConfig;
